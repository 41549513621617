import { Box, Container, Modal,Button, Stack, TextField, Input, Checkbox } from '@mui/material'
import React, {useState, useContext, useEffect} from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { AppContext } from "../context/AppProvider";
import * as API from '../utils/APICalls'
const default_Shop = require('../defaults/StorefrontDefault.json')

const CreateStorefontModal = (props) => {
     const { updateAppData, modalOpen ,userData, appData} = useContext(AppContext);
     const [ createModalOpen, setCreateModalOpen ] = useState(false)
    const [ tempShop, setTempShop ] = useState()
     const [ sameName, setSameName ] = useState(false)
     const fieldList = ['operating_name','campaign_name','url']
     const contactList = ['owner_email','support_email','url']

     const baseShop = {
        owner: userData?._id,
        admin: [userData?._id],
        employees: [userData?._id],
        owner_email: userData?.email ,
        support_email: userData?.email,
        operating_name: ``,
        campaign_name: '',
        url: ``,
        config: {
            headerHeight:"25vh",
            header_pic: "https://res.cloudinary.com/dzxzdsnha/image/upload/v1714322177/ShirtHeaderImage-Crop.jpg",
            bgYPos:0,
            avatar_pic: "https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg",
            aboutHeight: "25vh",
            tagline:'',
            aboutContent:"<div style='position:relative; padding:0.5rem'> <img style='float: left; width: 1.5rem; height:auto; left: 1rem;' src= 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1698192201/Parallaxr_Distressed_White_BG_kvsoiz.png' /> brand focused on actively seeing and embracing, through multiple perspectives.<br/><br/> e.g., <em>Such as it may appear, and those that may exist.<em/></div>",
            productsHeight:"fit-content",
            featured_items: [],
            featured_collection:""
            }
     }
     const initShop = () =>{
        setTempShop(baseShop)
     }

     const handleShopCreate = async() =>{
        try{

            const res = await API.createShop(tempShop)
            if(res){
                window.location.reload()
            }
            

        }catch(err){console.log('Shop creation errror',err)}

     }

      useEffect(()=>{
        if(!tempShop){
            initShop()
        }
      },[])
      useEffect(()=>{
       
      },[tempShop])
    
  return (
    <>
    <Modal 
        open={createModalOpen} 
        onClose={()=>setCreateModalOpen(!createModalOpen)}
        >
            <Container 
            className='center'
            sx={{p:1, 
                margin:'auto',
            fontFamily:'Gilroy, sans-serif',
            width:'80%', height:'60%',
            }}>
                <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center', position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    height:'fit-content',
                    minHeight:'50vh',
                    overflow: 'hidden',
                    overflowY:'scroll',
                    maxHeight:'70vh',
                    // maxHeight:'90vh',
                    maxWidth:'70vw',
                    margin:'auto',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    borderRadius:'10px',
                    boxShadow: 24,
                    p: 1,}}>
                        <CloseIcon onClick={()=>setCreateModalOpen(!createModalOpen)}  color='#000'sx={{position:'absolute',right:'0.5rem',top:'0.5rem'}}/>
                
                <Stack sx={{gap:'0.75rem', alignItems:'center', width:'100%', padding:'0.5rem'}}>
                    <h2>
                        Getting Started:
                    </h2>
                    <label>
                        <p style={{marginBottom:'0.5rem'}}>The name of your entity</p>
                        <TextField 
                            name="operating_name"
                            required
                            fullWidth
                            id="operating_name"
                            label="operating name"
                            autoFocus
                            onChange={(e)=>{
                                // baseShop[e?.target?.id] = e.target.value
                                // console.log('baseShop,', baseShop)
                                setTempShop({
                                    ...tempShop,
                                    [e?.target?.id]:  e.target.value
                                })
                            }}
                        />
                    </label>

                    <label>

                    <p style={{marginBottom:'0.5rem'}}>The name of this venture</p>
                    <TextField
                        name="campaign_name"
                        required
                        fullWidth
                        id="campaign_name"
                        label="campaign name"
                        value={ sameName ? tempShop?.operating_name:tempShop?.campaign_name}
                        autoFocus
                        onChange={(e)=>{
                            // baseShop[e?.target?.id] = e.target.value
                            // console.log('baseShop,', baseShop)
                            setTempShop({
                                    ...tempShop,
                                    [e?.target?.id]:  e.target.value
                                })
                        }}
                    />
                    </label>
                    <span style={{display:'flex', gap:'0.5rem', alignItems: 'center'}}>

                    <Checkbox type="checkbox" 
                    // checked={}
                    onChange={(e)=>{
                            setSameName(!sameName)
                            if(sameName){
                                setTempShop({
                                    ...tempShop,
                                    ['campaign_name']: tempShop?.operating_name
                                })
                            }else{

                            }
                            
                            console.log('baseShop:', baseShop)
                    }}/> ( check if the same )
                    </span>
                    <Button variant='outlined' onClick={()=>{
                        // console.log('tempShop:', tempShop)
                        handleShopCreate()

                    }}>
                        Create
                    </Button>

                </Stack>



                </Box>
            </Container>
        </Modal>
               <Button variant='outlined'
        onClick={()=>{setCreateModalOpen(!createModalOpen)}}
         sx={{
          // position:'absolute',
          // left:50
        }}>
          Create Storefront

        </Button>
    </>
  )
}

export default CreateStorefontModal