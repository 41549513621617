import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Tooltip } from '@mui/material'
import React, {useState} from 'react'
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import CommentModal from './CommentModal';

const AddComment = (props) => {

   const [ commentClick, setCommentClick ] = useState(false)
  
  return (
    <Tooltip title='leave a comment' //open={!commentClick}
      className='blink'
    >
      <Box
      size='small'
        onClick={()=>{
          setCommentClick(true)
        }}
      sx={{cursor:'pointer',
        // fontSize:'0.75rem',
        color:'#868686',
        color:'#FFF',
        width:'2rem',
        height:'2rem',
        marginRight:'0.5rem', 
        borderRadius:'50px 50px 50px  0',
        justifyContent:'center', 
        alignItems:'center',alignContent:'center',
        textAlign:'center',
        // padding:'0.5rem',
         zIndex:999,
        // backgroundColor:'#FFF',
        backgroundColor:'#ff6600',
        // backgroundColor:'rgba( 0 , 255, 1,0.5)',
        // backgroundColor:'rgba(144, 254, 195,0.5)',
        boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
        // border:'2px solid #757575',
        '&:hover': {background:'rgba(144, 254, 195,0.7)'},
        '&:active': {boxShadow:'inset 0 0 3px 2px rgba( 0, 0, 0, 0.2)'},
    
      }}>
          {/* &nbsp; */}
        {/* comment */}
        <MapsUgcIcon className='button-woddle' 
        style={{fontSize:'1.25rem',alignSelf:'center',margin:'auto',justifyContent:'center',margin:'auto'}}/>
              {/* <FontAwesomeIcon  className='button-woddle'
              icon={'faCommentMedical'} /> */}
      </Box>
    </Tooltip>
   

  )
}

export default AddComment