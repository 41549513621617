import React from 'react'

import { Typography, Box, Grid } from '@mui/material'
import { Route, Routes, BrowserRouter , useParams, Link} from 'react-router-dom';
import Copyright from './Copyright';


const Congratulations = (props) => {
    // const owner_email = props.owner_email
     const {owner_email} = useParams()
    console.log(props.owner_email)
  return (
    <Box>

    <Grid container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '90vh' }}>
       <Grid  item sx={{display:'flex',flexDirection:'column',gap:'1rem', 
            alignItems:'center',p: 2, border: '1px dashed grey', 
            justifyContent:'center', borderRadius:'10px',
            width: '90%', boxShadow:'0px 0px 8px 3px rgba(0,0,0,0.2)'}}>
        <Typography component="h1" variant="h5" >
            🥳 <u>Congratulations</u>! 🥳
        </Typography>
        <Typography component="h2"  >
            Please check your email for confirmation 
        </Typography>
        <Typography component="h1" variant="h5" >
            Thank You So Much!
        </Typography>
        <Typography component="p"  sx={{fontSize:'0.75rem'}}>
             *(any complication please contact &nbsp; 
             <Link href={owner_email} >{owner_email}</Link> )
        </Typography>
       </Grid>
            
    </Grid>
                <Copyright />
    </Box>
  )
}

export default Congratulations