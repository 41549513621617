import React, {useState, useContext, useEffect } from "react";
// import SortableList from 'react-sortable-list'
import { ReactSortable } from "react-sortablejs";
import axios from 'axios'


import { AppContext } from "../context/AppProvider";
import PersonaFieldComponent from "./PersonaFieldComponent"
import Modal from './Modal'
import PersonaCard from './PersonaCard'
import PersonaCardBack from './PersonaCardBack'
import QROptions from './QROptions'
import * as API from '../utils/APICalls'

import EnvRoute from '../middleware/envRoute';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Container, Divider, Stack, Typography } from "@mui/material";

const ENDPOINT = EnvRoute('prod'); 


const SinglePersona = (props) => {
    //   const ENDPOINT = 'http://localhost:5003/api'
    // const ENDPOINT = 'https://qaura-api.onrender.com/api'
    const { appData,userData, updateAppData,modalOpen,setModalOpen,personas, 
        currentPersona, setCurrentPersona, setActionError} = useContext(AppContext);
    const [ isVcard, setIsVcard ] = useState(currentPersona?.isVcard)
    const [ isProfile, setIsProfile ] = useState(currentPersona?.isProfile)
    const [ settings, setSettings ] = useState(false)
    const [ isFront, setIsFront ] = useState(true)
    const [ showList, setShowList ] = useState(false)
    const [ cardView, setCardView ] = useState('')
    const [ linkQR, seLinkQR ] = useState(false )
    const [ linkListState, setLinkListState ] = useState(currentPersona?.links?.data)
    const [ isListSaved, setIsListSaved ] = useState(true)
    const [ orderList, setOrderList ] = useState(false)
    let info = {...currentPersona}

    
    const handleListOrderChange = (newList) =>{
        setIsListSaved(false)
         setCurrentPersona({
            ...currentPersona,
            links:{
               on: currentPersona?.links?.on,
               data: [...linkListState]
            }
        })
        updateAppData({
            ...appData,
            currentPersona
        })

        console.log('original:', currentPersona?.links?.data)
        console.log('linkListState:', linkListState)
    }

     const addLink = () =>{
       let links = currentPersona?.links?.data
        links?.push({on:true,data:''})
        console.log("links: ", links)
         setCurrentPersona({
            ...currentPersona,
            links:{
                on: currentPersona?.links?.on?currentPersona?.links?.on:true ,
                data: links
            }
        })
        updateAppData({...appData,currentPersona})
        setLinkListState(currentPersona?.links?.data)
    }

    const checkListChange =() =>{
        return [...currentPersona?.links?.data ]!= [...linkListState]? true: false;
    }

    const toggleOn = async (e) =>{
        let { value } = e.target
        // setIsVcard(!currentPersona?.isVcard)
        
            // updateAppData({...appData,...currentPersona})
        try{
            const data = await API.updatePersona(currentPersona)
            setCurrentPersona({...data})
            updateAppData({...appData,currentPersona})
            console.log('[vCard]: currentPersona', data)
        }catch(err){console.log(err)}
    }

    const unsetProfiles = async () =>{
        
        try{
             personas?.forEach(async(p,i)=>{
                if(p?._id != currentPersona?._id){
                    const data = await API.updatePersona({
                        ...p,
                        isProfile: false
                    })
                }
                
            })
        }catch(err){console.log(err)}
        }

   const handleProfileSelection = async(e)=>{
        await unsetProfiles()        
        try{
            let profVal = !currentPersona?.isProfile
            const data = await API.updatePersona({
                ...currentPersona,
                isProfile: profVal
            })
            if (data){
                await setCurrentPersona({
                    ...data
                })//!currentPersona?.isVcard})
                await updateAppData({...appData,currentPersona})
                console.log('[Set as Profile]: currentPersona', data)
            }else{console.log('unable to update')}
            // await setIsProfile(!isProfile)

    }catch(err){console.log(err)}

    }
    useEffect(()=>{
        // console.log('currentPersona :',currentPersona)
            setShowList(false)
        },[currentPersona])
   
    useEffect(()=>{
        // console.log('currentPersona :',currentPersona)
            setShowList(false)
        },[appData])
    useEffect(()=>{
     
        },[])
    useEffect(()=>{
     
        },[linkListState])
    useEffect(()=>{
        setLinkListState(currentPersona?.links?.data)
        },[currentPersona])
 
  return (
    <>
    {currentPersona && 

    <div style={{
        position:'relative',
        display: 'flex', 
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        // width: '100%',
        border: '2px solid #000000',
        borderRadius:'10px',
        backgroundColor:'rgba(255,255,255, 0.3)',
        boxShadow: 'inset 0px 0px 7px 5px rgba(0,0,0,0.3)',
        backdropFilter:'blur(3px)',
        padding: '0.5rem',
        height: '95%',
        width: '95%',
        margin: 'auto',
        fontSize: '0.75rem'

    }}>
         {/* { appData.modalOpen &&
                 <Modal confirm={true}>
                   <h1 style={{color:"#FFFFFF", marginBottom:'0.5rem'}}>{appData?.modalView}</h1>
                   { appData?.modalView==='comfirm-delete' && (
                   <div style={{color:"#00FF00"}}>
                    Would you like to delete: {currentPersona?.personaTitle}
                   </div>) }
                  
                 </Modal>} */}
                 
         {/* <button className="button save-button blink" 
                    id="save-button" 
                    onClick={() => {
                        updatePersonaDb(currentPersona)
                         updateAppData({
                    modalOpen:false,
                    modalView:''
                })
                    }}
                    style={{padding:'0.25rem 0.25rem 0', position:'absolute', 
                    right:'0.5rem', top:'0.5rem', borderRadius:'5px', 
                    backgroundColor: 'rgba(61, 255, 158, 0.628)' }}
                    >
                <img src="/assets/save.svg" style={{height:'25px'}} alt="save assset info"/>
            </button> */}
            <span className="button-accent-hover"
            style={{position:'absolute', top:'0.5rem',right:'0.5rem',display: 'flex', alignItems:'center', gap:'0.5rem'}}> 
            {/* <b><u>link persona to asset </u> </b> */}
               <div  
               style={{
                
                display: 'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                gap:'0.5rem',
                   width:'fit-content',
                    cursor: 'pointer',
                    padding: '0 0.125rem',
                    borderRadius: '5px',
                    border: '2px solid #FFFFFF',
                    boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                    padding: '0.5rem',
                    backgroundColor: showList?'rgba(61, 255, 158, 0.7)':'rgba(61, 255, 158, 0.628)',
                    backdropFilter: showList?'blur(6px)':'',
                    zIndex:'9999'
                }} 
                    onClick={()=> {
                if(!showList){

                    setShowList(true)
                }}}
                // setShowList(!showList)
            
            >
                <div style={{position:'relative',borderBottom:'2px solid #FFFFFF',paddingBottom:'0.25rem', width:'100%'}}>

                    link to QR
                    {showList&&<img  width='10rem'
                    className="button-accent-hover"
                    onClick={()=>{

                        setShowList(!showList)
                    }}
                    style={{cursor:'pointer',position:'absolute', right:'0.125rem', top:'0.125rem'}}
                    src='/assets/close.svg' alt='close link to QR options'/>}
                </div>
                
                <img width='55rem' src='/assets/LinkToQR.svg' alt='link to asset'/>
        {showList &&  <QROptions view='edit-persona' field='persona'/>}
            </div>
            </span>

         <div
            style={{width:'100%',display:'flex',flexDirection: 'column', gap:'0.75rem', 
            justifyContent:'center',margin: 'auto'}}>
             {/* {currentPersona && ,*/}

             <span style={{display: 'flex', alignItems:'center', 
             justifyContent:'center',padding:'0.25rem',
             gap:'0.5rem',margin:'auto',height:'2.5rem',fontSize:'1rem'}}> 
            
                <div style={{
                    display:'flex',
                    flexDirection:'column',
                    cursor: 'pointer',
                    padding: '0.125rem 0.25rem',
                    borderRadius: '5px',
                    border: '2px solid #FFFFFF',
                    boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                    backgroundColor: currentPersona?.isProfile?'rgba(61, 255, 158, 0.628)':'rgba(253, 0, 0, 0.628)',
                    color:currentPersona?.isProfile?"#000000":'#ffffff'
                }}
                    // name={name}
                    value={currentPersona?.isProfile}
                    onClick={handleProfileSelection}
                >
                {currentPersona?.isProfile? 'on': 'off'}
                <b><u> {currentPersona?.isProfile?'Current ':'Set as '}profile</u> </b> 
            </div>
    
            </span> 

            <span style={{display: 'flex', alignItems:'center', gap:'0.5rem'}}> 
           
                <div style={{
                     display:'flex',
                     flexDirection:'column',
                    cursor: 'pointer',
                    padding: '0.125rem 0.25rem',
                    borderRadius: '5px',
                    border: '2px solid #FFFFFF',
                    boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                    backgroundColor: currentPersona?.isVcard?'rgba(61, 255, 158, 0.628)':'rgba(253, 0, 0, 0.628)',
                    color:currentPersona?.isVcard?"#000000":'#ffffff'
                }}
                    // name={name}
                    value={currentPersona?.isVcard}
                    onClick={async(e)=>{
                        setIsVcard(!isVcard)
                        setCurrentPersona({
                    ...currentPersona,
                        isVcard:isVcard})//!currentPersona?.isVcard})
                        updateAppData({...appData,currentPersona})
                       
                        const data = await API.updatePersona({...currentPersona,
                        isVcard:isVcard})
                      
                        console.log('[vCard]: currentPersona', data)
                   
                    }}
                >
                    
                {currentPersona?.isVcard? 'on': 'off'}
                <b><u> {!currentPersona?.isVcard? 'make ':''} shareable (vCard) </u> </b> 
            </div>
    
            </span> 
             {/* } */}
         </div>

        <Box   Box className='button-accent-hover'
                sx={{margin:'1rem auto',cursor:'pointer',border:'2px solid #000', backgroundColor:'rgba( 255,255,255,1 )',
                        borderRadius:'10px',padding:'0.5rem', boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.3)',
                        justifyContent:'center'}}  onClick={()=>{
                if(cardView !== 'edit'){setCardView('edit')}
                    else{setCardView('')}
            }}>

            {currentPersona?.isLocked ?<LockIcon sx={{fontSize:'3rem',margin:'auto',color: currentPersona?.isLocked ? 'limegreen':'red'}} />
                    :<LockOpenIcon sx={{fontSize:'3rem',margin:'auto',color: currentPersona?.isLocked ? 'limegreen':'red'}}/> }
        </Box>
         <div style={{margin:'0.75rem auto', width:'100%'}}>
             <div style={{display:'flex', flexDirection:'row', width:'30%',justifyContent:'center', margin:'0.75rem  auto',gap:'0.5rem'}}>
             <img className="settings-gear" width='35rem' src='/assets/SettingsGear.svg' alt="additional persona settings"
                    style={{
                    //     position:'absolute',
                    // right:'0.5rem',
                    // bottom:'0.5rem',

                    cursor:'pointer'}}
                    onClick={()=>{
                    //   setSettings(!settings)
                    if(cardView !== 'edit'){setCardView('edit')}
                    else{setCardView('')}
                    }}
                    />
                     <img className="button-woddle" width='30rem' src='/assets/PaintBrushMajor.svg' alt="view persona as contact"
                    style={{

                    cursor:'pointer'}}
                    onClick={()=>{
                    //   setSettings(!settings)
                        if(cardView !== 'style'){setCardView('style')}
                    else{setCardView('')}
            
                    }}
                    />
                     <img className="button-woddle" width='45rem' src='/assets/eye.svg' alt="view persona as contact"
                    style={{

                    cursor:'pointer'}}
                    onClick={()=>{
                    //   setSettings(!settings)
                     updateAppData({
                    ...appData,
                    modalOpen: true,
                    modalView:'persona-card'
                })
                    }}
                    />
            </div>
            
            {isFront?<PersonaCard view={cardView} persona={currentPersona}/>:<PersonaCardBack view={cardView} persona={currentPersona} /> }
           {/* <PersonaCard view={cardView}/> */}
            
           

         </div>
{/* {cardView === 'style' && <label class="switch">
  <input type="checkbox" value={isFront} onChange={()=>{setIsFront(!isFront)}}/>
  <span class="slider round">

  </span>
</label>} */}
{cardView === 'edit' &&
        <div style={{
                display: 'flex', 
                flexDirection:'column', 
                alignItems: 'left',
                width:'100%', 
                height:
                '100%',
                overflow:'hidden', 
                overflowY:'scroll', 
                padding:'0.5rem', 
                flex:1}}> 
            
           <Stack className='button-accent-hover'
                    sx={{margin:'1rem auto', gap:'0.5rem',border:'2px solid #000',
                    borderRadius:'10px',padding:'0.5rem', boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.3)',
                    justifyContent:'center', 
                    cursor:'pointer', 
                    textAlign:'center',
                    width:'150px',height:'110px'
                    }}
                onClick={async()=>{
                    let val = currentPersona?.isLocked
                    try{
                    const res = await API.updatePersona({
                        ...currentPersona,
                        isLocked: !val
                    })
                    if(res){
                        setCurrentPersona(res)
                        updateAppData({
                            ...appData,
                            currentPersona
                        })
                    }else{
                        setActionError('unable to toggle QR lock')
                    }
                    }catch(err){console.log('QR lock toggle error')}
                }}
                >
                {currentPersona?.isLocked ?<LockIcon sx={{margin:'auto',color: currentPersona?.isLocked ? 'limegreen':'red'}} />
                :<LockOpenIcon sx={{margin:'auto',color: currentPersona?.isLocked ? 'limegreen':'red'}}/> }
                <Divider />

                <Stack sx={{width:'100%',gap:'0'}}>
                    <Typography>
                    Persona is
                    </Typography>
                    <Typography sx={{fontWeight:'bold',color: currentPersona?.isLocked ? 'limegreen':'red'}}>
                    {!currentPersona?.isLocked ?'un':'' }locked
                    </Typography>
                    <Typography sx={{fontSize:'0.8rem'}}>
                    ( click to {currentPersona?.isLocked ?'un':'' }lock )
                    </Typography>
                </Stack>
                
                </Stack>  
            { Object.keys(currentPersona).map((key,i)=>{ 
                // let name = item?.keys()[i] 
                // console.log("currentPersona[key]: ",key ,currentPersona[key])
                // console.log("currentPersona?.links?.data.length ",currentPersona?.links?.data.length )
                if( key !== '_id' && key !=='createdAt' && key !== 'updatedAt' && key !=='__v' && key !=='owner'&&  key !=='isVcard'){

                    // if(key === 'links'&& linkListState >=1 ){
                    if(key === 'links'&& currentPersona?.links?.data?.length >=1 ){
                        // console.log("currentPersona['links']: ",key ,currentPersona['links'])
                        return(
                            <div style={{
                                display:'flex',
                                flexDirection:'column',
                                listStyle:'none',
                                // justifyContent: 'left',
                                textAlign: 'left', 
                                padding:'0.5rem',
                                // border:'1px solid red',
                                // border:'1px solid red',
                                width:'100%'}}>
                                <b><u>links</u>:</b>
                                <div style={{
                                        display:'flex', 
                                        flexDirection:'column', 
                                        gap: '0.25rem',
                                        // margin:'auto',
                                        // border:'1px solid red',
                                        justifyContent:'flex-end',
                                        width:'100%',
                                        
                                        }}>
                                    {!isListSaved && 
                                        <button className="button save-button"
                                            style={{color:'#000000',backgroundColor:'#FFFFFF', borderRadius:'0px 10px 0px 10px'}}
                                            onClick={async(e)=>{
                                                e.preventDefault()
                                                const data = await API.updatePersona({...currentPersona,
                                                    links:{
                                                        on: currentPersona?.links?.on,
                                                        data: linkListState
                                                    }
                                                })
                                                console.log('persona save data:' ,data)
                                                setIsListSaved(true)
                            
                                            }}  
                                            >
                                                save link order
                                        </button>}
                                    
                                        <button className="button save-button"
                                            style={{color:'#000000',backgroundColor:'#FFFFFF', borderRadius:'10px 0px 10px 0px'}}
                                            onClick={async(e)=>{
                                                e.preventDefault()
                                                if(orderList){
                                                    setLinkListState(currentPersona?.links?.data)
                                                    setIsListSaved(true)
                                                }
                                                setOrderList(!orderList)
                                            }}  
                                            >
                                            {orderList?'cancel':'edit link order'}
                                        </button>
                                </div>
                                {/* <div style={{display:'flex', flexDirection:'column',width: '80%', margin:'auto',justifyContent:'center'}}> */}
                                {orderList && <ReactSortable list={linkListState} setList={setLinkListState} 
                                    onChange={(e)=>{
                                        // setIsListSaved(false)
                                        handleListOrderChange(linkListState)
                                        }}>
                                {/* {currentPersona?.links?.data?.map((l,j)=>{ */}
                                {linkListState?.map((l,j)=>{
                                // if (i !== 0){
                                //     console.log("currentPersona['links']---link---->: ",l)
                                //     console.log("currentPersona['links']--index---->: ",i)
                                    //    <li>{l}</li>
                                   return (
                                        <div key={'item-'+j}
                                            className="item"
                                            style={{
                                                display:'flex',
                                                flexDirection:'row', 
                                                position:'relative',
                                                alignItems:'center',
                                                minWidth:'100%',
                                                marginTop:'0.5rem',
                                                justifyContent:'center'
                                            }}
                                       
                                                >
                                            <PersonaFieldComponent 
                                                value={l?.data}
                                                object={currentPersona}  
                                                name={'link-'+j}
                                                on={l?.on}
                                                key={'pfc-'+j}
                                                index={j}
                                                className='details'
                                                />
                                                <i className="uil uil-draggabledots"
                                                style={{position:'absolute',right:'2rem'}}
                                                ></i>
                                               
                                        </div>
                                   )
                            //    }else{return}
                            })}
                            </ReactSortable>}
                           {(!orderList)&& <div 
                        //      className="sortable-list"
                            style={{
                                listStyle:'none',
                                margin:'auto',
                                marginLeft:0,
                                // justifyContent: 'left',
                                // textAlign: 'left',
                                // alignSelf:'start',
                                width:'100%'
                                }}> 
                                 {currentPersona?.links?.data?.map((l,j)=>{
                                // if (i !== 0){
                                //     console.log("currentPersona['links']---link---->: ",l)
                                //     console.log("currentPersona['links']--index---->: ",i)
                                    //    <li>{l}</li>
                                   return (
                                        <div key={'item-'+j}
                                            className="item"
                                            style={{
                                                display:'flex',
                                                flexDirection:'row', 
                                                position:'relative',
                                                alignItems:'center',
                                                marginTop:'0.5rem',
                                                minWidth:'100%',
                                                justifyContent:'center',
                                                marginLeft:0,
                                            }}
                                       
                                                >
                                            <PersonaFieldComponent 
                                                value={l?.data}
                                                object={currentPersona}  
                                                name={'link-'+j}
                                                on={l?.on}
                                                key={'pfc-'+j}
                                                index={j}
                                                className='details'
                                                />
                                                {/* <i className="uil uil-draggabledots"
                                                style={{position:'absolute',right:'2rem'}}
                                                ></i> */}
                                               
                                        </div>
                                   )
                            //    }else{return}
                            })}
                             </div>  }
                            {/* </div> */}
                             <div style={{
                                 display: 'flex',
                                alingSelf:'center',
                                marginTop:'0.5rem',
                                width:'fit-content',
                                cursor: 'pointer',
                                padding: '0 0.125rem',
                                borderRadius: '5px',
                                boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                                padding: '0.25rem',// 0.125rem 0  0.125rem',
                                border: '2px solid #FFFFFF',
                                backgroundColor: 'rgba(61, 255, 158, 0.628)',
                                zIndex:99999,
                            }} 
                                onClick={()=>{
                                    addLink()
                                }}
                                >
                                <img width='30rem' src='/assets/AddLink.svg' alt='add link'/>
                            </div>
                        </div>
                        )
                    } 
                     if(key === 'links'&& !currentPersona?.links?.data?.length ){
                                return(
                                    
                            <div style={{
                                 display: 'flex',
                                alingSelf:'center',
                                alingSelf:'center',
                                width:'fit-content',
                                cursor: 'pointer',
                                padding: '0 0.125rem',
                                marginTop:'0.5rem',
                                borderRadius: '5px',
                                boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                                padding: '0.25rem',// 0.125rem 0  0.125rem',
                                border: '2px solid #FFFFFF',
                                backgroundColor: 'rgba(61, 255, 158, 0.628)'
                            }} 
                                onClick={()=>{
                                    addLink()
                                }}
                                >
                                <img width='30rem' src='/assets/AddLink.svg' alt='add link'/>
                            </div>
                                )
                            }
                    // if( key === 'personaTitle' ){return}
                    //if(key === 'alt_pic' || key ===  'personaTitle' ){return}
                    else{
                        return(
                            <PersonaFieldComponent 
                                value={key !=='personaTitle'? currentPersona[key]?.data:currentPersona[key]}
                                on={currentPersona[key]?.on}
                                object={currentPersona}  name={key}
                                key={'psf'+i}
                                index={i}
                                />
                        )
                    }
                }

            })}
        </div>

}
    </div>}
 </>
  )
}

export default SinglePersona