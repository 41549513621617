import React, {useState, useContext, useEffect } from "react";
import axios from 'axios'


import { AppContext } from "../context/AppProvider";
import PersonaFieldComponent from "./PersonaFieldComponent"
import QROptions from './QROptions'
import * as API from '../utils/APICalls'
import CloseButton from "./CloseButton";
import { Container } from "@mui/material";


const defaultPersona = require('../defaults/PersonaDefault.json')
const CreatePersona = () => {
    //   const ENDPOINT = 'http://localhost:5003/api'
    // const ENDPOINT = 'https://qaura-api.onrender.com/api'
    const [ showList, setShowList ] = useState(false)

    const { personas, setPersonas,modalOpen ,currentPersona, userData,updateAppData,
        setCurrentPersona,newPersona, setNewPersona} = useContext(AppContext);
    const [ isVcard, setIsVcard ] = useState(currentPersona?.isVcard)
        const [ isCreated, setIsCreated ] = useState(false)
    
     const untitledCount = () =>{
    let count = personas.filter(p=>p.personaTitle.includes('untitled')).length
    return count
}
    const savePersona = async (obj) =>{
         try{
            const data = await API.createPersona(obj,userData._id,personas)
       
                console.log('[creattePersona] save data: ',data)
                setCurrentPersona({...data})
                setPersonas([...personas,data])
                setIsCreated(true)
                // setnewPersona({...data})
                
        }catch(err){console.log(err)}   
    }
        const toggleOn = (e) =>{
            let { value } = e.target
            // setIsVcard(!isVcard)
            setNewPersona({...newPersona,isVcard:isVcard})
            try{
                // updatePersonaDb({...newPersona})
            }catch(err){console.log(err)}
            console.log('[vCard]: newPersona', newPersona)
        }
    const addLink = () =>{
       let links = newPersona?.links?.data
        links?.push({on:true,data:''})
        console.log("links: ", links)
        setNewPersona({
            ...newPersona,
            links:{
                on: newPersona?.links?.on?newPersona?.links?.on:true ,
                data: links
            }
        })
    }
  
    // useEffect(() => {
  
    //     }, [newPersona?.PersonaTitle])         

    useEffect(() => {
        window.scrollTo(0, 0)
        }, [])         

    useEffect(()=>{
    //    if(newPersona?.owner ===''|| !newPersona?.owner)
        setNewPersona({
            ...newPersona,
             owner:{
                on: true,
                data: userData?._id,
                },
            firstName:{
                on: true,
                data: userData?.firstName,
                },
            lastName:{
                on: true,
                data: userData?.lastName,
                },
            preferredName:{
                on: true,
                data: userData?.preferredName,
                },
            email:{
                on: true,
                data:  userData?.email
        }
        })
        // setNewPersona ({...defaultPersona})
    },[])

    useEffect(()=>{
        console.log('NewPersona :',newPersona)
        // setCurrentPersona({
        //     ...newPersona,
        //     owner: userData?._id
        // })
        // setNewPersona ({...defaultPersona})
    },[currentPersona])
    // },[NewPersona])
  return (
   
    // <div style={{flex:1,height:'100%',width:'100%',overflow: 'hidden',padding: '2rem 0rem'}}>
        <Container
    // className='left-content'
        style={{
            position:'relative',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width:'100%',
            // maxWidth:'80vw',
            height: '100%',
            minHeight:'13rem',
            // maxHeight:'40vh',
            borderRadius:'10px',
            backgroundColor:'rgba(256,256,256,1)',
            // backdropFilter: 'blur(5px)',
            // boxShadow: 'inset 0px 0px 10px 3px rgba(255, 255, 255, 0.5), 0px 0px 5px 3px rgba(0, 0, 0, 0.3)',
            border:'3px solid #000',
            padding:'0.25rem',
            overflow:'hidden',
            // overflowY:'scroll',
            justifyContent:'center',
            textAlign:'center',
            // margin:'1rem 1rem 1rem 1rem'
            // zIndex:'10'
            gap: '0.5rem',
            margin:'auto'
            
        }}  
        >
    <h2>Create a persona</h2>
        <div style={{position:'absolute', top:'0.125rem', right:'0.5rem'}}>
            <CloseButton func={updateAppData} val={{
                        modalOpen:!modalOpen,
                        modalView:''
                    }} />
        </div>
    {newPersona  && 

    <Container style={{
        position: 'relative',
        display: 'flex', 
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        // width: '100%',
        border: '2px solid #ffffff',
        borderRadius:'10px',
        borderBottom: '0',
        backgroundColor:'rgba(228,249,253, 1)',
        // backgroundImage: "url('/assets/bodybg.png')",
        backgroundAttachment:'fixed',
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        // boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.3)',
        boxShadow: 'inset -1px -3px 10px 5px rgba(0,0,0,0.8)',
        // backdropFilter:'blur(5px)',
        padding: '0.75rem',
        height: '100%',
        // width: '100%',
        margin: 'auto',
        fontSize: '0.75rem',
        color: '#000000',
        overflow: 'hidden',
        overflowY: 'scroll',
        

    }}>
        <span className="button-accent-hover"
        style={{position:'absolute', top:'0.5rem',right:'0.5rem',display: 'flex', alignItems:'center', gap:'0.5rem'}}> 
        {/* <b><u>link persona to asset </u> </b> */}
           <div  
           style={{
            
            display: 'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            gap:'0.5rem',
               width:'fit-content',
                cursor: 'pointer',
                padding: '0 0.125rem',
                borderRadius: '5px',
                border: '2px solid #000',
                boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                padding: '0.5rem',
                backgroundColor: showList?'rgba(61, 255, 158, 0.7)':'rgba(61, 255, 158, 0.628)',
                backdropFilter: showList?'blur(6px)':'',
                zIndex:'9999'
            }} 
                onClick={()=> {
            if(!showList){

                setShowList(true)
            }}}
            // setShowList(!showList)
        
        >
            <div style={{position:'relative',borderBottom:'2px solid #FFFFFF',paddingBottom:'0.25rem', width:'100%'}}>

                link to QR
                {showList&&<img  width='10rem'
                className="button-accent-hover"
                onClick={()=>{setShowList(false)}}
                style={{cursor:'pointer',position:'absolute', right:'0.125rem', top:'0.125rem'}}
                src='/assets/close.svg' alt='close link to QR options'/>}
            </div>
            
            <img width='55rem' src='/assets/LinkToQR.svg' alt='link to asset'/>
    {showList &&  <QROptions view='create-persona'/>}
        </div>
        </span>
        {/* <button className="button save-button blink" 
                    id="save-button" 
                    onClick={() => {
                        savePersona(newPersona)
                        // savePersona(currentPersona)
                        updateAppData({
                        modalOpen:false,
                        modalView:''
                    })
                    }}
                    style={{alignSelf: 'right',padding:'0.25rem 0.25rem 0', position:'relative', right:'0.5rem', top:'0.5rem'}}>
                <img src="/assets/save.svg" style={{height:'25px'}} alt="save persona"/>
            </button> */}
         <div style={{position:'relative',width:'100%',display:'flex',flexDirection: 'column', gap:'0.75rem', justifyContent:'left'}}>
             {/* {newPersona && ,*/}

         <div
            style={{width:'100%',display:'flex',flexDirection: 'column', gap:'0.75rem', 
            justifyContent:'center',margin: 'auto'}}>
             {/* {currentPersona && ,*/}

            <span style={{display: 'flex', alignItems:'center', gap:'0.5rem'}}> 
            <b><u> make shareable (vCard) </u> </b> 
                <div style={{
                    cursor: 'pointer',
                    padding: '0.125rem 0.25rem',
                    borderRadius: '5px',
                    border: '2px solid #FFFFFF',
                    boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                    backgroundColor: currentPersona?.isVcard?'rgba(61, 255, 158, 0.628)':'rgba(253, 0, 0, 0.628)',
                    color:currentPersona?.isVcard?"#000000":'#ffffff'
                }}
                    // name={name}
                    value={currentPersona?.isVcard}
                    onClick={(e)=>{
                        setIsVcard(!isVcard)
                        setCurrentPersona({...currentPersona,isVcard:isVcard})
                        toggleOn(e)
                        

                    }}
                >
                {currentPersona?.isVcard? 'on': 'off'}
            </div>
    
            </span> 
             {/* } */}
         </div>
             {/* } */}
         </div>
        <div style={{display: 'flex', flexDirection:'column', 
            alignItems: 'left',
                width:'100%', height:
                '100%',overflow:'hidden', 
                overflowY:'scroll', padding:'0.5rem', flex:'1'}}> 
           
            <h2 style={{ margin: '0.75rem 0', alignSelf:'start'}}><u>
               
                <PersonaFieldComponent
                    value={newPersona?.personaTitle}
                    object={newPersona}  name='personaTitle'
                    on={true}
                    edit_on={true}
                    view='create-persona'
                    // title_on={true}
                    key={1}
                    index={0} />
                {/* {newPersona?.personaTitle} */}
                </u></h2>  
            
           
            { Object.keys(newPersona).map((key,i)=>{ 
                // let name = item?.keys()[i] 
                // console.log("newPersona[key]: ",key ,newPersona[key])
                // console.log("newPersona?.links?.data.length ",newPersona?.links?.data.length )
                if( key !== '_id' && key !=='createdAt' && key !== 'updatedAt' && key !=='__v' && key !=='owner'&&  key !=='isVcard'){

                    if(key === 'links'&& newPersona?.links?.data?.length >=1 ){
                        // console.log("newPersona['links']: ",key ,newPersona['links'])
                        return(
                            <div style={{display:'flex',flexDirection:'column',listStyle:'none',
                                 justifyContent: 'left',textAlign: 'left', padding:'0.5rem'}}>
                            <ul style={{display:'flex',flexDirection:'column',listStyle:'none',
                                 justifyContent: 'left',textAlign: 'left'}}>
                                <b><u>links</u>:</b>
                                {newPersona?.links?.data?.map((l,i)=>{
                                // if (i !== 0){
                                //     console.log("newPersona['links']---link---->: ",l)
                                //     console.log("newPersona['links']--index---->: ",i)
                                    //    <li>{l}</li>
                                   return (
                                        <>
                                            <PersonaFieldComponent 
                                                value={l?.data}
                                                object={newPersona}  name={key}
                                                on={l?.on}
                                                edit_on={true}
                                                view='create-persona'
                                                key={i}
                                                index={i}
                                                />
                                        </>
                                   )
                            //    }else{return}
                            })}
                            </ul>  
                            <div style={{
                                 display: 'flex',
                                 alingSelf:'center',
                                alingSelf:'center',
                                width:'fit-content',
                                cursor: 'pointer',
                                padding: '0 0.125rem',
                                borderRadius: '5px',
                                border: '2px solid #FFFFFF',
                                boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                                padding: '0.25rem',// 0.125rem 0  0.125rem',
                                backgroundColor: 'rgba(61, 255, 158, 0.628)'}} 
                                onClick={()=>{
                                    addLink()
                                }}
                                >
                                <img width='30rem' src='/assets/AddLink.svg' alt='add link'/>
                            </div>
                        </div>
                        )
                    } if(  key ===  'personaTitle' ){return}
                    else{
                        return(
                            <PersonaFieldComponent 
                                value={key !=='personaTitle'?newPersona[key]?.data:newPersona[key]}
                                object={newPersona}  name={key}
                                key={i}
                                edit_on={true}
                                view='create-persona'
                                index={i}
                                />
                        )
                    }
                }
                    
            })}

        </div>
         <button className="button save-button " 
            id="save-button" 
            onClick={() => {
                       savePersona(newPersona)
                        // savePersona(currentPersona)
                        updateAppData({
                        modalOpen:false,
                        modalView:''
                    })
            }}
               style={{display:'flex', flexDirection:'column',alignItems:'center',
                color:'#000',margin:'1rem auto',padding:'0.25rem 0.25rem 0', 
                backgroundColor:'rgba(255,255,255,0.7)',
                position:'relative', right:'0.5rem', top:'0.5rem'}}>
            <img className='blink' src="/assets/save.svg" style={{height:'35px'}} alt="save QR info"/>
           <b>
             save
            </b>
            </button>
    </Container>
     } 
 </Container>
  )
}

export default CreatePersona