import React , { useState, useEffect, useContext } from 'react';
import { Route, Routes, BrowserRouter , useParams,useLocation} from 'react-router-dom';
import {QRCodeSVG} from 'qrcode.react';
import NavBar from './components/NavBar';
import Auth from './components/Auth'
import ScanRedirect from './components/ScanRedirect'
import Dashboard from './components/Dashboard'
import Persona from './components/Persona'
import Profile from './components/Profile'
import FieldMode from './components/FieldMode'
import AssetManager from './components/AssetManager'
import Menu from './components/Menu'
// import CreateQR from './components/CreateQR';
import Vcard from './components/Vcard'
import Home from './components/Home'
import AgentZeroUI from './components/AgentZeroUI'
import * as API from './utils/APICalls'

import { QRCodeProvider } from "./context/QRCodeProvider";
import { AppProvider, AppContext, AppState } from "./context/AppProvider";
import { SignupContext, SignupProvider }  from "./context/SignupProvider";

import EnvRoute from './middleware/envRoute';
import axios from 'axios';
import './App.css';
import colors from './defaults/ThemeDefaults'
import SignUp from './components/signupView';
import Congratulations from './components/Congratulations';
import Thanks from './components/Thanks';
import ResetPassword from './components/ResetPassword';
import AlixInWonderland from './components/AlixInWonderland';
import StephonsMobileBistro from './components/StephonsMobileBistro';
import CreateHMNIStickerAsset from './components/CreateHMNIStickerAsset';
import QRFraming from './components/QRFraming';
import Storefront from './views/Storefront';
import ShopMusicPlayer from './views/storefrontComponents/musicPlayer/ShopMusicPlayer';
import AgentInterface from './components/AgentZeroUI';
import RexPlayer from './views/storefrontComponents/musicPlayer/RexPlayer';
import CompleteOrder from './views/storefrontComponents/CompleteOrder';
import ErrorPage from './components/ErrorPage';


const ENDPOINT = EnvRoute('prod'); 

// if(!ENDPOINT[0].includes('localhost')){
  // window.location.hostname = "qaura.pro"; 
// }
function App() {
  
      const [loading, setLoading] = useState(true);

     

    //  useEffect (()=>{
    //     const fetchAlbums = async () => {  
    //       try{
    //         const result = await API.fetchAlbums()
    //         if(result){
    //            console.log("[fetchAlbum]:", result)
    //           setAlbums(result)
    //           setLoading(false)
    //         }
    //       }catch(err){console.log(err)}
    //     }

    //    fetchAlbums()

    //   },[])

  const { appData, updateAppData, appTheme, setAppTheme, modalOpen, 
    setIsLoggedIn, userData,setUserData ,view} = AppState();

     const { signupData, updateSignupData, tempOrder, updateTempOrder } = useContext(SignupContext);
  const [ hue, setHue ] = useState(0)
  const [ cream, setCream ] = useState(true)
  const defaultTheme = 'aura'

  const location = useLocation();
  const currentRoute = location.pathname;

  const [ rotDegree, setRotDegree ] = useState(45)
  const [ rotSwitch, setRotSwitch ] = useState(-1)

   const {owner_email} = useParams()
    const congrats_string = `congrats/:${owner_email}`
  // const [ modalOpen, setModalOpen ] = useState(false)
  // const []
  let midHue = 0

  let {id} = useParams()

 const sleep = (time) => {
      return new Promise(resolve => setTimeout(resolve, time))
    }
    
    let gradString =''
    const hueAnimator = async() =>{
      let midHue = 0
      while(midHue < 360 ){
        
        // console.log("midHue: ",midHue)
        // console.log("Hue: ",hue)
        // setRotDegree(rotDegree + (rotSwitch *(midHue))) 
        try{
          setHue(midHue)
          gradVar2 = `hsl(${hue}deg 16.5% 58.49%)`
          
        }catch(err){console.log(err)}
        midHue += 5 * rotSwitch 
        if(midHue === 355 || 0){setRotSwitch(rotSwitch * -1)}
        if ( midHue === 360 ){midHue = 0}
        await sleep(700)
      }
  }
  let gradVar1 = `rgba(253, 192, 247, 0.3)`
  let gradVar2 = `rgba(255, 234, 0, 0.3)`
  let gradVar3 = `rgba(16, 174, 216,0.3)`
  // let gradVar3 = `hsl(${hue}deg 16.5% 50.49%)`
  let gradVar4 = `hsl(${221-hue/5}deg, 50%, 70%)`
   
  // const updateUser = async(obj) =>{
  //       try{
  //           const config = {
  //               headers: {
  //               "Content-type": "application/json",
  //               Authorization: `Bearer ${userData?.token}`
  //               },
  //           };
  //           const { data } = await axios.put(
  //               `${ENDPOINT[0]}/user/update`,
  //               {
  //                 email: userData?.email,
  //                   ...obj,
  //               },
  //               config
  //               );
  //       }catch(err){console.log(err)}  
  //   }   

  const handleLogout =()=>{
    setIsLoggedIn(false)
    // setRoom()
    API.updateUser({isOnline:false})
    setUserData()
    updateAppData({...appData,userData})
    localStorage.setItem('QauRaUser', '')

    // updateRoom('user_exit')
    // hmsActions.leave();
  }


  useEffect (()=>{
          // setGradVar(`linear-gradient(45deg,${gradVar1} 10%, hsl(${hue}deg 16.5% 58.49%) 50%, ${gradVar3} 90% ) no-repeat`)
      // clearInterval()
      // hueAnimator()
    },[appData,modalOpen])
 
    
  useEffect (()=>{
    // let user
        // if(!userData){
        
        // try{
          // if(currentRoute.includes('/dashboard')){
          //   user = API.userByID(id)
          //   console.log("[App] useEffect -> user:",user)
          // }
          // else{
          //   user = JSON.parse(localStorage.getItem('QauRaUser'))

          // }
          // if(userData){
          //   setAppTheme({
          //       BGcolorMain: userData?.appTheme?.BGcolorMain?userData?.appTheme?.BGcolorMain:colors[defaultTheme]?.bg,
          //       TXcolorMain: userData?.appTheme?.TXcolorMain?userData?.appTheme?.TXcolorMain:colors[defaultTheme]?.tx,
          //       TXcolorAccent: userData?.appTheme?.TXcolorAccent?userData?.appTheme?.TXcolorAccent:colors[defaultTheme]?.txAccent
          //     })
          //   setUserData({...user,appTheme})
          //   updateAppData({...appData,userData})
          
          //   console.log('[App] local user:', userData)
          // }
          // else{
          //   setAppTheme({
          //     BGcolorMain:colors[defaultTheme]?.bg,
          //     TXcolorMain:colors[defaultTheme]?.tx,
          //     TXcolorAccent:colors[defaultTheme]?.txAccent
          //   })
          //   setUserData({...user,appTheme})

            // updateAppData({
            //   ...appData,
            //   modalOpen:true,
            //   modalView:'login-signup'
            // })
          
          // }
        // }catch(err){console.log(err)}
        // }
        // console.log('[App] local userData:', userData)
    },[userData])
    // componentDidMount() {
            localStorage.setItem('cart',[])

// }
   
  
  return (
   
      <div className="App" style={{ 
        // position: 'absolute',
        // top:0,
        // margin:'auto',
        height:'fit-content', 
        minHeight:'100%',
        minWidth: '375px',
        maxWidth: '1000px',
        // border: '0px solid rgba(255,255,255,0.0)',
        width:"100%",
        // borderRadius:'10px',
        background: userData?.appTheme?.BGcolorMain,
        color: userData?.appTheme?.TXcolorMain,
        fontFamily:'Gilroy, sans-serif',
        //    background: cream ? 'rgba(	250, 241, 227,0.9)':
        //   `linear-gradient( ${rotDegree}deg,
        //   ${gradVar1} 10%, ${gradVar2} 34%,${gradVar3} 50%, ${gradVar4} 90%),
        //   linear-gradient(50deg, 
        //   #ba4aff, rgba(	186, 74, 255,0 ) 70%),
        // linear-gradient(178deg,
        //   #008aff, rgba( 0, 138, 255, 0 ) 50%),
        // linear-gradient(24deg,
        //   #00ffc6, rgba(	0, 255, 198, 1) 35%)
        // no-repeat`
        backgroundImage:  currentRoute === '/' || (currentRoute.includes('/dashboard') || currentRoute.includes('/fm')) ?'url("assets/BodyBG2.png")':'',
        backgroundAttachment:'contain',
        margin: "auto",
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        
        }}>
        <div className='app-container'
          style={{
             height:'100%', 
              minHeight:'100vh',
              border: '3px solid #000',
        // paddding: '0.5rem',
  
          // backgroundColor: 'rgba(0,0,0,0.9)',
          // backdropFilter: 'blur(3px)',  
          // height:'100%', 
          // width: "100%",
          
          // overflow: 'hidden', 
          // overflowY:'scroll',
          // minWidth: '375px',
          // background: appTheme?.BGcolor,
        //   background: cream ? 'rgba(0, 0, 0,1)':
        //   `linear-gradient( ${rotDegree}deg,
        //   ${gradVar1} 10%, ${gradVar2} 34%,${gradVar3} 50%, ${gradVar4} 90%),
        //   linear-gradient(50deg, 
        //   #ba4aff, rgba(	186, 74, 255,0 ) 70%),
        // linear-gradient(178deg,
        //   #008aff, rgba( 0, 138, 255, 0 ) 50%),
        // linear-gradient(24deg,
        //   #00ffc6, rgba(	0, 255, 198, 1) 35%)
        // no-repeat`,
        backdropFilter: 'opacity(10%)',
        WebkitBackdropFilter: 'opacity(10%)',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        overflow:'hidden'
        // transition: 
      }} >
         {/* <BrowserRouter> */}
          <Routes>
            <Route path ="/" element ={<Home />} exact/> 
            <Route path ={`/auth`} element ={<Auth />}/>
            <Route path ="/assets/:id"  element ={<ScanRedirect />}/>
            <Route path ={`/dashboard/:id`} element ={<Dashboard _id={id}/>}/>
            <Route path ="/persona/:id" element ={<Persona />}/>
            <Route path ="/profile/:id" element ={<Profile />}/>
            <Route path ="/fm/:id" element ={<FieldMode />}/>
            {/* <Route path ="/Vcard/" element ={<Vcard />}/> */}
            <Route path ="/reset-password/:token" element ={<ResetPassword />}/>
            <Route path ="/storefront/:id" element ={<Storefront />}/>
            <Route path ="/signupsheet/:id" element ={<SignUp />}/>
            <Route path ="/agentzero" element ={<AgentZeroUI />}/>
            {/* <Route path ="/hello/" element ={<CreateHMNIStickerAsset/>}/> */}
          <Route path ="/rexplayer/:email/:trackId" element ={<RexPlayer />}/>
          <Route path ="/rexplayer/:email/" element ={<RexPlayer />}/>
          <Route path ="/rexplayer/" element ={<RexPlayer />}/>
           {/*<Route path ="/rexplayer/:email/:trackId" element ={<ShopMusicPlayer />}/>}
          <Route path ="/rexplayer/:email/" element ={<ShopMusicPlayer />}/>}
          <Route path ="/rexplayer/" element ={<ShopMusicPlayer />}/>} */}
          <Route path ="/music/:email/:trackId" element ={<RexPlayer />}/>
          <Route path ="/music/:email" element ={<RexPlayer />}/>
          <Route path ="/music" element ={<RexPlayer />}/>
            <Route path ="/qrFrame/:id" element ={<QRFraming/>}/>
            <Route path ="/cart/complete/:id" element ={<CompleteOrder id={'6634372a7d97baf1e077b325'} />}/>
            <Route path ="/partners/Parallaxr/:email/:product_id" element ={<Storefront id={'6634372a7d97baf1e077b325'} />}/>
            <Route path ="/partners/Parallaxr/:email" element ={<Storefront id={'6634372a7d97baf1e077b325'} />}/>
            <Route path ="/partners/Parallaxr" element ={<Storefront id={'6634372a7d97baf1e077b325'} />}/>
            <Route path ="/shop/:id" element ={<Storefront id={'6634372a7d97baf1e077b325'} />}/>
            <Route path ="/partners/Alix-In-Wonderland" element ={<AlixInWonderland />}/>
            <Route path ="/partners/AlixMakesFood" element ={<AlixInWonderland />}/>
            <Route path ="/partners/Stephons-Mobile-Bistro" element ={<StephonsMobileBistro />}/>
            <Route path ={`/congrats/:owner_email`} element ={<Congratulations owner_email={owner_email}/>}/>
            <Route path ={`/thanks/:owner_email`} element ={<Thanks owner_email={owner_email}/>}/>
            <Route path ={`/test-staging/`} element ={<SignUp/>}/>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        {/* </BrowserRouter> */}
      </div>
    </div>
  

  );
}

export default App;
