import { Box, Stack } from '@mui/material'
import React from 'react'

const RPLogo = () => {
  return (
 
        <Stack sx={{height:'150px',
            width:'100%',margin:'auto'}}>
        
            <Box component={'img'}
                    className='lock-logo'
                    src={`/assets/Rex_Entropy_Icon_Glow.png`}
                    sx={{
                        position:'absolute',
                        top:'0px',
                        // left:'50%',
                        margin:'auto',
                        width:'100%', 
                        maxWidth:'100px',
                        height:'auto', 
                        // opacity:'0.5',
                        border:'3px solid #000',
                        borderRadius:'50%',
                        backdropFilter:'blur(5px)',
                        backgroundColor:'#000',
                        boxShadow:'0 0 10px 4px rgba( 0,0, 0,0.2), inset 0 0 10px 3px rgba( 255, 255,255,0.3)',
                        position:'relative',
                        zIndex:0,
                        }}
                        />
                    
                <span style={{
                    position:'absolute',
                    bottom:'-1rem',
                    left:'25%',
                   transform:'translate(-10%,0)',
                    display:'flex',color:'#000' ,
                    backgroundColor:'#FFF',fontFamily:'Gilroy,sans-serif',
                    width:'fit-content',
                    boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
                    border:'2px solid #000', borderRadius:'20px',padding:'0.5rem' }}>
                <b style={{ position:'absolute',left:'2rem', top:'0.25rem'}}>
                    The 
                </b>
                <h1>RexPlayer</h1>
                </span>
            </Stack>
    
  )
}

export default RPLogo