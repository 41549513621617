import QRCodeStyling from "qr-code-styling";
import EnvRoute from '../middleware/envRoute';

const ENDPOINT = EnvRoute('prod'); 
const printTemplates = {
    sm:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1715724099/PARALLAXR_PRINTFILE_BLANK_8_5x11_vchagy.png',
    lg:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1715724099/PARALLAXR_PRINTFILE_BLANK_15X17_wcn2cs.png'
}


// export const 
