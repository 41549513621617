import React,{useEffect,useState} from 'react'
import { AppProvider, AppContext, AppState } from "../context/AppProvider";
import Copyright from './Copyright';


const steps =[{
    key: '01', 
    info:"Create a dynamic QR code. This means you can change what the QR links to whenever you'd like"},
    {key: '02', info:'Create an asset to host your QR'},{key: '03', info:'Create personas that embody aspects of your self that you would like to share through '},{key: '04', info:'Modify accordingly'},
    {key: '05', info:'Activate persona to allow it to be shared as a vCard '},{key: '07', info:'Activate "fieldMode" to allow scanners to select which persona they would like to interact with'},{key: '08', info:'Share whatever whenever with whomever'}]

const aboutText = `<div>A qr code management system centered around providing individuals and organizations with:

The opportunity to start the conversation abouts important to them, with our physical assets 
The ability to always be in the right place and time with our digital ones.
And the means to track the reach of their interactions
</div>`
const LandingPage = (props) => {
    const view = props.view
    const { appData, updateAppData, appTheme, modalOpen, 
    setIsLoggedIn, userData,setUserData } = AppState();
    const [ activeStep, setActiveStep ] = useState(0)
    const [ scroller1, setScroller1 ]= useState(0)

    

    const sleep = (time) => {
        return new Promise(resolve => setTimeout(resolve, time))
    }
    
    const infoLoop = async () =>{
        let i = activeStep
        // steps.forEach((s,i)=>{
            for(i;i<steps.length;i++){
                // console.log('step',steps[i])
                setActiveStep(i)
                if (i > steps.length-1 ){i=0 
                    setActiveStep(0)
                }
                await sleep(3500)
            }
            // })
        }
        
        const changeHeight = () =>{
            var scrollVal = window.scrollY
            var aDiv = document.getElementById("upper3");
            //Changing CSS Width
            
            /* This lags if you scroll fast.. aDiv.style.width = (100 - (scrollVal*100/800)) + "%"; 
            I just tried it out, so instead use the code down above, 800 to 1500 doesn't matter, I just increased time of animation
            */
           //NOTE this line checks if PERCENT <= 10 then sets width to 10%
        //    ( (100 - (scrollVal*100/1500)) ) <= 10 ? aDiv.style.height = "10%" : aDiv.style.height = aDiv?.style.height - scrollVal*5+"%";
        //! console.log("scrollVal:",scrollVal)
        if(scrollVal >= 5 &&  scrollVal <= 519){
                setScroller1('fit-content')
            }
        if(scrollVal >= 520){
            if (scroller1 ==='fit-content'){
                setScroller1(600)
            }else{

                let h = aDiv.style.height
                // setScroller1( h - h * .1);
                setScroller1( scroller1 - scrollVal * .1);
                // aDiv.style.backgroundColor= "red"
                // !console.log("aDiv.style.height:",aDiv.style.height)
            }
         }
        // if(scrollVal < 520){
        //     let h = aDiv.style.height
        //     // setScroller1( h - h * .1);
        //     setScroller1('fit-content');
        //     aDiv.style.backgroundColor= "#FFF"
        //     console.log("aDiv.style.height:",aDiv.style.height)
        //  }
          if(scrollVal >= 520 && scrollVal <= 530 ){
            setActiveStep(1)
        }
          if(scrollVal >= 531 && scrollVal <= 540 ){
            setActiveStep(2)
        }
          if(scrollVal >= 541 && scrollVal <= 550 ){
            setActiveStep(3)
        }
    }
        // infoLoop()
        // setInterval(infoLoop(),1000)
        useEffect(()=>{
            // setInterval(infoLoop,1000)
            if(scroller1 === 'fit-content'){

                infoLoop()
            }
        },[scroller1])
        window.addEventListener(
            "scroll",
            function () {
            requestAnimationFrame(changeHeight);
            },
            false
        );
        return (
    <div style={{
        display:'flex',
        flexDirection:'column',
        minHeight:'100%',
        maxWidth:'100%',
        width:'100%',
        margin:'auto',
        overflow:'hidden',
        borderRadius:'0px 0px 0px 0px'}}>
         {/* id="body"> */}
      
     
        {/* <div className="parallax"> */}
                {/* <div className="header">
                    <img src="./assets/Parallaxr_Logo_Dist.png"/> 
                    ⇕ scroll ⇕
                    <div>
                        &nbsp;
                    </div>
                </div> */}
            {/* <!-- <div className="footer"></div> --> */}
        
            {/* </div> */}
            <div className="upperLayer"
                style={{
                display:'flex',
                flexDirection:'column',
                width:'100%', 
                justifyContent:'center',
                height:'100%',
                backdropFilter:'blur(5px)',
                fontSize:'36px',
                margin:'auto',
                borderRadius:'0px 0px 0px 0px',
                padding:'1rem 0'}} 
            >
          <h3 className='text-shadow-02 blink-2'
            style={{fontFamily: 'Protest Riot, sans-serif',margin:'0.75rem auto',color:view === 'modal'?'#FFFFFF':`${appTheme?.TXcolorAccent}`}}>
            Greetings! 
            {/* {userData? userData?.preferredName :'login to use.'} */}
            </h3>
          <div className='landing-header'
                style={{
                    alignSelf:'center', 
                    backgroundColor:'#ffffff',
                    borderRadius:'10px 10px 10px 10px',
                    border: '.5rem solid #000000',
                    paddingTop:'1.5rem', 
                    boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.5)'}}>
                   
                    <img height='250rem' src='/assets/ProductSpread_01.png'
                        alt="QauRa Product Spread 01"/>
           

        </div>
            </div>
            <div  className="" id ="upper3" 
                style={{
                    display:'flex', 
                    flex:1,
                    margin:'auto',
                    justifyContent:'center',
                    height:scroller1 ==='fit-content'?scroller1:scroller1+'px',
                    width:'100%',
                    boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.5)',
                    color:'#000000',
                    borderRadius:'0px 0px 0px 0px', margin: 'auto'}}>
                  <div 
                    style={{

                        fontSize:'1rem',
                        height:'100%',
                        width:'100%',
                        margin: 'auto',
                        justifyContent:'center',
                        alignContent:'center',
                        padding:'0.5rem'}}>
                    <div 
                        style={{
                            width:'100%', 
                            display: 'flex', 
                            flexDirection:'column', 
                            justifyContent:'center'}}>
                       <span  style={{maxWidth:'600px', margin:'auto'}}>
                        
                        A <b>dynamic QR code management system </b>centered around providing individuals and organizations with:
                        </span> 
                        <br/>
                        
                    <div style={
                        {display: 'flex', 
                        flexDirection:'row',
                        justifyContent:'space-evenly', 
                        alignItems:'center', 
                        width:'100%',
                        flex: 1, 
                        gap:'0.5rem'}}>

                              <p style={{width:'50%',borderRight:'2px solid #858585',padding:'0.25rem'}}>
                                The opportunity to <b>start the conversation about what's important</b> to them, with our physical assets 
                            <br/>
                            <br/>
                                </p>  
                            {/* </li> */}
                            {/* <li> */}
                            <p style={{width:'50%',padding:'0.25rem'}}>
                                The ability to always <b>be in the right place and time</b> with our digital ones.
                                
                        <br/>
                        <br/>
                            </p>
                            {/* </li> */}
                            {/* <li> */}
                    </div>
                        <br/>
                            <p style={{borderTop:'2px solid #858585'}}>

                                And the means to <b>nurture & track</b> the reach of their <b>interactions</b>
                        <br/>
                            </p>
                            {/* </li> */}
                        
                        {/* </ul> */}
                    </div> 
                    </div>
                </div>
           
            
            <div style={{padding:'0.5rem',height:'600px',backgroundColor:'#fff',fontSize:'2=1.4=5rem'}} className="upperLayer" id ="upper1">
                {/* <h4>how it works:</h4>
               {steps[activeStep]?.info}
               <hr  style={{margin: '1rem auto'}}/>
               <div style={{
                    display:'flex',
                    width:'300px', 
                    height:'300px',
                    alignItems:'center',
                    justifyContent:'center', 
                    boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.5)',
                    backgroundColor:'rgba(0,0,0,0.2)',
                    border:'2px solid #000000', 
                    borderRadius:'10px', 
                    margin:'auto'}}>
                step {activeStep+1} image
               </div> */}
                {/* <span id="origins-header">"Origins"</span> */}
            </div>
            
            {/* <div className="parallax p3">
               
            </div> */}
        
        
           
        {/* <Copyright /> */}
        <div className="footer" style={{display:'flex', width:'100%', justifyContent: 'center', alignItems: 'center', color:'#000000', margin: 'auto'}}>
            <div style={{display:'flex', width:'100%', justifyContent: 'center', alignItems: 'center', color:'#000000', margin: '1rem auto'}}>
                © 2023 RexEntropyLLC
            </div> 
        </div>
        
    </div>
  )
}

export default LandingPage