import React , { useState, useEffect, useContext,useParams } from 'react';
import { Box, Button, Container, IconButton, Input, InputAdornment, TextField, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";

import { AppProvider, AppContext, AppState } from "../context/AppProvider";


import * as API from '../utils/APICalls'
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';


const ResetPasswordComponent = (props) => {
    const view = props.view
    const navigate = useNavigate();

    const {userData, actionError, setActionError,setActionText,actionText} =useContext(AppContext)
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ confirmPassword, setConfirmPassword ] = useState('')
    const [ showPW, setShowPW ] = useState(false)
    const [ toggleReset, setToggleReset ] = useState(false)



   const checkPasswords = async (props) => {
    try{
        if( password === confirmPassword ){
        //   console.log('!-----YAYYY...Match -----!')
          const res = await API.resetPassword({...userData,token:props?.token})
          console.log('!-----YAYYY...res -----!',res)
          if (res){
              setActionText('Password Reset')
              setToggleReset(!toggleReset)
              setTimeout(() => {
                navigate("/");
                }, 1500);

          }else{setActionError('🤔Error resetting password🤔')}
        }else{
  
          console.log('!-----AWWWWW...No Match -----!')
        }
      }catch(err){console.log({msg:err})}
    }
    
    const sendLink = async () =>{
        
        if(email){
            try{
                const res = await API.forgotPassword(email)
                console.log('sendLink res:', res)
                setActionText(res?.msg)
                setTimeout(() => {
                    setToggleReset(!toggleReset)
                    setActionError('')
                    setActionText('')
                }, 1500);
                // return res

            }catch(err){
                setActionError(err.message)
                console.log(err)
            }
        }
        else{ 
            setActionError('No mail provided')
            return null
        }
    }

    console.log('token:', props.token)
  return (
      <Container sx={{display:'flex',flexDirection:'column', gap:'0.5rem', maxWidth:'350px',justifyContent:'center', textAlign:'center'}}>
            <h4>Reset Password</h4>
        {!toggleReset && <Button sx={{margin:'auto'}} 
                    variant='contained'
                    onClick={()=>{setToggleReset(!toggleReset)}}>
                        Reset Password
                </Button>}
      {view === 'email link' && toggleReset && (<Box sx={{display:'flex',flexDirection:'column', gap:'0.5rem', width:'100%'}}>

            <TextField sx={{color:'#000'}}
                type={showPW ?'text':'password'}
                required
                label='password' onChange={async(e)=>{
                    setActionError('')
                    setPassword(e.target.value)}}
                  InputProps={{ endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setShowPW(!showPW)}
                  onMouseDown={()=>setShowPW(!showPW)}
                  edge="end"
                >
                  {showPW? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }} 
                    />
            <TextField sx={{color:'#000'}}
                type={showPW ?'text':'password'} 
                required
                label='confirm password' onChange={async(e)=>{
                    setActionError('')
                    setConfirmPassword(e.target.value)}}
                 InputProps={{ endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setShowPW(!showPW)}
                  onMouseDown={()=>setShowPW(!showPW)}
                  edge="end"
                >
                  {showPW? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}     
                    
                    />
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>

                <Button onClick={()=>{setToggleReset(!toggleReset)}}>cancel</Button>
                {/* <Button onClick={()=>setShowPW(!showPW)}> {showPW ? "hide" : "show"}</Button> */}
                <Button onClick={checkPasswords}>reset</Button>
            </Box>
       </Box>)}
            {(view === 'user menu' || view === 'login') && toggleReset && (<Box sx={{display:'flex',flexDirection:'column', gap:'0.5rem', width:'100%'}}>
                    
                <TextField sx={{color:'#FFF'}}
                type={'text'}
                required
                label='email' onChange={async(e)=>{
                    setActionError('')
                    setEmail(e.target.value)}}/>
               {actionError && <Typography sx={{color:'red'}}>{actionError}</Typography>}
               {setActionText && <Typography sx={{color:'green'}}>{setActionText}</Typography>}
            <Box sx={{display:'flex', flexDirection:'row', width:'100%',justifyContent:'space-between', margin:'auto'}}>

                   {view !== 'login' && <Button   onClick={()=>{setToggleReset(!toggleReset)}}>cancel</Button>}
                    <Button sx={{alignSelf:'flex-end'}} onClick={sendLink}>submit</Button>
                </Box>
                </Box>)}
    </Container>
  )
}

export default ResetPasswordComponent