import React, {useContext,useEffect, useState} from 'react'
import * as API from '../utils/APICalls'
import EnvRoute from '../middleware/envRoute';
import { AppContext } from "../context/AppProvider";
import PushPinIcon from '@mui/icons-material/PushPin';
import { Box, Button, Container, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material';
import CloseButton from './CloseButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

const GeoPins = (props) => {
    // console.log("geoPin:",props)
    const gp = props?.geoPin
    const { appData,userData, setUserData, updateAppData,modalOpen, setActionError, actionError} = useContext(AppContext);
    const [ isSaving, setIsSaving ] = useState()
    const [ name,setName ] = useState(null)
    const [ description,setDescription ] = useState(null)
    const [ addNew, setAddNew ] = useState(false)
    const [ currentLocation, setCurrentLocation  ] = useState(userData?.currentLocation)
    const [snackOpen, setSnackOpen ] = useState(false)
    const [ geoPin,setGeoPin ] = useState({
                lat: gp?.latitude,
                lon: gp?.longitude,
                city: gp?.city,
                state: gp?.state,
            })
    const  horizontal = 'center'
    const  vertical = 'bottom'
     const handleSnackClose  = () =>{ setSnackOpen(false)}
     let pins = []

     const handlePinDelete  = async  (e,index) =>{ 
        e.preventDefault()
        let pins = userData?.geoPins
        pins.splice(index,1)
        // console.log('[Delete] -Pins:',pins)
        try{

            const user = await API.updateUser({...userData,geoPins:pins})
          
            //  if(user){
                   // console.log('[handleGeoSelect] userData:',user)
                   localStorage.setItem('QauRaUser', JSON.stringify(user))
                  await setUserData(user)
                  await updateAppData({...appData,userData})
                   console.log('[handleGeoSelect] userData:',userData)
                   setSnackOpen(!snackOpen)
            //  }
        }catch(err){console.log(err)}

     }
     const handlePinEdit = () =>{ }

    const handleNameChange = async(e)=>{  
        await setName(e.target.value)
        try{
            setGeoPin({
                ...geoPin,
                name: e.target.value,
            })
            // console.log('[GeoPin]:',geoPin)
        }catch(err){console.log(err)}
    }

    const handleDescriptionChange = async(e)=>{
        await setDescription(e.target.value)
        try{
            setGeoPin({
                ...geoPin,
                description: e.target.value
            })
                // console.log('[GeoPin]:',geoPin)
        }catch(err){console.log(err)}
    }

const handleGeoSave = async(e)=>{
        e.preventDefault()
        setIsSaving(true)
       

        if( userData?.geoPins?.length >0 ){ 
            pins = userData?.geoPins
            pins?.push(geoPin)
        }else{
            pins = [geoPin]
        }
        console.log('[GeoSave] pins:', pins)
        // setUserData({
            //     ...userData,
            //     geoPins: pins
            // })
            
            // updateAppData({
                //     ...appData,
                //     userData
                // })
                
        try{
            const user = await API.updateUser({...userData,geoPins:pins})
            
            // if(user){
                console.log('[GeoPin]:',user)
                await setName()
                await setDescription()
                await setGeoPin()
                localStorage.setItem('QauRaUser', JSON.stringify(user))
                // .then()
                await setUserData(user)
                await updateAppData({...appData,userData})
                await setIsSaving(false)
                await setAddNew(false)
            // }
        }catch(err){console.log('[Error saving geoPin]:',err)}
    }

    const handleGeoSelect = async(e,pin)=>{
        e.preventDefault()
        try{
            setGeoPin(pin)
            setCurrentLocation(pin)
            // console.log('[handleGeoSelect] userData:',pin)
            const user = await API.updateUser({...userData,currentLocation:pin})
            if(user){
                // console.log('[handleGeoSelect] userData:',user)
                localStorage.setItem('QauRaUser', JSON.stringify(user))
               await setUserData(user)
               await updateAppData({...appData,userData})
                console.log('[handleGeoSelect] userData:',userData)
                setSnackOpen(!snackOpen)
            }else{console.log('[handleGeoSelect] no user?:',user)}
        }catch(err){
            console.log(err)
            setActionError('Error setting location')
        }

        
    }

    const toggleAdd = async(e)=>{
        e.preventDefault()
        if(addNew){
            setGeoPin({})
          
        }
        setAddNew(!addNew)
    }
    
    useEffect(()=>{


      },[userData?.geoPins,addNew,pins])
    
    useEffect(()=>{


      },[snackOpen])

    useEffect(()=>{


      },[geoPin,name,description])

  return (
    <Container sx={{display:'flex', flexDirection:'column', justifyContent:'center', 
    gap:'0.5rem',padding:'0.5rem'}}>
            <div style={{width:'100%', height:'3rem', backgroundColor:'#fff'}}>
                <h2>Geo-Pins</h2>
               { <span><b>Current Location: </b>{currentLocation?.name ? `${currentLocation?.name} ( ${currentLocation?.city}, ${currentLocation?.state} )`:'no location selected'}</span>}
            <div style={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
            <CloseButton func={updateAppData} val={{
                        modalOpen:!modalOpen,
                        modalView:''
                    }} />
        </div>
        </div>
       <Paper sx={{maxHeight:'45vh',overflowY:'scroll', flex:1}}>
         {/* <Snackbar
            anchorOrigin={{vertical,horizontal}}
            open={snackOpen}
            autoHideDuration={6000}
            onClose={handleSnackClose}
            message={`${userData?.currentLocation?.name} set to current location`}
            // action={action}
        >

        </Snackbar> */}
           {userData?.geoPins?.length  > 0  
            ? <List style={{listStyle:'none', width:'100%'}}>
                {userData?.geoPins?.map((g,i)=>{
                        return(
                            <ListItem sx={{display:'flex', width:'100%'}}  key={i} >
                                <Box sx={{width: '60px'}}>

                                    {/* <ListItemButton  sx={{ color:'red',width:'1rem', alignItems:'center',margin:0,padding:0}} 
                                    onClick={(e)=>handlePinDelete(e,i)}
                                    > */}
<ListItemIcon>

                                        <Tooltip title='delete pin'>
                                        {/* <ListItemIcon sx={{flexDirection:'column',alignItems:'center'}}> */}
                                           <IconButton onClick={(e)=>handlePinDelete(e,i)}>
                                            <DeleteForeverIcon  sx={{ color:'red'}}/>
                                            </IconButton>
                                            {/* <DeleteForeverIcon  sx={{ color:'red'}}/> */}
                                        {/* <p>delete</p> */}
                                        {/* </ListItemIcon > */}
                                        </Tooltip>
                                    {/* </ListItemButton> */}
</ListItemIcon>
                                </Box>
                                <Stack  sx={{display:'flex', flex:3}} >
                                    <ListItemText><b>{g?.name}</b></ListItemText>
                                    <p>{g?.description}</p>
                                    <ListItemText>{g.lat}/{g.lon}</ListItemText>
                                    <p style={{fontSize:'0.85rem'}}>( {g.city}, {g.state} )</p>

                                </Stack>
                                 <ListItemSecondaryAction sx={{textAlign:'center',justifyContent:'center'}}>

                                {/* <ListItemButton  sx={{display:'flex',flexDirection:'column', flex:1, 
                                    alignItems:'center',justifyContent:'center'}} 
                                    onClick={(e)=> handlePinEdit(e,g)}
                                    > */}
                                        <Tooltip title='editlocation'>

                                        <IconButton sx={{flexDirection:'column',alignItems:'center', color:'#868686'}}
                                         onClick={(e)=> handlePinEdit(e,g)}
                                        >
                                            <EditIcon   sx={{ color:'Orange'}}/>
                                        </IconButton >
                                        <p style={{fontSize:'0.75rem'}}>edit</p>
                                        </Tooltip>
                                    
                                {/* </ListItemButton> */}
                                {/* <IconButton sx={{display:'flex',flexDirection:'column', flex:1, 
                                    alignItems:'center',justifyContent:'center'}} 
                                    onClick={(e)=>handleGeoSelect(e,g)}
                                     > */}
                                        <Tooltip title='Set current location'>

                                        <IconButton sx={{flexDirection:'column',alignItems:'center', color:'#868686'}}
                                         onClick={(e)=>handleGeoSelect(e,g)}>
                                            <PushPinIcon sx={{ color:'lightGreen'}} />
                                        </IconButton >
                                        <p  style={{fontSize:'0.75rem'}}>select</p>
                                        </Tooltip>
                                    
                                {/* </IconButton> */}
                                 </ListItemSecondaryAction>
                                <Divider />
                            </ListItem>
                        )
                })}
            </List>
            :<p>No pins currently available</p>}
       </Paper>
       {addNew && <Box>
       <hr style={{marginBottom:'0.5rem'}}></hr>
            <b>Create Geo-Pin</b>
            <Box sx={{display:'flex',gap:'0.5rem', justifyContent:'center',textAlign:'left'}}>
                <Typography style={{textAlign:'flex-start',flex:1}}><b >Lat: </b> {gp?.latitude}</Typography>
                <Typography style={{textAlign:'flex-start',flex:1}}><b>Lon: </b> {gp?.longitude}</Typography>
            </Box>
            <Box sx={{display:'flex',gap:'0.5rem', justifyContent:'center',textAlign:'left',flex:1}}>
                <Typography  style={{textAlign:'flex-start',flex:1}}><b>City: </b> {gp?.city}</Typography>
                <Typography style={{textAlign:'flex-start',flex:1}}><b>State: </b> {gp?.state}</Typography>
            </Box>
           {addNew && <Stack sx={{display:'flex',flexDirection:'column',gap:'0.5rem', alignItems:'center',justifyContent:'center',flex:1,padding:'0.5rem'}}>
                <TextField value={name} onChange={handleNameChange} sx={{width:'100%'}} type='text' label="Name" required />
                <TextField value={description} onChange={handleDescriptionChange} sx={{width:'100%'}} type='text' label="Description" />
                <Button onClick={handleGeoSave} variant='outlined'>
                    save
                </Button>
            </Stack>}
       </Box>}
                <Button sx={{alignSelf:'center'}} onClick={toggleAdd} variant='outlined'>
                    {addNew?'Cancel':'Add New Pin'}
                </Button>
            

    </Container>
  )
}

export default GeoPins