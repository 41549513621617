import React, {useState, useContext, useEffect } from "react";

import * as API from '../utils/APICalls'
import { AppContext } from "../context/AppProvider";



const DeletePersona = () => {
    const { updateAppData,assets, currentPersona,modalOpen ,personas, userData,
        setCurrentAsset,newAsset, setNewAsset} = useContext(AppContext);

const deletePersona =async () =>{
      console.log('[Delete persona] (before):', personas)
      let ps = personas
      
      ps.splice(ps.findIndex(function(p){
        return p?._id === currentPersona?._id;
      }), 1);
      
      console.log('[Delete persona] (after):', personas)
      await API.deletePersona(currentPersona)

       updateAppData({
              modalOpen:false,
              modalView:''
          })
    }

  return (
     <div style={{color:"#ffffff",height: '100%',padding:'0.5rem'}}>
                        <h5> Would you like to delete:
                            <h2 style={{color:'#00FF00',border:'2px solid #00ff00', 
                              borderRadius:'10px', padding:'0.25rem 0.55rem', width:'fit-content', 
                              margin:'0.5rem auto'}}>
                              {currentPersona?.personaTitle}
                            </h2> 
                          </h5>
                      <button className="redirect-button" style={{backgroundColor:"#FF3D9E", margin: '0.75rem auto'
                        }}
                      onClick={()=>{
                        deletePersona()
                        
                      }}
                    >
                      delete
                  </button>
                   </div>
  )
}

export default DeletePersona