import React, {useState, useEffect, useContext} from 'react'
import {useParams} from'react-router-dom'
import axios from 'axios'

import * as API from '../utils/APICalls'

import EnvRoute from '../middleware/envRoute';
import PersonaCard from './PersonaCard';
import RedirectHeader from './RedirectHeader';
import Modal from './Modal'
import LandingPage from './LandingPage'

import { AppProvider, AppContext, AppState } from "../context/AppProvider";

const ENDPOINT = EnvRoute('prod'); 

const Persona = () => {
    // const ENDPOINT = 'http://localhost:5003/api'
    // const ENDPOINT = 'https://qaura-api.onrender.com/api'
    const [ persona, setPersona ] = useState(false)
    const { appData, modalOpen, geoData, setGeoData  } = AppState();

    const persona_id = useParams().id?.toString()

//find 
   
    const fetchPersona = async () =>{
        console.log("[fetching Persona]",persona_id)
        // const config = {
        //     headers: {
        //     // Authorization: `Bearer ${user?.token}`,
        //     },
        // };
        // try{
        //     const data  = await axios.post(`${ENDPOINT[0]}/persona/id`,{_id: persona_id}, config)
        //     console.log("data: ", data.data)
        //     setPersona(data?.data)
        // }catch(err){console.log(err)}
        const data = await API.fetchPersona(persona_id)
        console.log('persona response:',data)
        setPersona(data)
    }
    useEffect(()=>{
        if(!persona){
            fetchPersona()
        }
    },[])
    useEffect(()=>{
  
    },[persona])
  return (
    <div>
        <RedirectHeader />
         { appData.modalOpen &&
              <Modal confirm={false}>
                <h1 style={{color:"#FFFFFF"}}>{appData?.modalView}</h1>
                {/* { appData?.modalView==='scan' && <Scan /> }
                { appData?.modalView==='menu' && <Menu /> } */}
                {/* <div style={{height:'100%',overflow:'hidden',overflowY:'scroll'}}> */}
                    { appData?.modalView==='info' && (<LandingPage/> )}
                {/* </div> */}
              </Modal>}
        <PersonaCard persona={persona} view='view-persona'/>
        </div>
  )
}

export default Persona