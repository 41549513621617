import { Box, Button, Container, Input, Modal, 
    Stack, Typography,InputLabel,TextField  } from '@mui/material'
import React ,{useState, useContext} from 'react'
import CloseIcon from '@mui/icons-material/Close';

import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import * as API from '../../utils/APICalls'


const InterestWidget = (props) => {
     const { modalOpen, setModalOpen,
        cartOpen, setCartOpen,
        cart,setCart,order, setShop, shop,storefrontData, 
        updateStorefrontData} = useContext(StorefrontContext);
     const [ email, setEmail ] = useState(null)

    const handleModalClose = () =>{
       setModalOpen(false)
    }

    const handleFieldChange = (e) =>{
        setEmail(e.target.value)
    }
    const submitEmail = async (e) =>{
        e.preventDefault()
        // let pre_subs = shop?.subcribers
        let subs =[]
        if(email){

            if( !shop?.subscribers ){ 
                  await setShop({
                ...shop,
                subscribers: email
            })
            await updateStorefrontData({
                ...storefrontData,
                shop
            })
            }
            else{ 
                subs = shop?.subscribers
                subs.push(email)
                  await setShop({
                ...shop,
                subscribers: subs 
            })
            await updateStorefrontData({
                ...storefrontData,
                shop
            })
            }
        }
        
        // if(subs){
        //     console.log('Subscribers 1 :',subs)
        //      await setShop({
        //         ...shop,
        //         subscribers: subs 
        //     })
        //     await updateStorefrontData({
        //         ...storefrontData,
        //         shop
        //     })

        // }
           
        try{
            
            const data = await API.updateShop(shop)
            if (data){
                console.log('submit email response',data)
                await setShop(data[0])
            }
        }catch(err){console.log(err)}
        setModalOpen(false)

    }

  return (
     <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
    >
            <Container sx={{p:1}}>
                <Box sx={{ position: 'absolute',
                    display:'flex',
                    flexDirection:'column',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    height:'fit-content',
                    minHeight:'60vh',
                    overflow: 'hidden',
                    overflowY:'scroll',
                    maxHeight:'90vh',
                    // maxHeight:'90vh',
                    maxWidth:'98vw',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    borderRadius:'10px',
                    boxShadow: 24,
                    justifyContent:'center',
                    p: 1,}}>

                    <CloseIcon onClick={handleModalClose}  color='#000'sx={{position:'absolute',right:'0.5rem',top:'0.5rem',cursor:'pointer',zIndex:99999}}/>
                    
                    <Container sx={{display:'flex',flexDirection:'column',textAlign:'center', justifyContent:'center', height:'100%',padding:'0.5rem',margin:'1rem auto'}}>
                
                        <Box sx={{display:'flex',flexDirection:'column',textAlign:'center', 
                            justifyContent:'center', borderRadius: '10px',
                            border:'1px solid #868686',padding:'1.5rem'}}>
                            <Typography variant='h4'>
                                Thank you for your interest & support!
                            </Typography>
                            <Typography  variant='h5'>
                                Ordering is currently unavailable
                            </Typography>
                            <Typography>
                                If you would like to receive updates, please submit your email below!
                            </Typography>
                            <Stack sx={{width:'60%',
                                margin:'1.5rem auto',
                                display:'flex', 
                                flexDirection:'column',
                                gap:'0.75rem'
                                //  border:'1px solid red'
                                
                                }}>
                                {/* <InputLabel >
                                </InputLabel> */}
                                <TextField label='email' type='email' required onChange={(e)=>{handleFieldChange(e)}}/>
                                <Button 
                                    sx={{alignSelf:'end'}} 
                                    variant='outlined'
                                    onClick={(e)=>submitEmail(e)}
                                    >submit</Button>
                            </Stack>
                        </Box>
                    </Container>
                </Box>
            </Container>
    </Modal>
  )
}

export default InterestWidget