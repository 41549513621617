import { Box, Typography } from '@mui/material'
import React from 'react'

const OperatingLogoCopy = (props) => {
    const name = props.name
    const splt = Number(props.split)
    const font1 = props.font1
    const font2 = props.font2
    const line1 = name?.split(' ',splt)
    const line2 = name?.substr(name.indexOf(" ") + splt)

  return (
    <Box sx={{display:'flex',flexDirection:'column',marginTop:'-1rem'}}> 
        <Typography component="h1" variant="h2" sx={{fontFamily:font1? font1: 'Glass Antiqua'}}>
            <b>{line1}</b>
        </Typography>
        <Typography component="h1" variant="h4" sx={{fontFamily:font2? font2: 'Glass Antiqua'}}>
            <b>{line2}</b>
        </Typography>
    </Box>
  )
}

export default OperatingLogoCopy