import React, {useState, useContext, 
  useEffect, Component } from "react";
import { Button, Container, TextField,Box, Stack } from '@mui/material'
import { AppContext } from "../context/AppProvider";
import * as API from '../utils/APICalls'

const AddLink = () => {

    const { appData,updateAppData,assets, setAssets,
        modalOpen ,currentAsset, userData,setUserData } = useContext(AppContext);
      
    const [ addLink, setAddLink ] = useState(false)
    const [ linkData, setLinkData ] = useState('')
    const [ linkName, setLinkName ] = useState('')
    const [ link, setLink ] = useState({})
    let links
    // let link={
    //     data:'',
    //     name:''
    // }
    const handleNameChange = async (e) =>{
        await setLinkName(e.target.value)
        try{
            setLink({
                ...link,
                name: e.target.value,
            })
        }catch(err){console.log(err)}
    }
    const handleDataChange = async (e) =>{
        await setLinkData(e.target.value)
        try{
            setLink({
                ...link,
                data: e.target.value,
            })
        }catch(err){console.log(err)}
    }

    const  cancelLink = async (e) =>{
        setLink()
        setLinkData()
        setLinkName()
        setAddLink(false)
    }

    const  saveLink = async (e) =>{
    //    e.preventDefault()
        // console.log('saveLink info:',  linkName)
        console.log('saveLink link:',  link)
        let userLinks =[]; 
        
        if( userData?.hotLinks?.length > 0 ){
            userLinks = userData?.hotLinks
            userLinks.push({name:link?.name, data:link?.data})
            
        }else{
            userLinks = [{name:link?.name, data:link?.data}]
            
        }
        
        // console.log('saveLink userLinks:',  userData?.hotLinks )
        // const linkVar = userLinks//?.length > 0 ?userLinks:userLinks
        
        try {
            const user = await API.updateUser({...userData,hotLinks: userLinks})
            console.log("ADDLINK: userData:", user)

            // if(user){
               setUserData(user)
                updateAppData({...appData,userData})
                console.log("ADDLINK: userData:", userData)
                localStorage.setItem('QauRaUser', JSON.stringify(user))
                cancelLink()
                setAddLink(false)
            // }
            
        }catch(err){console.log(err)}
   


    }

    useEffect(()=>{
        // links = userData?.hotLinks
    },[linkName,linkData,addLink, link, userData])

  return (
    <Box sx={{display:'flex',flexDirection:'column'}}>
       {/* {userData?.hotLinks && links?.map((l,i)=>{
            return <span key={i}>{l?.data}</span>
       })} */}
       {/* {(addLink && !link?.data ) &&<TextField type='url' label='link data' onChange={async(e)=>{
            await setLinkData(e.target.value)
        }} required/>}

        {(addLink && link?.data && !link?.name) && <TextField type='text' label='link name'onChange={async(e)=>{
            await setLinkName(e.target.value)
        }} required/>} */}
        {addLink && <Stack sx={{display:'flex',flexDirection:'column',gap:'0.5rem', alignItems:'center',justifyContent:'center',flex:1,padding:'0.5rem'}}>
            <TextField type='url' label='link' sx={{width:'100%'}} value={linkData}
            onChange={handleDataChange} required/>

            <TextField type='text' label='link name'  sx={{width:'100%'}} value={linkName}
            onChange={handleNameChange} required/>
        <Stack sx={{display:'flex',flexDirection:'row', width:'100%',justifyContent:'space-between'}}>
            <Button onClick={cancelLink}>cancel</Button>
            <Button onClick={saveLink}>save</Button>
        </Stack>

        </Stack> }   
        {!addLink && <Button sx={{margin:'auto'}} onClick={()=>{
         setAddLink(true)
        }}>add link</Button>}
{/* 
        {(addLink && !link?.data ) && <Button onClick={async()=>{
            try{

                await setLink({
                    ...link,
                    data:linkData,
                    })
                PromptForName()
            }catch(err){console.log(err)}
    
        }}>next</Button>} */}
       
        </Box>
  )
}

export default AddLink