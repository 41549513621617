import React, { useState, createContext } from "react";
const Default_QR = require('../options_02.json')
// Create a new context
export const QRCodeContext = createContext();

// Create a context provider component
export const QRCodeProvider = ({ children }) => {
  // const [qrCodeData, setQRCodeData] = useState(Default_QR)
  const [qrCodeData, setQRCodeData] = useState(false)
  const [qrCodeDataPreview, setQRCodeDataPreview] = useState(false)
  const [ size, setSize ] = useState(false)

  
//  const viewSizing = (s) => {
//         const sizes = {
//             sm : {width:'150px', height:'150px'},
//             md : {width:'250px', height:'250px'},
//             lg : {width:'350px', height:'350px'}
//         }
//         // console.log('[viewSizing]:',sizes[s])
//         return sizes[s]
//     }
//     setSize(()=>{
//       viewSizing('sm')
//     })
  const updateQRCodeData = (newData) => {
    setQRCodeData((prevState) => ({
      ...prevState,
      ...newData,   
    }));
    // setQRCodeDataPreview({...qrCodeData,
    //   ...size
    // });
  };
  // console.log('QRProvider', qrCodeDataPreview)

  return (
    <QRCodeContext.Provider value={{ qrCodeData, updateQRCodeData, setQRCodeData }}>
      {children}
    </QRCodeContext.Provider>
  );
};
