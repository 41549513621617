import React,{useContext,useEffect, useRef,useState} from 'react'



import { Container,Box } from '@mui/material';

import Radar from 'radar-sdk-js';

import 'radar-sdk-js/dist/radar.css';
import MapComponent from './MapComponent';
import { AppContext } from "../context/AppProvider";

// import useWindowDimensions from "../../hooks/windowDimensions";


//  const pubKey = 'prj_test_pk_785369bc3670ce470ea47ca31b888989d2d48425'
 const pubKey = 'prj_live_pk_a0ad52a6a865a04e028bb5390e841e7228ef9475'
 
 
 
 const cords = [35.9457, -78.6433]
 const MapContainer = (props) => {
     const { appData,updateAppData,userData,currentScanSet,  setCurrentScanSet,
      scans, setScans,modalOpen, follows, setFollows, QRs,setModalOpen ,scanHeaders, setScanHeaders } = useContext(AppContext);
        // const { height, width } = useWindowDimensions();
        const currPos = [props?.lat,props?.lon]
        const pinArray = props?.pins
         const ref = useRef(null)
         const refParent = useRef(null)
         // render() {
          // const parentWidth = ref.current?.offsetWidth;
          // const parentHeight = ref.current?.offsetHeight;
          const [zoomAmount, setZoomAmount]= useState(15)
          const [parentWidth, setParentWidth]= useState(null)
          const [parentHeight, setParentHeight]= useState(null)
          console.log('parentHeight:',parentHeight)

        useEffect(()=>{
          if(parentWidth === null  || parentHeight === null){
            setParentWidth(refParent.current?.offsetWidth*2)
            setParentHeight(refParent.current?.offsetHeight*2)
          }
        },[ref,refParent])
    return (
      <Box id="map-container" style={{ display:'flex',justifyContent:'center',height:'100%',minHeight: '300px',maxHeight:'500px', position: 'relative', width: '100%',minWidth:'300px',maxHeight:'500px' }} ref={refParent}>
      <Box style={{ display:'flex',justifyContent:'center',flex:1,width: Number(refParent?.current?.offsetWidth), height: Number(refParent?.current?.offsetHeight), margin:'auto',overflow:'hidden',padding:'0.5rem'}} ref={ref}>
        {/* <div id="map" style={{ height: 'auto', position: 'absolute', width: '100%'}} /> */}
        {/* <img  src={`https://api.radar.io/maps/static?width=${parentWidth}&height=${parentHeight}&center=${cords}&zoom=${15}&style=radar-default-v1&scale=1&markers=color:0x000257|${cords}&publishableKey=${pubKey}`} alt="Static Location Map of Stephon's Mobile Bistro" 
       
        /> */}
        <MapComponent centralLongitude={userData?.currentLocation?.lon ? userData?.currentLocation?.lon : cords[1]} 
            centralLatitude={userData?.currentLocation?.lat ? userData?.currentLocation?.lat : cords[0]}
            markers={currentScanSet}
            />
      </Box>
      </Box>
    );
  }


export default MapContainer;