import React, {useState, useContext, useEffect } from "react";

import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";
import axios from 'axios'

import * as API from '../utils/APICalls'

import EnvRoute from '../middleware/envRoute';
import { Box } from "@mui/material";

const ENDPOINT = EnvRoute('prod'); 

const QRListCard = (props) => {
    const qr = props?.qr
    const id = props?.id
    
    const { appData, updateAppData,selectedQR,setSelectedQR,userData,currentAsset, QRs } = useContext(AppContext);
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const [active, setActive] = useState(false)

    //  const ENDPOINT = 'http://localhost:5003/api'
    // const ENDPOINT = 'https://qaura-api.onrender.com/api'
    // const user ={
    //     _id: '64a57f30cb8857d1c2c67620',
    //     token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0YTU5NjA4YWJlMWRkYzkwZGZiMWUxMyIsImlhdCI6MTY4ODU3MzQ0OCwiZXhwIjoxNjkxMTY1NDQ4fQ.aMCUSj2k4wczP3jjFqLa87qJRNA4djf1yRbRzcPQ0xA'
    // }

    const activeElemCheck = (i) => {
        //!Change to ID
    
    return ( active === i ) ?'rgba(61, 255, 158, 0.628)':'#fff'
    }

    const fetchQR  = async() =>{
 
        try{
         
            const data = await API.fetchQRByID(userData?.token,selectedQR?._id)
            // updateQRCodeData(data?.data)
            setSelectedQR(data?.data)
            updateAppData({...appData,selectedQR})
        }catch(err){console.log(err)}
        }

    const handleSelection = () =>{

        setActive()
        setActive(id)
        setSelectedQR()
        setSelectedQR({...qr})
        // updateQRCodeData({...selectedQR})
        console.log('click',selectedQR)
        updateAppData({...appData,selectedQR})

    
    }
    useEffect(()=>{
        // if(!QRs){
        //         fetchQR()
        // }

},[active,QRs,qrCodeData,selectedQR])


  return (
    <div className="asset-list-card"
        style={{
            position:'relative',
            minWidth: '130px',
            width:'95%',
            maxWidth: '125px',
            height: '2.75rem',
            borderRadius:'5px 5px',
            // border:`1px solid #000`,
            border:'2px solid #000000',
            // borderTop:'none',
            // borderBottom:'none',
            alignItems: 'center',
            textAlign: 'left',
            padding: '0.125rem  0.25rem',
            margin: '0.25rem ',
            boxShadow: '0px 0px 3px 2px rgba(0,0,0,0.5)',
            cursor:"pointer",
            // backgroundColor: activeElemCheck(id),
            backgroundColor:  selectedQR?._id === qr?._id ?'rgba(61, 255, 158, 0.628)':'#fff',
            backdropFilter: 'blur(5px)',
            overflow: 'hidden',
        }}
        onClick={()=>{
            handleSelection()
        }}
        >
            <Box sx={{position:'absolute', top: '0.2rem', right:'0.2rem'}}>
                <div style={{content:'',width:'0.75rem',height:'0.75rem', borderRadius:'50%',backgroundColor: qr?.isActivated ? '#00FF00':'#ffe485', boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.3)'}}></div>

            </Box>
        <h5 style={{borderBottom:'1px solid #000',overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis', width:"90%"}}>{qr?.name}</h5>
            <div id="hover-scroll-container" >  
                    <div id="hover-scroll-text">
                        <h6>{qr?.payload?qr?.payload:'*no payload set*'}</h6>
                       
                    </div>   
                 <span>
                     
                        <h6>{qr?.asset_id?`paired asset:`:''}{qr?.asset_id &&<>{qr?.asset_id}</>}{!qr?.asset_id && <span>*no asset id</span>}</h6>
                 </span>
                </div>
    </div>
  )
}

export default QRListCard