
import { AppProvider, AppContext, AppState } from "../context/AppProvider";
import React, {useEffect, useContext, useState} from 'react'
import SinglePersona from './SinglePersona'
import PersonaList from './PersonaList'
import SearchIcon from '@mui/icons-material/Search';
import { Input, InputAdornment, IconButton, Stack } from "@mui/material";

// import { AppContext } from "../context/AppProvider";

const PersonaManager = () => {
    const { appData, updateAppData,modalOpen,currentPersona , setCurrentPersona, personas} = useContext(AppContext);
    
    useEffect(()=>{
    },[currentPersona])

  return (
     <div className='persona-manager-container'
        style={{
        // height:'95%',
        display: 'flex',
        flexDirection: 'column',
        // flex:1,
        // width:'100%', 
        borderRadius:'10px',
        // border: '2px solid #000',
        // marginTop:'0.5rem',
        // borderLeft: 'none',
        // borderRigh: 'none',
        margin: 'auto',
        padding: '0.5rem',
        alignItems:'center',

        backgroundColor:'rgba( 256,256,256, 0.1)',
        backdropFilter: 'blur(5px)',
        // boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.4)',
        justifyContent: 'left',
        gap:'0.75rem',

    }}>
        {/* UserInfoCard */}
    <section className='user-info-bottom'
            style={{display:'flex', flexDirection: 'column',
            // position: 'relative',
            width: '100%',
            alignItems: 'center',
            gap:'0.75rem',
            marginTop:'0.5'}}
        >
       

                
       <div style={{display: 'flex', flexDirection:'row',width: '100%',flex:1, alignItems:'center',
        justifyContent:'space-between'}}>
        <span style={{display: 'flex', flexDirection:'row',
        // position:'absolute',left:'1rem',top:'0.75rem', 
        textAlign:'center',cursor: 'pointer',gap:'0.125rem',whiteSpace:'nowrap'}}
            onClick={()=>{
                if(currentPersona){setCurrentPersona(false)}else{return}
            }}
            >
           {/* {currentPersona &&  */}
           <img className={`${currentPersona?'rot180':'rot-180'}` }
            src={`/assets/arrow-${currentPersona?'up':'down'}.svg`} 
           style={{height:'0.75rem', alignSelf: 'center',opacity:currentPersona?'1':'0.3'}} alt="Clear Current Persona"/>
           {/* } */}
        </span>

           
             <Stack sx={{}}>

        <h4
            //  className='text-shadow-02' 
                style={{color:'#000', alignSelf:'center', marginBottom:'1rem'}}> 
                {personas?.length}&nbsp; <u>Personas</u>
            </h4>
          <Input
                    // onChange={handleSearchChange}
                      endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          // onClick={()=>toggleEdit(!edit)}
                          // onMouseDown={()=>toggleEdit(!edit)}
                          edge="end"
                        >
                        {/* <BackspaceIcon />  */}
                        <SearchIcon /> 
                        </IconButton>
                        
                      </InputAdornment>
                    }
                    />
        </Stack>
 <div className="manager-add-button"
            style={{
                display: 'flex',
                position: 'relative',
                right: '1rem',
                // alingSelf:'right',
                width:'fit-content',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '0 0.125rem',
                borderRadius: '5px 10px',
                border: '2px solid #FFFFFF',
                boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                padding: '0.25rem',// 0.125rem 0  0.125rem',
                
                backgroundColor: 'rgba(61, 255, 158, 0.628)'}} 
                onClick={()=>{
                    setCurrentPersona()
                    updateAppData({
                        ...appData,
                        modalOpen: true,
                        modalView:'create-persona'
                    })
                }}
            >
                    <img height='25rem' src='/assets/AddPersona.svg' alt='create persona'/>
                </div>
        </div>
                
            <div className='user-info-bottom-left'
            style={{
                // position:'relative',
                // overflow:'hidden',
                // overflowX: 'scroll',
                width: '90%',
                // maxWidth:'95vw',
                margin: 'auto',
                marginTop:'0.5rem',
                // flex: 1, 
                border:'2px solid #000000', 
                borderRadius:'10px',
                padding:'.25rem',
                backgroundImage:"url(" +'/assets/Bodybg.png' + ")",
                backgroundRepeat:"no-repeat", backgroundSize:"cover",
                boxShadow:'inset 0px 0px 5px 2px rgba(0,0,0,0.6)'}}
            >
                    <PersonaList />
            </div>
        {/* <div className='user-info-bottom-right'
         style={{width:'100%'}}
        > */}
               {/* {!currentPersona && <>
               
                "No personas selected"
               </>
                } */}
        {/* </div> */}
               {currentPersona?.personaTitle  ?
               <>
               <SinglePersona />
               <div className="manager-delete-button"
                style={{
                  display: 'flex',
                  justsifyContent:'right',
                  width:'fit-content',
                  cursor: 'pointer',
                  padding: '0 0.125rem',
                   margin: '2rem auto  1rem auto',
                  borderRadius: '5px',
                  boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                  padding: '0.25rem',// 0.125rem 0  0.125rem',
                  border: '2px solid #000000',
                  backgroundColor: 'rgba(255, 255, 255, 0.327)'
                }} 
                  onClick={()=>{
                    updateAppData({
                      modalOpen:true,
                      modalView:'confirm-persona-delete'
                  })
                      // deletePersona()
                  }}
                  >
                    <img width='30rem' src='/assets/DeletePersona.svg' alt='Delete Persona'/>
                </div>
               </> 
                :<p className="blink">no persona selected</p>}
    </section>
      </div>

    
  )
}

export default PersonaManager