import React, {useState, useContext, useEffect} from 'react'
import EditUserInfo from './EditUserInfo'
import { AppContext } from "../context/AppProvider";
import useWindowDimensions from "../hooks/windowDimensions";
import MetricCard from './MetricCard';


const UserInfoCard = (props) => {
    const { appData, updateAppData,modalOpen, userData,editUser, setEditUser, scans} = useContext(AppContext);
    const { height, width } = useWindowDimensions();
    const [ shown, setShown] = useState(false)
    // const editUser =props.editUser
    // const [editUser, setEditUser] = useState(false)
    // const user ={
    // _id: "644b1c67ca5845c6e9ba234a",
    // firstName: "Alan",
    // lastName:"Phoenix",
    // preferredName:"AJ",
    // email:"test@test.com",
    // phone:"9198675309",
    // pic:"https://res.cloudinary.com/dzxzdsnha/image/upload/v1684790229/hikszbmy1uysbolmkoiw.png"
    // }
   const user = {...userData}
     useEffect(()=>{
        if(scans && !shown){
            setEditUser(true)
            setShown(true)
            setEditUser(false)
        }
      },[scans])
  return (
      <div 
        className='user-info-card-wrap'
        style={{ display: 'flex', flexDirection: 'column',//width>=700?'column':'row'
            //   ,maxWidth: width<=500?'100px':'100%'
            margin:'auto',
            gap:'0.5rem',
            borderRadius:'10px', padding:'0.5rem',
            // backgroundColor:'rgba(256,256,256,0.3)',
            // backdropFilter: 'blur(5px)',
            // boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.4)',
            // // border: '2px solid yellow',
            alignItems:'center',
            justifyContent:'center',
        //  width:'100%',
    
            }}> 

            {/* <section className='user-info-top'> */}
        
        <div 
            className='user-info-left' 
            style={{ display: 'flex',flexDirection:'row',}}
            >
           
                <div className='left-content'
                    style={{
                        display: 'flex',
                        // flexDirection: width<=600?'column':'row',
                        width:'100%',
                        height: 'fit-content',
                    
                    }}  
                    >
                    <div style={{display:'flex', //flexDirection: width>=600?'row':'column', 
                    justifyContent:'center', gap:'0.5rem',margin:'auto',alignItems:'center'}}>
                   <div style={{display:'flex', borderRadius:'20px',overflow:'hidden', padding: '0.75rem',justifyContent:'center',}}>

                    <img src={userData?.pic?userData?.pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1684790229/hikszbmy1uysbolmkoiw.png'} alt='profile picture' 
                        style={{
                            width:'100%',
                            maxWidth: width <=600 ? '100px':'200px',
                            alignSelf:'center',
                            // zIndex:'10',

                            // height: 'auto',  
                            // border:'3px solid #fff',
                            borderRadius:'20px',overflow:'hidden',
                            border:'3px solid #fff',
                            boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.3)',


                        }}/>
                </div>
                <div 
                    className='user-info-left-bottom'
                    style={{
                        position: 'relative',
                        flexDirection:'row-reverse',
                        display: 'flex',
                        alignItems:'center',
                        justifyContent:'space-between',
                        // border:'2px solid #fff',
                        borderRadius: '0px 0px 20px 20px',
                        // borderLeft:'none',
                        width: '100%',
                        height: '4rem',
                        gap:'0.5rem'
                        // transform:'translate(25%,-180%)',
                        // zIndex:'1',
                        //  backgroundColor:'rgba(256,256,256,0.3)'
                        }}>
                    {/* <div style={{flexDirection:'column',
                        display: 'flex',width:'50%',
                        backgroundColor:'none',
                        border: 'none'
                        }}>
                        
                    </div> */}
                         <div className='button-accent-hover'
                            style={{
                                display: 'flex',
                                height: '1.75rem',
                                cursor: 'pointer',
                                padding: '0 0.125rem',
                                borderRadius: '5px',
                                border: '2px solid #FFFFFF',
                                boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                                padding: '0 0.125rem',

                                backgroundColor: 'rgba(253, 156, 0, 0.628)'}} 
                                onClick={()=>{
                                    props.func(props.val)
                                    // setEditUser(!editUser)
                                }
                            }
                                >
                            <img width='20rem' src='/assets/pencil.svg' alt="edit user info"/>
                        </div>
                                
                        <div className='user-info' style={{display:'flex', flexDirection:'column',textAlign:'left', margin: 'auto', width:'100%', padding:'0.25rem'}}>
                            <h3> {user?.preferredName}</h3>
                            <p>{user?.email}</p>
                            <p>{user?.phone}</p>
                        </div>
                        
                        </div>
                     
                    </div>
                    </div>
                    </div>
                    <div style={{display:'flex', flex:1, width:'100%'}}>
                       
                        {/* {editUser ? 
                            <EditUserInfo />
                            // <MetricCard />
                            : //userData?._id?
                                <MetricCard />
                                // :''
                            }  */}

                        </div>
                    {/* </div> */}
                    {/* {editUser && 
                    <EditUserInfo />
                   } */}
        
            {/* </section> */}
  


    </div>
    // </div>
  )
}

export default UserInfoCard