import React, {useEffect, useContext, useState} from 'react'

import axios from 'axios'

import * as API from '../utils/APICalls'
import { AppProvider, AppContext, AppState } from "../context/AppProvider";


const Follow = (props) => {
    const { appData,updateAppData, modalOpen, geoData, setGeoData  } = AppState();

    const [ email , setEmail ] = useState()
    const [isOpen, setIsOpen]  = useState(props.isOpen)

    const [ actionError, setActionError ] = useState('')
    const persona = props.persona 
   
    const getGeoData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log('user geo res.data: ', res?.data)
        setGeoData({...res?.data})
        updateAppData({...appData,geoData})
        console.log('user geodata: ', geoData)
        // if(res?.data){
        //     collectScanData(res?.data)

        // }
        return res.data
    }

    const handleChange = (e) => {
        setActionError('')
       setEmail(e.target.value)

       console.log(email)
    }
    const handleSave = async(e) => {
        e.preventDefault()
        if(!email){
            setActionError('Please provide an email to follow')
        }else{

            const f_obj = {
                owner: persona?.owner,
                POC_email: persona?.email.data,
                follower_email: email,
                geolocation: {...geoData}
            }
          const data = API.saveFollow(f_obj)
          setIsOpen(false)
        }
       
    }

    useEffect(()=>{
        if(!geoData){
             getGeoData()
        }
    },[geoData])

  return (<>
   {isOpen && <div className='center'
   
   style={{
    position: 'absolute', top:'50%', left:'50%', transform: 'translate(-50%,0%)', 

    width: '75%', height:'200px', zIndex:9999, backgroundColor: 'rgba(0,0,0, 0.7)', color: '#000', padding:'1rem',
    borderRadius:'15px', border: '3px solid #000', boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.5), 0px 0px 10px 5px rgba(0,0,0,0.5)',
    backgroundImage:"url(" +'/assets/Bodybg.png' + ")",
    backdropFilter:'blur(4px)', display:'flex', flexDirection:'column', gap:'0.5rem'
    }}
   
    >
         {/* <div style={{position:'relative',borderBottom:'2px solid #FFFFFF',paddingBottom:'0.25rem', width:'100%'}}> */}
                <img  width='20rem'
                className="button-accent-hover"
                onClick={()=>{setIsOpen(false)}}
                style={{color:'#fff',cursor:'pointer',position:'absolute', right:'0.75rem', top:'0.75rem'}}
                src='/assets/close.svg' alt='close follow modal'/>
            {/* </div> */}
        <div  className='center'>

       <h3>
        To follow {(persona?.preferredName?.on && persona?.preferredName?.data)?persona?.preferredName?.data:persona?.firstName?.data} for updates,
        </h3> 
        <label >
           enter your email !
            <input type='email' style={{margin:'0.5rem auto'}} value={email} onChange={handleChange}/>
        </label>
        <h3 style={{color:'red'}}> {actionError} </h3>
        <button className='button' style={{margin:'0.5rem auto'}}
                onClick={(e)=>{handleSave(e)}}
            >
            submit
        </button>
        </div>
    </div>}
  </>
  )
}

export default Follow