import React,{useEffect, useState, useRef, useContext} from 'react'
import { Button, Container } from '@mui/material';
// import { HostedForm } from 'react-acceptjs';
import {Elements,useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import * as API from '../utils/APICalls'

import EnvRoute from '../middleware/envRoute';
import { StorefrontContext, StorefrontProvider }  from "../context/StorefrontProvider";

const ENDPOINT = EnvRoute('prod'); 


// require('dotenv').config()

const authData = {
  apiLoginID: 'bizdev05',
  transactionKey:'4kJd237rZu59qAZd',
  clientKey: '2Q7eh2Wd9sn9bn2kkEct9qbVC6Bvp5kty9997PS6r5WQLp9R2VDr45RFS2UB9rnB',
};

const Payment = (props) => {
  //   const stripe = useStripe();
  // const elements = useElements();
  const { type, cart } = props
  const [ config , setConfig ] = useState()
    const [clientSecret, setClientSecret] = useState("");
    // const [stripePromise, settripePromise] = useState("");
 
    const [ paymentText,setPaymentText ] = useState('')

    const { modalOpen, setModalOpen,setCart,order,
    currentProduct , setCurrentProduct, setView, view,musicCart,
    } = useContext(StorefrontContext);

  // const handleSubmit = (response) => {
  //   console.log('Received response:', response);
  // };
  // // <Container >
  // // </Container>

useEffect(()=>{
try{

  const fetchPaymentConfig = async () =>{
    const res = await API.fetchPayKeys()
    // console.log('[fetchPaymentConfig]', res.data)
    if (res){ 
      setConfig(res.data)
    }

     else {return}
   }
   fetchPaymentConfig()
}catch(err){console.log(err)}

},[])
// console.log('process.env.STRIPE_PUBLISHABLE_KEY',config)

const stripePromise = loadStripe(config?.STRIPE_PUBLISHABLE_KEY);
// const clientKey = loadStripe(config?.STRIPE_SECRET_KEY);
 
// const options = {
//     // passing the client secret obtained from the server
//     clientSecret: config?.STRIPE_PUBLISHABLE_KEY ,
//   };

 useEffect(() => {
   console.log('/payment/createInten...cart',cart)
   console.log('/payment/createInten...musicCart',musicCart)
    fetch(`${ENDPOINT[0]}/payment/createIntent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: type === 'music'? musicCart?.items :cart?.items, cart_id: type === 'music'?musicCart?._id:cart?._id}),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log('/payment/createInten',data)
        setClientSecret(data?.clientSecret)});
  }, []);


  const appearance = { theme: 'stripe' };
  const options = { clientSecret, appearance };

   

  return (
    <>
     {clientSecret &&<Elements 
      stripe={stripePromise}
        options={{clientSecret}}
        // options={options}
      >
       
        <CheckoutForm cart={cart}/>
      </Elements>}
      {paymentText? paymentText: ''}
    </>
        // <HostedForm
        // buttonStyle={{textTransform:'uppercase',fontFamily:'Roboto',fontWeight:'bold',
        // padding:'0.5rem 1rem',boxShadow:'none', border:'none',width:'80px',
        // borderRadius:'5px',color:'#fff',backgroundColor:'rgba(24,118,210,1)',}}
        // environment='SANDBOX'
        // authData={authData} onSubmit={handleSubmit}
        // orderAmount={props?.total}
        // >
       
        // </HostedForm> 
  )
};

export default Payment;

function CheckoutForm(props) {
  const {cart} = props
  //  const { modalOpen, setModalOpen,cart,setCart,order,
  //   currentProduct , setCurrentProduct, setView, view,musicCart,
  //   } = useContext(StorefrontContext);
  const stripe = useStripe();
  const elements = useElements();
  console.log('[Just the Cart]',cart)
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('---submit click----',cart)
    if (!stripe || !elements) {
          // Stripe.js hasn't yet loaded.
          console.log('---No stripe loaded----')
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
//  let finalCart_id ='test'
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/order/complete/"+cart?._id,
        // return_url: `https://qaura.pro/cart/complete/${cart?._id}`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  // Your form logic here
  return(
    <>
     <form onSubmit={handleSubmit}>
          <PaymentElement 
            layout= {{
              type: 'accordion',
              defaultCollapsed: false,
              radios: true,
              spacedAccordionItems: false
            }}
            />
         
          <Button  style={{margin:'1rem auto'}}
            variant='contained' type="submit"disabled={!stripe}>Pay</Button>
        </form>
    </>
  )
}