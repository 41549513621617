import React , { useState, useEffect, useContext } from 'react';
import { AppProvider, AppContext, AppState } from "../context/AppProvider";
import * as API from '../utils/APICalls'

import colors from '../defaults/ThemeDefaults'

const ThemeColorPicker = () => {
    const { appData, updateAppData, appTheme, setAppTheme, modalOpen, 
    setIsLoggedIn, userData,setUserData } = AppState();
    const [ isSaved, setIsSaved ] = useState(true)
    const [ hue, setHue ] = useState(0)
    const [ showOptions, setShowOptions ] = useState(false)
    const [ rotDegree, setRotDegree ] = useState(45)
    let gradVar1 = `rgba(253, 192, 247, 0.3)`
    let gradVar2 = `rgba(255, 234, 0, 0.3)`
    let gradVar3 = `rgba(16, 174, 216,0.3)`
    // let gradVar3 = `hsl(${hue}deg 16.5% 50.49%)`
    let gradVar4 = `hsl(${221-hue/5}deg, 50%, 70%)`

    const themeColors= {} = colors
    // const themeColors = {
    
     
    //   aura:{
    //     bg:`linear-gradient( ${rotDegree}deg,
    //       ${gradVar1} 10%, ${gradVar2} 34%,${gradVar3} 50%, ${gradVar4} 90%),
    //       linear-gradient(50deg, 
    //       #ba4aff, rgba(	186, 74, 255,0 ) 70%),
    //       linear-gradient(178deg,
    //       #008aff, rgba( 0, 138, 255, 0 ) 50%),
    //       linear-gradient(24deg,
    //       #00ffc6, rgba(	0, 255, 198, 1) 35%)
    //       no-repeat`,
    //     tx:'rgba(0, 0, 0,1)',
    //     txAccent:'rgba(0, 0, 0,1)',
    //     },
    //      dark:{
    //     bg:'rgba(0, 0, 0,0.9)',
    //     tx:'rgba(0, 0, 0,1)',
    //     txAccent:'rgba(255, 255, 255,1)',
    // },
    //   light:{
    //     bg:'rgba(250, 241, 227,0.9)',
    //     tx:'rgba(0, 0, 0,1)',
    //     txAccent:'rgba(0, 0, 0,1)',
    // },
    //  custom:{
    //     bg:'',
    //     tx:'rgba(0, 0, 0,1)',
    //     txAccent:'rgba(255, 255, 255,1)',
    //     },
    //   none:{
    //     bg:'',
    //     tx:'rgba(0, 0, 0,1)',
    //     txAccent:'rgba(255, 255, 255,1)',
    //     },
     
    // }
  return (
    <div  style={{
        // transform:showOptions?'translate(0,110px)':'',
        width:'fit-content',   
        display:'flex',
        flexDirection:'column',
        padding: '0.25rem',
        background:'rgba(0,0,0,0.5)',
        border:'2px solid #FFF', borderRadius:'8px', 
        boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.5)',
        zIndex:999}}>

    <div style={{
        width:'150px',
        display:'flex',
        flexDirection:'row',
        
        justifyContent: 'center',
        alignItems: 'center',
        gap:'0.5rem'

            }}
                >
                {Object.keys(themeColors).map((opt,i)=>{
                    return(

                        <div title ={ opt ==='custom'?opt+' (coming soon)':opt}
                            key={i}
                            style={{
                            name:{opt},
                           width: '1rem', height:'1rem',border:`1px solid ${themeColors[opt]?.tx}`, 
                           background:themeColors[opt]?.bg?themeColors[opt]?.bg:'#fff',
                           cursor:'pointer',
                           alignItems:'center',
                           borderRadius:'5px', boxShadow:'0px 0px 2px 1px rgba(255,255,255,0.5)'}}
                           onClick={async(e)=>{
                                if (opt === 'custom'){

                                    setShowOptions(!showOptions)
                                }else{
                                    setShowOptions(false)
                                    setAppTheme({
                                        ...appTheme,
                                        BGcolorMain: themeColors[opt]?.bg,
                                        TXcolorMain:themeColors[opt]?.tx,
                                        TXcolorAccent:themeColors[opt]?.txAccent
                                    })
                                      setUserData({
                                    ...userData,
                                    appTheme: {

                                        BGcolorMain: themeColors[opt]?.bg,
                                        TXcolorMain:themeColors[opt]?.tx,
                                        TXcolorAccent:themeColors[opt]?.txAccent
                                    }
                                })
                                    updateAppData({
                                     ...appData,
                                     userData,
                                     appTheme
                                    })
                               setIsSaved(false)
                                // const data = await API.updateUser({...userData,appTheme:{...appTheme}})

                                }
                           }}
                           >{opt ==='custom' ?<img 
                src={`/assets/eye-dropper-solid.svg`} 
                style={{height:'.75rem'}}/>: opt==='none'?<img 
                src={`/assets/close.svg`} 
                style={{height:'.75rem'}}/>:<>&nbsp;</>}
                               </div>
                    )
                })}
            </div>
            {showOptions &&
             <section style={{
                width:'100%',
                display:'flex', 
                flexDirection:'column', 
                fontSize:'0.75rem',

                alignItems:'center', 
                justifyContent:'center',
                color:'#FFF'
                }}> 
              { Object.keys(appTheme).map((att,i)=>{
                  return(
                    
                    <span 
                        style={{
                            display:'flex', 
                            flexDirection:'row',
                            alignItems:'center', 
                            margin: 'auto',
                            justifyContent:'center',
                            width:'100%'}}
                            key={'themElem-'+i}
                            >

                {att}:
                    <input type='color'
                        style={{
                            width:'1.5rem', 
                            height:'1.5rem',
                            padding:0, 
                            }}
                        value={appTheme[att]}
                        onChange={async(e)=>{
                                setAppTheme({
                                    ...appTheme,
                                    [att]:e.target.value
                                })
                                setUserData({
                                    ...userData,
                                    appTheme:{
                                        ...appTheme,
                                        [att]:e.target.value
                                    }
                                })
                                updateAppData({
                                    ...appData,
                                    appTheme,
                                    userData
                                })
                                setIsSaved(false)
                                // const data = await API.updateUser({...userData,appTheme})
                                // console.log('[Color Picker]', data)
                            }}
                />
                
                </span>
                )
              })}
             </section>
             }
             {!isSaved && 
                   <button className='save-button'
                       style={{
                           color:'#000000',
                           backgroundColor:'#FFFFFF', 
                           height:'0.85 rem', 
                           fontSize:'0.7rem',
                           margin:'0.25rem auto' 
                        }}
                           onClick={async(e)=>{
                            e.preventDefault()
                               try{
                                   console.log('userdata: saving-----')
                                   const data = await API.updateUser({...userData,appTheme})
                                //    localStorage.setItem('QauRaUser', JSON.stringify(data))
                                   console.log('userdata:',data)
                                   setIsSaved(true)

                               }catch(err){console.log(err)}

                           }}
                           >
                      save
                   </button>
                  } 
    </div>
  )
}

export default ThemeColorPicker