import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Snackbar, Stack, Typography } from '@mui/material'
import { useEffect, useRef, useState, useContext } from "react";
import { SignupContext, SignupProvider}  from "../context/SignupProvider";
import ConfirmSignupModal from './ConfirmSignupModal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const AddToCartButton = (props) => {
    // const {item, size, quantity, price } = {...props}
    const [actionError, setActionError ] = useState('')
    const [isAdding, setIsAdding ] = useState(false)
    // const [snackOpen, setSnackOpen ] = useState(false)
    let snackOpen = props.snackOpen
    let setSnackOpen = props.setSnackOpen

    const { 
        signupData, 
        setSignupData,
        updateSignupData, 
        tempOrder,
        setTempOrder,
        updateTempOrder,
     cart, updateCart,setCart} = useContext(SignupContext);
    const [open, setOpen ] = useState(false);


    let cartObj = {
        item_type: props.item,
        size: props.size,
        price: props.price,
        quantity: props.quantity
    }

    const handleSnackClose  = () =>{
        setSnackOpen(false)
    }
     const handleClose = () => {
        setOpen(false);
    };
    const handleAddToCart = async (e) =>{
        setIsAdding(true)
        let tempCart =[]= cart? cart: []
        
        // e.preventDefault()
        
        try{
            if(cartObj?.price  && cartObj?.quantity > 0 ){
                        setActionError('')
                        
                        
                        if(tempCart?.length > 0){
                            tempCart.push(cartObj)
                            setCart(tempCart)
                            updateCart(tempCart)

                            // updateCart([...cart,{...cartObj}])
                        }else{
                            tempCart=[{...cartObj}]
                            setCart(tempCart)
                            // setCart([{...cartObj}])
                            updateCart(tempCart)

                            // updateCart([{...cartObj}])
                        }
                        // if(cart?.length > 0){
                            //     updateCart([...cart,{...cartObj}])
                            // }else{updateCart([{...cartObj}])}
                            
                            console.log('[ADD TO CART] tempCart:', tempCart)
                            setOpen(true)
                            await setCart(tempCart)
                            updateSignupData({
                                    ...signupData,
                                    cart
                                })
                        console.log('[ADD TO CART] Name:',cartObj?.item_type,'| Size:',cartObj?.size,' | Price:', cartObj?.price,
                         ' |Quant:', cartObj?.quantity, ' |Total: $',cartObj?.quantity * props?.price)

                         
                         // localStorage.setItem('cart',[])
                         localStorage.setItem('cart',JSON.stringify(tempCart))
                         let locCart = localStorage.getItem('cart')
                    
                         
                         if(locCart){
                             let temp  = JSON.parse(locCart)
                             if (temp) {setCart(temp)}
                            }   
                            console.log('[ADD TO CART] cart:',cart)
                        setSnackOpen(true)
                        // setSnackOpen(false)
                        setIsAdding(false)
                    }else{
                        setIsAdding(false)
                        setActionError('Item not filled out completely')
                        console.log('Item not filled out completely')
                        console.log('cartObj:', cartObj)
                }
                    }catch(err){
                        setIsAdding(false)
                        console.log(err)
                    }
        
    }
    useEffect(()=>{

    },[cart,isAdding,open])

  return (
    <Box >
         <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
       sx={{m:0,p:2,width:'100%',minWidth:'250px'}}
   
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add To Cart
        </DialogTitle>
        <DialogContent dividers  sx={{width:'fit-content',minWidth:'270px'}}>
        <Box sx={{position:'relative',display:'flex', flexDirection:'column'}} >
           {cartObj && <Stack sx={{display:'flex', flexDirection:'row',fontSize:'1rem', justifyContent:'baseline'}}>
                <b style={{width:'30%',maxWidth:'150px'}}>{cartObj?.item_type}</b>
                <Stack sx={{display:'flex',flexDirection:'row', flex:1,justifyContent:'space-between'}}>
                    <span style={{width:'30%', maxWidth:'100px'}}>
                    &nbsp;({cartObj?.size}) 
                    </span>  
                    <b style={{width:'30%',maxWidth:'50px',textAlign:'center'}}> 

                    {cartObj?.quantity}
                    </b>
                    <span style={{width:'30%',maxWidth:'50px',textAlign:'center'}} >
                    @ ${cartObj?.price}
                    </span>
                    &nbsp;=&nbsp;
                    
                    <span style={{width:'30%',maxWidth:'50px',textAlign:'center'}}>
                        ${cartObj?.price * cartObj?.quantity}
                    </span>
                </Stack>
            </Stack>}
            <Divider />
            <Button variant='contained' sx={{margin:'auto',mt:2, mb:1,}} 
            onClick={async(e)=>
                // await handleAddToCart(e)
                
                handleClose()
            
            } disabled={isAdding}>
                Add{isAdding?'ing ':' '}To Cart
            </Button>
        </Box>
        </DialogContent>

       </Dialog>
        <Typography sx={{color:'orange',fontSize:'0.75rem', fontWeight:'bold'}}>
            {actionError}
        </Typography>
        <Button variant='outlined' onClick={async(e)=>
            await handleAddToCart(e)} disabled={isAdding}>
            Add{isAdding?'ing ':' '}To Cart
        </Button>
    </Box>
  )
}

export default AddToCartButton