import React,{ useState, useEffect, useContext } from 'react'


import EnvRoute from '../../middleware/envRoute';
// import {AppState} from '../../context/AppProvider';
import { AppContext } from "../../context/AppProvider";

import  {useToast} from "@chakra-ui/react";
import axios from "axios";
import LogoCopy from '../LogoCopy';
import * as API from '../../utils/APICalls'
import { Button, TextField,Input, InputAdornment, IconButton  } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



const ENV_ENDPOINT =[]= EnvRoute('prod'); 





const Login = (props) => {
   const { view } = props
    const toast = useToast();
    const { appData, updateAppData,modalOpen ,
      userData, setUserData,appTheme, setAppTheme, actionError, setActionError, setActionText ,actionText} = useContext(AppContext);
    
    
    // const { userData, setUserData } = AppState()
      // const [ actionError, setActionError ] = useState(false)

        
      const [ email , setEmail ] = useState('');
      const [ password , setPassword ] = useState('');
      const [ show, setShow ] = useState(false)
      const [ loading , setLoading ] = useState(false)
      const [ isloggedIn , setIsLoggedIn ] = useState(false)
     

const updateUser = async(obj) =>{
  try{
    const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
    const { data } = await axios.put(
           `${ENV_ENDPOINT[0]}/user/update`,
           {
             email,
             ...obj,
           },
           config
         );
  }catch(err){console.log(err)}
}    
    const handleShowClicK = (e) => {
      e.preventDefault()
       setShow(!show)
      }

    
    const handleLogin = async (e) => {
        e.preventDefault()
        setLoading(true)
        setActionError('')
          if ( !email || !password ) {
        setActionError('please fill all the fields')
        setLoading(true)
      }
      try{
              const data = await API.Login({email,password});
              console.log('Login testing -->data: ', data)
              if(data === 'unauthorized'){setActionError('invalid email and/or password')}
              if ((data && data !== 'undefined' && data !== 'unauthorized' )){
                //  if ((data)){
                  let user = data?.data?.user
                 if(user?._id) setActionText('Login successful')
                if(user?.appTheme)
                {
                  setAppTheme({...user?.appTheme})
                  
                }
                setUserData(user)
                  // updateAppData({...appData,userData:data,appTheme:data?.appTheme})
                  // console.log('login data: ', data)
                  console.log('login userData: ', userData)
                  localStorage.setItem('QauRaUser', JSON.stringify(user))
                  
                  setUserData({...JSON.parse(localStorage.getItem('QauRaUser'))})
                  setLoading(false)
                  //  if(userData){

                     updateAppData({
                      ...appData,
                      userData,
                      modalOpen:false,
                      modalView:''
                    })
                    setActionText('')
                    if(view !== 'stream'){
                      window.location.assign(`/dashboard/${user?._id}`)
                    }
              
                }
            }catch(err){ 
              console.log('Login Error: ', err)
              setActionError('email and password do not match')
            setLoading(false)}
            // }
    //       } else{
    //   setActionError('unable to process at this time')
    //   setLoading(false)

    // }
    }

useEffect (()=>{

},[userData])



  return (
    <>{/* <div style={{display: 'flex', alignItems:'center', justifyContent:'center',margin:'auto',width: '60%', height:'50%'}}> */}
    <div className=''
     style={{color: '#000',display: 'flex', flexDirection: 'column', height:'100%'}}>

    <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
         <div className="sm:mx-auto sm:w-full sm:max-w-sm" >
          {/* <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          /> */}
          <div style={{display:'flex', flexDirection:'column', marginBottom:'0.75rem'}}>
            <LogoCopy />
            {/* <h3 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 mt-0.5" style={{margin: '0',color:"#fff"}}>
              QauRa
            </h3> */}
          </div>
            <hr  className='color-grad-blue-border' style={{boxShadow:'0px -4px 10px 5px rgba(250,250,250,0.2)', margin:'0.5rem auto'}}/>
            
          {/* <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900" style={{margin: '0',color:"#fff"}}>
            Join Room
          </h2>
          {room?.roomName} */}
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <form className="space-y-6" action="#" method="POST">

             <div style={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>

              {/* <div className="flex items-center justify-between">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 formLabel">
                    email:
                </label>
              </div> */}

              <div className="mt-2">
                <Input
                  id="email"
                  name="email"
                  type="email"
                  label ="email"
                  autoComplete="email"
                  placeholder='email'
                  onChange={(e)=>setEmail(e.target.value.toLowerCase())}
                  value ={email}
                
                  required
                
                  // className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
           
             
             <div className="flex items-center justify-between">
              {/* <span style={{display:'flex',flexDirection:'row', width: '100%'}}> */}
              {/* <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 formLabel">
                    password:  */}
                     {/* <Button 
                     variant="contained"
                    //  className="flex w-full justify-center rounded-md bg-indigo-600  py-0.25 text-xs font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 blink"
                style={{//boxShadow:'0px 0px 5px 4px rgba(0,0,0,0.3)', 
                margin: '0.75rem auto',alignSelf:'flex-end'}}
                      onClick={(e)=>handleShowClicK(e)}>
                    {show ? "hide" : "show"}
                  </Button> */}
                {/* </label> */}
                {/* </span>   */}
              </div>
             <div className="mt-2" style={{display:'flex', flexDirection:'row'}}>
              <Input 
                label ='password'
                type={show ?'text':'password'}
                placeholder='password'
                onChange={(e)=>setPassword(e.target.value)}
                // className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
                // border={'1px solid #868686'}
                // color={'#868686'}
                 endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowClicK}
                  onMouseDown={handleShowClicK}
                  edge="end"
                >
                  {show? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
                />
           
             </div>
  </div>
        

             <div style={{display:'flex', flexDirection:'column', justifyContent: 'center'}}>
              
              <div className="flex items-center justify-between " style={{border:'0', alignSelf: 'center', color:"#868686"}}>
                

              </div>
              {/* <select value={role}  defaultValue={role} onChange={(e)=>setRole(e.target.value)} style={{color: '#868686'}}>
                <option selected value="guest">guest</option>
                <option value="host">host</option>
              </select> */}

             
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              
            {/* <h3 style={{color: user?.token?"limegreen":"red", alignSelf:'center',margin: 'auto'}}>{actionError?actionError:''}</h3> */}

             {actionError ? <h3 style={{color: "red", margin: 'auto',fontSize:'0.85rem'}}>{actionError}</h3>:''}
             {actionText ? <h3 style={{color: "limegreen", margin: 'auto',fontSize:'0.85rem'}}>{actionText}</h3>:''}
            <div >
              {/* className={loading?'blink':''}> */}
              <Button
                type="submit"
                //!add blink
                variant="contained"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 blink"
                style={{//boxShadow:'0px 0px 5px 4px rgba(0,0,0,0.3)', 
                marginTop: '0.75rem'}}
                onClick={handleLogin}
                //  isLoading= {loading}
              >
                {loading? "Logging":"Log"} in
              </Button>
            </div>
            </div>
            
          </form>
          <div style={{display:'flex', margin: 'auto', alignItems: 'center', color:'#868686', margin: '0.5rem auto'}}>
            © 2023 RexEntropyLLC
        </div> 
          {/* <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <a href='#' className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Sign up!
            </a>
          </p> */}
        </div>
    </div>
    </div>
   {/* <div style={{display:'flex', margin: 'auto', alignItems: 'center', color:'#868686'}}>
     
     © 2023 RexEntropyLLC
     </div>  */}
    </>
  )
}


export default Login