import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";
import axios from 'axios'

import EnvRoute from '../middleware/envRoute';
import * as API from '../utils/APICalls'
import useWindowDimensions from "../hooks/windowDimensions";

import QROptions from './QROptions'
import PersonaOptions from './PersonaOptions'
import { TextField, Typography, Box } from '@mui/material';
import PayloadTypeSelect from './PayloadTypeSelect';


const ENDPOINT = EnvRoute('prod'); 

const QRFieldComponent = (props) => {
    /*
if view === "create" setTempPersona() && hide save button
if view === "edit" setTempPersona({...}) setCurrentPersona({...tempPersona}) const data = await API.updatePersona(currentPersona) 
*/ 
    //    const ENDPOINT = 'http://localhost:5003/api'
    // const ENDPOINT = 'https://qaura-api.onrender.com/api'
    const { height, width } = useWindowDimensions();

    const { object, value, name, sub, edit_on, type ,view,use, payload_type} = props
    const [ edit, setEdit ] = useState(edit_on)
    const { appData, updateAppData, assetQR, setCurrentAsset, currentAsset,newAsset,
            setNewAsset, currentPersona, setSelectedQR,selectedQR } = useContext(AppContext);
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const [ showList, setShowList ] = useState(false)

    const [ tempAsset, setTempAsset ] = useState(newAsset)
    const [ tempQR, setTempQR ] = useState( view ==='create-QR' ? qrCodeData : selectedQR )
    const [ isSaved, setIsSaved ] = useState(true)
    const [ loading, setLoading ] = useState(true)
    const [ picLoading, setPicLoading ] = useState(false)
    const [ pic, setPic ] = useState(false)
    //  console.log("object:",name)

    const checkType = () =>{
        if( name ==='name'|| name ==='_id'|| name ==='owner' ){
            return 'text'
        }
        if( name ==='payload'  &&  payload_type ==='image'){
            return 'file'
        }
        if( name ==='payload'  && ( payload_type ==='file'  ||  payload_type ==='url')){
            return payload_type.toString()
        }
        if( name ==='height' || name ==='width' || name ==='margin'){
            return 'number'
        }
        // if( name ==='isPhysical'|| name ==='isActivated' || name ==='useFieldMode'){
        //     return 'checkbox'
        // }
        // if(name ==='pic' ){
        //     return 'file'
        // }
        
    }
    
    const postPicDetails = (pics) => {
    setPicLoading(true);
    if (pics === undefined) {
        console.log("pics undefined")
        return;
    } 
    console.log(' cloudinary pics [pre]:',pics?.type);
  
  if (pics?.type === "image/jpeg" || pics?.type === "image/png" || pics?.type === "image/gif") {
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "TinCan");
      data.append("cloud_name", "dzxzdsnha");
      fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          setPic(data?.url?.toString());
          console.log('cloudinary data url: ',data?.url?.toString());
          
          setPicLoading(false);
          return data?.url?.toString()
        })
        .catch((err) => {
          console.log(err);
          setPicLoading(false);
        });
    } else {
     
      setPicLoading(false);
      console.log('Error Saving file')
      return;
    }
  }
     const handleChange =async(e) =>{
        let { name, value, files } = e.target
        let val 
        try{
         if ( checkType() === 'file' ){
                
               val = await postPicDetails(files[0])
                console.log('Post pic val',files[0])
                
             }else {val = value}
                if(view === 'create-QR'){
                    updateQRCodeData({ 
                            ...qrCodeData,
                            [name]: val
                        })
                }else{
                    setSelectedQR({ 
                        ...selectedQR,
                        [name]: val
                    })
                    updateAppData({
                        ...appData,
                        selectedQR
                    }
                    )
                    }
            }catch(err){console.log(err)}
           
            // setNewAsset({...newAsset})
            setIsSaved(false)

        // }
       
       
    }
   const viewBasedQR = async () =>{
    if(view === 'edit-QR'){return selectedQR}
    if(view === 'create-QR'){return qrCodeData}
   }
    const shortfield = () =>{
        if(name === 'width' || name === 'height' || name === 'margin' ){return '45px'}
        else{return null}
    }               
    const handleItemSave = async () =>{
        // if(name === 'payload'){
        //     updateQRCodeData({...qrCodeData, payload:tempQR?.payload});
        //     try{
        //         updateQRCodeDb({...tempQR})
        //     }catch(err){console.log(err)}
        // }else{
            // setCurrentAsset({...currentAsset,[name]: newAsset[name]});
            let qr = await viewBasedQR()
        // if (view === 'edit-QR')
            try{
                console.log('[QRFieldComponet] save [pre]:',selectedQR)
                // updateAssetDb({...currentAsset})
                if(view === 'create-QR'){
                    const data = await API.updateQRCode({...qrCodeData})
                    console.log('[QRFieldComponet] save:',data)
                   if(data){
                       updateQRCodeData(data)
                       
                   }
                }else{
                    const data = await API.updateQRCode({...selectedQR})
                    console.log('[QRFieldComponet] save:',data)
                    
                    if(data){
                       
                        updateAppData({...appData,selectedQR})
                    }
                }
            }catch(err){console.log(err)}
        // }
        setIsSaved(true)
    }
    useEffect(()=>{
        // setNewAsset({...tempAsset})
    },[newAsset,tempQR,qrCodeData,currentAsset,selectedQR,object, payload_type])
 
    useEffect(()=>{
    //   if(view === 'create-QR'){
    //     setSelectedQR(qrCodeData)
    //     return
    //   }
    //   if(view === 'edit'){
    //     setSelectedQR(qrCodeData)

    //   }
    },[])
 
  return (
    <div style={{
        display:'flex', 
        flexDirection:'column',
        alignItems:'center',
        width: '100%',
        gap:'0.5rem',
        // border:'1px solid red'
        }}>
    {/* {currentPersona &&
   (  */}
   
   <span style={{
        display:'flex', 
        flexDirection: width<600 ?'column':'row', 
        gap: '0.5rem',
        padding:'0.25rem',
        justifyContent:'center', 
        alignItems:'center', 
        alignSelf:'center', 
        width: checkType()==='checkbox'?'150px':edit?'80%':'70%'}}>
                {/* {!edit && (name !=='links') && <b>{name}:</b>}
        <div style={{
            cursor: 'pointer',
            padding: '0.125rem 0.25rem',
            borderRadius: '5px',
            border: '1px solid #868686',
            backgroundColor:checkOn()?'rgba(61, 255, 158, 0.628)':'rgba(253, 0, 0, 0.628)'}}>{checkOn()? 'on': 'off'}</div> */}
        
        <div style={{
            display:'flex',
            flexDirection:width <= 600 ?'row':'column', 
            gap:'0.25rem', 
            alignSelf:'center',
            fontSize:'0.75rem',
            alignItems: view ==='edit-QR' || view ==='create-QR'?'left':'center'}}>
            
            {(name !== 'name' && use !=='header') && <b style={{whiteSpace:'nowrap'}}>
                <>{name}</>:</b>}
                    
           {use !=='header' &&<div style={{color: isSaved?'#000000':'red',
                width:'fit-content',
                fontSize:'0.5rem',
                padding:'0.125rem ',
                border:`2px solid ${isSaved?'#00FF00':'red'}`,
                borderRadius:'5px 0px',
                backgroundColor:'rgba( 255,255,255,0.5)',
                // backgroundColor:'rgba( 0,0,0,0.5)',
                backdropFilter: 'blur(5px)'
                }}>
                {!isSaved?'unsaved':'saved'}
            </div>}
        </div> 

        {name!=='_id' && (
            // (edit && checkType() === 'text')
            (edit)
        ?<div style={{
                display:'flex', 
                flexDirection:'column',
                alignItems:'center', 
                justifyContent:'center', 
                gap: '0.5rem'}}>
        {(name ==='payload' && (view === 'create-QR' || view === 'edit-QR')) && 
              <h6>(link a persona, url,promo code, email address, etc...)</h6>}
        {name ==='payload' &&
              <PayloadTypeSelect/>}

      {name !== 'payload' &&  <TextField className='qr-item-text' id='qr-item-text'
            min={ name === width || name === height ? name === 'margin' ?'0':'100':''}
            max={ name === width || name === height ? name === 'margin' ?'50':'500':''}
            step={ name === width || name === height ? name === 'margin' ? '1':'5':''}
            style={{width:'100%', minWidth: shortfield()?'45px':'150px',maxWidth:shortfield()?'45px':'300px'}}
            type={checkType()}
            defaultValue={view === 'edit-QR'?selectedQR[name]:view === 'create-QR'?qrCodeData[name]:selectedQR[name]}
            name={name} onChange={handleChange}/>}

      {( name === 'payload' && ( payload_type === checkType() || payload_type === 'image' )) && <TextField className='qr-item-text' id='qr-item-text'
            min={ name === width || name === height ? name === 'margin' ?'0':'100':''}
            max={ name === width || name === height ? name === 'margin' ?'50':'500':''}
            step={ name === width || name === height ? name === 'margin' ? '1':'5':''}
            style={{width:'100%', minWidth: shortfield()?'45px':'150px',maxWidth:shortfield()?'45px':'300px'}}
            type={checkType()}
            defaultValue={ checkType() ==='file'?'':  view === 'create-QR'?qrCodeData[name]:selectedQR[name]}
            name={name} onChange={handleChange}/>
        }
            
        {( name === 'payload' && ( payload_type !== checkType()) && payload_type !== 'image') && <TextField className='qr-item-text' id='qr-item-text'
            min={ name === width || name === height ? name === 'margin' ?'0':'100':''}
            max={ name === width || name === height ? name === 'margin' ?'50':'500':''}
            step={ name === width || name === height ? name === 'margin' ? '1':'5':''}
            style={{width:'100%', minWidth: shortfield()?'45px':'150px',
                maxWidth:shortfield()?'45px':'300px'//, minHeight:'5rem'
            }}
            multiline
            rows={4}
            type={checkType()}
            defaultValue={view === 'edit-QR'?selectedQR[name]:view === 'create-QR'?qrCodeData[name]:selectedQR[name]}
            name={name} onChange={handleChange}/>
        }
           
        
             
        </div> 
        :( selectedQR ) && <p style={{width:'200px',textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}> 
        {selectedQR[name]}</p>
        ?(<Typography className='asset-item-text' 
            style={{
                fontWeight: use === 'header'?'bold':'',
                fontSize: use === 'header'?'1.25rem':'1rem',
                width:'300px',
                textOverflow: 'ellipsis', 
                whiteSpace: 'nowrap', 
                overflow: 'hidden',
        }}>{view === 'edit-QR'?selectedQR[name]:view === 'create-QR'?qrCodeData[name]:assetQR[name]}</Typography>)
        :<p className='blink-2'>please provide</p>
        )}
        {/* {name!=='_id' && (edit
        ? <input className='asset-item-text' id='asset-item-text'
            style={{width:'100%', minWidth: '150px',maxWidth:'300px'}}
            type={checkType()}
            defaultValue={selectedQR[name]}
            name={name} onChange={handleChange}/>
        :selectedQR[name]
        ?(<p className='asset-item-text' 
            style={{
            width:'200px',textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
        }}>{selectedQR[name]}</p>)
        :<p className='blink'>please provide</p>
        )} */}

<div style={{position:'relative',display:'flex', flexDirection:'column'}}>

           
           <div style={{position:'relative',display:'flex', flexDirection: edit?'column':'row', gap: '0.25rem'}}>
               {!edit && name ==='qr_id'&&(<b>{assetQR?.name? assetQR?.name :'please select a QR'}</b>)}
               {edit && name ==='qr_id'&&<QROptions view={view}/>}

            {(name ==='payload') && (edit) && 
            <div className="button-accent-hover"
                style={{
                    position: !showList ? 'relative'
                    : view === 'edit-QR' || view === 'create-QR'? 'absolute':'relative',
                    // ,bottom: showList?'100%':'0',
                    top: (showList && view === 'edit-QR' || view === 'create-QR' )?'50%':'0',
                    left: !showList?'0px': view === 'edit-QR' || view === 'create-QR'? width>=600?'-50%':'-50%':'-50%',
                    transform: (showList && view === 'edit-QR' || view === 'create-QR' )?'translate(-50%,-50%)':'',
                    // left: !showList?'0px': view === 'edit-QR' || view === 'create-QR'? width>=600?'-250px':'-80px':'10px',
                    zIndex: showList?'99999':'10',
                    display:'flex', 
                    flexDirection: showList && edit ?'column':'row',
                    alignItems:'center', gap:'0.5rem', padding:'0.5rem',
                    marginLeft: width>=500? '0.5rem':''
                    }}> 
                    
            <div style={{
                dispay:'flex', flexDirection:'row',
                cursor: 'pointer',
                padding: '0.25rem',
                borderRadius: '5px',
                border: '2px solid #FFFFFF',
                boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                padding: !edit?'0 0.125rem':'0.5rem',
                backdropFilter:'blur(3px)',
                backgroundColor: !edit?'rgba(253, 156, 0, 0.628)':'rgba(61, 255, 158, 0.628)',
                zIndex: '999999'
                }} 
               onClick={()=> {
                if(!showList){setShowList(true)}
                }}
                >
                    <div style={{dispay:'flex', flexDirection:'row',position:'relative',whiteSpace:'nowrap',
                        borderBottom:'2px solid #FFFFFF',paddingBottom:'0.5rem', width:'100%'}}>
                    
                        link to persona as...
                        {showList&&<img  width='10rem'
                        className="button-accent-hover"
                        onClick={()=>{setShowList(false)}}
                        style={{cursor:'pointer',position:'absolute', right:'0.125rem', top:'0.125rem'}}
                        src='/assets/close.svg' alt='close link to QR options'/>}
                    </div>
                    <img style={{marginTop:'0.5rem'}} width='55rem' src='/assets/LinkToPersona.svg' alt="link to persona"/>
                    
                    {showList &&  <PersonaOptions view={view} preOpen={showList} useCase='persona'/>}
                
                    {showList &&  <PersonaOptions view={view} preOpen={showList} useCase='profile'/>}
                 
                </div>
                </div>
                }
               {/* {(name ==='payload') &&   <Box>
                    link to...
                </Box>} */}
              
       {view !=='create-QR' && (
            <div style={{display:'flex', flexDirection:edit?'row':'column',alignItems:'center',justifyContent:'center', gap: '0.5rem'}}>
       
       {(name === 'name' && use !=='header') &&<div style={{color: isSaved?'#000000':'red',
                width:'fit-content',
                fontSize:'0.5rem',
                padding:'0.125rem ',
                border:`2px solid ${isSaved?'#00FF00':'red'}`,
                borderRadius:'5px 0px',
                backgroundColor:'rgba( 255,255,255,1)',
                // backgroundColor:'rgba( 0,0,0,0.5)',
                backdropFilter: 'blur(5px)'
                }}>
                {!isSaved?'unsaved':'saved'}
            </div>}

            <div className='button-accent-hover'
                style={{
                    display: 'flex',
                    gap:'0.25rem',
                    cursor: 'pointer',
                    padding: '0 0.125rem',
                    borderRadius: '5px',
                    border: '2px solid #FFFFFF',
                    boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                    padding: !edit?'0 0.125rem':'0.125rem 0.125rem 0  0.125rem',
                    backgroundColor: !edit?'rgba(253, 156, 0, 0.628)':'rgba(61, 255, 158, 0.628)'}} 
                    onClick={()=>{
                            if(edit){
                                handleItemSave()
                            }
                            setEdit(!edit)
                            }
                        }
                    >
                        {!isSaved && <div style={{color: isSaved?'#000000':'red',
                        width:'fit-content',
                        fontSize:'0.75rem',
                        padding:'0.125rem ',
                        fontWeight:'bold',
                        border:`2px solid ${isSaved?'#00FF00':'red'}`,
                        borderRadius:'5px 0px',
                        backgroundColor:'rgba( 255,255,255,1)',
                        // backgroundColor:'rgba( 0,0,0,0.5)',
                        backdropFilter: 'blur(5px)'
                        }}>
                        {!isSaved?'unsaved':'saved'}
                    </div>}
                    {!edit && <img width='20rem' src='/assets/pencil.svg' alt="edit item"/>}
                    {(edit && view !== 'create-QR' ) && <img width='17rem' src='/assets/save.svg' alt="save item"/>}
                    </div>
                    {edit && <div  style={{
                            cursor: 'pointer'}}
                            onClick={()=>{
                                if( view ==='create-QR' ){
                                    updateQRCodeData(tempQR)
                                }else{
                                    setSelectedQR(tempQR)
                                    updateAppData({
                                            ...appData,
                                            selectedQR
                                        })
                                }
                                setIsSaved(true)
                                setEdit(!edit)
                            
                            }}
                            > cancel </div>}
                     </div>
                )}
                 </div>
        </div>
    {/* <div style={{color: isSaved?'#000000':'red',fontSize:'0.75rem',padding:'0.125rem ',
    border:`2px solid ${isSaved?'#00FF00':'red'}`,borderRadius:'5px',
    backgroundColor:'rgba( 255,255,255,0.5)',
    // backgroundColor:'rgba( 0,0,0,0.5)',
    backdropFilter: 'blur(5px)'
    }}>{!isSaved?'unsaved':'saved'}</div> */}
    </span>
      {/* {(name !=='personaTitle' && name !=='QRname')&& !edit && <hr */}

         {/* {(name ==='name' && view !=='home' || view !== 'edit-asset') &&
         <hr
            style={{
                alignSelf: 'center',
                color: "#868686",
                backgroundColor: "#868686",
                height: '0.25px',
                width:"90%",
                marginTop:"0.5rem"
            }}
    />} */}
    {/* // :''}  */}
    {/* )} */}
    

        
    </div>
  )
}

export default QRFieldComponent