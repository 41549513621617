 const extensionOptions = {
            round:0, //qr?.shape ==='circle'?1 :0, // 0 - 1
            thickness: 60,
            // color: "#1513AB",
                // dasharray: '4 1',
            decorations: {
            // top: {
            //     type: "text",
            //     value: "Powered by QauRa",
            //     style: `font: .75em gilroy; fill: ${qr?.dotOptions?.color};`
                
            //     // style: "font: 30px sans-serif; fill: #1513AB;"
            // },
            // bottom: {
            //     type: "text",
            //     value: "©2023 RexEntropy LLC",
            //     style: `font: .75em gilroy; fill: ${qr?.dotOptions?.color};`
                
            // },
            // left: {
            //     type: "image",
            //     value: "./assets/Rex_Entropy_Icon_Glow.png",
            //     style: "width: 30px;  transform: translate(-15px, -15px);"
            //     // style: "font: 30px sans-serif; fill: #1513AB; letter-spacing: 10px;"
            // },
            // right: {
            //     type: "image",
            //     value:
            //     "./assets/Rex_Entropy_Icon_Glow.png",
            //     style: "width: 30px;  transform: translate(-15px, -15px);"
            // }
            },
            // borderInner: {
            // color: "#000",
            // thickness: 5,
            // // dasharray: '4 1'
            // },
            borderOuter: {
            color: "#000",
            thickness: 7
            // // dasharray: '4 1'
            }
        };

        export default extensionOptions