import { Box, Container, Modal } from '@mui/material'
import React, {useState, useContext} from 'react'
import CloseButton from '../../components/CloseButton'
import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import SizeSelector from './SizeSelector';

const ProductOptionModal = (props) => {
    // const [ productOptionModalOpen, setProductOptionModalOpen ] = useState(true)
   const { modalOpen, setModalOpen,cart,setCart,createCart,
         generateOrder,createOrder,selectedSize,
         setCustomer,
        order, view, setView, shop, setShop, currentOrder,
        storefrontData, setStorefrontData, currentProduct,
        setCurrentProduct,framingOpen,setFramingOpen,
        items, setItems,owner, setOwner,cartOpen, setCartOpen,
        subView
    } = useContext(StorefrontContext);

    const { item , productOptionModalOpen, setProductOptionModalOpen } = props
    return (
    <Modal
     open={productOptionModalOpen}
    onClose={()=>setProductOptionModalOpen(!productOptionModalOpen)}
     sx={{}}>
        <Container sx={{}}>
            <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '350px',
                    minHeight:'250px',
                    maxHeight:'80%',
                    backgroundColor:'#FFF',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    // gap:'0.5rem',
                    padding:'0.5rem',
                    paddingTop:'1rem'
                    
                    }}>
                        <Box sx={{position:'absolute', top:'-0.5rem', right:'-0.5rem'}}>
                            <CloseButton func={setProductOptionModalOpen} val={productOptionModalOpen}
                        
                            />
                        </Box>
                        <b>size:</b>
                         {item?.sizes && <SizeSelector sizes={item?.sizes}/>}
                </Box>

        </Container>
    </Modal>
  )
}

export default ProductOptionModal