import { Card, Stack, Paper, Container, Typography, Box, Divider, Switch, Input, InputAdornment, IconButton, TextField } from '@mui/material';
import React,{useContext, useEffect, useState} from 'react'
import { AppContext } from "../context/AppProvider";
import useWindowDimensions from "../hooks/windowDimensions";
import BackspaceIcon from '@mui/icons-material/Backspace';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'

const ItemFieldComponent = (props) => {
    const key = props.field
    const value = props.value
    const index = [props.index]
    const setHasChanged = props.setHasChanged
    const { appData,userData, setUserData, updateAppData, modalOpen,
         currentProduct, setCurrentProduct } = useContext(AppContext);
    const { height, width } = useWindowDimensions();
    const [ edit, toggleEdit ] = useState(false)
    const [ isUrl, toggleIsUrl ] = useState(true)

    const textTypes = ['name','subtitle','details','tags','affiliates']
    const numberTypes = ['price','cost_of_creation']
    const imageTypes = ['images','printBase_url','art_url','alt_art_url']
    const multiList = ['description','subtitle']
     const imageList = ['images','printBase_url','art_url','alt_art_url']


    const inputTypeFilter = (type) =>{
         if(textTypes.includes(type)){
            return 'text'
        }
         if(numberTypes.includes(type)){
            return 'number'
        }
         if(imageTypes.includes(type)){
            return isUrl?'url':'file'
        } 
    }

    const handleChange = (e) =>{
        let val = e.target.value
        if(index >=0 ){
           let temp = currentProduct[key]
           temp[index]=val
            setCurrentProduct({
                 ...currentProduct,
                 [key]: temp
            })
            updateAppData({...appData,currentProduct})
            console.log('currentProduct',currentProduct[key])
            props.setHasChanged(true)
          }else{
            setCurrentProduct({
              ...currentProduct,
              [key]: val
            })
            updateAppData({...appData,currentProduct})
            console.log('currentProduct',currentProduct[key])
            props.setHasChanged(true)
        }
        props.setHasChanged(true)
    }
    useEffect(()=>{

    },[currentProduct,appData])
    
  return (
    <Box >
       
       {!edit && !imageList.includes(key) && <p style={{cursor:'pointer'}}
            onClick={()=>{
                toggleEdit(!edit)
            }}>
            {index >=0 ? currentProduct[key][index]:currentProduct[key]}
            {index === 'na' && currentProduct[key]}
            {!currentProduct[key] && 'Click to enter'}
            
          </p>
          }

      {!edit && imageList.includes(key) &&
           <img style={{cursor:'pointer'}} src={ index === 'na'? currentProduct[key]:currentProduct[key][index]} 
              width='50px' height='auto' 
            onClick={()=>{
                toggleEdit(!edit)
            }}/>
            }

       {/* {(!edit && Array.isArray(currentProduct[key]) && !currentProduct[key].length && index = 0) &&<b>click to add</b>}      */}


       {edit && !multiList.includes(key)  && <Input 
            // multiline={multiList.includes(key)}
            // multiline={key === 'description' || key === 'subtitle'?true:false }
            defaultValue={index >=0 ? currentProduct[key][index]:currentProduct[key]}
            sx={{minWidth: '75px'}}
            type={inputTypeFilter(key)} onClick={()=>{
                // toggleEdit(!edit)
            
            }}
            onChange={(e)=>handleChange(e)}
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  aria-label="delete"
                  onClick={()=>{
                    if(index !== 'na'){
                      let temp = currentProduct[key]
                      temp.slice(0, index).concat(temp.slice(index+1))
                      setCurrentProduct({
                          ...currentProduct,
                          [key]: temp
                      })
                      updateAppData({...appData,currentProduct})
                    }else{
                      setCurrentProduct({
                          ...currentProduct,
                          [key]: ''
                      })
                      updateAppData({...appData,currentProduct})
                    }
                    setHasChanged(true)
                    toggleEdit(!edit)
                  }}
                  onMouseDown={()=>toggleEdit(!edit)}
                  edge="end"
                >
                 <CancelIcon  /> 
                 {/* <CheckCircleIcon />  */}
                </IconButton>
              </InputAdornment>
            }
            
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>toggleEdit(!edit)}
                  onMouseDown={()=>toggleEdit(!edit)}
                  edge="end"
                >
                 {/* <BackspaceIcon />  */}
                 <CheckCircleIcon /> 
                </IconButton>
              </InputAdornment>
            }
            >
        </Input>}
       {edit && multiList.includes(key)  && 
       <Stack sx={{position:'relative'}}>

       <TextField 
            multiline
            // maxRows={key !== 'description'? 4:2}
            defaultValue={index >=0 ? currentProduct[key][index]:currentProduct[key]}
            type={inputTypeFilter(key)} onClick={()=>{
                // toggleEdit(!edit)
            }}
            onChange={(e)=>handleChange(e)}
            // endAdornment={
            //   <InputAdornment position="end">
            // }
            >
        </TextField>
                <IconButton
                sx={{position:'absolute', right:'0rem',
                    bottom:'50%',tranform:'translate(0,-50%)',  
                    width:'1rem',height:'1rem',}}
                  aria-label="toggle password visibility"
                  onClick={()=>toggleEdit(!edit)}
                  onMouseDown={()=>toggleEdit(!edit)}
                  edge="end"
                >
                 {/* <BackspaceIcon />  */}
                  <CheckCircleIcon /> 
                </IconButton>
              {/* </InputAdornment> */}
       </Stack>}
       


    </Box>
  )
}

export default ItemFieldComponent