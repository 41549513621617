import { useParams } from "react-router-dom";
import React, {useEffect, useContext, useState} from 'react'
import * as API from '../utils/APICalls'
import * as Icons from '../utils/IconHandler'
import { Avatar, Box, Container, Typography,Tooltip,Link, Stack, Paper } from "@mui/material";
import RedirectHeader from "./RedirectHeader";
import Copyright from '../components/Copyright'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import ShareIcon from '@mui/icons-material/Share';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Brands from '@fortawesome/free-brands-svg-icons'
// import { byPrefixAndName } from '@awesome.me/kit-KIT_CODE/icons'

const Profile = () => {
    const [ profile, setProfile ] = useState(null)
    const [ vCardData, setVCardData ] = useState()
    const name = (profile?.preferredName?.on && profile?.preferredName?.data) ? profile?.preferredName?.data : profile?.firstName?.data
    const profile_id = useParams().id?.toString()

    const excludeList = ['__v','_id,updatedAt','createdAt','alt_pic',
        'cover_pic','isProfile','isVcard','about', 'preferredName',
        'personaTitle','directLink']

    const brands = ['facebook','instagram','linkedin','upwork', 'github',   'gitlab', 'twitter', 'bandcamp','soundcloud','spotify','youtube','vimeo',
        'airbnb','uber','lyft','stackoverflow','stackexchange','pinterest','skype','etsy','codepen', 'dropbox', 'googleplay','imdb','npm','meetup','itunes','cashApp','venmo','paypal','linktree','xbox','playstation','steam','snapchat','slack','kickstarter','patreon','stripe', 'discord','wordpress','tiktok','whatsapp',
        'windows','apple','shopify','twitch','tumblr','trello','unity','wikipedia'
    ]
    


    const elemCheck = (elem) =>{
        // if( elem?.on && name === 'links' ){
        //     return
        // }else
         if( elem?.on ){
            return elem?.data
        }else{return null}
        }

    const splitKey = ( key,char ) =>{
        let splitString = key.split(char).join(` ${char}`) 
        // console.log("[split String]:",splitString)
        return splitString
    }
    
    const linkCheck = (l) =>{
        if(l.indexOf('http://') === -1 || l.indexOf('https://') === -1){
            return 'https://'+ l
        }else{ return l}
    }

    const linkIconCheck = (l) =>{
        console.log('[linkIconCheck] brands?.some((k)=>l?.includes(k.toString))',brands?.find((k)=>l?.includes(k?.toString())))
        let result = brands?.find((k)=>l?.includes(k?.toString()))
        if(result){

            return result?.charAt(0).toUpperCase() + result?.slice(1);
        }else{ return false}
    }

    const checkProfile = (p) => {
        return  p?.isProfile === true    
    }
  
    const fetchProfile = async () =>{
        try{
            const profs = await API.fetchPersonas({_id: profile_id.toString()})
            if( profs ){
                console.log('***Profs***:', profs)
                const prof  = profs?.find(checkProfile)
                if(prof?.isProfile ){
                    await setProfile(prof)
                    console.log('***Prof***:', prof)
                }else{
                    let availProfs = profs?.filter(p => (p?.isVcard === true ))
                    await setProfile(availProfs[0])
                }
                // console.log('***Profile***:', profile)
            }else{
                console.log('***No Profile Exists***')
            }
        }catch(err){console.log(err)}
        
    }
    const fetchVCard  = async(info) =>{
        // console.log('fetchvCard persona: ', persona)

        if(profile){
            try{

                const data = await API.fetchVCard(info)
               if (data){
                   console.log('fetchvCard data: ', data)
                   setVCardData(data)
                   // setVCardData(data?{...data}:fetchVCard())
                   console.log('fetchvCard VCardData: ', vCardData)
               }else{console.log('unable to fecth vCard')}
            }catch(err){console.log(err)}
        }
    }

    const saveVCard = (data) =>{
        // vCard.saveToFile(`./${persona?.preferredName?.data?persona?.preferredName?.data:persona?.firstName?.data}.vcf`)
        console.log('[save vCard]: ',data)
        downloadToFile(data, `${name}vcard.vcf`, 'text/vcard')
    }

    function downloadToFile(content, filename, contentType) {
    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });

    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();

    URL.revokeObjectURL(a.href);
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    
        if(!vCardData){
            fetchVCard(profile)
        }
    }, [vCardData,profile])
    useEffect(()=>{
        if(!profile){
            fetchProfile()
        }
    },[])

    useEffect(()=>{
     
    },[profile])


  return (
    <Box sx={{position:'absolute',maxHeight:'100vh', minHeight:'700px',
        //  backgroundColor:'rgba(204, 251, 169, 0.3)',
        width:'100%',display:'flex', flexDirection:'column', gap:'0.5rem',
        overflow:'hidden', overflowY:'scroll'}}>
        <RedirectHeader />
       
        <Box 
            // component='img'
                sx={{position:'absolute', top:'0px', left:'0px', 
                fontFamily:'Gilroy, sans-serif',
                // backgroundColor:'rgba(203, 244, 245, 0.8)', 
                background: 'rgb(255,255,255)',minHeight:'240px',
                
                background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 15%, rgba(0,0,0,1) 100%)',
                width:'100%',height:'225px',zIndex:0, overflow:'hidden',
                boxShadow:'inset 0px -3px 10px 5px rgba(255,255,255,0.8),0px 0px 15px 10px rgba(0,0,0,0.4)', 
                opacity:1,borderBottom:'3px solid #000',
                marginBottom:'1.5rem'
            }}
            // src ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1705938116/Orzo_fkhl2x.jpg'
                >
    
                <Box 
                // component='img'
                    sx={{position: 'absolute', top:'0px', left:'0px', 
                    backgroundColor:'rgba(203, 244, 245, 0.8)',maxWidth:'100vw',
                    width:'100%',height:'auto',minHeight:'240px',zIndex:'-1', opacity:'1',
                    boxShadow:'inset 0px -3px 10px 5px rgba(255,255,255,0.8),',//,0px -2px 5px 3px rgba(0,0,0,0.5)'
                    backgroundImage:`url("${profile?.cover_pic?.data}")`,//backgroundAttachment:'fixed',
                    backgroundRepeat: 'no-repeat',backgroundSize: 'cover',
                    
                    }}
                    // src = {cover_pic}
                    // src ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1705938116/Orzo_fkhl2x.jpg'
                    >
                </Box>
            </Box>

            <Box
                sx={{
                    position:'relative',
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent:'center',
                    gap: '1rem',
                    width:'100%'
                    }}
                    >
                {/* <Box 
                //  component='img'
                // src='/assets/BodyBG_.png'
                    sx={{ position:'absolute', top:0, left:0, zIndex:-1, height:'100%',maxWidth:'100vw', width:'100%',backgroundColor:'rgba(0,0,0,0.3)',
                    background:`#fff`,
                    backgroundAttachment:'fixed',
                    backgroundSize:'contain'
                    }}

                >
                    &nbsp;
                </Box > */}
                <Box sx={{position:'absolute',top:'-40px',display:'flex', flexDirection:'column',
                gap:'0.5rem', padding:'0.25rem',
                // border: `1px solid green`
                }}>
                    
                <Avatar sx={{position:'relative', bgcolor: profile?.alt_pic?.data?'':'primary.main',          width:'150px', height:'150px', 
                        border:'3px solid #000', boxShadow:'0px 0px 8px 3px rgba(0,0,0,0.8)',borderRadius:'20px'}} 
                    src={profile?.alt_pic?.data}>
                </Avatar>

                <Box sx={{position:'relative',display:'flex',margin:'auto'}}>
                    <Tooltip title="Copy to clipboard">
                        <Link href=""  onClick={(e)=>{
                            e.preventDefault()
                            let val = window.location.href.toString()
                            // let val='https://qaura.pro/storefront/6634372a7d97baf1e077b325'
                            // let val= shop?.url
                            console.log('url:',val)
                            
                            // navigator.clipboard.writeText(val);
                            // alert(val,'\nurl copied to clipboard')
                            navigator.clipboard
                                .writeText(val)
                                .then(() => {
                                    alert(`${val} successfully copied`);
                                })
                                .catch(() => {
                                    alert("something went wrong");
                                });
                            // handleSnackClick()
                            }} >

                        <ShareIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                        {/* <Snackbar
                            anchorOrigin={'bottom'} 
                            open={snackOpen}
                            onClose={handleSnackClose}
                            message="url copied to clipboard!"

                        /> */}
                        </Link>
                        </Tooltip>
                        <Tooltip title="Download contact vCard">
                            <Link href="" label='save contact to phone' onClick={(e)=>{
                                e.preventDefault()
                                console.log('click',vCardData)
                                
                                if(vCardData){
                                    saveVCard(vCardData)
                                    }
                                }}>
                                <ContactPhoneIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                            </Link>
                        </Tooltip>
                </Box>

                 <Box sx={{position:'relative',//bottom:'0px',
                    display:'flex',//border:'1px solid red', 
                        minHeight:'1.5rem',
                        justifyContent:'space-evenly',
                        width:'100%', margin:'auto'}}>

                    { profile?.links?.data?.map((l,j)=>{
                    
                        let link = elemCheck(l)
                        console.log('[profile] link:', link)
                       if(linkIconCheck(link)){
                            let iconName = linkIconCheck(link)
                            console.log('iconName:', iconName)
                            return (
                                <div
                                style={{
                                    // display:'flex',
                                    color:'#000',  
                                    width:'1.5rem',height:'1.5rem',
                                    '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                    // border:'1px solid blue', 
                                    borderRadius:'3px',
                                    alignContent:'center',
                                    minWidth:'1rem',minHeight:'1rem'}} //key={i}
                                    
                                    key={j}
                                    > 
                                <a 
                                style={{
                                    // display:'flex',
                                    color:'#000',  
                                   
                                    // border:'1px solid blue', 
                                    borderRadius:'3px',
                                    alignContent:'center',
                                    minWidth:'1rem',minHeight:'1rem'}} //key={i}
                                    href={linkCheck(link)}
                                    target='_blank'
                                    key={j}
                                    > 
                                    <FontAwesomeIcon icon={Brands['fa'+ iconName]} key={j} />
                                </a>
                                </div>
                           )
                        }
        

                })}
                
            </Box>
                </Box>
            
 
            
            </Box >
{/* <Container sx={{position:'relative'}}> */}

           
                 
                        
                
            <Container sx={{
                    margin:'auto', marginTop:'12rem',
                    position:'relative',display:'flex', flexDirection:'column',
                    gap:'0.5rem',overflow:'hidden', overflowY:'scroll',
                    minHeight:'50vh', height:'100%',
                    width:'90%',flex:1,padding:'0.5rem',
                    borderTop:'3px ridge #757575', borderRadius:'2px',
                    boxShadow:'inset 0px 3px 5px 2px rgba( 0, 0, 0, 0.5)',
                    backgroundColor:'#FFF'
                    }} >
                <h2 
                // variant="h3" 
                sx={{
                margin:'2rem auto',marginBottom:'none', padding:'0.5rem', textAlign:'center'
                }}>
                    {profile?.preferredName?.data ? profile?.preferredName?.data :profile?.firstName?.data}
                </h2>

                    {(elemCheck(profile?.directLink)) && 
                        <Paper sx={{margin:'auto',height:'1.5rem', width:'fit-content', padding:'0.25rem 1.5rem',
                        borderRadius:'10px', '&:hover': {background:'rgba(144, 254, 195,0.2)'}}}>
                            <a style={{color:'#000'}} href={linkCheck(elemCheck(profile?.directLink))} target='_blank'>
                                {elemCheck(profile?.directLink)}
                            </a>
                        </Paper>
                        }
                {/* <Paper sx={{width:'100%'}}> */}
                    <b
                    // variant="h5"
                    sx={{fontSize:'3rem',
                        margin:'2rem auto',marginTop:'none', padding:'0.5rem', 
                        textAlign:'center', width:'65%', minWidth:'200px', maxWidth:'300px'
                        }}>
                        {elemCheck(profile?.about)}
                    </b>
                {/* </Paper> */}


                <Paper sx={{ display:'flex', flexDirection:'column',
                    margin:'auto', padding:'0.5rem', textAlign:'left',
                    gap:'0.5rem', width:'100%',maxWidth:'300px',minWidth:'300px',justifyContent:'center'
                    }}>

                    <Box sx={{justifyContent:'center',gap:'0.5rem'}}>
                        {profile && Object.keys(profile).map((keyVal,i)=>{ 
                            // let links = profile?.links?.data
                                if( !excludeList.includes(keyVal) && keyVal !== 'links' ){
                                    return(
                                        <Box key={i}>
                                            {(elemCheck(profile?.[keyVal])) 
                                            ? <span><b>{splitKey(keyVal,'N')[0].toUpperCase() + splitKey(keyVal,'N').substring(1)}: </b>{elemCheck(profile?.[keyVal])}</span>
                                            :<a style={{color:'#000'}} href={elemCheck(profile?.[keyVal])} target='_blank'>
                                                {/* {elemCheck(profile?.[keyVal])} */}
                                                </a>}
                                            {/* <br /> */}
                                        </Box>  
                                    )
                                    
                                }
                            })}
                            
                                
                    </Box>
                </Paper>
                    <Accordion  disableGutters
                        sx={{position:'relative',top:'0', width:'80%',minWidth:'250px',
                        margin:'auto',marginBottom:'0rem',textAlign:'center',zIndex:999}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"  
                    id="panel1-header"
                    sx={{display:'flex'}}
                    >
                     <b style={{margin:'auto', transform:'translate(1.5rem,0)'}}>Other Links:</b>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ display:'flex', flexDirection:'column',
                    margin:'auto', padding:'0.5rem', textAlign:'center',
                    gap:'0.75rem',width:'100%'
                     }}>
                <Box sx={{ display:'flex', flexDirection:'column',
                    margin:'auto', padding:'0.5rem', textAlign:'center',
                    gap:'0.75rem'}}>

                {profile && Object.keys(profile).map((keyVal,i)=>{ 
                    let links = profile?.links?.data
                    // let name = item?.keyVals()[i] 
                    // console.log("currentPersona[keyVal]: ",keyVal ,currentPersona[keyVal])
                    // console.log("currentPersona?.links?.data.length ",currentPersona?.links?.data.length )
                    // if( keyVal === 'directLink'|| keyVal === 'email'|| keyVal === 'phone'|| keyVal === 'links'){
                        // if( !excludeList.includes(keyVal) ){
                        if(keyVal === 'links' && profile?.links?.data?.length >=1 ){
                            // console.log("currentPersona['links']: ",key ,currentPersona['links'])
                            return(
                                <div key={'objElem'+i} style={{display:'flex',flexDirection:'column',gap:'0.25rem',
                                    justifyContent: 'left',textAlign: 'left', padding:'0.5rem'}}>
                                {/* <ul style={{display:'flex',flexDirection:'column',listStyle:'none',
                                    justifyContent: 'left',textAlign: 'left'}}> */}
                                    
                                    
                    
                                    {(profile?.links?.data && profile?.links?.on) && profile?.links?.data?.map((l,j)=>{
                                        let link = elemCheck(l)
                                        if(link){
                                            if(!linkIconCheck(link)){
                                            
                                                return (
                                                    <Paper className='blink-2'
                                                        sx={{padding:'0.5rem', height:'1.5re2m',
                                                         '&:hover': {background:'rgba(144, 254, 195,0.2)'}
                                                        // backgroundColor:'rgba(204, 251, 169, 0.3)'
                                                    }}
                                                         key = {'cardLink-'+j}>
                                                    { link && 
                                                    <a 
                                                        style={{color:'#000'}} //key={i}
                                                        href={linkCheck(elemCheck(l))}
                                                        target='_blank'
                                                        >
                                                        {link}
                                                    </a>
                                                    }
                                                    <br/>
                                                    
                                                    </Paper>
                                                )
                                            }
                                        }

                                    })}
                                {/* </ul>   */}
                                
                            
                            </div>
                            )
                        } 
                       
                    })}
                </Box>
                </Box>
                    </AccordionDetails>
                </Accordion>
                              
               
            {/* </Container> */}
            <Box sx={{position:'relative',bottom:0,left:'50%', 
                height:'6rem',
                transform:'translate(-50%,0)', width:'80%'}}>

                <Copyright 
                    view='signupSheet' //bg={} txt={}
                    />
            </Box>
</Container>
    </Box>
  )
}

export default Profile