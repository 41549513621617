import React , { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AppProvider, AppContext, AppState } from "../context/AppProvider";
import EnvRoute from '../middleware/envRoute';
import ThemeColorPicker from './ThemeColorPicker';
import { Box, Button, Container, TextField } from '@mui/material';
import *  as API from '../utils/APICalls'
import ResetPasswordComponent from './ResetPasswordComponent';
import EditUserInfo from './EditUserInfo';
import UserInfoCard from './UserInfoCard';
import CloseButton from './CloseButton';

const ENDPOINT = EnvRoute('prod'); 

const Menu = () => {
  const { appData, modalOpen, setIsLoggedIn, userData,setUserData,updateAppData } = AppState();
  const [ results, setResults ] = useState()
  const [ unhashedPass, setUnhashedPass ] = useState()
  const [ password, setPassword ] = useState('')
  const [ confirmPassword, setConfirmPassword ] = useState('')
  const [ editUser, toggleEditUser ] = useState(false)



const updateUser = async(obj) =>{
        try{
            const config = {
                headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userData?.token}`
                },
            };
            const { data } = await axios.put(
                `${ENDPOINT[0]}/user/update`,
                {
                  email: userData?.email,
                    ...obj,
                },
                config
                );
        }catch(err){console.log(err)}  
    }   
  const handleLogout =async ()=>{
        setIsLoggedIn(false)
        // setRoom()
        await  API.updateUser({isOnline:false})
        .then()
        setUserData()
        localStorage.setItem('QauRaUser', '')

        // updateRoom('user_exit')
        // hmsActions.leave();
    }
    const handleSearch = async (val) =>{
     const res = await API.searchUser(userData, val)
     setResults(res)
     console.log(res)
     return res
    }
    const checkPasswords = () => {
      if( password === confirmPassword ){
        console.log('!-----YAYYY...Match -----!')
      }else{

        console.log('!-----AWWWWW...No Match -----!')
      }
    }
    const unhashed = async (val) =>{
     const res = await API.searchUser(userData, val)
     setUnhashedPass(res)
     console.log('unhashed:',res)
     return res
    }

    useEffect(()=>{

    },[results])
  return (
    <Container style={{padding:"0.5rem", width:'95%',height:'90%'}}>
        <div style={{width:'100%', height:'3rem', backgroundColor:'#fff'}}>
        <h2 style={{color:'#000'}}>Account Settings</h2>
        <div style={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
            <CloseButton func={updateAppData} val={{
                        modalOpen:!modalOpen,
                        modalView:''
                    }} />
        </div>
        </div>
       <div style={{
            display:'flex',
            flexDirection:'column',
            backgroundColor:'#FFF',
            gap:'0.5rem',
            // width: '100%',
           
            height: '100%',
            padding:'1rem',
            overflow:'scroll',
            borderRadius:'5px', 
            color: "#000",
            boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.5)'}}
            >
            {/* <ThemeColorPicker /> */}
            {/* {!userData?.isAdmin && <h2 color='#FFF'>(nothin' yet)</h2>} */}
             {/* <div style={{display:'flex', flexDirection:'row', flex:1, maxWidth:'200px'}}>  */}
          <button className="button save-button" 
            style={{
                fontSize:'0.75rem',
                display:'flex', 
                flexDirection:'column',
                color:"#000000",
                // color:"#00FF00",
                height: 'auto', 
                padding:'0.25rem',
                // borderRadius:'0 5px',
                // position: 'absolute',
                // top:'10px',
                // left: '10px', 
                alignSelf:'left',
                alignItems:'center'}}
                // onClick={()=>{setModalOpen(!modalOpen)}}
                onClick={()=>{
                    handleLogout()
                   window.location.assign("/")
                }}
                type="submit" >
                <img src={'/assets/home.svg'} style={{height:'4vh', alignSelf: 'center'}} alt="Logout/home"/>
            logout
            </button>
            
        {/* </div>  */}
            {editUser && <EditUserInfo func={toggleEditUser} val={editUser} />}
          {!editUser && <div onClick={()=>{toggleEditUser(!editUser)}}><UserInfoCard  /></div>}
         {/* <EditUserInfo /> */}
           <ResetPasswordComponent  view='user menu'/>

            {userData?.isAdmin && <>
            <h2>Admin</h2>
            <h4>search user</h4>
            
            <TextField sx={{color:'#FFF'}} label='email or name' onChange={async(e)=>{handleSearch(e.target.value)}}/>
            <div style={{display:'flex',flexDirection:'column',backgroundColor:'#FFF',gap:'1rem',}}>
            
            {results && results?.map((r,i)=>{
              return (
                <div style={{whiteSpace:'wrap', overflow:'hidden',textAlign:'left',textOverflow:'ellipsis',padding:'0.5rem'}}  key={{i}}>
                {Object.keys(r).map((k,i) =>{
                  return (
                    <div><b>{k}:</b> {r[k].toString()}</div>
                  )
                })}
            <br></br>
            <hr></hr>
              </div>
            )
           }

          )}
</div></> }
            </div>
      {/* <h3>Menu</h3> */}
      {/* <button className="redirect-button"
                onClick={()=>{
                  handleLogout()
                  window.location.assign('/')
                }}
        >
            Log out
        </button> */}
    </Container>
  )
}

export default Menu