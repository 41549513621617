import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'

const AboutSection = (props) => {
  const { aboutHeight, aboutContent, tagline } = props.conf
  return (
    <Box sx={{
        // width: '100%', 
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        // height: aboutHeight,
        margin:'auto',
        justifyContent:'center',
        minWidth: '300px', 
        width: '80%', 
        // border: '1px solid #000',
        padding: '0.5rem',
    }}>
    <Paper sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '60%', 
        minWidth: '280px', 
        height: 'fit-content',
     
        // minHeight: aboutHeight,
        maxHeight: aboutHeight,
        border: '1px solid #000',
        margin:'auto',
        borderRadius: '10px',
        justifyContent:'center',
        gap:'0.5rem',
        // padding: '0.5rem',
        // overflowY:'scroll',
        backgroundColor:'#FFF',
        padding:'0.5rem'
       
        
    }}>
            {/* <h3>
                About Us
            </h3> */}
        {/* <span  sx={{flexDirection:'row', margin:'auto', textAlign:'bottom', gap:'0.5rem', justifyContent:'center'}} > */}
            {/* <u> */}

            <h2 style={{fontSize:'1.5rem', marginTop:'0rem',marginBottom:'0rem' }}>
            {/* Parallaxr */}
            {tagline}
            </h2> 
            {/* </u> */}
            {/* &nbsp;{tagline} */}
            {/* <b style={{fontSize:'1rem', }}>
          
            </b> 
        </span> */}
       
        <Typography sx={{maxWidth:'400px', margin:'0 auto' }}dangerouslySetInnerHTML={{ __html:aboutContent}}>
            {/* {aboutContent} */}
        </Typography>
    </Paper>
    </Box>
  )
}

export default AboutSection