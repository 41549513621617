import React, {useState, useContext,useEffect } from "react";
import axios from 'axios'

import ManagerQR from './ManagerQR'
import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";
import useWindowDimensions from "../hooks/windowDimensions";
import QRFieldComponent from './QRFieldComponent'

import QROptions from './QROptions'
import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'

import EnvRoute from '../middleware/envRoute';
import QRCodeForm from "./QRCodeForm2";
import QRCodeStyling from "qr-code-styling";
import CreateGeoCondition from "./CreateGeoCondition";
import GeoConditions from "./GeoConditions";
import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const ENDPOINT = EnvRoute('prod'); 


const SingleQR = () => {
  const [ showPopup, setShowPopup ] = useState(false)
  const [ showPin, setShowPin ] = useState(false)
   const [ update, setUpdate ] = useState(false)
  const [ popupText, setPopupText ] = useState('')
  const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
  const { appData, updateAppData,setPreviewQR, previewQR,selectedQR, setSelectedQR,
  modalOpen , setActionError, actionError, userData} = useContext(AppContext);
  const [ FMValue, setFMValue ] = useState(`https://qaura.pro/fm/${userData?._id}`)
  const { height, width } = useWindowDimensions();
  const [ settings, setSettings ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  
  const fmString = `https://qaura.pro/fm/${userData?._id}`
     const sleep = (time) => {
      return new Promise(resolve => setTimeout(resolve, time))
    }

    const fmCheck = ()=>{

      if(selectedQR?.payload?.toLowerCase() === fmString?.toLowerCase()){
        return true
      }
      else {
        return false
      }
    }
   const handleSave = async (e) => {
        e.preventDefault();
        let data = selectedQR?selectedQR:qrCodeData
        console.log('save QR data',data)
        try{

          const qrCode = new QRCodeStyling(data);
          // qrCode?.applyExtension(borderExtension(extensionOptions))
          if(data?.pic_url){
            console.log('[data?.pic_url]: ',data?.pic_url)
            window.location.href(`${data?.pic_url?.toString()}`)
          }
          // if(!qrCodeData?.pic_url){
          //   await qrCode?.download({ name: `QauRaQR_${data?.name? data?.name:'(UntitledQR)'}`, extension: "png" });
          // }
        }catch(err){console.log(err)}
  
        };


      const showCopyPopup = async(key) =>{
        setPopupText(`QR ${key} copied to clipboard`)
        setShowPopup(true)
        await sleep(1000)
        setShowPopup(false)
    }
    const generatePin = async () =>{
      setActionError('')
      try{
        const pin = await helpers.generateRandomHex(6)
        if(pin){
          const qr_res = await API.updateQRCode({...selectedQR,pin:pin})
          if(qr_res?.pin){
            selectedQR(qr_res)
          }else{
            setActionError('Unable to generate pin')
          }
        }

      }catch(err){console.log(err)}
    }
     const updateQRCodeDb = async(obj) =>{
       try {
        console.log('Save Click', qrCodeData)
        setLoading(true);
        const config = {
            headers: {
            "Content-type": "application/json",
            //   Authorization: `Bearer ${user.token}`,
            },
        };
        const { data } = await axios.put(
            `${ENDPOINT[0]}/qr/update`,
           { _id: qrCodeData?._id,
            ...obj},
            config
        );
        // updateQR()
        // updateQRCodeData({...data})
        setSelectedQR({...data})
        updateAppData({...appData,selectedQR})
       
        console.log('save response: ',data)
      setLoading(false);
    } catch (error) {
        // setActionError("error occured") 
        setLoading(false);
    }
    
  }  
   useEffect (()=>{
    // if(qrCodeData?._id !== previewQR?._id)
    //     setPreviewQR({ 
    //         ...qrCodeData})
        },[selectedQR,qrCodeData, appData])
    return (
  //  <div>
      <div style={{display: 'flex', 
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        // width: '100%',
        border: '2px solid #000000',
        borderRadius:'10px',
        backgroundColor:'rgba(256,256,256, 0.2)',
        boxShadow: 'inset -2px 0px 5px 3px rgba(0,0,0,0.3)',
        backdropFilter:'blur(3px)',
        padding: '0.5rem',
        height: '95%',
        width: '95%',
        margin: 'auto',
        fontSize: '0.75rem',
        gap: '0.5rem'
    }}>
      <span className='blink'
                style={{cursor:'pointer',display:'flex', flexDirection:'column',
                maxWidth:'100px', padding:'0.25rem 0.5rem',
                backgroundColor:'rgba( 255,255,255, 0.3)',backdropFilter: 'blur(4px)',
                margin:'0.5rem auto',
                boxShadow:`0px 0px 3px 1px rgba( 0, 0 , 0, 0.4),${fmCheck()?'inset -2px -2px 15px 5px rgba( 255, 255, 255, 0.3)':''}`,
                border:`2px solid ${fmCheck()?'#00ff00':'#ff0000ff'}`,borderRadius:'5px', gap:'0.125rem',                
                }}
                onClick={async (e)=>{
                  try{
                    e.preventDefault()
                    const data = await API.updateQRCode({
                      ...selectedQR,
                      payload: fmString
                    })
                    if(data){
                      setSelectedQR(data)
                      updateAppData({
                        ...appData,
                        selectedQR
                      })
                    }
                  }catch(err){console.log(err)}
                    // setUpdate(true)
                    // setfmCheck()(!currentAsset?.useFieldMode)
                    // setCurrentAsset({...currentAsset,useFieldMode:!currentAsset?.useFieldMode})
                   
                    // updateAppData({...appData,currentAsset})
                    // console.log('Updating fieldMode--appData : ', appData)
                    // if (update){
                    //   console.log('Updating fieldMode: ', currentAsset)
                    //   updateFieldMode()

                    // }
                   
                }}
                ><div style={{fontSize:'0.5rem',color:"#000000",margin:'0 auto'}}>
                  {update?'click again to confirm':''}
                </div>
                <div style={{display:'flex', flexDirection:'row',alignItems:'center', gap:'0.5rem'}}>

                <img width='15rem' src={`/assets/${fmCheck()?'FM_on':'FM_off'}.svg`} alt='field mode toggle'/>
                <p style={{color: fmCheck()?'#000':'#ff0000ff',fontSize:'0.7rem'}}><u>field mode</u>: {fmCheck()?'on':'off'} </p>
                </div>
            </span>


       <div 
      //  className='user-info-bottom-right'
            style={{width:'100%'}}
            >
           
{/* -------Frame--------- */}
              <div style={{display:'flex', 
                flexDirection:'column',
                position:'relative',

                padding:'0.5rem', 
                alignItems:'center', 
                justifyContent:'center', backgroundColor:'rgba(255,255,255,0.5)',
                backdropFilter: 'blur(3px)', border:'3px solid rgba(0,0,0,1)',boxShadow:"0px 0px 10px 3px rgba(0,0,0,0.6)",
                borderRadius:'10px', flex:1}}>

  {/* -------responsive container--------- */}
    <Box sx={{position:'relative',height:'5rem',width:'100%',
    // border:'1px solid red',
    justifyContent:'center', marginBottom:'1rem'
    }}>
                 <Box sx={{position:'releative', top:'0px', left:'0px'}}>

                  <QRFieldComponent 
                      // style={{}}
                        value={selectedQR?.name}
                        object={selectedQR}  name={'name'}
                        view='edit-QR' 
                         use='header'
                        />  
                 </Box>
                 </Box>

              <Box style={{
                position:'relative',
                display:'flex', 
                width:'100%',
                flexDirection: width<=500?'column':'column',
                justifyContent:'center',
                alignItems:'center', flex:1}}>
                  
              
                      {selectedQR?.isActivated && <Box 
                      
                      sx={{
                        position:'absolute', top: '0.5rem', right:'0.5rem',
                       display:'flex',flexDirection:'column',textAlign:'right', 
                       alignItems:'flex-end', height:'6rem'
                      //  gap:'0.5rem',
                // border:'1px solid #000', borderRadius:'5px',
                // padding:'0.25rem 0.5rem'
                }}>
                    
                  <Typography>{ selectedQR?.isActivated ? 'Active':'Inactive'}</Typography>
                      <div style={{content:'',width:'1rem',height:'1rem', borderRadius:'50%',
                        backgroundColor: selectedQR?.isActivated ? '#00FF00':'#ffe485', boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.3)'}}></div>
</Box>}
                  {/* <div style={{display:'flex', flexDirection:'column',width:'100%',border:'1px solid red'}}> */}
  {/* -------card 1--------- */}
               
  {/* -------card 2--------- */}
<a href={selectedQR?.pic_url} target='_blank'  download>
                     <button className="manager-add-button blink" type="submit"  
                        style={{
                          position:'absolute',
                          left:'0.125rem',
                          top:'0.125rem',
                          zIndex:99999,
                          cursor: 'pointer',
                          display: 'flex', 
                          flexDirection: 'column',
                          alignItems:'center', 
                          width: '35px',
                          border: '2px solid #FFFFFF',
                          boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                          padding: '0.25rem',// 0.125rem 0  0.125rem',
                          backgroundColor: 'rgba(61, 255, 158, 0.628)'}}
                        // onClick={handleSave}
                        >

                      <img src={'/assets/DownloadQR.svg'} style={{height:'30px'}} alt="download"/>
                    {/* <img src={'/assets/qrcode.svg'} style={{height:'19px'}} alt="QR code"/> */}
                    {/* QR Code */}
                  </button>
                  </a>
                <div style={{display:'flex',flexDirection:'column',position:'relative',flex:1,textAlign:'center',justifyContent:'center', gap:'0.5rem'}}>
                {/* <p style={{margin:'0.5rem 0'}}>QR name: {qrCodeData?.name}</p>  */}
                 

                <Box style={{
                  width:'fit-content',
                  display:'flex',
                  flexDirection:'column',
                  position:'relative',
                  margin: '1rem auto', 
                  justifyContent:'center',
                  
                
                  }}>
                    <Box className='button-accent-hover'
                      sx={{margin:'1rem auto',cursor:'pointer',border:'2px solid #000', backgroundColor:'rgba( 255,255,255,1 )',
                                borderRadius:'10px',padding:'0.5rem', boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.3)',
                                justifyContent:'center'}}  onClick={()=>{
                      setSettings(!settings)
                    }}>

                    {selectedQR?.isLocked ?<LockIcon sx={{fontSize:'3rem',margin:'auto',color: selectedQR?.isLocked ? 'limegreen':'red'}} />
                            :<LockOpenIcon sx={{fontSize:'3rem',margin:'auto',color: selectedQR?.isLocked ? 'limegreen':'red'}}/> }
                    </Box>

                  {/* <CreateGeoCondition qr={selectedQR}/> */}
                  <GeoConditions view='main' qr={selectedQR}/>
                     <div style={{
                          display:'flex',
                          flexDirection:'column'}}>
                         <b style={{margin:'0.5rem auto'}}>
                          {showPopup ? popupText:<>&nbsp;</>}
                        </b> 
                    

                    
                   <Box sx={{position:'relative'}}>

                         {!selectedQR?.isActivated && <Box className='center'
                         sx={{fontSize:'0.75rem',position:'absolute', boxShadow:'0px 0px px 1px rgba(0,0,0,0.3),inset 0px 0px 5px 2px rgba(255,255,255,0.5)',
                        display:'flex',flexDirection:'column',textAlign:'right', alignItems:'flex-end',gap:'0.1rem',zIndex:999,backgroundColor:'rgba(255,255,255,1)',
                        backdropFilter:'blur(5px)', width:'100px',
                        border:'2px ridge #000', borderRadius:'5px',padding:'0.25rem 0.5rem',cursor:'pointer','&:hover': {background:'rgba(144, 254, 195,0.7)',}}}
                        onClick={()=>{
                          window.open(selectedQR?.data,'_blank')
                        }}

                        >
                            
                    <Box 
                    sx={{display:'flex', alignItems:'center',//border:'1px solid red',
                        justifyContent:'space-evenly', width:'100%',
                    }}>

                      <div className={selectedQR?.isActivated?'':'blink'} style={{content:'',width:'0.85rem',height:'0.85rem', borderRadius:'50%',backgroundColor: selectedQR?.isActivated ? '#00FF00':'rgba(252, 143, 0,1)',//'#ffe485',
                         boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.3)'}}></div>
                      <Typography>{ selectedQR?.isActivated ? 'Active':'Inactive'}</Typography>
                    </Box>
              {!selectedQR?.isActivated &&<Stack sx={{display:'flex',width:'100%', justifyContent:'center',alignSelf:'center',textAlign:'center'}}>
                <Typography sx={{fontSize:'0.75rem', width :'100%',textAlign:'center'}}>
                <Divider />
                    click to activate
                </Typography>
               </Stack>}
                  </Box>}

                   { selectedQR?.pic_url 
                   ? <Box component={'img'}  src={selectedQR?.pic_url} sx={{width:'250px', height:'auto'}}>
                    </Box>
                   :<ManagerQR func={selectedQR?.width >250?'preview':'actual'}  view='dashboard-edit' QR={selectedQR} key='edit'/>}
                  <div className='button-accent-hover'
                      style={{
                        position:'absolute',
                        bottom:'0.5rem',
                        right:'0.75rem',
                        cursor: 'pointer',
                        width: '1.25rem',
                        height:'1.25rem',
                        // padding: '0 0.125rem',
                        borderRadius: '5px',
                        border: '2px solid #FFFFFF',
                        boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                        // padding: '0.125rem 0.125rem 0  0.125rem',
                        backgroundColor: 'rgba(255, 255, 255,1 )'}} 
                        onClick={()=>{
                            updateAppData({
                      ...appData,
                      modalOpen: true,
                      modalView:'edit-QR'
                  })
                          }}
                        >
              <img style={{backgroundColor: 'rgba(253, 156, 0,0.68)', borderRadius:'5px', width:'100%'}} src='/assets/pencil.svg' alt="edit item"/>
              {/* {edit && <img width='17rem' src='/assets/save.svg' alt="save item"/>} */}
           </div>
            
                   </Box>
                   
                   </div>
                   
                   
                  </Box>
                   <Typography style={{display:'flex',marginBottom:'0.75rem',alignItems:'center', margin: 'auto'}}>
                      <b>pin: &nbsp;</b>
                      {selectedQR?.pin ? showPin ? selectedQR?.pin:'******'
                      : <Box sx={{border:'2px solid #90FEC3',borderRadius:'5px',
                          padding:'0.25rem 0.5rem',cursor:'pointer',width:'50%',
                        '&:hover': {background:'rgba(144, 254, 195,0.2)'}
                        }}
                      onClick={generatePin}
                      >
                        <Typography>Generate Pin</Typography>
                        </Box>} 

                     {/* </div> */}
                  {selectedQR?.pin && <div  style={{
                      height:'25px', 
                      alignSelf: 'center', 
                      marginLeft:'0.75rem',
                      cursor:'pointer',
                      padding: '0 0.125rem',
                      borderRadius: '50%',
                      border: '2px solid #FFFFFF',
                      boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                      padding: '0.125rem',
                      backgroundColor:'rgba(249, 249, 137, 0.68)'}} 
                        onClick={()=>{
                         
                          setShowPin(!showPin)
                          // console.log('Copy  data: ', selectedQR?._id)
                        }}>
                      <VisibilityIcon sx={{}}/>
                      {/* <img  className='button-accent-hover'
                        src={'/assets/copy_black.svg'} 
                         style={{height:'25px', alignSelf: 'center', 
                        cursor:'pointer'}} 
                       
                        alt="copy pin"/> */}
                   </div>}
                  {selectedQR?.pin && <div  style={{
                      height:'25px', 
                      alignSelf: 'center', 
                      marginLeft:'0.75rem',
                      cursor:'pointer',
                      padding: '0 0.125rem',
                      borderRadius: '50%',
                      border: '2px solid #FFFFFF',
                      boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                      padding: '0.125rem',
                      backgroundColor:'rgba(140, 203, 233,0.68)'}} 
                        onClick={()=>{
                          navigator.clipboard.writeText(selectedQR?.pin)
                          showCopyPopup('pin')
                          // console.log('Copy  data: ', selectedQR?._id)
                        }}>
                      <img  className='button-accent-hover'
                        src={'/assets/copy_black.svg'} 
                         style={{height:'25px', alignSelf: 'center', 
                        cursor:'pointer'}} 
                       
                        alt="copy pin"/>
                   </div>}
                    </Typography>

            <Typography style={{display:'flex',marginBottom:'0.75rem',
                    alignContent:'center',alignItems:'center',whiteSpace:'nowrap', margin:'auto'}}>
                      <b>id: &nbsp;</b>
                      <span style={{ overflow:'hidden',textOverflow:'ellipsis',maxWidth:'280px',width:'90%',minWidth:'150px'}}>

                       {selectedQR?._id} 
                      </span>

                     {/* </div> */}
                   <div  style={{
                      height:'25px', 
                      alignSelf: 'center', 
                      marginLeft:'0.75rem',
                      cursor:'pointer',
                      padding: '0 0.125rem',
                      borderRadius: '50%',
                      border: '2px solid #FFFFFF',
                      boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                      padding: '0.125rem',
                      backgroundColor:'rgba(140, 203, 233,0.68)'}} 
                        onClick={()=>{
                          navigator.clipboard.writeText(selectedQR?._id)
                          showCopyPopup('id')
                          // console.log('Copy  data: ', selectedQR?._id)
                        }}>
                      <img  className='button-accent-hover'
                        src={'/assets/copy_black.svg'} 
                         style={{height:'25px', alignSelf: 'center', 
                        cursor:'pointer'}} 
                       
                        alt="copy"/>
                   </div>
                   
                     
                    </Typography>
                   
                    <QRFieldComponent 
                      value={selectedQR?.payload}
                      object={selectedQR}  name='payload'
                      view='edit-QR'
                      payload_type={selectedQR?.payload_type}
                    />

                  </div>


              
                {/* </div> */}

            </Box>
                  {/* <QRFieldComponent 
                  value={selectedQR?.description}
                  object={selectedQR}  name='description'
                  view='edit-asset'
                  /> */}
                    <img className="settings-gear" width='35rem' src='/assets/SettingsGear.svg' alt="additional asset settings"
                    style={{position:'absolute',
                    right:'0.5rem',
                    bottom:'0.5rem',

                    cursor:'pointer'}}
                    onClick={()=>{
                      setSettings(!settings)
                    }}
                    />
        </div>



        {settings &&( 
            <Container sx={{display:'flex', flexDirection:'column',
            width:'90%', justifyContent:'center',gap:'0.5rem'}}>
            <h2>
              settings
              </h2>
             {/* <QRCodeForm qr={selectedQR} view='dashboard' key='edit'/> */}

 <Box sx={{
                          display:'flex',
                          flexDirection:'column',justifyContent:'center'}}>
                         {/* <b style={{margin:'0.5rem auto'}}>
                            {showPopup ? popupText:<>&nbsp;</>}
                          </b>  */}
                          <Stack className='button-accent-hover'
                              sx={{margin:'1rem auto', gap:'0.5rem',border:'2px solid #000',
                                borderRadius:'10px',padding:'0.5rem', boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.3)',
                                justifyContent:'center', 
                                cursor:'pointer', 
                                textAlign:'center',
                                width:'150px',height:'110px'
                               }}
                            onClick={async()=>{
                              let val = selectedQR?.isLocked
                              try{
                                const res =await API.updateQRCode({
                                  ...selectedQR,
                                    isLocked: !val
                                })
                                if(res){
                                  setSelectedQR(res)
                                  updateAppData({
                                    ...appData,
                                    selectedQR,
                                })
                                }else{
                                  setActionError('unable to toggle QR lock')
                                }
                              }catch(err){console.log('QR lock toggle error')}
                            }}
                            >
                            {selectedQR?.isLocked ?<LockIcon sx={{margin:'auto',color: selectedQR?.isLocked ? 'limegreen':'red'}} />
                            :<LockOpenIcon sx={{margin:'auto',color: selectedQR?.isLocked ? 'limegreen':'red'}}/> }
                            <Divider />

                            <Stack sx={{width:'100%',gap:'0'}}>
                              <Typography>
                                QR is
                                </Typography>
                              <Typography sx={{fontWeight:'bold',color: selectedQR?.isLocked ? 'limegreen':'red'}}>
                                {!selectedQR?.isLocked ?'un':'' }locked
                                </Typography>
                              <Typography sx={{fontSize:'0.8rem'}}>
                                ( click to {selectedQR?.isLocked ?'un':'' }lock )
                                </Typography>
                            </Stack>
                         
                          </Stack>
                          <Typography style={{display:'flex',marginBottom:'0.75rem',alignItems:'center'}}>
                      <b>pin: &nbsp;</b>
                      {selectedQR?.pin ?selectedQR?.pin
                      : <Box sx={{border:'2px solid #90FEC3',borderRadius:'5px',
                          padding:'0.25rem 0.5rem',cursor:'pointer',width:'50%',
                        '&:hover': {background:'rgba(144, 254, 195,0.2)'}
                        }}
                      onClick={generatePin}
                      >
                        <Typography>Generate Pin</Typography>
                        </Box>} 

                     {/* </div> */}
                  {selectedQR?.pin && <div  style={{
                      height:'25px', 
                      alignSelf: 'center', 
                      marginLeft:'0.75rem',
                      cursor:'pointer',
                      padding: '0 0.125rem',
                      borderRadius: '50%',
                      border: '2px solid #FFFFFF',
                      boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                      padding: '0.125rem',
                      backgroundColor:'rgba(140, 203, 233,0.68)'}} 
                        onClick={()=>{
                          navigator.clipboard.writeText(selectedQR?.pin)
                          showCopyPopup('pin')
                          // console.log('Copy  data: ', selectedQR?._id)
                        }}>
                      <img  className='button-accent-hover'
                        src={'/assets/copy_black.svg'} 
                         style={{height:'25px', alignSelf: 'center', 
                        cursor:'pointer'}} 
                       
                        alt="copy pin"/>
                   </div>}
                    </Typography>
                    {selectedQR?.pin && <Box sx={{border:'2px solid #90FEC3',borderRadius:'5px',
                          padding:'0.25rem 0.5rem',cursor:'pointer', marginBottom:'0.5rem',
                          '&:hover': {background:'rgba(144, 254, 195,0.2)'}
                      }}
                      onClick={generatePin}
                      >
                        <Typography>Generate New Pin</Typography>
                        </Box>}
                  {actionError ?<Typography sx={{color:'red'}}>{actionError}</Typography>:''}

                    
                   </Box>
        <div style={{ display:'flex', flexDirection:'column',
        // border:'1px solid red',width:'100%',
        //     justifyContent:'right'
            }}>
                <div className="manager-delete-button"
                style={{
                  display: 'flex',
                  justsifyContent:'center',
                  width:'fit-content',
                  cursor: 'pointer',
                   margin: '2rem auto  1rem auto',
                  padding: '0.125rem',
                  borderRadius: '5px',
                  boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                  // padding: '0.25rem',// 0.125rem 0  0.125rem',
                  border: '2px solid #000000',
                  backgroundColor: 'rgba(255, 255, 255, 0.327)'
                }} 
                  onClick={()=>{
                    updateAppData({
                      modalOpen: true,
                      modalView:'confirm-qr-delete'
                  })
                      // deletePersona()
                  }}
                  >
                    <img width='35rem' src='/assets/DeleteQR.svg' alt='Delete QR'/>
                </div>
    </div> 
            </Container>
                )}
               
        </div>
      
   </div>
  //  </div>
  )
}

export default SingleQR