export const items = [
    {
        type:'Roasted Garlic Hummus',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'Chickpeas, tahini, roasted garlic, lemon juice, olive oil, cumin, salt and pepper.',
        sizes: ['8oz.','16oz.'],
        prices: [6,10],
        tags:['deli','catering'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true

    },
    {
        type:'Apple Chicken Salad',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/ChickenSalad_v8yki1.jpg',
        descr:'Grilled chicken thighs, red onion, celery, Apples, mixed nuts, mayo, cayenne, salt and pepper.',
        sizes: ['8oz.','16oz.'],
        prices: [7,12],
         tags:['deli','catering'],
        rating:{
            isOn: true,
            ratings:[5,5,5,3,5]
        },
        isActive: true
    },
 
    {
        type:'Roasted Sweet Potato Pasta Salad',
        pic:'',
        descr:'Orzo pasta, roasted sweet potato, roasted chickpeas fresh parsley, with a lemon feta dressing.',
        sizes: ['8oz.','16oz.'],
        prices: [6,11],
         tags:['deli','catering'],
         rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true

    },
    {
        type:'Roasted Butternut Squash Salad',
        pic:'',
        descr:'Butternut Squash, Parsley, pomegranate, pistachios, baby kale and goat cheese.',
        sizes: ['8oz.','16oz.'],
        prices: [6,11],
        tags:['deli','catering'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    }, 
    {
        type:'Veggie Quiche',
        pic:'',
        descr:'Spinach, mushrooms, broccoli and goat cheese in my made from scratch crust.',
        sizes: ['8in.'],
        prices: [26],
         tags:['deli','catering'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true

    }, 
    {
        type:'Bacon Gruyere Quiche',
        pic:'',
        descr:'Bacon, gruyere and onion in my made from scratch crust.',
        sizes: ['8in.'],
        prices: [28],
        tags:['deli','catering'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    }, 
    {
        type:'Margerita Flatbread',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1709417692/AIW-Flatbread-Marg_vsvdqd.jpg',
        descr:'Pesto base, tom, mozz with fresh basil and balsamic glaze',
        sizes: ['feeds 3-5'],
        prices: [16],
        tags:['hosting', 'catering'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    }, 
    {
        type:'Green Apple and Brie Flatebread',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1709417693/AIW-Flatbread-GreenAppleBrie_kj8bhk.jpg',
        descr:'Green apples, brie cheese, caramelized onions topped with fresh arugula and balsamic glaze.',
        sizes: ['feeds 3-5'],
        prices: [16],
        tags:['hosting','catering'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    }, 
    {
        type:'Charcuterie',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1709397420/AIW-Charcuterie-Close-Up_yd22lu.jpg',
        descr:'Assortments of meats, cheeses, preserves, mustards etc.',
        sizes: ['feeds 4','feeds 8-10'],
        prices: [40,70],
        tags:['charcuterie'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    }, 
]