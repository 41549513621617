import React from 'react'

const LogoCopy = () => {
  return (
    <div style={{ display: 'flex',flexDirection:'column',alignSelf:'center'}}>
            <h1 className="text-shadow-02 blink-2">QauRa</h1>
            <h6 style={{color:'rgba(255,255,255,1)',zIndex:9999}}>
              (dynamic QR manager)
              </h6>
             <span>
            <h5 className="text-shadow-01"style={{color:'rgba(35,40,53,1)'}}>
                  Expand Your Brand, 
                </h5>
                  </span>  
            <h5 className="text-shadow-01"style={{color:'rgba(35,40,53,1)'}}>
              <span>
                Grow Your Glow
                  </span>  
                </h5>
        </div>
  )
}

export default LogoCopy