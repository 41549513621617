import React, { useState, useContext, useEffect, createRef, useRef } from 'react'
import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";

import QRCodeStyling from "qr-code-styling";
import { borderExtension } from "../extensions/QRextensions";

import useWindowDimensions from "../hooks/windowDimensions";
import * as API from '../utils/APICalls'
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import extensionOptions from '../defaults/ExtensionOptions';
import LSAQR from './LSAQR';



// const path = require('path');
// import path from 'path';
// import "./Demo.css";




const BuildLSAsset = () => {
    const defaultSrc =
  "https://res.cloudinary.com/dzxzdsnha/image/upload/v1690071590/Origins_Expanded_Resize_Lo_mpoxfh.jpg";
    const [ extOpts, setExtOpts ]= useState(extensionOptions)
    const { userData, setAssets,appData, 
            updateAppData,setCurrentAsset, 
        currentAsset, newAsset, LSAsset, setLSAsset, assetQR, setAssetQR, 
        setNewAsset, currentPersona,selectedQR, rawQR, setRawQR } = useContext(AppContext);
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const [ editCrop, setEditCrop] = useState(true)
    const [ isCropping, setIsCropping ] = useState(false)
    const [ isMerging, setIsMerging ] = useState(false)
    const [ picLoading, setPicLoading ] = useState(false)
    const [ pic, setPic ] = useState(defaultSrc)
    const [ QRImage, setQRImage ] = useState(false)
    const [ picIsUrl , setPicIsUrl ] = useState(false)
    const [ fileCopy , setFileCopy ] = useState(false)
    const { height, width } = useWindowDimensions();
    const [image, setImage] = useState(defaultSrc);
    const [cropData, setCropData] = useState(false);
    const [mergedImage, setMergedImage] = useState();
    const cropperRef = createRef();
    
    const ref3 = useRef(null);
    // const [ rawQR, setRawQR ]= useState(false)
    
//     const onChange = (e) => {
//     e.preventDefault();
//     let files;
//     if (e.dataTransfer) {
//       files = e.dataTransfer.files;
//     } else if (e.target) {
//       files = e.target.files;
//     }
//     const reader = new FileReader();
//     reader.onload = () => {
//       setImage(reader.result);
//     };
//     reader.readAsDataURL(files[0]);
//   };


const checkType = () =>{

            if (picIsUrl) {
                return 'text'
            }else{ return 'file' }
        }
        
    const LSAToCloud = (pic) =>{

      
    }
const postPicDetails =  (pics) => {
    // console.log("postPicDetails pics/type:", pics, pics?.type)
        setPicLoading(true);
        // const data = await API.savePic(pics)
        //     setPic(data)
        //         console.log("pic",data)
        if (typeof pics === undefined) {
            console.log("pics undefined")
          return;
        } 

        // var png = img_b64.split(',')[1];
      
    //   if (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "image/gif" ) {
         try{




             const data = new FormData();
               data.append("file", pics);
               data.append("upload_preset", "TinCan");
               data.append("cloud_name", "dzxzdsnha");
               fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
                 method: "post",
                 body: data,
               })
                 .then((res) => res.json())
                 .then((data) => {
                   setPic(data?.url?.toString());
                //    setLSAsset({
                //              ...LSAsset,
                //              isLSA: true,
                //              url: data?.url?.toString()
                //          })
                    setNewAsset({
                        ...newAsset,
                        pic: data?.url?.toString(),
                        LSAsset: {
                            isLSA: true,
                            url: data?.url?.toString()
                        }
                    })
                   updateAppData({
                             ...appData,
                             newAsset,
                             LSAsset
                         })
                
                   console.log('[BuildLSAsset]:',data.url);
                   setPicLoading(false);
                 })
                 .catch((err) => {
                   console.log(err);
                   setPicLoading(false);
                 });
         }catch(err){console.log(err)}
        // } else {
        //   setPicLoading(false);
        //   return;
        // }
      }
        const handleChange =(e) =>{
        let { name, value ,files} = e.target
        // console.log('HandleChange--->tempPersona(before):',tempAsset)
        // if(name === 'pic'){
            if(!picIsUrl){
                postPicDetails(files[0])
                // onChange(files[0])
                
            }else{
                setPic(value)
                setLSAsset({
                    ...LSAsset,
                    bgPic: value
                })
                setNewAsset({
                    ...newAsset,
                    LSAsset: {...LSAsset}
                })
                updateAppData({
                        ...appData,
                        newAsset,
                        LSAsset
                    })
                    
            }
                  
    //                 let files;
    // if (e.dataTransfer) {
    //   files = e.dataTransfer.files;
    // } else if (e.target) {
    //   files = e.target.files;
    // }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(pic);
  
            // }
            // else{
            //     setLSAsset({
            //         ...LSAsset,
            //         [name]: value
            //     })
            //     // setCurrentAsset({...currentAsset})
            //     setIsSaved(false)
            // }
            }
           
    const handleItemSave = async() =>{
            try{
                // const data = await API.updateAsset({...currentAsset})
                // setIsSaved(true)
                //  const as  = await API.fetchAssets(userData)
                // setAssets(as)
            }catch(err){console.log(err)}
        
        }

     let qrOptions ={
            ...assetQR,
            // ...size
            // width:150, 
            // height:150,
            width: width * .4, 
            height: width * .4,
            type:'svg',
            margin: (width * .4)/30-2 
        }
    let qrCode = new QRCodeStyling(qrOptions);

const mergeImages = async(image1, image2) => {
    // let updatedQR = qrCode?.applyExtension(borderExtension(extensionOptions));
    let raw_qr = await qrCode?.getRawData('png')//.toDataURL()
        // Save the merged image to a file
        var blob1 = new Blob([cropData], {type: "image/png"});
        var blob2 = new Blob([raw_qr], {type: "image/png"});
        // Save the blob to a file
        var file1 = new File([blob1], "BGCrop.png");
        var file2 = new File([blob2], "qr.png");
        const url1 = URL.createObjectURL(file1);
        const url2 = URL.createObjectURL(file2);
        // setRawQR(qrCode?.pic_url)
        setRawQR(url2)
        setFileCopy(url1)
        console.log('[mergedImages] RawQR blob:', rawQR)

        // var image1 = new Image();
        // image1.src = url1.toString().split('blob:')[1];
        // image1.src = cropData;
        // const image1 = document.getElementById('img-preview')
        // // const image1 = document.getElementById('croppedData')
        // const image2 = document.getElementById('rawQR')

        // var merged = await mergeImages(image1, image2);
        // setMergedImage(merged)
        // console.log('merged:', merged)
        // console.log('mergedImages:', mergedImage)
    
        var canvas = document.createElement("canvas");
        
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");

        // Draw the first image
        ctx.drawImage(image1, 0, 0,width,height)
        // Draw the second image on top of the first imagex
        //   const img2 = await rawQR
        ctx.shadowBlur = 20;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.shadowColor = 'rgba(0,0,0,1)';
        ctx.drawImage(image2,canvas.width/2 - image2?.width/2-20,canvas.height/2 - image2?.height/2);
        // ctx.drawImage(image2,100,100);

        // Return the merged image
        // console.log('canvas.toDataURL("image/png"):', canvas.toDataURL("image/png"))
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const blob = new Blob([imageData], { type: 'image/png' });
        const file = new File([blob], 'QauRa-MergedLSAsset.png' );
        const mergedURL = URL.createObjectURL(file)
        const canvasOutput = canvas.toDataURL("image/png")
        setMergedImage(canvasOutput)
        postPicDetails(canvasOutput)
        
        // setCurrentAsset({
        //     ...currentAsset,
        //     qr_id: assetQR?._id,
        //     LSAsset:{
        //         isLSA: true,
        //         // bgPic: image1,
        //         url: canvasOutput
        //     }

        // })
        // updateAppData({
        //     ...appData,
        //     currentAsset
        //     })
       
        // setMergedImage(mergedURL)

        setIsMerging(false)
        console.log('mergeImages output:',file)
        return canvas.toDataURL("image/png");

    }

    const getCropData = async() => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            // setIsCropping(true)
            setCropData(cropperRef.current?.cropper.getCroppedCanvas()?.toDataURL());
        // if(cropData){
           
        //     let raw_qr = await qrCode?.getRawData('png')//.toDataURL()
        //     // Save the merged image to a file
        //     var blob1 = new Blob([cropData], {type: "image/png"});
        //     var blob2 = new Blob([raw_qr], {type: "image/png"});
        //         // Save the blob to a file
        //         var file1 = new File([blob1], "BGCrop.png");
        //         var file2 = new File([blob2], "qr.png");
        //         const url1 = URL.createObjectURL(file1);
        //         const url2 = URL.createObjectURL(file2);
        //             setRawQR(url2)
        //             setFileCopy(url1)
        //         console.log('[mergedImages] RawQR blob:', rawQR)
    
        //         // var image1 = new Image();
        //         // image1.src = url1.toString().split('blob:')[1];
        //         // image1.src = cropData;
        //         const image1 = document.getElementById('img-preview')
        //         // const image1 = document.getElementById('croppedData')
        //         const image2 = document.getElementById('rawQR')
    
        //         // var image2 = new Image();
        //         // image2.src = rawQR.toString().split('blob:')[1]
        //         // console.log('merged image1.src:',  image1.src)
        //         // console.log('merged image2.src:',  image2.src)
    
        //         // Merge the two images
        //         var merged = await mergeImages(image1, image2);
        //         setMergedImage(merged)
        //         console.log('merged:', merged)
        //         console.log('mergedImages:', mergedImage)
        //         // Set the background image of the element
        //         // document.addEventListener('load',()=>{
                
        //         // })
               
        //     // }
        // // Save the merged image to a file
        // // var blob = new Blob([mergedImage], {type: "image/png"});

        // // // Save the blob to a file
        // // var file = new File([blob], "mergedImage.png");
        // // // file.save();
        // // console.log('file:', file)
        // }
        setIsCropping(false)
    }
  };

    useEffect(() => {
        
    
        }, [assetQR]);

    
    // let qrCode = new QRCodeStyling(qrOptions);
    // const rawQR = qrCode.getRawData('png')
    useEffect (()=>{
        
        // console.log('rawQR', rawQR)
       
        },[rawQR])
        
    useEffect (()=>{
    
        },[mergedImage])

    useEffect (()=>{
        
     },[LSAsset,appData,cropData,assetQR,rawQR,newAsset])

      
        
    return (
        <div style={{
                display:'flex', 
                flexDirection:'column',
                border:'2px solid #000', 
                borderRadius:'10px', 
                backgroundColor:'rgba(255,255,255,0.5)', 
                backdropFilter:'blur(4px)', 
                margin: 'auto', 
                width: '100%',
                minHeight:'350px',
                height:'100%',
                overflowY:'scroll'
                }}>
            build lock-screen asset
            <span style={{display:'flex', flexDirection:'column', justifyContent:'center', width:'80%', margin:'auto'}}>    
                {/* <div style={{display:'flex',flexDirection:'column'}}> */}
                        {/* url?:
                        <input value={picIsUrl} type='checkbox'
                            onChange={(e)=>{
                                setPicIsUrl(!picIsUrl)
                            }}/> */}
                            <span style={{display:'flex', flexDirection:'row', alignItems:'center', gap: '0.25rem'}}>
                            {/* {currentAsset[name]?'on': 'off'} */}
                            upload
                                <label className="switch-sml" style={{marginTop:'0.5rem'}}>
                                
                                <input type="checkbox" defaultValue={false} checked={picIsUrl}
                                // key={name}
                                onChange={()=>{
                                        setPicIsUrl(!picIsUrl)
                                    }}/>
                                    <span className="slider-sml round">

                                    </span>
                                </label>
                                url
                                {/* {!currentAsset[name]?'on': 'off'} */}
                            </span>
                {/* </div> */}
                <input className='asset-item-text' id='asset-item-text'
                style={{width:'100%', minWidth: '150px',maxWidth:'300px'}}
                type={checkType()}
                // defaultValue={LSAsset?.bgPic}
                name={'pic'} 
                onChange={(e)=>{
                    handleChange(e)
                    // if(name === 'pic'){ postPicDetails(e.target.files[0])}
                    // else{ 
                        // handleChange(e)
                    //  }
                }}/>

                </span>
        <hr/>
            

            <div>
                <div style={{margin:'0.5rem auto',display:'flex', flexDirection:'column', gap:'0.25rem'}}>
                    <div style={{margin:'0.5rem auto',display:'flex', flexDirection:'row', gap:'0.25rem'}}>
                    {mergedImage && 
                    <button className='save-button'style={{ float: "right",color:'#000000', height:'0.85 rem', fontSize:'0.7rem' }} >
                        <a style={{textDecoration:'none',color:'#000000'}} href={mergedImage} download="QauRa_LockScreenAsset.png">Download</a>
                    </button>
                   } 
                    {/* <div ref={ref3} id={'canvas3'} //style={{position:'absolute', top:0, left:0,zIndex:9999}}
                    ></div> */}
                    {/* <LSAQR /> */}
                    {cropData && <button className='save-button'style={{ float: "right",color:'#000000', height:'0.85 rem', fontSize:'0.7rem'  }} 
                        onClick={(e)=>{
                            e.preventDefault()
                            setIsCropping(true)
                            // if(cropData){
                                //     setEditCrop(true)
                                // }else if(cropData === '#'){
                                    setCropData()
                                    
                                    setMergedImage()
                                    getCropData()
                                    // if(cropData){

                                    //         postPicDetails(mergedImage)
                                    //     }
                                        setEditCrop(!editCrop)
                                        setIsCropping(false)
                                        // }
                             }}>
                    {!cropData || (cropData && editCrop)? 'crop image':isCropping?'cropping image':'edit crop'} 
                    </button>}
                    </div>


                    {/* {cropData && <button className='save-button'style={{ float: "right",color:'#000000' }} 
                        onClick={(e)=>{
                            e.preventDefault()
                            const image1 = document.getElementById('img-preview')
                            // const image1 = document.getElementById('croppedData')
                            const image2 = document.getElementById('rawQR')
                            setIsMerging(true)
                            setMergedImage(false)
                            mergeImages(image1,image2)
                            setIsMerging(false)
                        }}>
                        {!mergedImage && !isMerging? 'merge images':isMerging?'merging image':''} 
                    </button>} */}
                </div>

                <div className="box" 
                    style={{ 
                        display:'flex',
                        // flexDirection:'',
                        margin:'auto',
                        width: "50%", 
                        justifyContent: "center" 
                        }}>
            
                {(!cropData || (cropData && editCrop)) &&
                        
                <div style={{
                        display: 'flex', 
                        flexDirection:'column',
                        cursor:'pointer',
                        gap:'0.5rem',
                        width:'100%',
                        height:'100%'}} 
                    onClick={(e)=>{
                         e.preventDefault()
                            setIsCropping(true)
                            getCropData()
                            setEditCrop(!editCrop)
                            setIsCropping(false)

                        }}>
                    <h1>preview</h1>
                    {editCrop &&<p>(click preview to crop)</p>}
                    <div  style={{ position:'relative',
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            width: "100%", 
                            // float: "left", 
                            height: "100%" 
                            }}>

                        <div className="img-preview"
                        id='img-preview'
                            style={{ 
                                position:'relative',
                                width: "100%", 
                                // float: "left", 
                                height: '300px', 
                                boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.5)',
                                borderRadius:'5px'
                        }}
                        > 
                        </div>
                        {/* <hr style={{
                            position:'absolute',
                            height:'100%',
                            top:0,
                            left: '50%',
                            color:'red',
                            
                            }}>

                                </hr> */}
                        <div style={{
                            position:'absolute',
                            height:'100%',
                            top:0,
                            left: '50%',
                            border:'none',
                            borderLeft:'1px dotted red'
                            
                            }}>

                            </div>
                        <div style={{
                            position:'absolute', 
                            top:'10%', 
                            left:'0',
                            zIndex:9999,
                            transform:'scale(0.3)', 
                            // opacity:'0.7',
                            // border:'1px solid red',
                            width: '100%',
                            height:'100%'
                            }}>
                            <LSAQR />
                            {/* <div ref={ref3} id={'canvas3'} //style={{position:'absolute', top:0, left:0,zIndex:9999}}
                            ></div> */}
                        </div>
                    </div>
                </div>
                }
                {/* </div> */}
                {/* <div ref={ref3} id={'canvas3'} //style={{position:'absolute', top:0, left:0,zIndex:9999}}
                ></div> */}
                {/* <div className="box"
                    style={{ 
                        width: "50%", 
                        float: "right", 
                        height: "300px",
                    }}
                    > */}
                   {!editCrop && <div style={{display: 'flex', flexDirection:'column',cursor:'pointer',gap:'0.5rem'}} 
                        onClick={(e)=>{
                            e.preventDefault()
                            if(!mergedImage){ 
                            const image1 = document.getElementById('croppedData')
                            // const image1 = document.getElementById('croppedData')
                            const image2 = document.getElementById('rawQR')
                            // const image2 =assetQR?.pic_url
                            setIsMerging(true)
                            // setMergedImage(false)
                            mergeImages(image1,image2)
                            setIsMerging(false)}
                        }}> 
                    <h1>
                        <span>{cropData && (!mergedImage && !isMerging)?'cropped':mergedImage ?'merged':cropData &&  isMerging?'merging':'cropping'}</span>
                    </h1>
                        {(cropData  && !editCrop && !mergedImage) &&<p>(click to merge)</p>}
                    {/* {mergedImage && <p>(click to download)</p>} */}
                {cropData && typeof cropData !== undefined ? 
                    <div style={{position:'relative',padding:'0.5rem'}} >
                        {cropData && !mergedImage && <img
                             style={{ width: "100%", 
                             boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.5)', 
                             borderRadius:'5px',
                             
                             //  border:'1px solid blue' , 
                             zIndex:99997}} src={cropData} alt="cropped" id="croppedData" />}
                        {mergedImage &&
                         <a style={{textDecoration:'none',color:'#000000'}} href={mergedImage} download="QauRa_LockScreenAsset.png">
                             <img 
                                 style={{ 
                                     // position:'absolute', 
                                     // top:'0', left:'0',
                                     // width: '200px', 
                                     borderRadius:'5px',
                                     width:'100%',
                                     zIndex: 99999, 
                                     // border:'2px solid green', 
                                     boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.5)',
     
                                     // transform: "rotate('90deg')"
                                     }} src={mergedImage} id='elementId' alt="merged" 
                                    //  onClick={(e)=>{
     
                                    //  }}
                                     
                                     
                                     />
                         </a>
                                }
                       
                        {rawQR && !mergedImage && <img 
                            style={{ 
                                position:'absolute', 
                                top:'45%', 
                                left:'30%',
                                width: '40%', 
                                boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.5)',
                                // border:'1px solid red',
                                zIndex:99998
                                 }} src={rawQR} id='rawQR' alt="rawQR" />}
                        </div>
                        :
                        ''}
                </div>}
                
                    </div>
                  
        </div>
        <div style={{display:'flex', flexDirection:'column',flex:1}}>

            {/* <img src={pic} /> */}
            {/* <img src={LSAsset?.bgPic} 
                style={{display:'block',maxWidth:'100%'}}
                id='image'
            /> */}
            
            
            </div>
                { (!cropData ||( cropData && editCrop)) && <>
                 <b> screen</b>
                    <span><b>width: </b>{width} | <b>height:</b> {height}</span>
                <Cropper
                         ref={cropperRef}
                         style={{ height: "200px"}}
                         zoomTo={0.1}
                         // initialAspectRatio={width/height}
                         aspectRatio={width/height}
                         preview=".img-preview"
                         src={pic}
                         viewMode={1}
                         minCropBoxHeight={10}
                         minCropBoxWidth={10}
                         background={false}
                         responsive={true}
                         autoCropArea={2}
                         checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                         guides={true}
                         />
                         </>
                         
                         }
        
            
        </div>
  )
}

export default BuildLSAsset