import React, {useState, useContext,useEffect } from "react";

import { AppContext } from "../context/AppProvider";


const PersonaListCard = (props) => {
    const { appData, updateAppData,modalOpen,setCurrentPersona, currentPersona } = useContext(AppContext);
    const [active, setActive] = useState(false)
 
   const persona = props?.persona
   const id = props?.id

useEffect(()=>{

},[active,currentPersona])
  return (
    <div className="persona-list-card" id="persona-list-card"
        style={{
          position:'relative',
            minWidth: '150px',
            width: '4rem',
            height: '3.5rem',
            borderRadius:'5px 5px',
            border:'2px solid #000000',

            // border:`1px solid ${(active===id?'#6ca2f9':'#fff')}`,
            alignItems: 'center',
            textAlign: 'left',
            padding: '0.25rem 0.25rem',
            margin: '0.25rem',
            boxShadow: '0px 0px 3px 2px rgba(0,0,0,0.3)',
            cursor: "pointer",
            // backgroundColor:"rgba(256,256,256,0.2)",
            backdropFilter: 'blur(5px)',
            whiteSpace:'nowrap',
          overflow:'hidden'
        }}
        onClick={()=>{
            setCurrentPersona()
            setCurrentPersona({...persona})
            setActive(id)
            console.log('key',id)
            console.log('click', {...persona})
        }}
        >
          {persona.isProfile && <div style={{position:'absolute',right:'0.25rem',
          top:'0.25rem', //backgroundColor:'#FFF',
          height:'1rem', width:'1rem'}}>
            <div style={{borderRadius:'50%', width:'100%',height:'100%',
              textAlign:'center',justifyContent:'center', 
              boxShadow: '0px 0px 3px 2px rgba(0,0,0,0.3), inset 0px 0px 3px 2px rgba(255,255,255,0.3)',
              
              backgroundColor:persona.isProfile?'limegreen':'grey'}}>
              P
            </div>
          </div>}
        <h4 style={{borderBottom:'1px solid #000'}}>{persona.personaTitle}</h4>
        <h6>{persona.title.data?persona.title.data:'no organizational title'}</h6>
          <div style={{position:'absolute',bottom:'0.25rem',fontSize:'0.75rem', 
          outline:'1px solid #000000', borderRadius:'0 3px',width:'fit-content',boxShadow:'0px 0px 3px 2px rgba(0,0,0,0.4)', padding:'0 0 0 0.125rem'}}>
                FieldMode: 
              <b className={persona?.isVcard ?'blink':''}style={{margin:'0.125rem 0',color: persona?.isVcard ?'#00FF00':'red'
              ,padding: '0 0.125rem',borderRadius:'0 3px', background: '#000'}}>
                 {persona?.isVcard ?'on':'off'}
              </b>
              </div>
    </div>
  )
}

export default PersonaListCard