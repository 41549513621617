import ContactPhone from '@mui/icons-material/ContactPhone'
import { Container, Modal,Box, Button, Input, Typography, TextField } from '@mui/material'
import React, { useState } from 'react'
import CloseButton from '../../../components/CloseButton'

const ZoomModal = (props) => {
    const { setZoomModalOpen, zoomModalOpen, src } = props
    const [ pin, setPin ] = useState()
    const [ actionError, setActionError ] = useState('')
   
  return (
    <>
        <Modal 
            open={zoomModalOpen}
            onClose={()=>setZoomModalOpen(!zoomModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    backgroundColor:'#000',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem'
                    
                    }}>
                        <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setZoomModalOpen} val={zoomModalOpen}
                        
                            />
                        </Box>
                    <Box component={'img'}
                    src={src}
                    sx={{width:'100%', height:'auto'}}
                    >

                    </Box>
                      
                    </Box>
                    
            </Container>
        </Modal>
        
    </>
  )
}

export default ZoomModal