import React, { useEffect, useRef, useState, useContext } from "react";
import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";
import axios from 'axios'
import * as API from '../utils/APICalls'

import EnvRoute from '../middleware/envRoute';

import AssetListCard from './AssetListCard'
const ENDPOINT = EnvRoute('prod'); 


const AssetList = (props) => {
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    // const [ assets, setAssets ] = useState(false)
    const { appData, updateAppData, assets, setAssets , setUserData, userData, modalOpen, currentAsset, setCurrentAsset } = useContext(AppContext);
    const [isLoading, setIsLoading ] = useState(false)

   
    const user = {...userData}
    const fetchAssets  = async() =>{
        // if(!userData){
            // setUserData(localStorage.getItem('QauraUser'))
            // setUserData(localStorage.getItem('QauraUser'))
            // updateAppData({...appData, userData})
            // console.log("fetching asset data for: ", userData)//!something about this allows for the userData to acutally persist
        // }
        setIsLoading(true)
        console.log("fetching asset data for: ", userData)//!something about this allows for the userData to acutally persist
        try{
            const data  = await API.fetchAssets(userData)
            // console.log('assets data: ',data)
            if(data)
            {
                
                setAssets(data)
                // console.log('assets: ',assets)
                setIsLoading(false)
            }
        }catch(err){
            console.log(err)  
            setIsLoading(false)
        }
        }
    
    useEffect(()=>{
            let user
        if(!userData){
        
        try{

          user = JSON.parse(localStorage.getItem('QauRaUser'))
     

          if(user){
            setUserData({...user}) 
            updateAppData({...appData,userData})

            console.log('[App] local user:', user)
            console.log('[App] local userData:', userData)
          }
          else{return}
        }catch(err){console.log(err)}
        }
        },[])

    useEffect(()=>{
        if(!assets){
            fetchAssets()
            // console.log('assets: ',assets)
        }
    },[assets])
    useEffect(()=>{
        // f/etchAssets()
    },[assets])
     
  return (
  
    //  <div style={{height: '100%', borderRadius:'20px',
    //     // padding:'0.25rem',
    //     width: '100%'
    //     }}>
        //  <div data-slick='{"slidesToShow": 4, "slidesToScroll": 4}'
         <div 
          style={{display: 'flex',
            flexDirection:'row', width:'100%', padding: '0.15rem', borderRadius:'5px',
            alignItems:'center', overflow:'hidden', overflowX:'scroll'}}>
        {assets ? assets?.map((a,i)=>{
            return( 
            <AssetListCard  
            // onClick={()=>{
            //     updateQRCodeData(a)
            // }} 
            asset={a} id={i} key={i} /> )}):
             <div id="scroll-container" >  
                    <div id="scroll-text">
                        <h4>fetching assets...</h4>
                        {/* <h4>{(!assets?.length && !isLoading)? 'no assets available': 'fetching assets...'}</h4> */}
                    </div>              
                </div>
            }
    {/* </div> */}
    </div>
  )
}

export default AssetList