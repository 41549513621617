// jsx
import React, { useState } from 'react';
// import { sendQuery } from './apiService';
import axios from 'axios';

const API_URL = 'http://localhost:5001'; // Adjust to your local server port

export const sendQuery = async (query) => {
  try {
    const response = await axios.post(`${API_URL}/`, {user: query });
    return response.data;
  } catch (error) {
    console.error('Error querying agent:', error);
    throw error;
  }
};

function AgentInterface() {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await sendQuery(query);
      console.log('Result:', result)
      if(result?.tool_name === 'response'){
        if( result?.thoughts ){
            setResponse(result?.thoughts);
        }
      }
    } catch (error) {
      setResponse(['Error communicating with agent','']);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <h1>Agent-Zero AI Interface</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your query"
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Submit'}
        </button>
      </form>
      {isLoading && <p>Loading...</p>}
      {response && <div>
        <h2>Agent Response:</h2>
        <p>{response?.map((r,i)=>{
            return <p>{r}</p>
        })}</p>
      </div>}
    </div>
  );
}

export default AgentInterface;