

import { AppProvider, AppContext, AppState } from "../context/AppProvider";
import React, {useEffect, useContext, useState} from 'react'
import SingleQR from './SingleQR'
import QRList from './QRList'
import { QRCodeContext } from "../context/QRCodeProvider";
import SearchIcon from '@mui/icons-material/Search';
import { Input, InputAdornment, IconButton, Stack } from "@mui/material";

import * as API from '../utils/APICalls'

import axios from 'axios'




const Default_QR = require('../defaults/QRRough.json')

const QRManager = () => {
    // const ENDPOINT = 'http://localhost:5003/api'
    // const ENDPOINT = 'https://qaura-api.onrender.com/api'


    const { appData, updateAppData,selectedQR,setSelectedQR, currentQR, QRs } = useContext(AppContext);
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    // console.log("(currentQR?.useFieldMode): ",currentQR?.useFieldMode)
    const [ useFieldMode, setUseFieldMode ] = useState(currentQR?.useFieldMode)
    const [ loading, setLoading ] = useState(true)


    // const updateFieldMode = async () =>{
    //     setCurrentQR({...currentQR,useFieldMode:!currentQR?.useFieldMode})
    //     try{
    //         updateQRDb({...currentQR,useFieldMode:!currentQR?.useFieldMode})
    //         console.log('[currentQR]: ', currentQR)
    //     }catch(err){console.log(err)}
        
    //     }

    // const updateQRDb = async (obj) =>{
    //      try{
    //         const config = {
    //             headers: {
    //             "Content-type": "application/json",
    //             },
    //         };
    //         const { data } = await axios.put(
    //             `${ENDPOINT[0]}/QR/update`,
    //             {
    //                 _id: currentQR._id,
    //                 ...obj,
    //             },
    //             config
    //             );
    //             console.log('[updateQRs]: data', data)
    //             // setCurrentQR({...data})
    //     }catch(err){console.log(err)}   
    // }

     const updateQRCodeDb = async(obj) =>{
       try {
        console.log('Save Click', qrCodeData)
        setLoading(true);
        // const config = {
        //     headers: {
        //     "Content-type": "application/json",
        //     //   Authorization: `Bearer ${user.token}`,
        //     },
        // };
        const { data } = await API.updateQRCode({...obj})
        // const { data } = await axios.put(
        //     `${ENDPOINT[0]}/qr/update`,
        //    { _id: qrCodeData?._id,
        //     ...obj},
        //     config
        // );
        // updateQR()
        updateQRCodeData({...data})
       
        console.log('save response: ',data)
      setLoading(false);
    } catch (error) {
        // setActionError("error occured") 
        setLoading(false);
    }
    
  }  
    useEffect(() =>{
        updateQRCodeData({...Default_QR})
        },[])
    useEffect(() =>{

        },[currentQR,qrCodeData])
  return (
     <div className='QR-manager-container'
        style={{
            display:'flex', 
            flexDirection: 'column',
            // width:'100%', 
            // height:'95%',
            borderRadius:'10px',
            margin: 'auto', 
            padding: '0.5rem',
            alignItems:'center',
            justifyContent: 'center',
            gap:'0.5rem'
            }}>
        {/* UserInfoCard */}
    {/* <section className='user-info-bottom'
        style={{display:'flex', flexDirection: 'row',borderTop:'1px solid #868686'}}
    > */}

   <div style={{display: 'flex', flexDirection:'row',width: '100%',flex:1, alignItems:'center',
        justifyContent:'space-between'}}>

        <span style={{display: 'flex', flexDirection:'row',
        // position:'absolute',left:'1rem',top:'0.75rem', 
        textAlign:'center',cursor: 'pointer',gap:'0.125rem',whiteSpace:'nowrap'}}
            onClick={()=>{setSelectedQR(false)}}
            >
           {/* {currentQR &&  */}
           <img className={`${selectedQR?'rot180':'rot-180'}` }
           src={`/assets/arrow-${selectedQR?'up':'down'}.svg`} style={{height:'0.75rem', alignSelf: 'center',opacity:selectedQR?'1':'0.3'}} alt="Clear Current QR"/>
           {/* } */}
        </span>
        <Stack sx={{}}>

         <h4 
        //  className='text-shadow-02 blink' 
         style={{color:'#000',zIndex:0, marginBottom:'1rem'}}> 
            {QRs?.length}&nbsp;<u>QRs</u> 
         </h4>
          <Input
                    // onChange={handleSearchChange}
                      endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          // onClick={()=>toggleEdit(!edit)}
                          // onMouseDown={()=>toggleEdit(!edit)}
                          edge="end"
                        >
                        {/* <BackspaceIcon />  */}
                        <SearchIcon /> 
                        </IconButton>
                        
                      </InputAdornment>
                    }
                    />
        </Stack>
    <div className="manager-add-button"

              style={{
            display: 'flex',
            position: 'relative',
            right: '0.5rem',
            top: '0.25rem',
            // alingSelf:'right',
            width:'fit-content',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '0 0.125rem',
            borderRadius: '5px 10px',
            border: '2px solid #FFFFFF',
            boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
            padding: '0.25rem',// 0.125rem 0  0.125rem',
            
            backgroundColor: 'rgba(61, 255, 158, 0.628)'}} 
            onClick={()=>{
                updateQRCodeData({...Default_QR})
                updateAppData({
                    ...appData,
                    modalOpen: true,
                    modalView:'create-QR'
                })
            }}
            >
                <img height='25rem' src='/assets/createQR.svg' alt='create QR'/>
            </div>
            </div>
        <div className='QR-manager-left'
         style={{
            overflow:'hidden',
            overflowX: 'scroll',
            width: '90%',
            // minWidth:'28px',
            margin:'auto',
            // flex: 1,
            // height:'100%',
            // minHeight:'20rem',
            border:'2px solid #000000', 
            borderRadius: '10px',
            padding:'.25rem',
            backgroundImage:"url(" +'/assets/Bodybg.png' + ")",
            backgroundRepeat:"no-repeat", backgroundSize:"cover",
            boxShadow:'inset 0px 0px 5px 2px rgba(0,0,0,0.6)'}}>
            <QRList />
        </div>
    {/* </div> */}
    <div style={{margin:'0.5rem auto',width:'100%'}}>

            {selectedQR ? <SingleQR />:<p className="blink">no QR selected</p>}
    </div>
            {/* <label forHtml='payload'> link: </label><input type='text' name='payload' value={currentQR?.payload}/>
            <label forHtml='name'> name: </label><input type='text' name='name' value={currentQR?.name}/> */}
      
      
    {/* </section> */}
      </div>

    
  )
}


export default QRManager