import * as React from 'react';
import { useEffect, useRef, useState, useContext } from "react";
import PropTypes from 'prop-types';
import { SignupContext, SignupProvider }  from "../context/SignupProvider";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Rating from '@mui/material/Rating';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from './Copyright';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SignupOrderOption from './SignupOrderOption';
import ConfirmSignupModal from './ConfirmSignupModal';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

import { experimentalStyled as styled } from "@mui/material/styles";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ShareIcon from '@mui/icons-material/Share';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import * as API from '../utils/APICalls'
import InquirySendModal from './InquirySendModal';
import { AppBar, Backdrop, CardMedia, Tab, Tabs, Tooltip } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import * as vars from '../utils/vars'
import AddToCartButton from './AddToCartButton';
import CheckoutModal from './CheckoutModal';
import CustomerInfoForm from './CustomerInfoForm';

import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay]);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

 const AlixInWonderland =(props)=> {
    

 const { signupData, 
        setSignupData,
        updateSignupData, 
        tempOrder,
        setTempOrder,
        updateTempOrder,
        firstName, 
        setFirstName,
        lastName, 
        setLastName,
        email, 
        setEmail,
        phone, 
        setPhone,
        department, 
        setDepartment,
        notes, setNotes, 
        inquiryText, 
        setInquiryText,
        isFollower, tempOrders,
        setIsFollower, cart,setCart,order} = useContext(SignupContext);
    let localCart= []
    const [ orderObj, setOrderObj ] = useState({})
    const [currentIndex, setCurrentIndex] = useState(0);
    //  const [ firstName, setFirstName ] = useState('')
    //  const [ lastName, setLastName ] = useState('')
    //  const [ email, setEmail ] = useState('')
    //  const [ phone, setPhone ] = useState('')
    //  const [ department, setDepartment ] = useState('')
    //  const [ inquiryText, setInquiryText ] = useState('')
    //  const [ isFollower, setIsFollower ] = useState(true)
    const [value, setValue] = useState(0);
    const [ cartAmount, setCartAmount] = useState(0);
    const [ cartOpen, setCartOpen ] = useState(false)
    const [ inquiry, setInquiry ] = useState({})
    const [ orderView,setOrderView ] = useState(true)
    const [ showAbout, setShowAbout ] = useState(true)
    // const [ showAbout, setShowAbout ] = useState(value === null ?true:false)
    const [ open, setOpen ] = useState(false);
    const [ isSubmitting , setIsSubmitting ] = useState(false)
    const [ vCardData, setVCardData ] = useState()
    const [ actionError, setActionError ] = useState('')

    let locCart = localStorage.getItem('cart');
     if(locCart){
            locCart = JSON.parse(locCart)
        if (locCart) {setCart(locCart)}
     }
  const [snackOpen, setSnackOpen] = useState(false);

  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /*/----------------------------//
    Campaign Details
//----------------------------*/

const owner = '65cebbf3cc3c6b916336ee63'
const owner_email ='alixmakesfood@gmail.com'
const owner_firstName ='Alix'
const owner_lastName ='Talley'
const owner_phone = '(919)710-9792'
const shop_headers = ['https://res.cloudinary.com/dzxzdsnha/image/upload/v1709397837/AIW-Charcuterie-Far-01_o6rzzn.jpg','https://res.cloudinary.com/dzxzdsnha/image/upload/v1709417693/AIW-Flatbread-GreenAppleBrie_kj8bhk.jpg','https://res.cloudinary.com/dzxzdsnha/image/upload/v1709417692/AIW-Flatbread-Marg_vsvdqd.jpg']
// const shop_bg ='https://res.cloudinary.com/dzxzdsnha/image/upload/e_blur:2000/v1709397837/AIW-Charcuterie-Far-01_o6rzzn.jpg'
const shop_bg ='https://res.cloudinary.com/dzxzdsnha/image/upload/e_blur:1000/v1709417693/AIW-Flatbread-GreenAppleBrie_kj8bhk.jpg'
const owner_pic = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1706284713/Alix-Headshot_hdwrga.jpg'
// const owner_pic = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1707066484/Alix-Headshot-02_pw7go7.jpg'

const operating_name  = 'Alix Makes Food!'
const operating_nameHTML  = `<span style='font-size:3rem;'>A</span>lix <span style='font-size:3rem;'>M</span>akes <span style='font-size:3rem;'>F</span>ood!`
// const operating_name  = 'Alix-In-Wonderland!'


const campaingName  = 'Signup Sheet'
const type= 'bake sale'
const shop = {
    owner:owner,
    owner_email:owner_email,
    campaingName:campaingName,
    operating_name:operating_name,

}
const ownerObj ={

    firstName : {on:true, data:owner_firstName},
    lastName : {on:true, data:owner_lastName},
    organization: {on:true, data:operating_name},
    email: {on:true, data:owner_email},
    phone: {on:true, data:owner_phone},
    alt_pic: {on:true, data:owner_pic},
    directLink:{on:true, data:['https://AlixMakesFood.com']}
    // directLink:{on:true, data:['https://qaura.pro/partners/alix-in-wonderland']}
}


const infoHTML = " <h2 style='font-family:Sofia;'>Hi Everyone!🖐️ </h2><br/> <b>&nbsp; &nbsp;My name is Alix</b>, and I love to make simple food <b>absolutely delicious!</b> I would love to bring my obsession for all things tasty to you. There's <b>much more to come</b>, but have a look and see what I have to offer.</b> <br/><br/>&nbsp; &nbsp;Take a trip with me down the rabbit hole of scrumptious flavor and <b>let me feed you!</b> </b><br/> <br/> <b>Thanks for stopping by!</b>"

const items = vars.items
var deliItems = []

console.log('deliItems:', deliItems)
// const BlurryDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
//   backdropFilter: "blur(5px)",
//   // other styles here...
// }));
  const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


   const fetchVCard  = async(ownerInfo) =>{
        // console.log('fetchvCard persona: ', persona)
        if(ownerInfo){
            const data = await API.fetchVCard(ownerInfo)
            console.log('fetchvCard data: ', data)
            setVCardData(data)
            // setVCardData(data?{...data}:fetchVCard())
            console.log('fetchvCard VCardData: ', vCardData)
        }
    }

  const handleCartClick =(e)=>{
        // e.preventDefault()
        //  let loc = localStorage.getItem('cart')
        // if (loc){ localCart = JSON.parse(loc)}
        // else{localCart = null}
        //TODOShow cart
        setCartOpen(true)
        console.log('[Cart Click] local:', localCart)
        console.log('[Cart Click] tempOrder:', tempOrder)
        console.log('[Cart Click]: SignupData', signupData)
    }
  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };



const orderTemplate = () =>{
    return items?.map((e,i)=>{
        return ({
        item_type: e?.type,
        size: e?.sizes[0],
        quantity:0

    }) })
}

const getAvg = (values) => {
    return values?.reduce((m, x, i) => m + (x - m) / (i + 1), 0)
}
const saveVCard = (data) =>{
    // vCard.saveToFile(`./${persona?.preferredName?.data?persona?.preferredName?.data:persona?.firstName?.data}.vcf`)
    console.log('[save vCard]: ',data)
    downloadToFile(data, `${ownerObj?.firstName?.data}-${ownerObj?.lastName?.data}-vCard.vcf`, 'text/vcard')
}

const downloadToFile = (content, filename, contentType) => {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
}

const returnCartAmount =(a)=>{
    let count = 0
    a.forEach((e,i)=>{
        count = count + e?.quantity
    })
    console.log('[Cart Count]:',count)
    return count
}
//TODO MAKE FUNCTION TO GET CAMPAIGN DETAILS FROM DB TO BUILD ITEM LIST

const handleAboutHide =(e)=>{
    setShowAbout(!showAbout)
}

    const handleInquiryChange = (e) =>{
        setActionError('')
       setInquiryText(e.target.value)
        // console.log('inquiry:', inquiry)

    }
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true)
    // const data = new FormData(event.currentTarget);
    // setOrderObj({
    //     ...orderObj,
    //     owner_email,
    //     owner,
    //     operating_name,
    //     campaingName,
    //     firstName: firstName,
    //     lastName: lastName,
    //     email: email,
    //     phone:  phone,
    //     department: department,
    //     // firstName: data.get('firstName'),
    //     // lastName: data.get('lastName'),
    //     // email: data.get('email'),
    //     // phone: data.get('phone'),
    //     // department: data.get('department'),
    //     isFollower: isFollower,
    //     inquiry: inquiryText
    //     // tempOrder: tempOrder
       
    // })
    if( firstName === ''|| lastName === '' ||
        email  === '' || phone  === ''){
            alert('Please complete or re-enter your contact information')
        setIsSubmitting(false)
        }
    else{

        try{
    
            updateSignupData({ ...signupData,
                owner_email,
                owner,
                operating_name,
                campaingName,
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone:  phone,
                department: department,
                isFollower: isFollower,
                inquiry: inquiryText,
                order:[...tempOrder],
                tempOrder,
                cart
            })
            console.log('Submit tempOrder',  tempOrder);
            console.log('signupData', signupData);
            setOpen(!open)
            setIsSubmitting(false)
        }catch(err){
            setIsSubmitting(false)
            setActionError('Order failed to submit')
            console.log(err)  
        }
    }
  };
  
    useEffect(() => {
        window.scrollTo(0, 0)
        if(!vCardData){
            fetchVCard(ownerObj)
        }
    }, [vCardData])

    useEffect(() => {
        
    }, [signupData,orderObj,tempOrder,cart])

    useEffect(() => {
        // setCartAmount(cart?.length)
        // if(!cart){
            let loc =localStorage.getItem('cart')
            if (loc){ 
                localCart = JSON.parse(loc)
                setCart(localCart)
            }
            else{localCart = null}
            console.log('[Main] cart:',cart)
        // }
    }, [cart, locCart])

    // useEffect(() => {

    //     locCart = localStorage.getItem('cart')
    // // let cart =[]
    //     if(locCart){
    //         locCart = JSON.parse(locCart)
    //     if (locCart) {setCart(locCart)}
    // }
    // }, [])
    useEffect(() => {

 
    }, [localCart])

  return (
    <SignupProvider>
        <Container  sx={{position: 'relative', 
                 width:'100%',height:'fit-content',p:0}}>

        <CheckoutModal cartOpen={cartOpen} 
            setCartOpen={setCartOpen} modalView={'cart'}  subView={'cart'}
            cart={cart} shop={shop} setCart={setCart}
           
            />
       
        <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="md" minWidth="xs" className='signup-container' >
              <AppBar position="sticky"
                sx={{background:"rgba(255,255,255,1)",boxShadow:'0px 1px 5px 3px rgba(0,0,0,0.5)', 
                    top: '0px', left:'0px', maxWidth:'100vw', width:'100%',
                    borderRadius:'0px 0px 5px 5px', border:'3px solid black',borderTop:0,
                    backdropFilter:'blur(6px)', display:'flex',position:"sticky",
                    flexDirection:' row', justifyContent:'center',
                    // background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 15%, rgba(255,255,255,1) 100%)',
                    }}>
                {/* <Stack sx={{flexDirection:'row',width:'40%',alignSelf:'center'}} > */}

                    <Tooltip title="Follow on Instagram">
                        <Link href="https://www.instagram.com/alix.makes.food" target='_blank'>
                            <InstagramIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}}  />     
                        </Link>
                    </Tooltip>

                    <Tooltip title="Follow on Facebook">
                        <Link href="#" target='_blank'>
                            <FacebookIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Copy to clipboard">

                        <Link href="#"  onClick={(e)=>{
                            // let val=ownerObj?.directLink?.data.toString()
                            // let val='https:/qaura.pro/partners/alix-in-wonderland'
                            let val='https://qaura.pro/partners/AlixMakesFood'
                            console.log('url:',val)
                            
                            // navigator.clipboard.writeText(val);
                            // alert(val,'\nurl copied to clipboard')
                            navigator.clipboard
                                .writeText(val)
                                .then(() => {
                                    alert(`${val} successfully copied`);
                                })
                                .catch(() => {
                                    alert("something went wrong");
                                });
                            handleSnackClick()
                            }} >

                        <ShareIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                        {/* <Snackbar
                            anchorOrigin={'bottom'} 
                            open={snackOpen}
                            onClose={handleSnackClose}
                            message="url copied to clipboard!"
                        
                        /> */}
                        </Link>
                    </Tooltip>
                    <Tooltip title="Download contact vCard">
                        <Link href="#" label='save contact to phone' onClick={()=>{
                            console.log('click',vCardData)
                            // if(vCardData){
                                saveVCard(vCardData)
                                // }
                            }}>
                            <ContactPhoneIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                        </Link>
                    </Tooltip>
                {/* </Stack> */}


                    <Tooltip title="cart">
                        <Link sx={{position:'absolute',right:'0.5rem',}}
                        href="#" label='cart' onClick={(e)=>{handleCartClick(e)}}>
                                <Stack sx={{display:'flex', flexDirection:'row'}}>
                                    <b style={{position:'absolute', right:'0.5rem'}}>{returnCartAmount([...cart])}</b>
                                    <ShoppingCartIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                                </Stack>
                        </Link>
                    </Tooltip>
            </AppBar>
            <CssBaseline />
            <Box 
            // component='img'
                sx={{position:'absolute', top:'0px', left:'0px', 
                // backgroundColor:'rgba(203, 244, 245, 0.8)', 
                background: 'rgb(255,255,255)',
                background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 15%, rgba(0,0,0,1) 100%)',
                width:'100%',height:'225px',zIndex:0, overflow:'hidden',
                boxShadow:'inset 0px -3px 15px 10px rgba(0,0,0,0.8),0px -2px 5px 3px rgba(0,0,0,0.5)', 
                opacity:1,borderBottom:'3px solid #000',
                marginBottom:'1.5rem'
            }}
            // src ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1705938116/Orzo_fkhl2x.jpg'
                >
          

                    {/* // component='img' */}
            <Box 
                sx={{position: 'absolute', top:'0px', left:'0px', 
                backgroundColor:'rgba(0, 0, 0, 1)',
                 width:'100%',height:'auto',minHeight:'240px',zIndex:-1, opacity:'1',
                boxShadow:'inset 0px -15px 10px 5px rgba(0,0,0,0.8)',//,0px -2px 5px 3px rgba(0,0,0,0.5)'
                zIndex:2,
                //  backgroundImage:`url("${shop_headers[0]}")`,
                 backgroundAttachment:'fixed',
                 backgroundSize:'contain',
                 backgroundRepeat:'no-repeat',
            
                }}
                
                >

                     <Swiper
                    //  className='swiper-container'
                    //  sx={{position:'fixed'}}
                     cssMode={true}
                        spaceBetween={0}
                        slidesPerView={1}
                        onSlideChange={handleSlideChange}
                        speed={1000}
                        // autoplay={true}
                        loop={true}
                        // modules={[Autoplay]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        // navigation={true}
                        // pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)} // Optional: access Swiper instance
                    >
                        {shop_headers.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img src={image} alt={`Image ${index + 1}`} style={{ width: '100%', height: '100%' }} />
                        </SwiperSlide>
                        ))}
                    </Swiper>

                    {/* // src ={shop_header} */}
    
                    {/* // src ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1705938116/Orzo_fkhl2x.jpg' */}
            </Box>
            </Box>

            <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent:'center',
                        gap: '0.5rem',
                        
                    }}
                    >
            <Avatar sx={{ bgcolor: '#868686', width:'150px', height:'150px',
            border:'3px solid #000',marginBottom:'-1rem', boxShadow:'0px 0px 15px 5px rgba(0,0,0,0.8)'}} 
            src={owner_pic}>
            </Avatar>
            <Typography component="h1" variant="h5" 
            sx={{zIndex:1 , color:"#000", backgroundColor:'rgba(255,255,255,1)', fontFamily:'Sofia,sans-serif',
                    padding:'0.25rem 0.75rem', borderRadius:'10px 10px 0 0', 
                    boxShadow:'0px -4px 3px 1px rgba(0,0,0,0.3)',marginTop:'0rem'}}>
               <b> Greetings from </b>
            </Typography>

            <Box sx={{ display:'flex', flexDirection:'column', gap:'0.5rem', 
            borderRadius: '10px 10px 0 0',
            border:'3px ridge #000',
            borderBottom:'none',
            boxShadow:'0px -5px 8px 5px rgba(0,0,0,0.5)',
            backgroundColor:'rgba(255,255,255,0.9)',
           background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 10%, rgba(255,255,255,1) 100%)',
            justifyContent:'center',
            p:2, marginTop:'-1rem'
            }}>

            <Box //component='img'
            // src='/assets/BodyBG_alpha.png'
             minWidth="400px" 
            sx={{ position:'absolute', top:0, left:0, zIndex:-1, height:'100%',
            maxWidth:'100vw', width:'100%',
           
        //    backgroundImage:`url("/assets/BodyBG_alpha.png")`,
                backgroundImage:`url("${shop_bg}")`,
                 backgroundAttachment:'fixed',
                 backgroundSize:'cover',
                // backgroundRepeat:'no-repeat',
                backgroundPosition:'center'
                //  backdropFilter:'blur(10px)'
        }}
            // className='bg-blur'
            >
                {/* <Backdrop open={true} onClick={null} sx={{
                color: '#fff',
                zIndex: 0,
                }}></Backdrop> */}
                 {/* <Dialog
                    fullWidth
                    onClose={() => {}}
                    open={true}
                    maxWidth="xs"
                    sx={{
                    backdropFilter: "blur(5px)",
                    zIndex:0,
                    //other styles here
                    }}
                ></Dialog> */}
            </Box >

            <Typography component="h1" variant="h4" sx={{fontFamily:'Sofia,sans-serif'}} dangerouslySetInnerHTML={{ __html:operating_nameHTML}} >

            {/* <b>{operating_name}</b> */}
            </Typography>

           <Box sx={{position:'relative', padding: showAbout ? '0.75rem':'0rem 1rem', border: '1px dashed grey', 
             justifyContent:'center', borderRadius: '10px', width: !showAbout?'fit-content':'100%', maxWidth: !showAbout?'fit-content':'500px' ,margin: 'auto'}}>
                    <IconButton
                        aria-label="close"
                        onClick={handleAboutHide}
                         sx={{
                            position: showAbout?'absolute':'relative',
                            right: showAbout? 8:0,
                            top: showAbout? 8:0,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        >
                            {showAbout?<CloseIcon />:<div style={{display:'flex',flexDirection:'column'}}>
                                🖐️
                                <MoreHorizIcon />
                            </div>
                            }
                        </IconButton>
                {showAbout &&  <Typography component="p" sx={{fontSize:'0.85rem', textAlign:'left',textIndent: '1rem'}} dangerouslySetInnerHTML={{ __html:infoHTML}}>
                     
              
                </Typography>}
             </Box>

               <Box sx={{ width: '100%', display: 'flex', justifyContent:'center' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider',width:'100%',display:'flex', justifyContent:'center' }}>
                <Tabs value={value} onChange={handleChange} aria-label="">
                    <Tooltip title="Spreads & Salads">
                        <Tab label="Deli" {...a11yProps(0)} />
                    </Tooltip>
                    <Tooltip title="Appetizers & Charcuterie">
                        <Tab label="Hosting Essentials" {...a11yProps(1)} />
                    </Tooltip>
                    <Tooltip title="Private Events & Catering">
                        <Tab label="Inquiries" {...a11yProps(2)} />
                    </Tooltip>
                </Tabs>
            </Box>
      </Box>
        
       {value === 1 && <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                <Typography>Charcuterie</Typography>
                <Switch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={(e)=>{setOrderView(!orderView)}} checked={!orderView} />
                <Typography>Flatbreads & Bites</Typography>
            </Stack>}

            {/* <Typography component="h2" variant="h5">
            Feel free to place an order
            </Typography> */}
            
           


            <CustomTabPanel value={value} index={0}>

            <Box sx={{display: 'flex', flexDirection:'column', 
                    margin:'0.5rem auto', alignItems:'center',justifyContent:'center',gap:'0.5rem'}}>

                { items && items?.map((o,i)=>{
                     if(o?.tags?.includes('deli')){

                    return ( 
                        <Accordion  key={i} sx={{ width:'70%',minWidth:'300px',margin:'auto',textAlign:'center'}}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            // sx={{fontFamily:'Sofia'}}
                            >

                            {/* {o?.type} |  ( 16oz. )  X  1 */}
                            {o?.type}
                            {/* <Rating name="half-rating" defaultValue={getAvg(o?.rating?.ratings) } precision={0.5} /> */}
                            {/* {tempOrder[i]?.size ? tempOrder[i]?.size:'___'}  X  { tempOrder[i]?.quantity? tempOrder[i]?.quantity:'___'} */}
                            </AccordionSummary>
                            <AccordionDetails>
                              {o?.pic &&  <Box
                                    component="img"
                                    sx={{
                                        height: 'auto',
                                        width: 270,
                                        // maxHeight: { xs: 233, md: 167 },
                                        maxWidth: { xs: 300, md: 250 },
                                        borderRadius: '15px 15px 0px 0px',
                                        margin:'auto',
                                        padding: '0.5rem',
                                        fontFamily:'Gilroy'
                                    }}
                                    alt= {o.type}
                                    src= {o.pic}
                                    />}

                                <Box sx={{ p: 2, border: '1px dashed grey', justifyContent:'center' }}>

                                {o?.descr}
                                <SignupOrderOption option={o} index={i} userObj={orderObj}/>
                               
                                </Box>
                            </AccordionDetails>
                        </Accordion>
 
        )}})
                }
                </Box>
    
             </CustomTabPanel>

               <CustomTabPanel value={value} index={1} >
                {orderView && <Box //component='img' 
                    // sx={{width:'100%',borderRadius:'20px'}}
                    sx={{display: 'flex', flexDirection:'column', 
                    margin:'0.5rem auto', alignItems:'center',justifyContent:'center'}}
                    src='https://res.cloudinary.com/dzxzdsnha/image/upload/v1709397837/AIW-Charcuterie-Far-01_o6rzzn.jpg'>
                        <Box component='img' 
                    // sx={{width:'100%',borderRadius:'20px'}}
                        sx={{display: 'flex', flexDirection:'column', width:'70%',borderRadius:'10px',
                        margin:'0.5rem auto', alignItems:'center',justifyContent:'center'}}
                        src='https://res.cloudinary.com/dzxzdsnha/image/upload/v1709397837/AIW-Charcuterie-Far-01_o6rzzn.jpg'></Box>
                    {/* More info coming soon */}
                    { items && items?.map((o,i)=>{
                        if(o?.tags?.includes('charcuterie')){
                                return ( 
                                    <>
                                    {/* <Accordion  key={i} sx={{width:'70%',minWidth:'300px',margin:'auto',textAlign:'center'}}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        > */}

                                        {/* {o?.type} |  ( 16oz. )  X  1 */}
                                        {o?.type}
                                        {/* <Rating name="half-rating" defaultValue={getAvg(o?.rating?.ratings) } precision={0.5} /> */}
                                        {/* {tempOrder[i]?.size ? tempOrder[i]?.size:'___'}  X  { tempOrder[i]?.quantity? tempOrder[i]?.quantity:'___'} */}
                                        {/* </AccordionSummary>
                                        <AccordionDetails> */}
                                        {/* {o?.pic &&  <Box
                                                component="img"
                                                sx={{
                                                    height: 'auto',
                                                    width: 270,
                                                    // maxHeight: { xs: 233, md: 167 },
                                                    maxWidth: { xs: 300, md: 250 },
                                                    borderRadius: '15px 15px 0px 0px',
                                                    margin:'auto',
                                                    padding: '0.5rem'
                                                }}
                                                alt= {o.type}
                                                src= {o.pic}
                                                />} */}

                                            <Box sx={{ p: 2, border: '1px dashed grey', justifyContent:'center' }}>

                                            {o?.descr}
                                            <SignupOrderOption option={o} index={i} userObj={orderObj}/>
                                            </Box>
                                        {/* </AccordionDetails>
                                    </Accordion> */}
                                    </>
            
                    )}})
                    }
                    
                    </Box>}
               {!orderView && <Box  sx={{display: 'flex', flexDirection:'column', 
                    margin:'0.5rem auto', alignItems:'center',justifyContent:'center',gap:'0.5rem'}}>
                    { items && items?.map((o,i)=>{
                        if(o?.tags?.includes('hosting')){
                                return ( 
                                    <Accordion  key={i} sx={{width:'70%',minWidth:'300px',margin:'auto',textAlign:'center'}}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        >

                                        {/* {o?.type} |  ( 16oz. )  X  1 */}
                                        {o?.type}
                                        {/* <Rating name="half-rating" defaultValue={getAvg(o?.rating?.ratings) } precision={0.5} /> */}
                                        {/* {tempOrder[i]?.size ? tempOrder[i]?.size:'___'}  X  { tempOrder[i]?.quantity? tempOrder[i]?.quantity:'___'} */}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {o?.pic &&  <Box
                                                component="img"
                                                sx={{
                                                    height: 'auto',
                                                    width: 270,
                                                    // maxHeight: { xs: 233, md: 167 },
                                                    maxWidth: { xs: 300, md: 250 },
                                                    borderRadius: '15px 15px 0px 0px',
                                                    margin:'auto',
                                                    padding: '0.5rem'
                                                }}
                                                alt= {o.type}
                                                src= {o.pic}
                                                />}

                                            <Box sx={{ p: 2, border: '1px dashed grey', justifyContent:'center' }}>

                                            {o?.descr}
                                            <SignupOrderOption option={o} index={i} userObj={orderObj}/>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
            
                    )}})
                    }
                     
                </Box>}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <CustomerInfoForm shop={shop} 
                            // cart={cart}
                            // setSubView={setSubView} 
                            subView={'inquiry'}/>
                    </CustomTabPanel>
                    
              {value !==2 && <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, margin:'auto' }}
                        onClick={handleCartClick} 
                        // disabled={returnCartAmount(cart)<=0?true:false}
                        >
                        Checkout
                    </Button>}

            </Box>
            </Box>
            <Box sx={{margin:'1rem auto', marginBottom: '0'}}>

            <Copyright view='signupSheet'/>
            </Box>
        </Container>
        </ThemeProvider>
        </Container>
    </SignupProvider>
  );
}
export default AlixInWonderland 