import React , { useState, useContext, useEffect } from 'react'
import { Box, Paper, Rating, Stack, Typography } from '@mui/material'
import { StorefrontContext, }  from "../../context/StorefrontProvider";
import useWindowDimensions from "../../hooks/windowDimensions";


import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const ReviewSection = (props) => {
    const { aboutHeight, reviewsHeight } = props.conf
    const { item , view} = props
    const { shop, items} = useContext(StorefrontContext);
    const [ currentIndex, setCurrentIndex ] = useState(0);

    const [ reviews, setReviews ] = useState(null)
    const { height, width } = useWindowDimensions();


    let reviewCheck = 0

    const collectReviews = async () =>{
        let arr = []
        if ( view === 'shop'){
            
            items?.forEach((item,i)=>{
                item?.reviews?.forEach((r,i)=>{
                    arr.push(r)
                })
            })
            console.log('Reviews:', arr)
            await setReviews(arr)
            return arr
        }
        else if ( view === 'product'){
            item?.reviews?.forEach((r,i)=>{
                    arr.push(r)
                })

            console.log('Reviews:', arr)
            await setReviews(arr)
            return arr
        }
    }

    const handleSlideChange = (index) => {
        setCurrentIndex(index);
    };

    useEffect(()=>{
        if(!reviewCheck){
            collectReviews()
            reviewCheck = 1
        }

    },[items])
    useEffect(()=>{
     
    },[width])

  return (
 
      <Box sx={{
        positon:'relative',
        width: '100%', 
        maxWidth:  width >= 750 ? '800px':'350px',
        height: reviewsHeight,
        // border: '1px solid #000',
        // // borderRadius: '10px',
        // borderLeft: 'none',
        // borderRight: 'none',
        display:'flex', 
        flexDirection: width >= 750?'row-reverse':'column',
        // flexDirection:'column',
        gap:'0.5rem',
        margin: '1rem auto',
        padding:'0.5rem',
        alignItems:'center'
        }}>
       
        <h2>
            Reviews
        </h2>
       
       <Swiper
        //  className='swiper-container'
            // sx={{margin:'auto', maxWidth: width >= 750 ?'800px':'375px'}}
            cssMode={true}
            spaceBetween={200}
            slidesPerView={2}
            // slidesPerColumn={1}
            breakpoints={{
                    // when window width is >= 640px
                    0: {
                    width: 350,
                    // margin:'auto',
                    slidesPerView: 1,
                    spaceBetween:200
                    },
                   
                    640: {
                    width: 640,
                    slidesPerView: 1,
                    },
                    750: {
                    width: 750,
                    slidesPerView: 1,
                    },
                }}
            onSlideChange={handleSlideChange}
            speed={1000}
            // autoplay={true}
            loop={true}
            // modules={[Autoplay]}
            autoplay={{
                delay: 10000,
                disableOnInteraction: false,
                reverseDirection: true 
            }}
            navigation={true}
            // pagination={{ clickable: true }}
            onSwiper={(swiper) => console.log(swiper)} // Optional: access Swiper instance
        >
         {reviews && reviews?.map((r,i)=>{
            // console.log('collection tesst',collection)
            
            // if(collection?.items?.includes(`${r?._id.toString()}`) === true){
                // console.log('[collection items]:',r?.name)
                return (
                <SwiperSlide key={i} sx={{margin:'auto', maxWidth:  width >= 750 ? '70%':'150px', alignItems:'center',justifyContent:'center'}}>
                      <Paper sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '70%', 
                            maxWidth: '350px', 
                            height: 'fit-content',
                            minHeight: '200px',
                            maxHeight: reviewsHeight,
                            // border: '1px solid #000',
                            margin:'auto',
                            // borderRadius: '10px',
                            justifyContent:'center',
                            margin:'auto',
                            gap:'0.5rem',
                            padding: '0.75rem',
                            // overflowY:'scroll',
                            backgroundColor:'#FFF',
                            alignSelf:'center'
                        }}>
                            <Typography>
                            "{r?.review}"
                            </Typography>
                            <b style={{alignSelf:'end'}}>-{r?.name}</b>
                            <Rating style={{alignSelf:'end'}} name="half-rating" defaultValue={r?.rating } precision={0.5} />
                    </Paper>
                </SwiperSlide> ) 
            // }else{return}
           })}
    </Swiper>
    
      
    </Box>
  )
}

export default ReviewSection