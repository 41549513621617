import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";
import * as API from '../utils/APICalls'

import Modal from './Modal'
import EnvRoute from '../middleware/envRoute';
import { Container, Stack } from "@mui/material";

const ENDPOINT = EnvRoute('prod'); 

const QROptions = (props) => {
    // const view = props.view
    // const field = props.field
    // const index = props.index
    const { view, field, index, payload} = props
    const { appData, updateAppData,modalOpen, newPersona, setNewPersona, currentPersona, setCurrentPersona,
             assetQR, setAssetQR, currentAsset, setCurrentAsset, userData, setQRs,QRs,
            newAsset , setNewAsset,selectedQR , setSelectedQR} = useContext(AppContext);
    const [ showList, setShowList ] = useState(false)
    const [ optionQR, setOptionQR ] = useState()
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    // const [ selectedQR , setSelectedQR ] = useState(false)
    
    const fetchQRs = async () =>{
        let temp
        try{
            const data = await API.fetchQRs(userData?._id)
            temp = data
            console.log('[QRoptions] fetchQRs->',data)
        }catch(err){console.log(err)}
        if (temp){
            setQRs(temp)
            updateAppData({...appData, QRs})
        }else{return null}
    }
    const linkCheck = (l) =>{
        if(l.indexOf('http://') === -1 || l.indexOf('https://') === -1){
            return 'https://'+ l
        }else{ return l}
    }

    const handleSelection = async(q)=>{
            console.log(q)
            if(view === 'create-asset'){
                // setNewAsset({
                //     ...newAsset,
                //     qr_id:q?._id
                // })
                setCurrentAsset({
                    ...currentAsset,
                    qr_id:q?._id
                })
                setAssetQR(q)
                // updateAppData({...appData,newAsset,assetQR})
                updateAppData({...appData,currentAsset,assetQR})
            }
            else if(view === 'edit-asset'){
                    try{
                    setAssetQR({...q})
                    setCurrentAsset({...currentAsset, qr_id:q?._id})
                    updateAppData({...appData,assetQR,currentAsset})
                    const data = await API.updateAsset({currentAsset})
                    }catch(err){console.log(err)}
            }
            else if(view === 'create-persona'){
                // set selectedQR to a 
                setNewPersona({...newPersona, qr_id:q?._id})
                updateAppData({...appData,newPersona})
            }
            else if(view === 'edit-persona'){
                try{
                    const data = await API.updateQRCode({
                        ...q,
                        payload: field === 'persona'?`https://qaura.pro/persona/${currentPersona?._id}`: field === 'links'? linkCheck(currentPersona?.links?.data[index]?.data) : field?.toString().includes('link-')? linkCheck(currentPersona[field]?.data):currentPersona[field]?.data
                    })
                }catch(err){console.log(err)}
            }

            else if(view === 'hotLink'){
                console.log('[Hotlink] payload', payload)
                let temp = {
                    ...q,
                    payload:payload
                }
                try{
                    const data = await API.updateQRCode({
                        ...temp
                    })
                    console.log('[Hotlink] QRUpdate response', data)
                    if (data){
                        setOptionQR(q)
                        fetchQRs()
                    }
                }catch(err){console.log(err)}
            setShowList(false)
            // updateQRCodeData({...q})
            }
        }


    useEffect (()=>{
      
    },[selectedQR])

    useEffect (()=>{
        if(!userData){
            console.log('[QRoptions] -> no user logged in')
        }
        console.log('[QRoptions] VIEW:',view)
        fetchQRs()
    },[])

  return (

    <Container style={{alignItems:'center', justifyContent:'center',textAlign:'center', alignSelf:'center', minWidth:'150px',maxHeight:'70vh', 
        margin: '0.5rem auto',padding:'0.25rem', borderRadius:'5px',backgroundColor:'rgba(255,255,255,0.6)',backdropFilter: 'blur(3px)',overflow: 'hidden',
        overflowY:'scroll',
        outline:'2px solid #000'}}>
            <div>

            select a QR
            </div>

        <span style={{display: 'flex', flexDirection:'row', alignItems:'center', textAlign:'center',cursor: 'pointer',gap:'0.125rem',whiteSpace:'nowrap'}}
            onClick={()=>{setShowList(!showList)}}
            >
            <u style={{display:'flex', flexDirection:'row', width:'100%',textAlign:'center', 
                justifyContent:'center', gap:'0.25rem',
                alignItems:'center',marginBottom:'0.5rem',
                zIndex:'9999', padding:'0.5rem', height:'1rem'
                     }}>
                { (view !=='create-persona' || view !=='hotLink') && (!showList && assetQR?.name) ? assetQR?.name :''}
                {/* {view ==='hotLink' && (!showList && optionQR?.name)?optionQR?.name:'select a QR'} */}
            <img src={`/assets/arrow-${showList?'up':'down'}.svg`} style={{height:'0.45rem', alignSelf: 'center'}} alt="dashboard"/>
            </u>
        </span>
        
        {/* <Modal> */}
            {showList && 
                <div style={{ display: 'flex',
                flexDirection:'column',
                justifyContent:'center',
                margin:'auto',
                alignItems:'center',listStyle:'none', gap:'0.25rem' }}>
                {QRs && QRs?.map((q,i)=>{
                    return(

                    <Stack className="qr-option-li" value={q.name} key={i}
                    style={{display:'flex', marginLeft:'0', minWidth:'100%',
                    height:'4rem',
                     gap:'0.125rem'
                }}
                    >
                       <div style={{border:'0.75px solid #868686',borderRadius:'4px',zIndex:'9999',
                       padding:'0.25rem 0.5rem', height:'fit-content',width:'135px',textOverflow: 'ellipsis', margin:"auto",
                       whiteSpace: 'nowrap', overflow: 'hidden'}}
                       onClick={()=>handleSelection(q)}
                       >
                        <b style={{fontSize:'0.85rem'}}>
                        {q.name}
                        </b>
                        <hr/>
                        {/* linked to:  */}
                        <img width='30px' src='/assets/linkToQR.svg' alt='linked to icon' />
                       <div style={{fontSize:'0.75rem',textOverflow: 'ellipsis', margin:"auto",
                       whiteSpace: 'nowrap', overflow: 'hidden'}}>{q.payload}</div>
                        <br />
                        
                        </div> 
                    </Stack>
                    )
                })}
            </div>}
        {/* </Modal> */}
    </Container>
  )
}

export default QROptions