import React,{useContext,useEffect,useState} from 'react'
import { Box, Button, Container, Divider, FormControl, Switch, Stack,
    Accordion, AccordionSummary, AccordionDetails, Modal, Paper, Select, TextField, Typography } from '@mui/material'
import { AppContext } from "../context/AppProvider";
import CloseButton from './CloseButton';
import CreateGeoCondition from './CreateGeoCondition';
import { IconButton } from '@chakra-ui/react';
import DeleteForever from '@mui/icons-material/DeleteForever';
import * as API from '../utils/APICalls'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';




const GeoConditions = (props) => {
    const { view, qr } = props
    const { appData,userData, setUserData, updateAppData,setSelectedQR, selectedQR, setActionError, actionError} = useContext(AppContext);
    const [ showModal, setShowModal ] = useState(false)
    const [ geoOn, setGeoOn ] = useState(false)

    const handleModalOpen = (e)=>{
        e.preventDefault()
        setShowModal(true)
    }
    const handleOnToggle = async (index,el, state)=>{
        try{
            let conds = qr?.geoConditions 
            let temp = {
                ...el,
                on: !state
            }
            conds[index] = temp
         console.log('[Toggle] Change:', conds)
            const data = await API.updateQRCode({
            ...qr,
            geoConditions: conds 
            
        })
        if(data){
            setSelectedQR(data)
        }
        }catch(err){console.log(err)}
    }
    const handleGeoDelete  = async  (e,index) =>{ 
        e.preventDefault()
        let conds = qr?.geoConditions
        conds.splice(index,1)
         const data = await API.updateQRCode({...qr,geoConditions:conds})
          if(data){
                console.log('[handleGeoSelect] dataData:',data)
                setSelectedQR(data)
          }

     }
useEffect(()=>{

},[qr,selectedQR])
  return (
    <div style={{position:'relative'}}>
    <div style={{position:'relative',  width:'fit-content',margin:'auto'}}>
        <Button variant='outlined'
            onClick={handleModalOpen}
        >
            Geo-Conditions
        </Button>
         {qr?.geoConditions && 
                <div  className={qr?.geoConditions?.find(g => g?.on === true) ?'blink':'' } 
                    style={{ 
                        minWidth:'1rem', minHeight:'1rem', 
                        width:'5%',
                        height:'5%',
                        textAlign:'center',
                        justifyContent:'center',
                        position:'absolute',
                        top:'-0.5rem',
                        left:'-0.5rem',
                        padding:'0.125rem',
                        backgroundColor:'#fff',
                        borderRadius:'50%',
                        border:`3px solid ${qr?.geoConditions?.find(g => g?.on === true) ?'#00FF00':'#ffe485'}`,color:'#000',
                        boxShadow:'0px 0px 2px 1px rgba(0,0,0,0.5)'
                     }}>
          <Typography className={qr?.geoConditions?.find(g => g?.on === true) ?'blink2':'' } 
                sx={{ fontSize:'0.75rem',fontWeight:'bold',}} >{ qr?.geoConditions?.length}</Typography> 

        </div>
        } 
        <Modal open={showModal}>
            <Container sx={{display:'flex', flexDirection:'column',minHeight:'40vh',maxHeight:'80vh',width:'90%',
            position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)',
            justifyContent:'center',alignItems:'center',overflow:'hidden', overflowY:'scroll',
        }} >

             <CloseButton func={setShowModal} val={!showModal}/>
                <Paper sx={{display:'flex', flexDirection:'column',height:'100%',boxShadow:'0px -3px 10px 5px rgba( 0, 0, 0, 0.6 )',
                    alignItems:'center', padding:'0.75rem',textAlign:'center',gap:'0.5rem',
                    borderRadius:'5px', overflowY:'scroll', fontFamily:'Gilroy,sans-serif'}} >
                   <Typography style={{fontWeight:'bold'}}>Geo-Conditions</Typography>
                    <Box sx={{display:'flex', flexDirection:'column',gap:'0.5rem',
                        alignItems:'center',justifyContent:'center'}}>
                       
                       {qr?.geoConditions ? qr?.geoConditions?.map((g,i)=>{
                            if(g?.pin?.range){
                            
                                return (
                                    <Paper style={{position:'relative',display:'flex', border:'2px solid #000', borderBottom:'none',
                                     width:'70%',minWidth:'300px',
                                    flexDirection:'column',padding:'0.75rem',boxShadow:'0px -5px 5px 2px rgba( 0, 0, 0, 0.3 )' }} key={i}>
                                        <Accordion  sx={{display:'flex',flexDirection:'column',minWidth:'300px',margin:'auto',textAlign:'center',alignItems:'center'}}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            sx={{textAlign:'center',alignItems:'center', display: 'flex', width:'100%'}}
                                            >
                                                <Stack sx={{flexDirection:'row', width:'100%',justifyContent:'space-between'}}>
                                                <Box sx={{position:'absolute', top: '0.85rem', left:'0.85rem'}}>
                                                    <div style={{content:'',width:'1rem',height:'1rem', borderRadius:'50%',backgroundColor: g?.on? '#00FF00':'#ffe485', boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.3)'}}></div>
                                                </Box>
                                                    <b style={{flex:1, textAlign:'center'}}>{g?.name}</b>
                                                    {/* <Box sx={{flex:1}}>
                                                
                                                         </Box> */}
                                             </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                      <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                                        <Typography>off</Typography>
                                        <Switch defaultValue={g?.on} inputProps={{ 'aria-label': 'ant design' }}
                                            onChange={(e)=>{
                                               handleOnToggle(i,g,g?.on)
                                                }} checked={g?.on} />
                                            
                                        <Typography>on</Typography>
                                    </Stack>
                                    <IconButton className='accent-hover'
                                    sx={{position:'absolute',left:'-0.75rem', top:'-0.5rem',borderColor:'red',color:'red', background:'#FFF'}}>
                                        <DeleteForever onClick={(e)=>{
                                            handleGeoDelete(e,i)
                                        }}/>
                                    </IconButton>
                                    <Box  sx={{flexDirection:'column'}}>

                                       <span><b>{g?.pin?.range}</b> miles from <b>{g?.pin?.name}</b> </span> 
                                       <Typography>( {g?.pin?.lat}/{g?.pin?.lon} )</Typography>
                                      
                                      <b>{g?.payload_type} payload interaction:</b> 
                                        <p> {g?.bypassPayload}</p><br/>
                                    </Box>
                                        <Divider />
                                    </AccordionDetails>
                                    </Accordion>
                                    </Paper>
                                )
                            }
                            if(g?.region){

                                return (
                                      
                               
                         
                                    <Paper style={{position:'relative',display:'flex', border:'2px solid #000', borderBottom:'none',
                                     width:'70%',minWidth:'300px',
                                    flexDirection:'column',padding:'0.75rem',boxShadow:'0px -5px 5px 2px rgba( 0, 0, 0, 0.3 )' }} key={i}>
                                        <Accordion  sx={{display:'flex',flexDirection:'column',minWidth:'300px', width:'100%',margin:'auto',textAlign:'center',alignItems:'center'}}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                      sx={{textAlign:'center',alignItems:'center', display: 'flex', width:'100%'}}
                                    >
                                        <Stack sx={{flexDirection:'row', width:'100%',justifyContent:'space-between'}}>
                                     <Box sx={{position:'absolute', top: '0.85rem', left:'0.85rem'}}>
                                        <div style={{content:'',width:'1rem',height:'1rem', borderRadius:'50%',backgroundColor: g?.on? '#00FF00':'#ffe485', boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.3)'}}></div>

                                    </Box>
                                        <b style={{flex:1, textAlign:'center'}}>{g?.name}</b>
                                         {/* <Box sx={{flex:1}}>
                                       
                                    </Box> */}
                                    </Stack>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                         <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                                        <Typography>off</Typography>
                                        <Switch defaultValue={g?.on} inputProps={{ 'aria-label': 'ant design' }}
                                            onChange={(e)=>{
                                                handleOnToggle(i,g,g?.on)
                                                }} checked={g?.on} />
                                            
                                        <Typography>on</Typography>
                                    </Stack>
                                       <IconButton className='button-accent-hover'
                                       sx={{position:'absolute',left:'-0.75rem', top:'-0.5rem',borderColor:'red',color:'red', background:'#FFF',zIndex:99999}}>
                                        <DeleteForever onClick={(e)=>{
                                            handleGeoDelete(e,i)
                                        }}/>
                                    </IconButton>

                                      <p>
                                        for
                                        </p> 
                                       
                                          <span><b>{g?.region?.city ? g?.region?.city:''}</b>, {g?.region?.state?g?.region?.state_code:''}</span> 
                                          <Typography>
                                            ( {g?.region?.county?g?.region?.county:''} | {g?.region?.country?g?.region?.country_code?.toUpperCase():''} )
                                            </Typography> 
                                              <b> <u>{g.payload_type} payload interaction</u>:</b> <br/>
                                        <Divider />
                                        <span> {g?.bypassPayload}</span>
                                     </AccordionDetails>
                                       </Accordion>
                                    </Paper>
                                )
                            }
                       }):'no geo-conditions yet'}
                        </Box>
                        <Divider />
                        <CreateGeoCondition qr={qr} />
                        </Paper>
                    </Container>
                </Modal>
                </div>
                </div>

      
  )
}

export default GeoConditions