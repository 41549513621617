import { Card, Stack, Paper, Container, Typography, Box, Divider, Switch, Button, List, ListItem, Input, IconButton, InputAdornment } from '@mui/material';
import React,{useContext, useEffect, useState} from 'react'
import { AppContext } from "../context/AppProvider";
import CloseButton from './CloseButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BackspaceIcon from '@mui/icons-material/Backspace';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'
import useWindowDimensions from "../hooks/windowDimensions";
import ItemFieldComponent from './ItemFieldComponent';


const ProductInfoComponent = (props) => {
     const { appData,userData, setUserData, updateAppData, modalOpen,
        currentShop, setCurrentShop,defaultProduct,modalSubView,
        setModalSubView,
         currentProduct, setCurrentProduct } = useContext(AppContext);
         const initialCurrentProduct ={}= currentProduct
         const { height, width } = useWindowDimensions();

         const keyList = ['name','type','subtitle','description',
            'printBase_url','art_url','sizes', 'alt_art_url','cost_of_creation',
            'price','details','tags','images','affiliates','shares']
        const imageList = ['images','printBase_url','art_url','alt_art_url']
        const numberList = ['price','cost_of_creation']

        const [ hasChanged,setHasChanged ] = useState(false)
        const [ tempProduct,setTempProduct ] = useState({})

          if(props.view === 'add-product' ){
            setCurrentProduct(defaultProduct)
        }
        const  toggleAvailablity =  async (item) =>{
            try{

                var val = item.isActive
                const res = await API.updateItem({...item,isActive:!val})
                if(res)
                    {
                    setCurrentProduct(res)
                    updateAppData({
                        ...currentProduct,
                    })
                    fetchShop()
                    }
                }catch(err){console.log(err)}
        }

    const pushToArray = (key,arr) =>{
        if(tempProduct[key]){

            var temp = arr
    
            temp.push(tempProduct[key])
            // temp.push(e.target.value)
            setCurrentProduct({
                ...currentProduct,
                [key]: temp
            })
            updateAppData({...appData,currentProduct})
            setTempProduct({
            })
            setHasChanged(true)
        }

    }
        
    const handleProductSave = async () =>{
        try{
            
            const {res} = await API.updateItem(currentProduct)
            if(res){
                setCurrentProduct(res)
                setModalSubView('products')
                updateAppData({
                    ...appData,
                    currentProduct,
                    modalView:'storefront',
                    modalSubView
                })
                fetchShop()
            }
            setHasChanged(false)
        }catch(err){console.log(err)}
    }

    const fetchShop = async () =>{
        try{ 
          const data = await API.fetchShop(currentShop)
            if(data){
              await setCurrentShop(data)
              await updateAppData({
                ...appData,
                currentShop
              })
              console.log('Storefronts:', data)
              
            }
            //   storeCheck = 1
          }catch(err){console.log(err)}
    }

     useEffect(()=>{

    },[currentProduct,appData,currentShop])
     useEffect(()=>{
      
    },[currentProduct,tempProduct])
  
     return (
        <Container sx={{position:'relative',
            display:'flex',flexDirection:'column',
            maxHeight:'90vh',
            minHeight:'50vh',
            height:'fit-content',
            justifyContent:'center',
            padding:'0.5rem',
            width:'100%',
            maxWidth:'800px',
            textAlign:'left'
            }}>
     { props.view !== 'add-product'  && <div style={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
            <CloseButton func={updateAppData} 
                val={{
                    modalOpen:!modalOpen,
                    modalView:''
                }} />
        </div>}
      { props.view !== 'add-product' && <ArrowBackIcon style={{fontSize:'2rem',position:'absolute',
            left:'1rem', cursor: 'pointer', opacity: '0.5',zIndex:9999,
            top: '0rem', marginTop:'0.5rem'}}
            onClick={()=>{
              
              updateAppData({
                ...appData,
                modalView:'storefront',
                modalSubView: 'products'
              })
            }}
            
            />}

             
        <Typography variant='p' sx={{position:'absolute', left:'50%', top:'1rem',
        fontSize:'1.25rem',width:'100%', textAlign:'center',
        transform: 'translate(-50%,0)',fontFamily:'Gilroy, sans-serif'}}>
           {currentProduct?.name && <b>
                {currentProduct?.name}
            </b> }
        </Typography>
        <Typography variant='p' sx={{position:'absolute', left:'50%', top:'3rem',
        fontSize:'0.85rem',
        transform: 'translate(-50%,0)',fontFamily:'Gilroy, sans-serif'}}>
           
                 ( Click the field you wish to edit )
            
        </Typography>
           
            <Paper sx={{position:'relative', minHeight:'20vh',
            marginTop:'4rem',
            overflowX:'hidden', overflowY:'scroll',padding:'0.5rem',
                height:'100%', maxHeight:'60vh', boxShadow:'0px 0px 5px 1px rgba( 0, 0, 0 , 0.3 )', border:'1px solid #757575'
                }}>
                <Card sx={{position:'absolute',left:'0.75rem',transform:'translate(0%,0)',top:'0.75rem',padding:'0.5rem',textAlign:'center',
                    margin:'auto',width:'fit-content',borderRadius:'10px', border:'1px solid #757575'}}>

                    <b>availablility</b>
                    <Divider/>  
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                        <Typography>off</Typography>
                        <Switch defaultValue={currentProduct?.isActive} inputProps={{ 'aria-label': 'ant design' }}
                            onChange={(e)=>{
                            toggleAvailablity(currentProduct)
                                }} checked={currentProduct?.isActive} />
                            
                        <Typography>on</Typography>
                    </Stack>
                    </Card>
               <Container sx={{marginTop:width < 425?'10px':'150px', overflowX:'hidden', overflowY:'scroll'}}>
               <Box sx={{marginTop:width < 425?'120px':'150px'}}>
              <Card sx={{display:'flex',position: width < 425 ?'relative':'absolute', alignItems:'center', width:'60%',
                    maxWidth:'400px',right:width < 425?'0':'0.75rem',top:width < 425?'0':'0.75rem',
                    backgroundColor:'#FFF',alignContent:'center',padding:'0.5rem',
                    border:'1px solid #757575', borderRadius:'8px 4px 8px 8px',margin:'auto',
                    }}>
                <img src ={currentProduct?.images[0]} width='auto' height='150px' style={{margin:'auto'}}alt='main product'/>
           
              </Card> 
              <Box sx={{position:'relative',marginTop: width < 425 ? '1rem':'160px'}}>

                    {Object.keys(currentProduct)?.map((key,i)=>{
                    //   if(typeof currentProduct[key] !== 'object'){
                      if(keyList.includes(key) ){
                      if( !Array.isArray(currentProduct[key]) && typeof currentProduct[key] !== 'object' ){
                        
                          return(
                               <span key={i}>
                                   <b>
                                       {helpers.splitKey(key,'_',' ')}: 
                                   </b>
                                   <Typography>
                                      {/* {imageList.includes(key) && <img src ={currentProduct[key]} width='50px' height='auto'/>} */}
                               <ItemFieldComponent field={key} value={currentProduct[key]} 
                                        hasChanged={hasChanged} setHasChanged={setHasChanged} 
                                       index='na'/>
                                       {/* {!imageList.includes(key) && <ItemFieldComponent field={key} value={currentProduct[key]} 
                                        hasChanged={hasChanged} setHasChanged={setHasChanged} 
                                       index='na'/>} */}
                                   </Typography>
                               </span>
                           )
                      } else if( Array.isArray(currentProduct[key]) ){
                          
                          return(
                              <Box>
                                      <b>
                                          {helpers.splitKey(key,'_',' ')}: 
                                      </b>
                             
                                      <List sx={{display:'flex',FlexDirection:'row',gap:'0.5rem', flex:1, width:'100%',
                                                flexWrap:'wrap',
                                                // border:'1px solid red',
                                                justifyContent:'space-evenly',overflow:'hidden',overflowY:'scroll'}}>
                                               { currentProduct[key]?.length && currentProduct[key]?.map((el,j)=>{
                                                   return(<ListItem key={j}
                                                     sx={{ width:'30%',minWidth: '150px', cursor: key === 'images' ?'pointer':"" }}
                                                       onClick={()=>{
                                                            
                                                           if( key ==='images'){
                                                               console.log('[ Image Click ]',el )
                                                           }
                                                        }}>
                                            <ItemFieldComponent field={key} value={el} index={j} 
                                             hasChanged={hasChanged} setHasChanged={setHasChanged} 
                                            />
                                                       {/* {imageList.includes(key) && 
                                                           <img src ={el} width='auto' height='80px'/>
   
                                                       }
                                                       {!imageList.includes(key) && <ItemFieldComponent field={key} value={el} index={j} />} */}
                                                       </ListItem>
                                                       )
                                               })}
                                           <ListItem>
                                             <Input 
                                    // multiline={multiList.includes(key)}
                                    // multiline={key === 'description' || key === 'subtitle'?true:false }
                                    placeholder='add...'
                                    type='text'//inputTypeFilter(key)} onClick={()=>{
                                        // toggleEdit(!edit)
                                    sx={{minWidth: '150px'}}
                                    // }}
                                    // value={tempProduct[key][0]?.length? tempProduct[key][0]:''}
                                    onChange={(e)=>{
                                        // handleChange(e)
                                        setTempProduct({
                                            ...tempProduct,
                                            [key]:e.target.value
                                        })
                                        
                                        setHasChanged(true)
                                    }}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="done"
                                        onClick={(e)=>{
                                            pushToArray(key,currentProduct[key])
                                            // e.target.value=''
                                            setTempProduct({})

                                        }}
                                        onMouseDown={(e)=>{
                                            pushToArray(key,currentProduct[key])
                                            // e.target.value=''
                                             setTempProduct({})

                                        }}
                                        edge="end"
                                        >
                                        <CheckCircleIcon /> 
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    >
                                </Input>
                                           </ListItem>
                                       </List>
                                <Box onClick={()=>{
                                    // currentProduct[key]

                               }}>
                                    {/* [click to add] */}
                                    
                                    
                                </Box>
                                
                                  </Box>
                              )
                      }
                      }

                    })}
              </Box>
               </Box>
               </Container>
            </Paper>
               <Button 
               disabled={!hasChanged}
               variant="outlined" sx={{margin:'0.75rem auto'}}
               onClick={handleProductSave}
               >
                save product
               </Button>
        </Container>
  )
}

export default ProductInfoComponent