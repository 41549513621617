import React, { useState, createContext } from "react";
const Default_QR = require('../options_02.json')
// Create a new context
export const SignupContext = createContext();


const t_ord =[
        {    
        item_type:'Roasted Garlic Hummus',
        size:'',
        quantity:0
    },
    {
        item_type:'Apple Chicken Salad',
        size:'',
        quantity:0
    },
    {
        item_type:'Orzo Pasta Salad',
        size:'',
        quantity:0
    },
    {
        item_type:'Roasted Butternut Squash Salad',
        size:'',
        quantity:0
    },
        
    ]
// Create a context provider component
export const SignupProvider = ({ children }) => {

    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ department, setDepartment ] = useState('')
    const [ notes, setNotes ] = useState('')
    const [ inquiryText, setInquiryText ] = useState('')
    const [ isFollower, setIsFollower ] = useState(true)
    const [ signupData, setSignupData] = useState({})
    const [ cart, setCart ] = useState([])
  
    // const [ tempOrder, setTempOrder ] = useState([...t_ord])
    const [ tempOrder, setTempOrder ] = useState([])

    const  updateCart = (newData) => {
      setCart((prevState) => ([
        // ...prevState,
        ...newData
      ]));
  
    };
    const  updateTempOrder = (newData) => {
      setTempOrder((prevState) => ([
      //   ...prevState,
        ...newData
      ]));
  
    };

  const  updateSignupData = (newData) => {

    setSignupData((prevState) => ({
    //   ...prevState,
    //   ...newData,
        order: [...tempOrder],
        firstName,
        lastName,
        email,
        phone,
        department,
        notes,
        inquiryText,
        isFollower,
        cart

    }));
 
  };


  return (
    <SignupContext.Provider value={{ 
        signupData, 
        setSignupData,
        updateSignupData, 
        tempOrder,
        setTempOrder,
        updateTempOrder,
        firstName, 
        setFirstName,
        lastName, 
        setLastName,
        email, 
        setEmail,
        phone, 
        setPhone,
        department, 
        setDepartment,
        notes, 
        setNotes, 
        inquiryText, 
        setInquiryText,
        isFollower, 
        setIsFollower,
        cart,
        setCart,
        updateCart
        }}>
            
      {children}
    </SignupContext.Provider>
  );
};
