import React,{ useState, useEffect, useContext } from 'react'

// import { VStack, Input, Button,Text } from '@chakra-ui/react'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { Container, TextField, Button ,Input, InputAdornment, IconButton  } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { InputGroup, InputRightElement } from "@chakra-ui/input";
import { useToast } from "@chakra-ui/toast";
import axios from 'axios';
// import {useHistory} from 'react-router-dom'
import {AppState} from '../../context/AppProvider';

import EnvRoute from '../../middleware/envRoute';
import colors from '../../defaults/ThemeDefaults'

import LogoCopy from '../LogoCopy';

import * as API from '../../utils/APICalls'

const ENV_ENDPOINT = EnvRoute('prod'); 


const Signup  = (props) => {
  const room_id = props.room_id
  const { view, qr_id } = props
  const [ firstName , setFirstName ] = useState()
  const [ lastName , setLastName ] = useState()
  const [ preferredName , setPreferredName ] = useState()
  const [ email , setEmail ] = useState()
  const [ phone, setPhone ] = useState()
  const [ password , setPassword ] = useState()
  const [ confirmPassword , setConfirmPassword ] = useState()
  const [ pic , setPic ] = useState()
  const [ isOnline , setIsOnline ] = useState(true)
  const [ TC_id, set_TC_id ] = useState()


  const [ picLoading , setPicLoading ] = useState(false)
  const [ show , setShow ] = useState(false)
  // const [ user , setUser ] = useState(false)
  const [ actionError, setActionError ] = useState()
  const [ loading, setLoading ] = useState()
  const linkage = true

  const { userData, setUserData,appData, updateAppData } = AppState()
 
//   const history = useHistory()

  const handleClicK = () => {setShow(!show)}

  const toast = useToast();

    const handleError = (err) =>
      setActionError(err)
      // toast.error(err, {
        //   position: "bottom-left",
        // });
    const handleSuccess = (msg) =>
        setActionError(msg)
  
    // toast.success(msg, {
    //   position: "bottom-right",
    // });

//  =================
//  + SubmitHandler

//  =================
  const submitHandler = async (e) => {
    e.preventDefault()
    setPicLoading(true);
    setLoading(true)
    if (!firstName || !lastName || !email || !password || !confirmPassword || !phone) {
      setActionError('please fill all the feilds')
      setPicLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      setActionError('passwords do not match')
      setPicLoading(false);
      return;
    }
    
    try{
      // const  data  = await API.createUser(
      const  data = await API.signup(
        { firstName,
          lastName,
          preferredName:preferredName? preferredName : firstName,
          email,
          phone,
          password,
          pic:pic,
          isOnline:true,
          linkage:true,
          appTheme:{
            //  BGcolorMain:colors['aura']?.bg,
            //   TXcolorMain:colors['aura']?.tx,
            //   TXcolorAccent:colors['aura']?.txAccent
          }
        });
        const { success, message ,user} = data;
      if (success) {
        await setUserData(user)
        await updateAppData({
          ...appData,
          userData
        })
        handleSuccess(message);

        console.log(message);
        setTimeout(() => {
          // navigate("/");
          if( view === 'activate qr'){

            window.location.assign(`/assets/${qr_id}`)
          }else{

            window.location.assign(`/dashboard/${user?._id}`)
          }
        }, 1000);
      } else {
        handleError(message);
        console.log(message);
      }

        // if( data === undefined ){
        //   setActionError('user already registered')
        // }else{

        //   console.log('[Registration] data: ', data);
        //   setIsOnline(true)
        //   setActionError('registration successful')
        //   localStorage.setItem("QauRaUser", JSON.stringify(data));
        //   setUserData({...data})
        //   console.log('signup data: ', data)
        //   console.log('signup userData: ', userData)
        //   setPicLoading(false);
      
        //   window.location.assign(`/dashboard/${userData?._id}`)
        // }
    } catch (err) {
        console.log('[Registration Error]: ',err)
          setActionError('a registration error occurred')
      setPicLoading(false);
    }
  };

//  =================
//  + Post Details

//  =================
  const postDetails = (pics) => {
    setPicLoading(true);
    if (pics === undefined) {
    
      return;
    } 
  
  if (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "image/gif") {
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "TinCan");
      data.append("cloud_name", "dzxzdsnha");
      fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          setPic(data?.url?.toString());
          console.log(data?.url?.toString());
          setPicLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPicLoading(false);
          setActionError('Error loading picture')
        });
    } else {
          setActionError('Please Select an Image!')

    
      setPicLoading(false);
      setLoading(false);
      return;
    }
  }
  
  return (
     <div className='signup-wrap' style ={{color: '#000',display: 'flex', flexDirection: 'column', height:'100%',minHeight:'100vh'}}>
        <div style={{display:'flex', flexDirection:'column', marginBottom:'0.75rem'}}>
           <LogoCopy />
         </div>
           <hr  className='color-grad-blue-border' style={{boxShadow:'0px -4px 10px 5px rgba(250,250,250,0.2)'}}/>
        <br/>
        {/* <h2 className="mt-10 text-center text-2xl leading-9 tracking-tight text-gray-900" 
        style={{margin: '1rem 0', color:'#000', fontFamily:'Gilroy,sans-serif'}}>
          Sign Up</h2> */}
        <Container sx={{display:'flex', flexDirection:'column', gap:'0.5rem'}} >

      <div id ="first-name"style={{display:'flex', flexDirection: 'column', margin: 'auto', gap:'0.5rem'}} >
        {/* <label>first name</label> */}
          <Input 
            placeholder='first name'
            type="text"
            label="first name"
            autoComplete="first name"
            value={firstName}
            onChange={(e)=>setFirstName(e.target.value)}
            required
            />
      </div>

      <div id ="last-name"  style={{display:'flex', flexDirection: 'column', margin: 'auto'}}>
        {/* <label>last name</label> */}
          <Input 
            value={lastName}
            label="last name"
            placeholder='last name'
            autoComplete="last name"
            type="text"
            onChange={(e)=>setLastName(e.target.value)}
            required
            />
      </div>

      <div id ="preferred-name" style={{display:'flex', flexDirection: 'column', margin: 'auto'}} >
        {/* <label>preferred name</label> */}
          <Input 
            placeholder='preferred name'
            label="preferred name"
            type="text"
            value={preferredName}
            onChange={(e)=>setPreferredName(e.target.value)}
            required
            />
      </div>

      <div id ="email" style={{display:'flex', flexDirection: 'column', margin: 'auto'}} >
        {/* <label>email</label> */}
          <Input 
           label='email'
            type="email"
            placeholder='email'
            autoComplete="email"
            value={email}
            onChange={(e)=>setEmail(e.target.value.toLowerCase())}
            required
            />
      </div>

      <div id ="phone"  style={{display:'flex', flexDirection: 'column', margin: 'auto'}} >
        {/* <label>phone</label> */}
          <Input 
            label="phone"
            type="tel"
            placeholder='phone'
             autoComplete="phone"
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            required
            />
      </div>

      <div id ="password" style={{display:'flex', flexDirection: 'column', margin: 'auto'}}  >
        {/* <span style={{display: 'flex', margin: 'auto', width:'100%'}} >
          <label>
            password  
            <button className='button-green'style={{color:'#000000',  margin:'0 .5rem', width:'fit-content'}} onClick={handleClicK}>
              {show ? "hide" : "show"}
            </button>
          </label>
        </span> */}
            {/* <Button variant="contained" style={{ margin:'0.5rem auto'}} onClick={handleClicK}>
              {show ? "hide" : "show"}
            </Button> */}
        
        <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Input 
              label="password"
              type={show ?'text':'password'}
              placeholder='password'
               autoComplete="password"
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
              // border={'1px solid #868686'}
              // color={'#868686'}
              required
               endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClicK}
                  onMouseDown={handleClicK}
                  edge="end"
                >
                  {show? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            />
        <div >
            
        </div>
        </span>
      </div>

      <div id ="confirm-password"  style={{display:'flex', flexDirection: 'column', margin: 'auto' }} >
        {/* <span style={{display: 'flex', margin: 'auto', width:'100%',justifyContent:'center'}} >
          <label>
            confirm password
             <button className='button-green'style={{color:'#000000',  margin:'0 .5rem',width:'fit-content'}} onClick={handleClicK}>
              {show ? "hide" : "show"}
            </button> 
          </label>
        </span>
         */}
        <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

            <Input
              label="confirm password"
              type={show ?'text':'password'}
              placeholder='re-enter password'
             
              value={confirmPassword}
              onChange={(e)=>setConfirmPassword(e.target.value)}
              required
              endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClicK}
                  onMouseDown={handleClicK}
                  edge="end"
                >
                  {show? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
              />
        <div>
        </div>
        </span>
      </div>

      <div id ="pic" style={{display:'flex', flexDirection: 'column', 
      border:'2px solid #000', borderRadius:'5px',gap:'0.5rem',
          margin: 'auto',padding:'0.75rem'}} required>
        <label>upload pic</label>
        <Input
        type="file"
        p={1.5}
        accept ="image/*"
        // value={pic}
        onChange={(e) => postDetails(e.target.files[0])} />
     
      </div>
      <h3 style={{color: userData?.token ?"limegreen":"red", margin: 'auto'}}>{actionError?actionError:''}</h3>
       <Button
       variant="contained"
        className='blink-2'
        style={{ marginTop: '1rem', width:"50%",margin: '0.5rem auto',
         //,boxShadow:'0px 0px 5px 4px rgba(0,0,0,0.3)'
      }}
        onClick={(e)=>{submitHandler(e)}}
        // border={'1px solid #868686'}
        // isLoading={picLoading}
      >
        {loading?'Signing':'Sign'} Up
      </Button>
        <div style={{display:'flex', margin: 'auto', alignItems: 'center', color:'#868686', margin: '0.5rem auto'}}>
            © 2023 RexEntropyLLC
        </div> 
        </Container>

    </div>

  )

}

export default Signup