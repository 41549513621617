import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
// import ReCAPTCHA from "react-google-recaptcha";


import { useEffect, useRef, useState, useContext } from "react";
import { SignupContext, SignupProvider }  from "../context/SignupProvider";
import { AppContext } from "../context/AppProvider";


import * as API from '../utils/APICalls'
import * as vars from '../utils/vars'

import axios from 'axios'

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));


export default function ConfirmSignupModal(props) {
    const order = props.order
    const owner= props.owner
    const owner_email = props.owner_email
    const operating_name = props.operating_name
    const items = vars.items
    const sitekey = process.env?.REACT_APP_RECAPTCHA_SITE_KEY.toString()
    const recaptcha = useRef();

    const email = props.email
    let tempArr
    const { signupData, updateSignupData, tempOrder, updateTempOrder ,cart} = useContext(SignupContext);
   
    const [open, setOpen ] = React.useState(props?.open);
    const { appData,userData, updateAppData,modalOpen,setModalOpen ,currentPersona, setCurrentPersona} = useContext(AppContext);
    let actualOrder = []
    const [ orderTotal, setOrderTotal] = useState()
    const [ isSubmitting , setIsSubmitting ] = useState(false)
    const [ actionError, setActionError ] = useState('')


    let total 
    const [ geoData, setGeoData ]  = useState()

    const headerList =['firstName','lastName','email','phone','department']
 
    const getGeoData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log('user geo res.data: ', res?.data)
        setGeoData({...res?.data})
        // updateAppData({...appData,geoData})
        console.log('user geodata: ', geoData)
        // if(res?.data){
        //     collectScanData(res?.data)

        // }
        return res.data
    }
    const handleConfirm = async () => {
       setIsSubmitting(true)
      if (actualOrder?.length > 0){
        let token
      // grecaptcha.enterprise.ready(async () => {
      //       token = await grecaptcha.enterprise.execute('6LcpemYpAAAAAIGcFJ4JfeSgvta2sxUMtqjjIysK', {action: 'LOGIN'});
      //     });
      //   if(token){
        // grecaptcha.ready(function() {
        //   grecaptcha.execute('reCAPTCHA_site_key', {action: 'submit'}).then(async function(token) {
              // Add your logic to submit to your backend server here.
              try{
                  let data = await API.saveSignup({
                      ...signupData,
                      ...order,
                      order:actualOrder,
                      order_total:orderTotal,
                      owner,owner_email,operating_name
                    })

                  if(signupData?.isFollower){
                      API.saveFollow({
                          owner: owner,
                          POC_email: owner_email,
                          geolocation:geoData,
                          follower_email: email
                      })
                  }
                  console.log('handle confirm data:',data)
                  if(data?._id){
                    setOpen(false);
                    setIsSubmitting(false)
                    //TODO Redirect to Payment with total amount
                
                    window.location.assign(`/congrats/${owner_email}`)
                  }else{
                    console.log("processing:",data)
                    setActionError('processing')
                    setIsSubmitting(false)
                    return
                  }
                }catch(err){
                  console.log(err)
                  setActionError('Error sending')
                  setIsSubmitting(false)
              }
        //   });
        // });

        // }
      }else{
        alert('Please enter order to submit')
      }
    };
    const handleClose = () => {
        setOpen(false);
    };

  const getTotal = (arr) =>{
      let t = 0//= orderTotal
      let preTot = Number(orderTotal)
      // actualOrder?.forEach((o,i)=>{
      for(let i = 0; i < actualOrder?.length; i++){
        // let o = actualOrder[i]
        console.log('---------o?.price:',actualOrder[i]?.price,'------------o?.quantity:',actualOrder[i]?.quantity)
       
        t = t + Number( actualOrder[i]?.price ) * Number(actualOrder[i]?.quantity)
        console.log('---------order total:',t,'------------')
      }
      console.log('---------order total:',t,'------------')
      setOrderTotal(t) 

      return Number(t)
    } 
 let ord_tot //= getTotal(actualOrder)
//  setOrderTotal(ord_tot)
    useEffect(()=>{
      if(!geoData){
            getGeoData()
      }
    },[geoData])

    
    useEffect(() => {
      tempArr = [] = tempOrder
      // console.log('orderObj: ',orderObj)
    }, [signupData,tempOrder])

    useEffect(() => {
      ord_tot = getTotal(actualOrder)
    }, [ord_tot])
    // console.log('order.order:',tempOrder)
    // console.log('SITE KEY:',sitekey)

  return (
   
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
       sx={{m:0,p:2}}
    
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Please Confrim Your Order!
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
           { Object.keys(signupData)?.map((k,i) => {
               let  value = Object.values(signupData)
            //    console.log("confirm signupData[k]: ", order)
                if(headerList?.includes(k.toString())){
                    return (
                        <span key={i}>
                            <b>
                                {k === 'firstName' || k === 'lastName' ? k.split('N').join(' n') : k}: {order[k]}
                            </b> 
                            <br/>
                        </span>
                    )
                }
            }
           )

           }
          </Typography>
          <Typography gutterBottom sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
           {cart && cart?.map((o,i)=>{
              try{

                console.log('tempOrder  o:',tempOrder)
                let vals = Object.values(cart[i])
              
               return (
                 <div key={i}>
                          <span style={{display:'flex', justifyContent:'space-evenly'}}>
  
                         { vals?.map((e,k)=>{
                           
                            if( vals[1] !=='' && vals[2] > 0 ){
                             
                              if(k === 0 ){
                                actualOrder?.push(o)
                                console.log('actualOrder:', actualOrder)
                                // var item = items?.find(obj => obj.type === vals[0].toString())
                                
  
                                // setOrderTotal(orderTotal+ (vals[2] * item?.prices?.indexOf(vals[1]))
                                // total = orderTotal + (vals[2] * item?.prices[item.sizes.indexOf(vals[1])])
                                // console.log('order total:', total)
                                
                              }
                              //  if (  i === tempOrder?.length - 2 && k === vals.length -2){
                              //    ord_tot = getTotal(actualOrder)
                              //    console.log('ord_tot',ord_tot)
                              //   setOrderTotal(ord_tot)
                              // }
                              
                              return (
                                <span key={k} style={{width:'100%'}}>
                                    {/* {(k % 3 === 0 )? <b key={k}>{e}</b>:{e}}  */}
                                    {k === 1?<> &nbsp;</> :''}
                                   {k === 2?'$':''} {e?e:'-'}
                                   {/* {k === 1?'@':''} */}
                                   {k === 2?<b>&nbsp; x</b>:''}&nbsp; 
                                  </span>
                                  )
                                  
                                }
                                // setOrderTotal(total) 
                         })}
                          </span>
                      <br/>
                      </div>
                  )
              }catch(err){console.log(err)}
           })}
           <span><b>Total:</b> <u> ${orderTotal} </u></span>
           <b>Notes:</b> {order?.notes}
           {/* {Object.keys(order)?.map((or,i)=>{
                return or.split(',').toString()
           })} */}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Update
          </Button>

          <Button autoFocus onClick={handleConfirm} disabled={isSubmitting}>
            Confirm{isSubmitting?'ing':''}
          </Button>
        </DialogActions>
            {/* <ReCAPTCHA  ref={recaptcha} sitekey={sitekey} /> */}
      </Dialog>
   
  );
}