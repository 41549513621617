import React, { useState, useContext, useEffect , useRef} from "react";
import { QRCodeContext } from "../../context/QRCodeProvider";
import axios from "axios";
import QRCodeStyling from "qr-code-styling";
import { AppContext } from "../../context/AppProvider";
import * as API from '../../utils/APICalls'
import * as helpers from '../../utils/helpers'

import QRFieldComponent from "../QRFieldComponent";
import useWindowDimensions from "../../hooks/windowDimensions";
import EnvRoute from '../../middleware/envRoute';
import General from "./General";
import Corner from "./Corner";
import Misc from "./Misc";
import Dot from "./Dot";
import Logo from "./Logo";
import { Box, Button, Stack } from "@mui/material";
import QRFraming from "../QRFraming";


const ENDPOINT = EnvRoute('prod'); 

const QRCodeForm = (props) => {
  const view = props.view
  // if(!qrCodeData?.pic_url){


    //  return new Promise((resolve, reject) => {
    //         saveGeneratedPic(
    //             position => {
    //                 resolve(pic_url);
    //             },
    //             error => {
    //                 reject(error);
    //             }
    //         );
    //           });


  //     let temp = await saveGeneratedPic()
  //     await updateQRCodeData({
  //       ...qrCodeData,
  //       pic_url: temp
  //     })
  //   }
 
    
  // }

  const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);

  const [ image1Url, setImage1Url ] = useState(null); // URL or data URI for image 1
//   const [image1Url, setImage1Url] = useState('https://res.cloudinary.com/dzxzdsnha/image/upload/v1911336337/QauRaQR_Main-Personal_3_jageai.png'); // URL or data URI for image 1
  const [ image2Url, setImage2Url ] = useState('/assets/QauRa-QR-Frame.png'); // URL or data URI for image 2

  const canvasRef = useRef(null);

  
  const { height, width } = useWindowDimensions();

  const { appData, updateAppData,modalOpen, userData, selectedQR, setSelectedQR ,defaultQR} = useContext(AppContext);
  
  const [ pic_url, setPic_url ] = useState("");
  const [ activeTab, setActiveTab ] = useState("general");
  const [ bgColorType, setBgColorType ] = useState({...qrCodeData?.backgroundOptionsHelper?.colorType});
  const [ dotColorType, setDotColorType ] = useState({single: true, gradient: false});
  const [ cornerColorType, setCornerColorType ] = useState({single: true, gradient: false});
  const [ corneDotColorType, setCornerDotColorType ] = useState("single");
  const [ actionError, setActionError ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ saved, setSaved ] = useState(false)
  const [ mergedImage, setMergedImage ] = useState(false)


  const tabs = ['general','dot', 'corner','border', 'logo', 'misc']

  // const QR_API = `https://qaura-api.onrrender.com/api`
  // const QR_API_LOC = `http://localhost:5003/api`


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const mergeImages = async () =>{
  //   const canvas = canvasRef.current;

  //     const qrCode = await new QRCodeStyling(qrCodeData).getRawData('png');
  //     console.log('[qrCodeForm-mergeImages] qrCode:',qrCode)

  //     // const imageData = await canvas.toDataURL('image/png').replace("image/png", "image/octet-stream");
      
      
  //     const ctx = canvasRef.current.getContext('2d');

  //     const image1 = new Image();
  //     image1.onload = () => {
  //       ctx.drawImage(image1, 13.5, 11.5, 385, 385); // Adjust as needed
  //     };
  //     image1.src = qrCode;
  //     image1.crossOrigin = 'Anonymous';

  //     const image2 = new Image();
  //     image2.onload = () => {
  //       // Draw image2 on top of image2 (adjust position and size)
  //       ctx.drawImage(image2, 0, 0, 410, 410); // Adjust as needed
  //     };
  //     image2.src = image2Url;
  //     image2.crossOrigin = 'Anonymous';
  //   const imageData = await canvasRef?.current?.toDataURL('image/png').replace("image/png", "image/octet-stream");
  //       setMergedImage(imageData)

  // }
  
  const saveGeneratedPic = async () =>{
    try{
    const imageData = await canvasRef?.current?.toDataURL('image/png').replace("image/png", "image/octet-stream");
      
            
            
            
              const data = new FormData();
               data.append("file", imageData);
               data.append("upload_preset", "TinCan");
               data.append("cloud_name", "dzxzdsnha");
               fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
                 method: "post",
                 body: data,
               })
                 .then((res) => res.json())
                 .then((data) => {
                  if (data){
                    setPic_url(data?.url?.toString());
                    updateQRCodeData({
                        ...qrCodeData,
                       pic_url: data?.url?.toString()
                    })

                  //  updateAppData({
                  //            ...appData,
                  //            selectedQR,
                  //        })
                
                   console.log('[QRCodeForm]:',qrCodeData);
                  //  setPicLoading(false);
                  return data?.url?.toString()
                  }
                 })
                 .catch((err) => {
                   console.log(err);
                  //  setPicLoading(false);
                 });
    }catch(err){console.log(err)}
  }

  const updateQR = async(qrObj) =>{
    // let qrObj
      try {
       
         
        // console.log('----updating from click----', qrCodeData)
        
        qrObj ={
          ...qrCodeData,
          data: `https://qaura.pro/assets/${qrCodeData?._id}`,
          
        }
        setLoading(true);
        
        console.log('----updating qrObj----', qrObj)
      //  const config = {
      //      headers: {
      //      "Content-type" : "application/json",
      //      //   Authorization: `Bearer ${user.token}`,
      //      },
      //  };
      
       const  data  = await API.updateQRCode({...qrObj})
        
        console.log('update response: ',data)
        updateQRCodeData(data)
        setLoading(false);
      } catch (error) {
        setActionError("error occured") 
        setLoading(false);
      }
      // return qrObj
    }
    const returnID = async (obj) =>{
      return obj._id
    }
    const handleUpdate = async() =>{
       const data = await API.updateQRCode({
            ...qrCodeData
            })
            // if(data){

              console.log('[handleUpdate] data:', data)
              updateQRCodeData()
            // }

            
          updateAppData({
            ...appData,
            modalOpen: false,
            modalView: ''
          })

    }
    const handleSave = async() =>{
      if (view === 'create-QR'){
        try {
          setLoading(true);
          const hexString = await helpers.generateRandomHex(6);
          // const pic_url =  await saveGeneratedPic()
          const data = await API.createQR({
            ...qrCodeData,
              owner: userData?._id, 
              pin: hexString,
            })
            // if(data){

              console.log('[handleSave] data:', data)
              await updateQRCodeData(data)
            // }

          updateAppData({
            ...appData,
            modalOpen: false,
            modalView: ''
          })
      
          // updateQR()
          
          // console.log('update response (QR): ',await returnID(data))
          // console.log('update response userData (user): ',await returnID(userData))
          // console.log('update response data(QR): ', data)
          // localStorage.setItem('locQR',JSON.stringify(data))
          // let locQR = JSON.parse(localStorage.getItem('locQR'))
        
          // setLoading(false);
          // updateAppData({
          //   ...appData,
          //   modalOpen: false
          // })
          updateQRCodeData()
        } catch (error) {
          setActionError("QR save error occured") 
          setLoading(false);
        }
      }else if(view === 'edit-QR'){
        try {
          const data = await API.updateQRCode(qrCodeData)
          // updateQR()
          updateQRCodeData(data)
          console.log('Pre-update: ', qrCodeData)
          // if ( data?._id  &&  data?.data === data_default ){
          //   updateQR()
          // }
          console.log('save response: ', data )
          setLoading(false);
        } catch (error) {
          setActionError("error occured") 
          setLoading(false);
        }
      }
      updateAppData({
        ...appData,
        modalOpen: false,
        modalView: ''
      })
      
      //    console.log('Save Click', qrCodeData)
      //   setLoading(true);
      //   const config = {
      //       headers: {
      //       "Content-type": "application/json",
      //       //   Authorization: `Bearer ${user.token}`,
      //       },
      //   };
      //   const { data } = await axios.post(
      //       `${ENDPOINT[0]}/qr/create`,
      //      ...qrCodeData,
      //       config
      //   );
     
  
    
  }  
const cancelQR = () =>{
  
  updateQRCodeData({ });
    updateAppData({
            ...appData,
            modalOpen: false,
            modalView:''
        })

}
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    updateQRCodeData({ image: file });
    if( view==='edit-QR' ){ setSelectedQR({...qrCodeData})}

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const qrCode = new QRCodeStyling(qrCodeData);
    await qrCode?.download();

    try {
      const response = await axios.post("API_endpoint", qrCodeData);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // const data_default = 'https://qaura.netlify.com/assets/00000001'
  const data_default = 'https://qaura.pro/assets/00000001'

  useEffect(() => {
   console.log("qrCodeData: ",qrCodeData)
   if ( qrCodeData?._id && qrCodeData?.data.toString() === data_default.toString()){
       updateQR()
   }
  }, [qrCodeData,selectedQR])
   useEffect(()=>{
        if (view === 'create-QR'){
          updateQRCodeData()
          // updateQRCodeData({...defaultQR})
          updateQRCodeData(defaultQR)
        }
        else if(view === 'edit-QR'){
          updateQRCodeData()
            // updateQRCodeData({...selectedQR})
            updateQRCodeData(selectedQR)
          }
          // setSelectedQR({...qrCodeData})
        
      },[view])

   useEffect(()=>{
 
    if(!qrCodeData){
       if (view === 'create-QR'){
          updateQRCodeData()
          // updateQRCodeData({...defaultQR})
          updateQRCodeData(defaultQR)
        }
        else if(view === 'edit-QR'){
          updateQRCodeData()
            // updateQRCodeData({...selectedQR})
            updateQRCodeData(selectedQR)
          }
    } 
  
  },[])


      useEffect(() => {
      if(qrCodeData?.pic_url ){
        updateQRCodeData()
      }else{
        
      }
  }, []);

  // const handleDownload =async () => {
  //   const canvas = canvasRef.current;

  //   const imageData = await canvas.toDataURL('image/png').replace("image/png", "image/octet-stream");
  //   const link = document.createElement('a');
  //   link.href = imageData;
  //   link.download = `QauRa_FramedQR-${id}.png`;
  //   link.click();
  // };
  

  return (
    <div className='qr-form-wrap' 
      style={{
        minWidth: '90%',
        maxHeight:'100%', 
        // overflow:'hidden', 
        margin: 'auto'}}>
      {/* <canvas ref={canvasRef} width={410} height={410} /> */}

     <section className="color-grad-green-border blink"
        style={{
          display:'flex', 
          // flex:1,
          flexDirection:'row',
          // width:'89%',// minWidth: '350px',
          height: '70vh',
          minHeight: '400px',
          margin:'1rem auto',
          padding:'0.25rem', 
          boxShadow:"0px 0px 10px 5px rgba(223 255 103 / 75%), inset 0px 0px 10px 5px rgba(223 255 103 / 75%)", 
          borderRadius:"10px", gap:'0.5rem',
          // backgroundColor:'rgba(255,255,255,0.8)',
          // background: 'linear-gradient(45deg,rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.3)10%, rgba(255,255,255,0.4)15%, rgba(255,255,255,0.2)23%, rgba(255,255,255,0.2)45%, rgba(255,255,255,0.3)50% ,rgba(255,255,255,0.4)53%, rgba(255,255,255,0.1)83%, rgba(255,255,255,0.3)90%, rgba(255,255,255,0.2)93%, rgba(255,255,255,0.7)94%, rgba(255,255,255,0.3)96%, rgba(255,255,255,0.1)100%)',
          backdropFilter: 'blur(5px)',
          backgroundAttachment: 'fixed',
          backgroundPosition: '0px 0px',
        //   backgroundSize:'cover',
          // border:'2px outset rgba(255,255,255,0.5)',
          overflow:'hidden',
          overflowY: 'scroll'}}
        >
          
    <span //className="button-row"
        style={{
          width:'3rem',
          height:'fit-content',
          display: 'flex', 
          flexDirection:'column',
          alignItems:'center',
          display:"space-between",
          // backgroundColor:"rgba(0,0,0,0.3)", 
          borderRadius:'5px', 
          padding:'0.25rem 0.5rem',
          boxShadow: 'inset 0px -1px 3px 1px rgba(0, 0, 0, 0.5)',
          // marginBottom: '0.5rem'
          }}>
        {/* <section className="options-header"> */}
            <div className="settings-nav">
              <div style={{
                  display:'flex', 
                  flexDirection:'column',
                  alignItems: 'center'}}>

                {/* {tabs.map((t,i)=>{
                  return(

                  <button className={activeTab === t ? "active" : "button save-button "}
                  style ={{minWidth:'3rem', borderRadius:'',color:'#000', fontSize:'0.5rem'}}
                  onClick={() => {handleTabClick(t)}}
                  >
                  {t}
                  </button>
                  )
                })} */}


                <hr style={{width:'100%', margin:'0.25rem auto'}}/>
           {qrCodeData?.pin && <button 
            style={{width:'2.5rem', height:'2.5rem',padding:'0.25rem 0.25rem 0',backgroundColor: (qrCodeData?.pic_url && qrCodeData?.pin)?'rgba(0,255,0,0.7)': 'orange'}}
                className="button save-button blink" 
                    id="save-button" 
                    onClick={() => { 
                        if(qrCodeData?.pic_url && qrCodeData?.pin){

                          handleUpdate()
                        }
                        }
                    }
                    >
                <img src="/assets/save.svg" style={{height:'20px'}} alt="save QR info"/>
            </button>}
              </div>
            </div>
        {/* </section>  */}
      </span>

    <section className="options-content" style={{display: 'flex', 
    flexDirection:' column',justifyContent:'center',alignItems:'center',gap:'0.75rem',margin:'auto'}}>
    
      { (!qrCodeData?.pic_url && view === 'edit-QR' ) &&  <QRFraming qr={qrCodeData} view='edit-QR' />}
      { (qrCodeData?.pin && !qrCodeData?.pic_url && view === 'create-QR' ) &&  <QRFraming qr={qrCodeData} />}

        {(!qrCodeData?.pin && view === 'create-QR') && <Box sx={{display: 'flex', 
          flexDirection:' column',justifyContent:'center',alignItems:'center',gap:'0.75rem'}}>

         <QRFieldComponent 
                value={qrCodeData?.name}
                object={qrCodeData}  name='name'
                view={view}
                edit_on= {true}
                />
            <QRFieldComponent 
                value={qrCodeData?.payload}
                object={qrCodeData}  name='payload'
                view={view}
                edit_on={true}
                />
        <Stack sx={{flexDirection:'row',width:'100%', justifyContent:'space-evenly', margin:'1rem auto'}}>

        <Button variant='outlined'  sx={{minWidth:'160px'}} 
        onClick={cancelQR}>
         Cancel
         </Button>
        <Button variant='outlined' sx={{minWidth:'160px'}} 
        // onClick={mergeImages}>
        onClick={handleSave}> 
        Generate QR
        </Button>
        </Stack>
        </Box>}

      </section>
    </section>

    </div>
  );
};

export default QRCodeForm;
