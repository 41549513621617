import React, {useState, useContext, 
  useEffect, Component, useRef  } from "react";
import { Button, Container, Modal, Paper, TextField, Tooltip } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import QrCodeIcon from '@mui/icons-material/QrCode';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction,
  Box
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import InboxIcon from "@material-ui/icons/Inbox";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseButton from './CloseButton'
import { AppContext } from "../context/AppProvider";
import AddLink from "./AddLink";
import * as API from '../utils/APICalls'
import QRList from "./QRList";
import QROptions from "./QROptions";
import LinkToQR from "./LinkToQR";



const HotLinks = () => {
  
   const { updateAppData,appData,assets, setAssets,modalOpen ,currentAsset, userData,setUserData,
        setCurrentAsset,newAsset, setNewAsset} = useContext(AppContext);
        
    const [ linkModalOpen, setLinkModalOpen ] = useState(false)
    const [ selectedLink, setSelectedLink ] = useState(false)
    const ref = useRef(null)

    const  onDragEnd =(result)=> {
    // dropped outside the list
      if (!result.destination) {
        return;
      }

      const items = reorder(
        this.state.items,
        result.source.index,
        result.destination.index
      );

      this.setState({
        items
      });
  }

 
    const  handleLinkModalClose = () => {
        setLinkModalOpen(false);
        // setModalView('')
        // setSubView('cart')
    };
    const  handleLinkModalOpen = async (e,link) => {
        setLinkModalOpen(true);
        try{
          await setSelectedLink(link)
        }catch(er){console.log(er)}
        // setModalView('')
        // setSubView('cart')
    };

    const handleLinkDelete  = async  (e,index) =>{ 
        e.preventDefault()
        let links = userData?.hotLinks
        links.splice(index,1)
        // console.log('[Delete] -links:',links)
        try{

          const user = await API.updateUser({...userData,hotLinks:links})
        
          //  if(user){
                 // console.log('[handleGeoSelect] userData:',user)
                 localStorage.setItem('QauRaUser', JSON.stringify(user))
                await setUserData(user)
                await updateAppData({...appData,userData})
                 console.log('[handleGeoSelect] userData:',userData)
                 // setSnackOpen(!snackOpen)
          //  }
        }catch(err){console.log(err)}

     }
  // fake data generator
  const fetchLinks = async () =>{
    const user = await API.userByID(userData?._id)
    setUserData(user)
    updateAppData({
      ...appData,
      userData
    })
    return user
  }
    // Array.from({ length: count }, (v, k) => k).map((k) => ({
    //   id: `item-${k}`,
    //   primary: `item ${k}`,
    //   secondary: k % 2 === 0 ? `Whatever for ${k}` : undefined
    // }));

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: "rgb(235,235,235)"
    })
  });

  const getListStyle = (isDraggingOver) => ({
    //background: isDraggingOver ? 'lightblue' : 'lightgrey',
  });

  useEffect(()=>{

  },[selectedLink])

  useEffect(()=>{

  },[userData,appData])
  useEffect(()=>{

  },[userData?.hotLinks])
// useEffect(()=>{
//   document.onload=fetchLinks()
// },[userData])
  return (
    <Container sx={{display:'flex',flexDirection:'column',gap:'0.5rem'}}>
       <div style={{width:'100%', height:'3rem', backgroundColor:'#fff'}}>
        <h2>Hot Links</h2>
        <div style={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
            <CloseButton func={updateAppData} val={{
                        ...appData,
                        modalOpen:!modalOpen,
                        modalView:''
                    }} />
        </div>
        </div>

       
      {/* {linkModalOpen &&  */}
      <Modal open={linkModalOpen}>
          <Container sx={{ width:'fit-content', height:'fit-content',position:'absolute', 
            left: '50%', top:'50%', transform: 'translate(-50%,-50%)'}}>
              <div style={{position:'absolute', right:'-1.5rem',top:'0.5rem'}}>

               <CloseButton func={handleLinkModalClose} val={{linkModalOpen}} />
              </div>
            <Paper sx={{}}>
              <LinkToQR view='hotLink' field='hotLink' payload={selectedLink}/>
            </Paper>
          </Container>
      </Modal>
          {/* } */}
        {userData?.hotLinks && 
        (<Paper sx={{maxHeight:'70vh',overflowY:'scroll'}}>
          <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
            {/* // <RootRef ref={provided.innerRef}> */}
          {(provided, snapshot) => (
              <List style={getListStyle(snapshot?.isDraggingOver)} ref={ref}>
          {userData?.hotLinks?.map((l,i)=>{
              return(
                 <Draggable key={i} draggableId={i} index={i}>
                    {(provided, snapshot) => (
                      <ListItem
                        component="li"
                        props={{ ref: ref }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={
                        //   getItemStyle(
                        //   snapshot.isDragging,
                        //   provided.draggableProps.style
                        // ),
                       {width:'250px',whiteSpace:'nowrap', overflow:'hidden',textOverflow:'ellipsis'}}
                      >
                        <Tooltip title='delete link'>
                        <ListItemIcon>
                          <IconButton onClick={(e)=>handleLinkDelete(e,i)}>
                             <DeleteForeverIcon  sx={{ color:'red'}}/>
                          </IconButton>
                        </ListItemIcon>
                        </Tooltip>

                        <ListItemText
                          primary={l?.name}
                         secondary={l?.data}
                        />
                       
                        <ListItemSecondaryAction>
                           <Tooltip title='edit link'>
                              <IconButton>
                                <EditIcon />
                              </IconButton>
                           </Tooltip>

                          <Tooltip title='copy link'>

                            <IconButton onClick={(e)=>{
                                navigator.clipboard
                                  .writeText(l?.data)
                                  .then(() => {
                                      alert(`${l?.data} successfully copied`);
                                  })
                                  .catch(() => {
                                      alert("something went wrong");
                                  });
                            }}>
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title='pair to qr'>
                            <IconButton  onClick={(e)=>handleLinkModalOpen(e,l?.data)}> 
                                <QrCodeIcon />
                              </IconButton>
                          </Tooltip>

                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </Draggable>
              )
          })}
            {provided.placeholder}
              </List>
            )}
           {/* //  </RootRef> */}
        </Droppable>
      </DragDropContext>
      
        </Paper>)}
        <AddLink />

       

    {/* <Modal open={linkModalOpen}>
      <Container sx={{minHeight:'200px', height:'50%'}}>
             <Box sx={{ 
                    display:'flex',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'90%',
                    minHeight:'40vh',
                    overflow: 'hidden',
                    overflowY:'scroll',
                    maxHeight:'90vh',
                    // maxHeight:'90vh',
                    maxWidth:'98vw',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    borderRadius:'10px',
                    boxShadow: 24,
                    p: 1,}}>
                        <CloseIcon onClick={handleLinkModalClose}  color='#000'sx={{position:'absolute',right:'0.5rem',top:'0.5rem'}}/>
       <Box sx={{}}>
        <QROptions/> 
       </Box>
          </Box>
      </Container>
    </Modal> */}
    </Container>
  )
}

export default HotLinks






// class App extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       items: getItems(10)
//     };
//     this.onDragEnd = this.onDragEnd.bind(this);
//   }

//   onDragEnd(result) {
//     // dropped outside the list
//     if (!result.destination) {
//       return;
//     }

//     const items = reorder(
//       this.state.items,
//       result.source.index,
//       result.destination.index
//     );

//     this.setState({
//       items
//     });
//   }

