import { Container, Modal,Box, Button,
     Input, Typography, TextField, Stack } from '@mui/material'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';

import CloseButton from '../../../components/CloseButton'

const TipModal = (props) => {
    const { tipModalOpen,setTipModalOpen } = props
  return (
     <>
        <Modal 
            open={tipModalOpen}
            onClose={()=>setTipModalOpen(!tipModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    backgroundColor:'rgba( 0, 0, 0, 0.8)',
                    backdropFilter:'blur(5px)',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem',
                    color:'#FFF'
                    }}>
                        <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setTipModalOpen} val={tipModalOpen}
                            />
                        </Box>
                         
                         
                        <Stack 
                        
                            sx={{margin:'auto', width:'200px', height:'200px', 
                            backgroundColor:'rgba(255,255,255,0.3)', 
                            backdropFilter:'blur(2px)',
                            padding:'0.5rem',justifyContent:'center',
                            borderRadius:'10px',  gap:'0.5rem', alignItems:'center',
                            alignContent:'center', border:'2px solid #FFF'
                            }}>
                                <Typography sx={{}} className='blink'>
                                   <b>Send some love</b> 
                                </Typography>
                                <Typography sx={{marginBottom:'2rem'}} className='blink'>
                                   <b>🔥🙏🙌🥳🙌🙏🔥</b> 
                                </Typography>


                            <Link to='https://cash.app/$AJPHNX' target='_blank'>
                             <Button variant='outlined' sx={{backgroundColor:'rgba(0,214,78)',color:'#fff'}}>
                               <b>CashApp</b>  
                            </Button>  
                            </Link>
                           
                            <Link to='https://venmo.com/code?user_id=842007039705088035&created=1727462433' target='_blank'>
                             <Button variant='outlined' sx={{backgroundColor:'rgba(0,140,255)',color:'#fff'}}>
                       
                               <b>Venmo</b> 
                            </Button>  
                            </Link>
                            {/* <Typography  variant='caption'>
                                
                                ( Check back soon! )
                            </Typography> */}
                        </Stack>
                  
                    </Box>                   
            </Container>
        </Modal>
        
    </>
  )
}

export default TipModal