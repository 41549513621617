import React from 'react';
import Radar,{Marker} from 'radar-sdk-js';

import 'radar-sdk-js/dist/radar.css';
import { Box, Container } from '@mui/material';

// const pubKey = 'prj_test_pk_785369bc3670ce470ea47ca31b888989d2d48425'
const pubKey = 'prj_live_pk_a0ad52a6a865a04e028bb5390e841e7228ef9475'

const MapComponent = ({ centralLongitude, centralLatitude, markers }) => {
  React.useEffect(() => {
    Radar.initialize(pubKey);

    // Create a map with props
    const map = new Radar.ui.map({
      container: 'map',
      style: 'radar-default-v1',
      center: [centralLongitude, centralLatitude],
      zoom: 14,
      showMarkers:true
    });

    // Add markers
    // if (markers) {
      markers?.map((s) => {
        console.log('s:',s)
        if(s?.geolocation?.lon && s?.geolocation?.lat){
        Radar.ui.marker({ text: s?.qr_name}) // assuming marker has a text prop
            .setLngLat([Number(s?.geolocation?.longitude), Number(s?.geolocation?.latitude)])
          .addTo(map);
        }
        });
    // } 
    // else {
      // Add default marker for clarity, remove if unnecessary
      Radar.ui.marker({ text: 'HQ' })
        .setLngLat([Number(centralLongitude), Number(centralLatitude)])
        .addTo(map);
    // }

    // Cleanup on unmount (optional)
    // return () => Radar.destroy();
  }, [centralLongitude, centralLatitude, markers]); // re-render on prop changes

  return (
    <Container id="map-container" style={{display:'flex', height: '100%', position: 'relative', width: '100%',padding:'0.25rem' }}>
      <Box id="map" style={{ height: '100%', position: 'relative', width: '100%',margin:'auto',alignSelf:'center' }} >
        {/* {markers?.map((s) => (
        <Marker
          key={ s?.geolocation?.IPv4}
          latitude={s?.geolocation?.latitude}
          longitude={s?.geolocation?.longitudee}
          title={ s?.geolocation?.IPv4}
          description={s?.qr_name}
          showMarkers
        />
      ))} */}
    </Box>

    </Container>
  );
};

export default MapComponent;
