import React,{useEffect, useState, useRef} from 'react'
import LockoutModal from './LockoutModal'
import ShopMusicPlayer from './ShopMusicPlayer'
import { Link, useParams } from 'react-router-dom';
import { Container ,Box} from '@mui/material'
import * as API from '../../../utils/APICalls'
import * as helpers from '../../../utils/helpers'

const artist_id = "66f5e536da93fc184974748c"

const RexPlayer = (props) => {
    // const { albums ,artist, setArtist} = props
      const [ artist, setArtist ] = useState({})
   const [ albums, setAlbums ] = useState()
    const { trackId , email } = useParams()
    const [listener, setListener] = useState({})
    const [ verCheck, setVerCheck ] = useState(false)
    var albs
   
    // let email = listener?.email


     const checkForListener = async (email) =>{
            try{
            const res = await API.fetchListener(email)

            if (res?._id){
                console.log('[checkForListener] res:', res)
                setListener(res)
                // console.log('[checkForListener] res:', listener)
                localStorage.setItem('listener', JSON.stringify(res))   
                setVerCheck(true)
            }else if (!res?._id && !verCheck){
                const newList =  await API.createListener(email)
                if(newList){
                  console.log('[checkForListener] create:', newList)
                  setListener(newList)
                  localStorage.setItem('listener', JSON.stringify(newList))
                }
                setVerCheck(true)
                
                }
            
            }catch(err){console.log('[CheckForListen]',err)}
        }

   useEffect (()=>{
        const fetchArtist = async () => {  
          try{
            const result = await API.fetchArtist(artist_id)
            if(result){
              //  console.log("[fetchArtist]:", result)
                albs = []=result?.albums
               setArtist(result)
               setAlbums(albs)
              //  console.log("[fetchArtist] albums:", albs) 
              // setLoading(false)
            }
          }catch(err){console.log(err)}
        }

       fetchArtist()

      },[])

   useEffect (()=>{
       if(albs && !albums){
        setAlbums(albs)
       }

      },[])
    
  return (
    < >
    {/* <Box sx={{maxWidth:'100vw', margin:'auto'}}> */}
        {!listener?.isVerified && <LockoutModal lock={helpers.validateEmail(email) && listener?.isVerified ? false:true} 
        listener={listener}
        setListener={setListener}
        checkForListener={checkForListener}
        />}
        {/* {listener?.email && !listener?.isVerified && <LockoutModal lock={helpers.validateEmail(email)?false:true} email={listener?.email}/> } */}
        {listener?.email && listener?.isVerified && artist && albums && <ShopMusicPlayer albums={albums} artist={artist} setArtist={setArtist} listener={listener} setListener={setListener} email={listener?.email}/> }
      {/* <ShopMusicPlayer albums={albums} listener={listener} email={listener?.email}/>  */}
    {/* </Box> */}
    </>
  )
}

export default RexPlayer