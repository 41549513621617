import { Box, Button } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import * as API from '../utils/APICalls'
import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";


const InputTypeSelect = (props) => {
    const types = ['url','text','image','file']
    const [ payloadType, setPayloadType ] = useState('')
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    
     const { appData, updateAppData, assetQR, setCurrentAsset, currentAsset,newAsset,
            setNewAsset, currentPersona, setSelectedQR,selectedQR } = useContext(AppContext);
    const handleTypeSet = async (val)=>{
        // e.preventDefault()
       props.func(val)

    }

    useEffect(()=>{
        if(!selectedQR?.payload_type){
                handleTypeSet('url')
            }
        console.log('Geo-Condition PayloadType:', payloadType)
  },[ selectedQR,payloadType])

    return (

    <Box sx={{display:'flex',textAlign:'center'}}>

        {types?.map((t,i)=>{
            return(<Button
                key={i}
                variant = { props.val === t ? 'outlined' : ''  }
                // sx={{}}
                onClick={(e)=>handleTypeSet(t)}
                >{t}</Button>)
        })}
    </Box>
  )
}

export default InputTypeSelect