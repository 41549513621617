import React, {useState, useContext, useEffect } from "react";
import QROptions from './QROptions'
import * as API from '../utils/APICalls'

const LinkToQR = (props) => {
    const [ showList, setShowList ] = useState(false)
    const { view , field, payload } = props
  return (
    <span className="button-accent-hover"
            style={{
                // position:'absolute', top:'0.5rem',right:'0.5rem',
                fontFamily:'Gilroy, sans-serif',
                display: 'flex', alignItems:'center',
                 gap:'0.5rem'}}> 
            {/* <b><u>link persona to asset </u> </b> */}
               <div  
               style={{
                position:'relative',
                display: 'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                gap:'0.5rem',
                   width:'fit-content',
                    cursor: 'pointer',
                    padding: '0 0.125rem',
                    borderRadius: '5px',
                    border: '2px solid #FFFFFF',
                    boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                    padding: '0.5rem',
                    backgroundColor: showList?'rgba(61, 255, 158, 0.7)':'rgba(61, 255, 158, 0.628)',
                    backdropFilter: showList?'blur(6px)':'',
                    zIndex:'9999'
                }} 
                    onClick={()=> {
                if(!showList){

                    setShowList(true)
                }}}
                // setShowList(!showList)
            
            >
                <div style={{position:'relative',borderBottom:'2px solid #FFFFFF',paddingBottom:'0.25rem', width:'100%'}}>

                    link to QR
                    {showList&&<img  width='10rem'
                    className="button-accent-hover"
                    onClick={()=>{

                        setShowList(!showList)
                    }}
                    style={{cursor:'pointer',position:'absolute', right:'0.125rem', top:'0.125rem'}}
                    src='/assets/close.svg' alt='close link to QR options'/>}
                </div>
                
                <img width='55rem' src='/assets/LinkToQR.svg' alt='link to asset'/>
        {showList &&  <QROptions view={view} field='persona'payload={payload}/>}
            </div>
            </span>
  )
}

export default LinkToQR