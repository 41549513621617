import React, {useState, useEffect, useContext} from 'react'
import { AppContext } from "../context/AppProvider";

import PersonaListCard from './PersonaListCard'
import * as API from '../utils/APICalls'

import axios from 'axios'
import EnvRoute from '../middleware/envRoute';

const ENDPOINT = EnvRoute('prod'); 




// const list = personaList()
const PersonaList = () => {
    // const [ personas, setPersonas ] = useState(false)
    const { personas, setPersonas,userData, modalOpen, currentPersona, newPersona } = useContext(AppContext);

    const user = {} ={...userData}

    const fetchPersonas  = async() =>{
        console.log("[fetching Personas]---> user:", userData)
        try{
            const data  = await API.fetchPersonas(userData)
            setPersonas(data)
        }catch(err){console.log(err)}
        }
    useEffect(()=>{
        if(!personas){
            fetchPersonas()
        }
      
    },[personas,userData])
    useEffect(()=>{
        
            fetchPersonas()
      
    },[currentPersona])


    return (
    // <div style={{position:'relative',height: '100%', borderRadius:'20px',
    //   overflowX:'scroll'
    //     }}>
         
        <div  style={{display: 'flex',flexDirection:'row',width:'100%', 
            borderRadius:'5px', color:'#000000',
            overflow:'hidden',overflowX:'scroll',zIndex:'2000'}}> 
                {personas ?  personas?.map((p,i)=>{
                    return( <PersonaListCard 
                    persona={p} id={i} key={i} /> )})
                    :<div id="scroll-container" >  
                    <div id="scroll-text">
                        <h4>fetching personas...</h4>
                    </div>              
                </div>
                }
                {/* {currentPersona &&  */}
              
                    {/* } */}

        </div>
    // </div>
  )
}

export default PersonaList