import React, { useState,useRef,useContext,useEffect } from "react";
import { AppContext } from "../../context/AppProvider";
import { QRCodeContext } from "../../context/QRCodeProvider";
import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import { Box, Button, Container, Input, Modal, 
    Stack, Typography,InputLabel,TextField  } from '@mui/material'
import * as API from '../../utils/APICalls'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

const CompletePrintfile = (props) => {
   const view = props.view
    const type = props.type
     const {  setModalOpen, defaultQR, setCurrentOrder,
            currentProduct, setBuildStep, buildStep,
            cart,setCart,currentOrder, setShop, shop,storefrontData, 
            updateStorefrontData} = useContext(StorefrontContext);
            
    const { currentAsset,
        newAsset , } = useContext(AppContext);
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);

 const [qrPin, setQrPin] = useState('');
  const [orderId, setOrderId] = useState('');
  const [itemId, setItemId] = useState('');
  const [fontType, setFontType] = useState('Gilroy');
  const [fontOpen, setFontOpen] = useState(false);
  const [ hasChanged,setHasChanged ] = useState(true);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isSaved, setIsSaved ] = useState(false);
  const [ editMode, setEditMode ] = useState(true);
  const [ refresh,setRefresh ] = useState(false);
  const [multiLine ,setMultiLine ] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [qrImage, setQrImage] = useState(null); // State to store the QR image
  const [assetImage, setAssetImage] = useState(null); // State to store the QR image
  const canvasRef = useRef(null);

  const fonts =['Permanent Marker','Gilroy', 'Protest Riot']//,'Sofia']

   const handleFontClose = () => { setFontOpen(false);};
    const handleFontOpen = () => { setFontOpen(true);};
     const handleFontChange = async(e) => {
        setHasChanged(true)
        setIsSaved(false)
        await setFontType(e.target.value)
      
        // console.log('[handleGpChange] selectedPin:',selectedPin)
 
    };

    const sleep = (time) => {
      return new Promise(resolve => setTimeout(resolve, time))
    }
  const downloadImage = async () => {
    const canvas = canvasRef.current;
    const image = canvas.toDataURL('image/png').replace("image/png", "image/octet-stream");
    
    // postPicDetails(image)
  
    const link = document.createElement('a');
    link.href = image;
    link.download = `Parallaxr-${currentProduct?.name}-PRINTFILE.png`;
    link.click();
  };
  

  const saveImage = async () => {
  
    setIsSaving(true)
    const canvas = canvasRef?.current;
    const image = canvas?.toDataURL('image/png').replace("image/png", "image/octet-stream");
    try{
      postPicDetails(image)
      setIsSaving(false)
    }catch(err){console.log(err)
      setIsSaving(false)
    }
    
  };

  const saveOrder = async (ord) =>{
    try{
      if(ord?.printFile_public_id !=='' || ord?.printFile_public_id !==null ){
                  
        console.log('[Saved Order (PreSave)]:', ord)
        const order = await API.updateOrder(ord)
        console.log('[Saved Order]:', order)
      
      if(await order){
        await setCurrentOrder(order)
        await updateStorefrontData({
          ...storefrontData,
            currentOrder
          })
       if(qrCodeData){
        updateQRCodeData()
      }
        setBuildStep(null)
        await updateStorefrontData({
          ...storefrontData,
            buildStep
          })
        }
        console.log('[Current Order] :', currentOrder)
      }
      // else{
      //   saveImage()
      //   sleep(5000)
      // }
      setIsSaving(false)
      }catch(err){
        setIsSaving(false)
        console.log('Unable to save order',err)}
    }
    
    const previousPicCheck = async (view) => {
        switch(view){
            
            case'build-shirt':
            if ( currentOrder?.printFile_public_id ){
                console.log('Previous order file check',currentOrder)
                await API.deleteOrderPrintfile( currentOrder )
            }
            break;
        }
    }
  
  const postPicDetails = async (pics) => {
      const canvas = canvasRef?.current;
        const image = canvas?.toDataURL('image/png').replace("image/png", "image/octet-stream");
        

      setIsSaving(true)
        // if (typeof pics === undefined) {
        //     console.log("pics undefined")
        //     return;
        // } 
        
        
        if(currentOrder?.printFile_public_id?.length > 0){
             previousPicCheck(view)
            //  const a = await API.deleteOrderPrintfile( currentOrder )
            //  console.log('[Postpic] a:',a)
            }
            //    if ( currentOrder?.printFile_public_id ){
                
                // }
                

          console.log('[Postpic] image:',image)
         try{
             const data = new FormData();
               data.append("file", image);
               data.append("upload_preset", "TinCan");
               data.append("folder", "ORDERS");
               data.append("cloud_name", "dzxzdsnha");
               fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
                 method: "post",
                 body: data,
               })
                 .then((res) => res.json())
                 .then((data) => {
          

                  if( view === 'build-shirt'){
                    console.log('[postPic] process:',data)
                    let orderObj = {
                      ...currentOrder,
                       printFile: data?.url?.toString(),
                        printFile_public_id: data?.public_id?.toString(),
                        isComplete: true,
                        status: 'cart'
                    }
                    
                    setCurrentOrder({
                    ...orderObj
                    })
                    updateStorefrontData({
                      ...storefrontData,
                          currentOrder
                      })
                      // if(currentOrder?.printFile_public_id?.length){
                        // if(!orderObj?._id){
                        saveOrder(orderObj)
                        // }
                        // else if(orderObj?._id){
                        //   overlayId(orderObj)
                        // }
                      // }else{
                      //   sleep(5000)
                      //   saveImage()
                      // }
                    
                  }
                  
                  //    console.log('[BuildLSAsset]:',data.url);
                  setHasChanged(false)
                  setIsSaved(true)
                  setEditMode(false)
                  setIsSaving(false)
                  //  setPicLoading(false);
                 })
                 .catch((err) => {
                   console.log('failure to save',err);
                  //  setPicLoading(false);
                 });
         }catch(err){
            setIsSaving(false)
            console.log(err)}
           
               
                 
                  
    
      }
  const handleChange = (event) => {
    if (event.target.name === 'qrPin') {
      // setHasChanged(true)
      setQrPin(event.target.value);
    } else if (event.target.name === 'orderId') {
      // setHasChanged(true)
      setOrderId(event.target.value);
    }
    setHasChanged(true)
    setIsSaved(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true)
    // const text = `${qrPin}\n${orderId}`; 
    try{

        // const image = await fetch('https://res.cloudinary.com/dzxzdsnha/image/upload/v1711293982/Framed_QR_hzwxrs.png'); // Replace with path to your image
        // // const image = await 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1711293982/Framed_QR_hzwxrs.png'; // Replace with path to your image
        // // const image = await fetch('path/to/your/qr_image.png'); // Replace with path to your image
        // const qrImageBlob = await image?.blob();
        // console.log('[handleSubmit] qrImageBlob:',qrImageBlob)
        // setQrImage(URL.createObjectURL(qrImageBlob));
        // setIsSubmitting(false)
        setIsSubmitting(false)
    }catch(err){
        setIsSubmitting(false)
        console.log(err)
    }
  };
  const drawImage = (ctx, imageUrl, x, y, width, height) => {
  const img = new Image();
  img.src = imageUrl;
  img.crossOrigin = 'Anonymous';
  img.onload = () => {
    ctx.drawImage(img, x, y, width, height);
  };
};

const drawText = (ctx, text, x, y, maxWidth=840,fontSize = 300, color = 'black') => {
    let currentFontSize = fontSize;
  ctx.font = `900  ${currentFontSize}px ${fontType}`;
   // Measure text width
  let textWidth = ctx.measureText(text).width;
   // Calculate horizontal centering offset
   //   console.log(['text width:', textWidth])
   // Check for overflow and adjust font size if needed
   while (textWidth > maxWidth) {
       currentFontSize -= 2; // Adjust decrement value as needed
       ctx.font = `${currentFontSize}px ${fontType}`;
       textWidth = ctx.measureText(text).width;
    }
    const horizontalCenterOffset = (maxWidth - textWidth) / 2;

  ctx.fillStyle = color;
  ctx.fillText(text, x + horizontalCenterOffset, y);
  ctx.strokeStyle = 'black'
    // ctx.lineWidth = fontOutline(fontType)
    ctx.lineJoin = "round"
    ctx.strokeText(text, x + horizontalCenterOffset, y) 
};
  const overlayId = async (ord) =>{
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.style.letterSpacing = '5px';

    ctx.save(); 
    ctx.restore();

    if( type === 'standard' ){
         drawText(ctx, ord?._id?.toUpperCase(), 800, 250); 
     }
    else if(type ==='nametag plain'){
        //  ctx.restore();
        ctx.translate(1275, 1650)
        // ctx.translate(400, 4140)
        ctx.rotate(Math.PI/2);
        drawText(ctx, ord?._id?.toUpperCase(), -750, -825, 1000); 
      }
    }

    const updateOrder = async (order) =>{
      try{

        const upd_ord = await API.updateOrder(order)
        if(upd_ord){
          setCurrentOrder({
            ...upd_ord
          })
          updateStorefrontData({
            ...currentOrder
          })
        }
      }catch(err){console.log(err)}

    }


useEffect(() => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  canvas.style.letterSpacing = '5px';


  // Replace with path to your base image
  const baseImage = new Image();
  baseImage.onload = async () => {
    baseImage.crossOrigin = 'Anonymous';
    ctx.drawImage(baseImage, 0, 0, canvas.width, canvas.height);
    ctx.save(); 
    
    //  if (qrImage) {
    //    ctx.save(); 
    //    if( type ==='nametag mod' || type ==='porthole' ){ 
       if( type ==='standard' ){
        //    ctx.restore();
            drawText(ctx, currentOrder?._id?.toUpperCase(), 700, 200,1200); 
        }
        
        else if(type ==='nametag plain'){
            // ctx.restore();
            // ctx.restore();

            ctx.translate(1275, 1650)
            ctx.rotate(Math.PI/2);

            // drawText(ctx, currentOrder?._id?.toUpperCase(), 0, 0, 1000); 
            drawText(ctx, currentOrder?._id?.toUpperCase(), -850, -1080, 1100); 
            // drawText(ctx, currentOrder?.cart_id?.toUpperCase(), 2100, 1850, 1000); 
            ctx.resetTransform();
            // ctx.rotate(-Math.PI/2);
            // drawImage(ctx, qrImage,270, 500, 410 * 1.4 , 410 * 1.4);// x-coordinate, y-coordinate, width, height
            // Adjust these values to position and size the image on the canvas
        
        }
      ctx.restore();
    // }
     // Draw Text
  // const qrPinText = qrPin; // Assuming minimal formatting needed
  const qrPinText = currentOrder?.qr_pin; // Assuming minimal formatting needed
  const orderIdText = orderId;
  
//  if(multiLine){
    //  drawText(ctx, currentOrder?.cart_id?.toUpperCase(), 800, 200); // Adjust X and Y for company placement
//  }
    
  };
  baseImage.src = currentOrder?.printFile;
  // baseImage.src = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1717531063/PARALLAXR_PRINTFILES/REFS/HELLO_PRINTFILE-REF_nnmy1z.png';
  canvas.oncontextmenu = function() {return false};
  setIsLoading(false)
}, [qrImage,qrPin,orderId,fontType,multiLine]);



  useEffect(()=>{

  },[currentOrder,newAsset,currentAsset,hasChanged,isSaved,isSaving])
  return (
    <Box sx={{fontFamily:'Gilroy,sans-serif',display:'flex', marginTop:'1rem',
          maxWidth:'500px', flexDirection:'column', 
          gap:'0.5rem',justifyContent:'center',padding:'2rem'}}>
      <Typography variant="h4"sx={{fontFamily:'Gilroy,sans-serif',fontWeight:'bold'}}>Building Your Print</Typography>
     
  
             {/* <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                <Typography>2 lines</Typography>
                <Switch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={(e)=>{setMultiLine(!multiLine)}} checked={!multiLine} />
                <Typography>1 line</Typography>
            </Stack> */}

          {/* {type} */}
      {type !== 'nametag plain'&&<canvas ref={canvasRef} width={4500} height={5100} />}
     {type === 'nametag plain' && <canvas ref={canvasRef} width={2550} height={3300} />}
       {isLoading && <HourglassBottomIcon />}
        <Stack sx={{display:'flex', flexDirection:'row', gap:'0.5rem', margin:'1rem auto'}}>
            {/* <Button variant='outlined' type="submit" onClick={()=>setRefresh(!refresh)}>Refresh{isSubmitting?'ing':''}</Button> */}
            
            {/* {!isSaved && hasChanged && !currentOrder?._id && <Button variant='outlined' 
              disabled={isSaving}
              onClick={(e)=>{
                try{
                  e.preventDefault()
                  saveImage()

                }catch(err){console.log(err)}

                }}>Complet{isSaving ?'ing':'e'} Order</Button>} */}

                { !isSaving && <Button variant='outlined' 
                  // disabled={disableCheck()}
                  onClick={()=>{
                    setEditMode(true)
                    setIsSaved(false)
                    if(currentOrder?.printFile_public_id){
                        setCurrentOrder({
                            ...currentOrder,
                                asset_url: '',
                                asset_pic_public_id: '',
                                printFile: '',
                                printFile_public_id: '',
                        })
                    }
                    setBuildStep(2)
                  }

                  }>Previous Step</Button>}
            { currentOrder?._id && <Button variant='outlined' 
            //   disabled={isSaving}
              onClick={(e)=>{
                //   e.preventDefault()
               
                // try{
                //   updateOrder(currentOrder)
                  postPicDetails()
                  setIsSaved(true)
                //   console.log('[NEW COMPLETION CLICK]:',currentOrder)
                // }catch(err){console.log('[NON COMPLETION CLICK]',err)}

                }}>finaliz{isSaving ?'ing ':'e'} printfile</Button>}

           
        </Stack>
    </Box>
  );
};
export default CompletePrintfile