import React,{ useEffect, useRef, useState, useContext } from  'react'
import { AppBar, Button, CardMedia, Container, Divider, List, ListItem, ListItemIcon, ListItemText, Modal, Stack, Tooltip } from '@mui/material';

import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CustomerInfoForm from './CustomerInfoForm';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


const CheckoutModal = (props) => {
    const cartOpen = props.cartOpen
    const setCartOpen = props.setCartOpen
    var init_cart = props.cart
    var cart = props.cart
    var setCart = props.setCart
    const shop = props.shop
    const [ modalView, setModalView ] = useState(props.modalView)
    const [ subView, setSubView ] = useState('review')
    // const [ subView, setSubView ] = useState(props.subView)


    const handleCheckout = () =>{
        if(subView ==='cart'){
            setSubView('checkout')
        }
        if(subView ==='checkout'){
            setSubView('confirm')
        }
    }
    // const locCart = localStorage.getItem('cart')
    // let cart =[]
    // if (locCart) cart = JSON.parse(locCart)
    
    const handleRemoveClick = (arr,obj,i) => {
        const index = arr.indexOf(obj)
       if(index > -1){
        arr.splice(index,1)
          setSubView('cart')
       }
       console.log('[Remove Click]:',arr)
       cart = arr
       localStorage.setItem('cart',JSON.stringify(cart))
       props.setCart(cart)
       console.log('[Remove Click] cart(prop):',cart)
    };
    
    const handleCartClose = () => {
        setCartOpen(false);
        setModalView('')
        setSubView('cart')
    };

    const cartTotal = () =>{
        let total = 0
        // if (cart){
            cart?.forEach((e,i)=>{
                total = ( e.quantity * e.price ) + total
            })
            console.log('[Cart Total]:', total)
            return total
        // } else {return}

    }

    useEffect(()=>{
        //  if (locCart) cart = JSON.parse(locCart)
        console.log('[Cart Modal] cart :', cart)
      
    },[cart,])
  if(init_cart !== cart){
            setSubView('checkout')
            .then()
            setSubView('cart')
        }
  return (
      <Modal
                open={cartOpen}
                onClose={handleCartClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
               
            >
                <Container sx={{p:1}}>

                <Box sx={{ position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'90%',
                    minHeight:'60vh',
                    overflow: 'hidden',
                    overflowY:'scroll',
                  maxHeight:'90vh',
                    // maxHeight:'90vh',
                    maxWidth:'98vw',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    borderRadius:'10px',
                    boxShadow: 24,
                    p: 1,}}>
                        <CloseIcon onClick={handleCartClose}  color='#000'sx={{position:'absolute',right:'0.5rem',top:'0.5rem'}}/>
                   {/* <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                    <Typography>Map</Typography>
                    <Switch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={(e)=>{
                        if(modalView ==='map'){
                            setModalView('menu')
                        }
                        if(modalView ==='menu'){
                            setModalView('map')
                        }
                    }} checked={modalView ==='menu'} />
                    <Typography>Menu</Typography>
                </Stack> */}
              {(props.modalView ==='cart' && subView !== 'checkout') && (  
                    <Box sx={{p:1}}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily:'Sofia',fontWeight:'bold'}}>
                           Cart
                        </Typography>
                        {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          
                        </Typography> */}
                        {/* <Box sx={{border:'1px solid #000'}}> */}
                        <Box sx={{display:'flex',flexDirection:'column',border:'1px solid #000',borderRadius:'10px',
                            overflow:'hidden',overflowY:'scroll',minHeight:'100%', height:'100%',p:1}}>
                            {cart && cart?.map((p,i)=>{
                                return( 
                                    <Box sx={{position:'relative',display:'flex', flexDirection:'column',p:1, gap:'0.5rem'}} key={i}>
                                        <RemoveCircleIcon className='button-woddle'
                                            sx={{cursor:'pointer'}}
                                            onClick={(e)=>{handleRemoveClick(cart,p,i)}}/>
                                        <Stack sx={{display:'flex', flexDirection:'row',fontSize:'1rem', justifyContent:'baseline'}}>
                                            <b style={{width:'30%',maxWidth:'150px'}}>{p?.item_type}</b>
                                            <Stack sx={{display:'flex',flexDirection:'row', flex:1,justifyContent:'space-between'}}>
                                              <span style={{width:'30%', maxWidth:'100px'}}>
                                                &nbsp;({p?.size}) 
                                                </span>  
                                                <b style={{width:'30%',maxWidth:'50px',textAlign:'center'}}> 

                                                {p.quantity}
                                                </b>
                                                <span style={{width:'30%',maxWidth:'50px',textAlign:'center'}} >
                                                @ ${p.price}
                                                </span>
                                                &nbsp;=&nbsp;
                                                
                                                <span style={{width:'30%',maxWidth:'50px',textAlign:'center'}}>
                                                 ${p.price * p.quantity}
                                                </span>
                                            </Stack>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                    )
                                })
                            }

                       {cartTotal(cart) > 0 ?
                        <Typography sx={{margin:'auto'}}><b>Total:</b>&nbsp;${cartTotal(cart)}</Typography>
                      :<Typography sx={{margin:'auto'}}>
                            Cart currently empty
                            </Typography> } 
                  
                        </Box>  
                        {/* </Box> */}
                        
                          <Button variant='contained' disabled={cartTotal(cart)<=0}
                            sx={{display:'flex',position:'relative',margin:'1rem auto'}}
                            onClick={handleCheckout}
                            >
                                Checkout
                            {/* {subView ==='cart' ?'Checkout':'Review Order'} */}
                        </Button>
                        </Box>
                     )}
                     {
                        subView === 'checkout' &&
                        <Container>
                             <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily:'Sofia',fontWeight:'bold'}}>
                           Checkout Continued
                        </Typography>
                            <CustomerInfoForm shop={shop} 
                                cart={cart}
                                setSubView={setSubView} 
                                subView={subView}/>
                        </Container>
                     }
                     
                </Box>
                </Container>
            </Modal>
  )
}

export default CheckoutModal