import { Box, Button } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import * as API from '../utils/APICalls'
import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";


const PayloadTypeSelect = () => {
    const types = ['url','text','image','file']
    const [ payloadType, setPayloadType ] = useState('')
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    
     const { appData, updateAppData, assetQR, setCurrentAsset, currentAsset,newAsset,
            setNewAsset, currentPersona, setSelectedQR,selectedQR } = useContext(AppContext);
    const handleTypeSet = async (val)=>{
        // e.preventDefault()
        var tempObj = {
            ...selectedQR,
            payload_type: val.toString()
        }
        await setPayloadType(val)
        // if (val === payloadType){
        const data = await API.updateQRCode({ ...tempObj })
        console.log('[QRFieldComponet] save:',data)
        if(data){
            // updateQRCodeData(data)
            setSelectedQR(data)
            updateAppData({
                ...appData,
                selectedQR
            })
        }
        // }
    }
    useEffect(()=>{
    if(!selectedQR?.payload_type){
        handleTypeSet('url')
    }
      console.log('PayloadType:', payloadType)

    
  },[ selectedQR,payloadType])
    return (

    <Box sx={{display:'flex',textAlign:'center'}}>

        {types?.map((t,i)=>{
            return(<Button
                key={i}
                variant = { selectedQR?.payload_type === t ? 'outlined' : ''  }
                // sx={{}}
                onClick={(e)=>handleTypeSet(t)}
                >{t}</Button>)
        })}
    </Box>
  )
}

export default PayloadTypeSelect