
import React, {useState, useContext, useEffect } from "react";

import * as API from '../utils/APICalls'
import { AppContext } from "../context/AppProvider";
import { Box, Button } from "@mui/material";


const DeleteQR = () => {
    const { updateAppData,assets, currentAsset,modalOpen ,personas, userData,
        selectedQR,newAsset, QRs} = useContext(AppContext);
    
    const deleteQR = async() =>{
    //   console.log('[Delete Asset] (before):', assets)
      let as = QRs
        as.splice(as.findIndex(function(a){
          return a?._id === currentAsset?._id;
        }), 1);
      
      console.log('[Delete Asset] (after):', assets)
     const data = await API.deleteQR(selectedQR)
       updateAppData({
              modalOpen:false,
              modalView:''
          })
    }

  return (
    <Box style={{color:"#ffffff",height: '100%',padding:'0.5rem', width:'250px',minWidth:'fit-content',margin:'auto',
    display:'flex', flexDirection:'column',
    border:'1px solid #000', borderRadius:'5px', textAlign:'center',color:'#000000'}}>
                     <h5> Would you like to delete:
                       </h5>
                        <h2 style={{borderBottom:'2px solid #000000', 
                          borderRadius:'5px', padding:'0.25rem 0.55rem', width:'fit-content', 
                          margin:'0.5rem auto'}}>
                          {selectedQR?.name?selectedQR?.name:selectedQR?._id}
                        </h2> 
                        <h2 style={{//borderBottom:'2px solid #000000', 
                         // borderRadius:'5px', 
                          padding:'0.25rem 0.55rem', width:'fit-content', 
                          margin:'0.5rem auto'}}>
                      
                         ID:<u>{selectedQR?.name?selectedQR?._id:''}</u> 
                        </h2> 
                   <Button className="redirect-button button-woddle" style={{backgroundColor:"#FF3D9E", color:'#FFF',margin: '0.75rem auto'
                   }}
                    onClick={()=>{
                      deleteQR(selectedQR)
                      
                    }}
                  >
                      delete
                  </Button>
                   </Box>) 
                   }
 
export default DeleteQR