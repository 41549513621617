import React ,{  useEffect, useContext,useState }from 'react'
import * as API from '../utils/APICalls'
import { StorefrontContext, StorefrontProvider }  from "../context/StorefrontProvider";
import { AppContext } from "../context/AppProvider";
import { Box, Button, Container, Divider, IconButton, List, Modal,
  ListItem, ListItemIcon, Stack, Switch, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseButton from './CloseButton';
import CreateStorefontModal from './CreateStorefontModal';

const StorefrontConsole = () => {
  const { appData, updateAppData, userData, shops, setShops, orders, setOrders,
    currentShop, setCurrentShop, modalOpen, setModalOpen } = useContext(AppContext)
  const [ shopOrders, setShopOrders ] = useState([])
  const [ createModalOpen, setCreateModalOpen ] = useState(false)
  const [ subView, setSubView ] = useState('orders')

  const subViews = ['products','orders','settings']
  
  let storeCheck = 0
  let orderCheck = 0
   const fetchShops = async () =>{
        try{ 
          const data = await API.fetchShops(userData)
            if(data){
              await setShops(data)
              await updateAppData({
                ...appData,
                shops
              })
              console.log('Storefronts:', data)
              
              storeCheck = 1
            }
          }catch(err){console.log(err)}
    }
   const fetchOrders = async (shop_id) =>{
        try{ 
          const data = await API.fetchOrders(shop_id)
            if(data){
              // await setOrders(data)
              // await updateAppData({
              //   ...appData,
              //   orders
              // })
              // console.log('orders:', data)
               await setShopOrders(orders)
              // orderCheck = 1
              return data
            }
          }catch(err){console.log(err)}
    }

  const  handleShopClick = (shop) =>{
        setCurrentShop(shop)
        updateAppData({
            modalView:'storefront'
        })

  }
  const  toggleCreateModal =  async (shop) =>{
    
   setCreateModalOpen(!createModalOpen)
  }
  const  toggleStorefront =  async (shop) =>{
    var val = shop.isActive
    const res = await API.updateShop({...shop,isActive:!val})
    
    if(res)
      {
        fetchShops()
      }
  }
  
  useEffect(()=>{
     
    if( storeCheck < 1){
      fetchShops()
    }
  },[])

  useEffect(()=>{
    
    // if( orderCheck < 1){
    //   fetchOrders()
    // }
  },[shopOrders,shops])

  return (
    <Container sx={{fontFamily:'Gilroy, sans-serif',padding: '0.5rem'}}>
         {/* <div style={{position:'absolute', top:'0.5rem', right:'0.5rem'}}> */}
        {/* </div> */}
        <Box sx={{display: 'flex', justifyContent:'space-between'}}>
        <h2>Storefronts</h2>
      <CreateStorefontModal />
            
            <CloseButton func={updateAppData} val={{
                        modalOpen:!modalOpen,
                        modalView:''
                    }} />
        </Box>
      <List sx={{ display:'flex', flexDirection:'column',gap:'0.5rem',padding:'0.5rem'}} >
        { shops && shops?.map((s,i)=>{
          // try{
              // let orders = fetchOrders(s?._id) 
              // console.log('[Fetch Orders]:', orders)
             
            // }catch(err){console.log(err)}
          return (
            <ListItem key={i}
              sx={{display:'flex', cursor: 'pointer', borderRadius:'10px',
                boxShadow:'0px 0px 5px 1px rgba( 0, 0, 0 , 0.3 )',
                '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                flexDirection:'column', width:'100%',border:'1px solid #757575'}} 
              onClick={()=>{
                handleShopClick(s)
            }}> 
            <Box
              sx={{display:'flex', gap:'0.5rem',justifyContent:'space-between', width:'100%', alignItems:'center'}} 
              
            > 
            {/* <Stack sx={{textAlign: 'center'}}>

                  <Typography>ordering</Typography> */}
                  {/* <Divider /> */}
             {/* <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                          <Typography>off</Typography>
                          <Switch defaultValue={s?.isActive} inputProps={{ 'aria-label': 'ant design' }}
                              onChange={(e)=>{
                                 toggleStorefront(s)
                                  }} checked={s?.isActive} />
                              
                          <Typography>on</Typography>
                      </Stack>
            </Stack> */}
              <Stack sx={{display:'flex', flexDirection:'row', gap:'0.5rem'}}>
                <span> {s?.campaingName}</span>
                <h3> {s?.operating_name} </h3>
              </Stack>
               <Tooltip title='copy link'>

                    <IconButton onClick={(e)=>{
                        navigator.clipboard
                          .writeText(`https://qaura.pro/storefront/${s._id}`)
                          .then(() => {
                              alert(`successfully copied`);
                          })
                          .catch(() => {
                              alert("something went wrong");
                          });
                    }}>
                      <ContentCopyIcon />
                    </IconButton>
                    </Tooltip>
                    {/* <Stack>
                     <b>
                       Products
                      </b>
                      {s?.items.map((item,j)=>{
                        return (
                          <div key={'item-'+i}>
                            {item?.name}
                          </div>
                        )
                      })}
                    </Stack>
                    <Stack>
                     <b>
                       Orders
                      </b>
                      {orders?.length>0 && orders?.map((item,k)=>{
                        return (
                          <div key={'order-'+i}>
                            {item?._id}
                          </div>
                        )
                      })}
                    </Stack> */}
                     
            </Box>
            </ListItem>
          )
        }) }
      </List>
    </Container>
  )
}

export default StorefrontConsole