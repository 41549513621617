import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from "../context/AppProvider";
import axios from 'axios'
import { QRCodeContext } from "../context/QRCodeProvider";
import QROptions from './QROptions'

import * as API from '../utils/APICalls'
import { TextField } from '@mui/material';


const PersonaFieldComponent = (props) => {     
    //  const ENDPOINT = 'http://localhost:5003/api'
    // const ENDPOINT = 'https://qaura-api.onrender.com/api'

    const { object, value, name, index, sub, on, edit_on, view,title_on} = props
    const [ edit, setEdit ] = useState(edit_on)
    const {personas,setPersonas, modalOpen, userData,currentPersona,
        updateAppData, appData, setCurrentPersona ,tempPersona, setTempPersona} = useContext(AppContext);
    const [ type, setType ] = useState()
    
    const [ onValue, setOnValue ] = useState()
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const [ personaTitle, setPersonaTitle ] = useState(currentPersona?.personaTitle)
    const [ isCreated, setIsCreated ] = useState( currentPersona?._id? true:false )
    const [ isSaved, setIsSaved ] = useState(true)
    const [ prompt, setPrompt ] = useState()
    const [ tempValue, setTempValue ] = useState()
    const [ pic, setPic ] = useState(false)
    const [ showList, setShowList ] = useState(false)


    const [ picLoading, setPicLoading ] = useState(false)

    const [ picIsUrl , setPicIsUrl ] = useState(false)

    const picCheck = () =>{
        if(name==='alt_pic' && currentPersona?.alt_pic?.data || name==='cover_pic' && currentPersona?.cover_pic?.data){
            setPicIsUrl(true)
            // return
        }
    }
/*
if view === "create" setTempPersona() && hide save button
if view === "edit" setTempPersona({...}) setCurrentPersona({...tempPersona}) const data = await API.updatePersona(currentPersona) 
*/ 
    const checkType = () =>{
        if(name ==='preferredName'||name ==='title'||name ==='about'||name ==='personaTitle'){
            return 'text'
        }
        if(name ==='directLink'|| name.toString().includes('link-')){
            return 'url'
        }
        if(name ==='phone' || name ==='email'){
            return name
        }
       if(name ==='alt_pic' || name==="cover_pic" ){
            // picCheck()
            if (picIsUrl) {
                return 'text'
            }else{ return 'file' }
        }
    
    }
    
    const checkOn = () =>{
        let ref = null
            if( name.toString().includes('link-') ){
                ref = object?.links?.data[index]?.on
                // ref = onValue
            // }else if( name !== 'links' ){
            //     ref = object[name]?.on
                // ref = onValue
            }else{ 
                ref =  object[name]?.on
            }
            // console.log('ref: ', ref)   
            if (ref){return true}
        }

    const checkValue = (name) =>{
        let val
            if(name.toString().includes('link-') ){
                val = currentPersona?.links?.data[index]?.data
            }
            if( name ==='personaTitle' ){
                val = currentPersona?.personaTitle
            }else{ 
                val =  currentPersona?.[name]?.data
            }
            // console.log('val: ', val)   
            // if (val){return val}
            return val
        }
        
        
        const toggleOn = async (e) =>{
            let {  value } = e.target
            if( name.toString().includes('link-')){
                // setOnValue(!value)
                // setOnValue(!currentPersona?.links?.data[index]?.on)
                // tempPersona?.links?.data[index]?.on = onValue
                // console.log('tempPersona?.links?.data[index]?.on: ', tempPersona?.links?.data[index].on)   
            //! setCurrentPersona({
            //             ...currentPersona,//...currentPersona.links,
            //         links:{
            //             // ...currentPersona.links,
            //             on: currentPersona?.links?.on,
            //             data:{
            //                 ...currentPersona?.links?.data,
            //                 [index]:{
            //                 on: onValue,
            //                 data: currentPersona?.links?.data[index].data
            //             }}
            //         }
            //!     })
                // setCurrentPersona({ 
                // ...currentPersona,
                //     links:{
                //         data:{
                //         [key]:{
                //             on: !value,    
                //             data: currentPersona?.links?.data[index]?.data
                //         }
                //     }
                //     }
                // });
                
            // if(name === 'personaTitle'){
            //     setTempPersona({ 
            //     ...tempPersona,
            //         [name]:{
                         
            //             data: tempPersona[name]?.data
            //         }
            //     });
            // }
            // const data = await API.updatePersona({...currentPersona})
                
            }else{
                setOnValue(!currentPersona[name]?.on)
                setCurrentPersona({ 
                ...currentPersona,
                    [name]:{
                        on: !value,    
                        data: currentPersona[name]?.data
                    }
                });
            const data = await API.updatePersona({...currentPersona})


        }
        console.log(`--------[${name} Toggle Check]-------`,onValue);
        updateAppData({...appData,currentPersona})
        // const data = await API.updatePersona({...currentPersona})
        
    }
    const postPicDetails = (pics) => {
    setPicLoading(true);
    if (pics === undefined) {
        console.log("pics undefined")
      return;
    } 
  
    console.log('--------[Saving Pic check]-------',pics);
  if (pics.type === "image/jpeg" || pics.type === "image/png"  ) {
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "QauRa");
      data.append("cloud_name", "dzxzdsnha");
      fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          setPic(data?.url?.toString());
          setCurrentPersona({
                    ...currentPersona,
                    [name]:{
                        on: true,
                        data: data.url.toString()
                    }
                })
          console.log('[Saved pic URL]:',data.url.toString());
          setPicLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPicLoading(false);
        });
    } else {
      setPicLoading(false);
      return;
    }
  }
    
    const handleChange =(e) =>{
        let { name, value } = e.target
        if( name === 'personaTitle'){
            // console.log('HandleChange--->tempPersona(before):',value)
            // setPersonaTitle(value)
             setCurrentPersona({ 
                ...currentPersona,
                    personaTitle:value
                });
        // } else if( name === 'links'){
        } else if( name.toString().includes('link-') ){
            let tempLinks = currentPersona?.links?.data
            tempLinks[index].on = tempLinks[index]?.on
            tempLinks[index].data = value
            setCurrentPersona({ 
                ...currentPersona,
                    links:{
                        on: true,
                        data: tempLinks
                    }
                    });
            // console.log('tempLinks-->', tempLinks[index].data)
        }
        else if(name === 'alt_pic'|| name === 'cover_pic'){
             if(!picIsUrl){
                postPicDetails(value)
                
            }else{
                setCurrentPersona({ 
                ...currentPersona,
                    [name]:{
                        on: onValue,    
                        data: value
                    }
                    });
            }
        }
        else{
            setCurrentPersona({ 
                ...currentPersona,
                    [name]:{
                        on: onValue,    
                        data: value}
                    });
        }
        setIsSaved(false)
        // console.log('HandleChange--->tempPersona(after):',tempPersona)
        updateAppData(...appData,currentPersona)
        // console.log('HandleChange--->appData:',appData)
       
    }

     const handleCancel= async (e) =>{
    //   console.log('[Cancel] tempPersona: ', tempPersona)
      if(name === 'personaTitle'){
        //   setCurrentPersona({
        //     ...currentPersona, 
        //     personaTitle: currentPersona?.personaTitle
        // })
        
      } else  if(  name.toString().includes('link-')){
            let tempLinks = currentPersona?.links?.data
            tempLinks[index].on = tempLinks[index]?.on
            tempLinks[index].data = value
            setCurrentPersona({ 
                ...currentPersona,
                    links:{
                        on: currentPersona.links.on,
                        data: [...tempLinks]
                    }
                    });
            // console.log('tempLinks-->', tempLinks[index].data)
        }else{
      setCurrentPersona({
        ...currentPersona,
        [name]: {...currentPersona[name]}
    })}
    //   updatePersonaDb({...tempPersona})
    //   console.log('[Cancel] currentPersona: ', currentPersona)
      setIsSaved(true)
    }

     const createPersona = async (obj) =>{
         try{
            const data = await API.createPersona(obj,userData?._id,personas)
    
                setCurrentPersona({...data})
                setPersonas([...personas,data])
                setIsCreated(true)
                // setnewPersona({...data})
        }catch(err){console.log(err)}   
    }
    
  const untitledCount = () =>{
    let ps = personas
    let count = ps.filter(p=>p.personaTitle.contains('untitled')).length
    return count
}
 
   
    const 
    handleSave = async (e) =>{
      console.log('[Save] currentPersona: ', currentPersona)
    //   if(!tempPersona.personaTitle){
    //       setCurrentPersona({
    //         ...tempPersona, 
    //         personaTitle:'untitled persona: #'+ untitledCount()
    //     })
    //   }else{
    //   setCurrentPersona({...tempPersona})}
    //   if(tempPersona?._id){
        const data = await API.updatePersona(currentPersona)
    // }
    //   console.log('[Save] currentPersona: ', currentPersona)
      if (data){
        setEdit(!edit)
        setIsSaved(true)
        }else{
            setPrompt('click again to confirm')
            console.log(prompt)
        }
    }
     const removeLink = async (i)=>{
       let links = currentPersona?.links?.data
       links?.splice(i,1)
       console.log('[item component] links--->: ', links)
        setCurrentPersona({
            ...currentPersona,
            links:{
                on: currentPersona?.links?.on ? currentPersona?.links?.on:true ,
                data: links
            }
        })
        updateAppData({...appData,currentPersona})
        const data = await API.updatePersona(currentPersona)
        setCurrentPersona(data)
        updateAppData({...appData,currentPersona})
    }

    useEffect(() =>{
        // setTempPersona({...currentPersona})

    //    setTempPersona({...currentPersona})
    },[currentPersona,onValue])
    
    // useEffect(() =>{
        // if(name === "personaTitle"){
        //     console.log('[load] personaTitle: ', value)
        // }
    // },[tempPersona, qrCodeData])
  return (
    <div style={{
        position:'relative',
        display:'flex', 
        flexDirection: name ==='personaTitle'?'row': 'column',
        justifyContent:'center',
        width:'100%'}}>
    {/* {currentPersona &&
   (  */}
   <span style={{
        display:'flex', 
        flexDirection:'row', 
        gap: '0.5rem',
        padding:'0.25rem',
        margin:'auto',
        justifyContent:name === 'personaTitle'|| name ==='QRname'?'center':'left', 
        alignItems:'center', 
        width: edit?'85%':'100%'}}>
                {/* {!edit && (name !=='links') && <b><u>{name}</u>:</b>} */}
            
            <div style={{display:'flex',flexDirection:'column', gap:'0.5rem', alignItems:'center'}}>
            {(!name.toString().includes('link-') && name !== 'personaTitle' && name !=="QRname") &&  
                <b><u>{name}</u>:</b>}  
                {view !=='create-persona' && (
    <div className='button-accent-hover'
        style={{
            cursor: 'pointer',
            // position:'absolute',
            // right:'0.5rem',
            // top: '0.5rem',
        
            padding: !edit?'0 0.125rem':'0.125rem 0.125rem 0  0.125rem',
            borderRadius: '5px',
            border: '2px solid #FFFFFF',
            boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
            width: '1rem',
            height: '1rem',
            backgroundColor: !edit?'rgba(253, 156, 0, 0.628)':'rgba(61, 255, 158, 0.628)'}} 
            onClick={()=>{
                if(!edit){setEdit(!edit)}
                if(edit){
                    if(isCreated){

                        handleSave()
                    }else{
                        createPersona({...currentPersona})
                    }
                    // setEdit(!edit)
                }
            }}
            >
            {!edit && <img width='15rem' height={'15rem'} src='/assets/pencil.svg' alt='edit item'/>}
            {edit && <img width='14rem' height={'14rem'}  src='/assets/save.svg' alt='save item'/>}
            </div>
)}
            <div style={{
                
                color: isSaved?'#000000':'red',
                width:'fit-content',
                fontSize:'0.65rem',padding:'0.125rem ',
                border:`2px solid ${isSaved?'#00FF00':'red'}`,borderRadius:'5px 0px',
                backgroundColor:'rgba( 255,255,255,0.5)',
                // backgroundColor:'rgba( 0,0,0,0.5)',
                backdropFilter: 'blur(5px)'
                }}>
                {!isSaved?'unsaved':'saved'}
            </div>
         {(name !=="personaTitle" && name !== "QRname"  && !edit) && 
            <b style={{padding:'0 0.125rem',border:'2px solid #000000',borderRadius:'4px', backgroundColor:'#FFFFFF',color:checkOn()?'limegreen':'red'}}>
                { checkOn()?'on':'off'}
                </b>}
        {/* {(name !=="personaTitle" && name !== "QRname"  && !edit) && <div>{ (name ==='links' ? currentPersona?.links?.data[index]?.on: currentPersona?.[name]?.on)?'on':'off'}</div>} */}
        {(name !=="personaTitle" && name !== "QRname" && edit) && 
            <span style={{display:'flex', flexDirection:'column',alignItems:'center', minWidth:'3rem'}}>
        {/* off  */}
                {checkOn()?'on':'off'}

        <label className="switch-sml" style={{marginTop:' 0.125rem'}}>
        
        {/* <input type="checkbox" value={onValue}  */}
       
        <input type="checkbox" //defaultValue={ name !== 'links' } 
            checked={checkOn()}
            // style={{}}
            onChange={async(e)=>{
                // setOnValue(!onValue)
                // toggleOn(e)
                if (name.includes('link-')){
                    let val = currentPersona?.links?.data[index]?.on
                    let data = currentPersona?.links?.data
                    let elem = data[index]
                    console.log('[data]---index:', index)
                    console.log('[data]---val:', val)
                    console.log('[data]---old:', data)
                    // setOnValue(!val)
                    elem = {
                        on:!val,
                        data:currentPersona?.links?.data[index].data
                    }
                    data[index] = {...elem}
                    // data = 
                    console.log('[data]---elem:', elem)
                    console.log('[data]---new:', data[index])
                    console.log('[data]---new data:', data)
                    setCurrentPersona({
                        ...currentPersona,//...currentPersona.links,
                        links:{
                        //    ...currentPersona?.links,
                            on: true,
                            data:{...data}
                            }
                        //     data:{
                        //         ...currentPersona?.links?.data,
                        //         [index]:{
                        //             on: onValue,
                        //             data: currentPersona?.links?.data[index].data
                        // }}
                    // }
                }
                )}
                else{
                    setOnValue(!onValue)
                    setCurrentPersona({...currentPersona,
                    [name]:{
                        on:onValue,
                        data: currentPersona[name].data
                    }
             })}
            const data = await API.updatePersona({...currentPersona})
             console.log('toggle on/off',data)  
            }}/>
            
            <span className="slider-sml round" style={{backgroundColor: checkOn()?'rgba(61, 255, 158, 0.628)':'red'}}>
                {/* {checkOn()?'on':'off'} */}
            </span>
        </label>
       {/* on */}
     </span> }
      
            </div>  

            { name.toString().includes('link-')  && (edit)
            && 
            // <span style={{display:'flex', flexDirection:'row'}}>
            <div style={{
                alignItems: 'center',
                cursor: 'pointer',
                padding: '0.125rem 0.25rem',
                borderRadius: '5px',
                border: '2px solid #FFFFFF',
                boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                backgroundColor: 'rgba(253, 0, 0, 0.628)',
                color:checkOn()?"#000000":'#ffffff'
                }}
                name={name}
                // value={name==='links'? currentPersona?.[name]?.data[index]?.on: currentPersona?.[name]?.on}
                value={checkOn()}
                onClick={(e)=>{
                removeLink(index)
                }}
            > <img height='10rem' width='10rem' src='/assets/close.svg' alt='delete item'/></div>
            // </span>
            }
       
        {/* <div style={{ */}


        {/* !----------- */}

        {/* {name !=="personaTitle" && name !== "QRname" && <div style={{
            cursor: 'pointer',
            padding: '0.125rem 0.25rem',
            width:'13px',
            borderRadius: '5px',
            border: '2px solid #FFFFFF',
            boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
            backgroundColor: checkOn()?'rgba(61, 255, 158, 0.628)':'rgba(253, 0, 0, 0.628)',
           color:checkOn()?"#000000":'#ffffff'
            }}
            name={name}
            value={name==='links'? currentPersona?.links?.data[index]?.on: currentPersona?.[name]?.on}
            onClick={async(e)=>{
                toggleOn(e)

            }}
            >{checkOn()? 'on': 'off'}</div>} */}


        {/* !----------- */}




             {/* {name =='personaTitle'?!currentPersona?.personaTitle &&<h6>persona title</h6>:''} */}
             {( name ==='personaTitle' && edit && !currentPersona?.personaTitle) &&<h6>persona title</h6>}
         <div style={{display:'flex', flexDirection: 'column'}}>
          {(edit &&  (name === 'alt_pic'||name === 'cover_pic')) && 
              <span style={{display:'flex', flexDirection:'row', alignItems:'center', gap: '0.5rem'}}>
                {/* {currentAsset[name]?'on': 'off'} */}
                upload
                    <label className="switch-sml" style={{marginTop:'0.5rem'}}>
                    
                    <input type="checkbox" defaultValue={picIsUrl} checked={picIsUrl}
                    key={name}
                    onChange={()=>{
                        setPicIsUrl(!picIsUrl)
                        }}/>
                        <span className="slider-sml round">

                        </span>
                    </label>
                    url
                    {/* {!currentAsset[name]?'on': 'off'} */}
                </span>
            // <label >    
            //     url?:
            //     <input value={picIsUrl} type='checkbox'
            //     onChange={(e)=>{
            //         setPicIsUrl(!picIsUrl)
            //     }}/>
            // </label>

            }
        {edit ?
        
          <TextField style={{width:'100%', minWidth: '150px'}} 
          placeholder={!picIsUrl&&(name === 'alt_pic' || name ==='cover_pic')?'': name.toString().includes('link-')?currentPersona?.links?.data[index]?.data:(view==='create-persona' && userData[name])?userData[name]:checkValue(name)} 
          defaultValue={!picIsUrl&&(name === 'alt_pic' || name ==='cover_pic')?'': name.toString().includes('link-')?currentPersona?.links?.data[index]?.data:(view==='create-persona' && userData[name])?userData[name]:checkValue(name)} 
        // value={checkValue(name)} 
        // value={picIsUrl&&(name === 'alt_pic' || name ==='cover_pic')?'':(name ==='links'?currentPersona?.links?.data[index]?.data:checkValue(name))} 
        name={name} type={checkType(name)} onChange={handleChange} key={props?.index} />
           
        :name === 'personaTitle'
        ?<h3>{currentPersona?.personaTitle}</h3>
        :<p className='persona-item-text' style={{display:'flex',maxWidth:'300px',textAlign:'right'}}>
            {value}
        </p>
    }
         </div>
    </span>
    <div style={{display:'flex',flexDirection:'row', gap:'0.5rem'}}>

    {((name === 'directLink'||  name.toString().includes('link-')|| name ==='phone'|| name ==='email') && view !=='create-persona'  && edit) &&
     <span className="button-accent-hover"
            style={{display: 'flex', alignItems:'center', gap:'0.5rem', margin:'auto'}}> 
            {/* <b><u>link persona to asset </u> </b> */}
               <div  
               style={{
                
                    display: 'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    width:'fit-content',
                    cursor: 'pointer',
                    padding: '0 0.125rem',
                    borderRadius: '5px',
                    border: '2px solid #FFFFFF',
                    boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                    padding: '0.5rem',
                    backgroundColor: showList?'rgba(61, 255, 158, 0.7)':'rgba(61, 255, 158, 0.628)',
                    backdropFilter: showList?'blur(6px)':'',
                    zIndex:'9999'
                }} 
                    onClick={()=> {
                if(!showList){

                    setShowList(true)
                }}}
                // setShowList(!showList)
            
            >
                <div style={{position:'relative',borderBottom:'2px solid #FFFFFF',paddingBottom:'0.25rem', width:'100%'}}>

                    link to QR
                    {showList&&<img  width='10rem'
                    className="button-accent-hover"
                    onClick={()=>{

                        setShowList(!showList)
                    }}
                    style={{cursor:'pointer',position:'absolute', right:'0.125rem', top:'0.125rem'}}
                    src='/assets/close.svg' alt='close link to QR options'/>}
                </div>
                
                <img width='55rem' src='/assets/LinkToQR.svg' alt='link to asset'/>
        {showList &&  <QROptions view='edit-persona' field={name} index={index}/>}
            </div>
            </span>
            
            }

            <div >
                {/* style={{gap: name ==='personaTitle'?'0.75rem':'1rem'}}> */}
        
</div>
      
    </div>
                    {(edit && view !== 'create-persona')&& <b 
                        style={{alignSelf:'center', cursor: 'pointer', fontSize: name ==='personaTitle'?'0.75rem':'1rem', marginTop:'0.5rem'}}
                        onClick={()=>
                            {handleCancel()
                            setEdit(!edit)}}
                            > <u>cancel</u> </b>}
        {/* {name !=='personaTitle'&&<hr
        style={{
            alignSelf: 'center',
            color: "#868686",
            backgroundColor: "#868686",
            height: '0.25px',
            width:"90%",
            marginTop:"0.5rem"
        }}
    />} */}
        {/* {name =='personaTitle'?!currentPersona?.personaTitle &&<hr
        style={{
            alignSelf: 'center',
            color: "#868686",
            backgroundColor: "#868686",
            height: '0.25px',
            width:"90%",
            marginTop:"0.5rem"
        }}
    />:''} */}

    {/* )} */}
    
      {(name !=='personaTitle' && name !=='QRname') && <hr
        style={{
            alignSelf: 'center',
            color: "#868686",
            backgroundColor: "#868686",
            height: '0.25px',
            width:"90%",
            marginTop:"0.5rem"
        }}
    />}

        
    </div>
  )
}

export default PersonaFieldComponent