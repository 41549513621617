import React, {useState, useContext, useEffect } from "react";

import * as API from '../utils/APICalls'
import { AppContext } from "../context/AppProvider";


const DeleteAsset = () => {
    const { updateAppData,assets, currentAsset,modalOpen ,personas, userData,
        setCurrentAsset,newAsset, setNewAsset} = useContext(AppContext);
    
    const deleteAsset = async() =>{
    //   console.log('[Delete Asset] (before):', assets)
      let as = assets
        as.splice(as.findIndex(function(a){
          return a?._id === currentAsset?._id;
        }), 1);
      
      console.log('[Delete Asset] (after):', assets)
     const data = await API.deleteAsset(currentAsset)
       updateAppData({
              modalOpen:false,
              modalView:''
          })
    }

  return (
    <div style={{color:"#ffffff",height: '100%',padding:'0.5rem'}}>
                     <h5> Would you like to delete:
                        <h2 style={{color:'#00FF00',border:'2px solid #00ff00', 
                          borderRadius:'10px', padding:'0.25rem 0.55rem', width:'fit-content', 
                          margin:'0.5rem auto'}}>
                          {currentAsset?.name?currentAsset?.name:currentAsset?._id}
                        </h2> 
                      </h5>
                   <button className="redirect-button" style={{backgroundColor:"#FF3D9E", margin: '0.75rem auto'
                   }}
                    onClick={()=>{
                      deleteAsset()
                      
                    }}
                  >
                      delete
                  </button>
                   </div>) 
                   }
 
export default DeleteAsset