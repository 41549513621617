import React, { useEffect, useState } from 'react'
import { Container, Modal,Box, Button, Input, Typography, TextField, Stack, IconButton, InputAdornment, Chip } from '@mui/material'
import CloseButton from '../../../components/CloseButton'
import * as API from '../../../utils/APICalls'

const UpdateModal = (props) => {
    const { setUpdateModalOpen, updateModalOpen, 
        listener, setListener,
        item, email, } = props

    const [ preferredName, setPreferredName ] = useState('')

    const handleNameUpdate = async () =>{
            setListener({
                ...listener,
                email,
                preferredName
            })
            localStorage.setItem('BOCUser', JSON.stringify({
                ...listener,
                email,
                preferredName
            }))
        try{
            const res = await  API.updateListenerName({email,preferredName})
            if ( res ){
                // setListener(res)
                setUpdateModalOpen(false)
                setPreferredName('')
            }
        }catch(err){console.log('[handleNameUpdate] error:',err)}
        // setUpdateModalOpen(false)
    }
   useEffect (()=>{

      },[listener])
  return (
     <>
        <Modal 
            open={updateModalOpen}
            // open={true}
            onClose={()=>setUpdateModalOpen(false)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '300px',
                    height:'180px',
                    backgroundColor:'#FFF',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem'
                    
                    }}>
                        <Box sx={{position:'absolute', top:'-0.5rem', right:'-0.5rem'}}>
                            <CloseButton func={setUpdateModalOpen} 
                                        value={updateModalOpen}
                            />
                        </Box>
                        <Stack sx={{gap:'0.5rem', width:'100%', }}>
                           
                            <h3 style={{fontWeight:'bold'}}>
                            What would do you go by?
                            </h3> 

                            <TextField label='preferred name'
                            value={preferredName}
                            onChange={(e)=>{setPreferredName(e.target.value)}}
                            />

                            <Button variant='outlined' 
                                sx={{position:'fixed', bottom:'0.5rem', right:'0.5rem'}}
                                onClick={async ()=>{
                                   await handleNameUpdate()
                                }}
                            >
                                update
                            </Button>
                        </Stack>
                    </Box>
                    
            </Container>
        </Modal>
        
    </>
  )
}

export default UpdateModal