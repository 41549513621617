import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";
import axios from 'axios'
import useWindowDimensions from "../hooks/windowDimensions";

import EnvRoute from '../middleware/envRoute';
import QROptions from './QROptions'
// import OnOffToggle from './OnOffToggle'

import * as API from '../utils/APICalls'
import { TextField } from '@mui/material';


const ENDPOINT = EnvRoute('prod'); 

const AssetFieldComponent = (props) => {
    /*
if view === "create" setTempPersona() && hide save button
if view === "edit" setTempPersona({...}) setCurrentPersona({...tempPersona}) const data = await API.updatePersona(currentPersona) 
*/ 
    //    const ENDPOINT = 'http://localhost:5003/api'
    // const ENDPOINT = 'https://qaura-api.onrender.com/api'
    const { height, width } = useWindowDimensions();

    const { object, value, name, sub, edit_on, type ,view} = props
    const [ edit, setEdit ] = useState(edit_on)
    const { userData, setAssets,appData, updateAppData,setCurrentAsset, currentAsset,newAsset,
        assetQR, setNewAsset, currentPersona,selectedQR } = useContext(AppContext);
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const [ tempAsset, setTempAsset ] = useState(newAsset)
    const [ tempQR, setTempQR ] = useState(qrCodeData)
    const [ isSaved, setIsSaved ] = useState(true)
    const [ loading, setLoading ] = useState(true)
    const [ picLoading, setPicLoading ] = useState(false)
    const [ pic, setPic ] = useState(false)
    const [ picIsUrl , setPicIsUrl ] = useState(false)

    //  console.log("object:",name)

    const checkType = () =>{
        if(name ==='name'||name ==='qr_id'||name ==='asset_type'||name ==='description'){
            return 'text'
        }
        if( name ==='isPhysical'|| name ==='isActivated' || name ==='useFieldMode'){
            return 'checkbox'
        }
        if(name ==='pic' ){
            if (picIsUrl) {
                return 'text'
            }else{ return 'file' }
        }
        
    }
    
    const postPicDetails =  (pics) => {
    setPicLoading(true);
    // const data = await API.savePic(pics)
    //     setPic(data)
    //         console.log("pic",data)
    if (pics === undefined) {
        console.log("pics undefined")
      return;
    } 
  
  if (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "image/gif" ) {
     try{
            const data = new FormData();
           data.append("file", pics);
           data.append("upload_preset", "TinCan");
           data.append("cloud_name", "dzxzdsnha");
           fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
             method: "post",
             body: data,
           })
             .then((res) => res.json())
             .then((data) => {
               setPic(data?.url?.toString());
               if(view === 'edit-asset'){

                   setCurrentAsset({
                             ...currentAsset,
                             pic: data?.url?.toString()
                         })
                   console.log('[AsssetFieldComp]:',data.url.toString());
                   setPicLoading(false);
                 }
               if(view === 'create-asset'){

                   setNewAsset({
                             ...newAsset,
                             pic: data?.url?.toString()
                         })
                   console.log('[AsssetFieldComp]:',data.url.toString());
                   setPicLoading(false);
                 }
                })
            
             .catch((err) => {
               console.log(err);
               setPicLoading(false);
             });
     }catch(err){console.log(err)}
    } else {
      setPicLoading(false);
      return;
    }
  }
     const handleChange =(e) =>{
        let { name, value ,files} = e.target
        // console.log('HandleChange--->tempPersona(before):',tempAsset)
        if(name === 'pic'){
            if(!picIsUrl){
                postPicDetails(files[0])
                
            }else{
                 if(view === 'edit-asset'){
                     setCurrentAsset({
                         ...currentAsset,
                         pic: value
                        })
                    }
                    if(view === 'create-asset'){
                        
                        setNewAsset({
                            ...newAsset,
                            pic: value
                        })
                    }
                }
            }else{
                if(view === 'edit-asset'){
                    setCurrentAsset({
                        ...currentAsset,
                        [name]: value
                    })
                    // setCurrentAsset({...currentAsset})
                    setIsSaved(false)
                }
                if(view === 'create-asset'){
                    setNewAsset({
                        ...newAsset,
                        [name]: value
                    })
                    // setCurrentAsset({...currentAsset})
                    setIsSaved(false)

                }
        }
        }
       
       

                    
    const handleItemSave = async() =>{
        try{
            const data = await API.updateAsset({...currentAsset})
            setIsSaved(true)
             const as  = await API.fetchAssets(userData)
            setAssets(as)
        }catch(err){console.log(err)}
    
    }

    useEffect(()=>{
      
    },[newAsset,tempQR,qrCodeData,pic,currentAsset,selectedQR])
 
  return (
    <div style={{display:'flex', flexDirection:'column',alignItems:'left', width:'100%'}}>
    {/* {currentPersona &&
   (  */}
   
   <div style={{display:'flex', flexDirection: width<600?'column':'row', gap: '0.5rem',padding:'0.25rem',
        justifyContent:width<600?'left':'center', alignItems:'center', alignSelf:'center', width: checkType()==='checkbox'?'150px':edit?'80%':'70%'}}>
                {/* {!edit && (name !=='links') && <b>{name}:</b>}
        <div style={{
            cursor: 'pointer',
            padding: '0.125rem 0.25rem',
            borderRadius: '5px',
            border: '1px solid #868686',
            backgroundColor:checkOn()?'rgba(61, 255, 158, 0.628)':'rgba(253, 0, 0, 0.628)'}}>{checkOn()? 'on': 'off'}</div> */}
        <div style={{display:'flex', flexDirection: width<600?'row':'column', gap:'0.25rem', alignItems:'center'}}>

       {/* <b style={{whiteSpace:'nowrap'}}><>{name==='qr_id'?'QR name':name==='name'?'asset name':name}</>:</b> */}
            
            <div style={{color: isSaved?'#000000':'red',
                width:'fit-content',
                fontSize:'0.65rem',padding:'0.125rem ',
                border:`2px solid ${isSaved?'#00FF00':'red'}`,borderRadius:'5px 0px',
                backgroundColor:'rgba( 255,255,255,0.5)',
                // backgroundColor:'rgba( 0,0,0,0.5)',
                backdropFilter: 'blur(5px)'
                }}>
                {!isSaved?'unsaved':'saved'}
        </div>
        </div>  
         <div style={{display:'flex', flexDirection: 'column'}}>

        {(edit && (name === 'pic')) && 
            <label >    
                {/* url?:
                <input value={picIsUrl} type='checkbox'
                onChange={(e)=>{
                    setPicIsUrl(!picIsUrl)
                }}/> */}
              
             <span style={{display:'flex', flexDirection:'row', alignItems:'center', gap: '0.5rem'}}>
       {/* {currentAsset[name]?'on': 'off'} */}
       upload
        <label className="switch-sml" style={{marginTop:'0.5rem'}}>
        
        <input type="checkbox" defaultValue={picIsUrl} checked={picIsUrl}
           key={name}
           onChange={()=>{
               setPicIsUrl(!picIsUrl)
            }}/>
            <span className="slider-sml round">

            </span>
        </label>
        url
        {/* {!currentAsset[name]?'on': 'off'} */}
     </span>
            
            </label>
            }
             {/* {(!edit && (checkType() === 'checkbox')) && <b style={{color:currentAsset[name]?'limegreen':'red', fontSize:'1.5rem'}}>{currentAsset[name]?'on':'off'}</b>} */}
            {(!edit && (checkType() === 'checkbox')) &&  <b style={{padding:'0 0.125rem',border:'2px solid #000000',borderRadius:'4px', backgroundColor:'#FFFFFF',color:currentAsset[name]?'limegreen':'red'}}>{currentAsset[name]?'on':'off'}</b>}
       
        {(edit && (checkType() === 'checkbox')) && 
             <span style={{display:'flex', flexDirection:'row', alignItems:'center', gap: '0.5rem'}}>
       {/* {currentAsset[name]?'on': 'off'} */}
       off
        <label className="switch" style={{marginTop:'0.5rem'}}>
        
        <input type="checkbox" defaultValue={currentAsset[name]} checked={currentAsset[name]}
           key={name}
           onChange={()=>{
                setCurrentAsset({...currentAsset,
                    [name]:!currentAsset[name]})
                updateAppData({...appData,currentAsset})
                // updateAssset({currentAsset[name]})
                 setIsSaved(false)
             console.log('currentAsset[name]',currentAsset)  
            }}/>
            <span className="slider round">

            </span>
        </label>
        on
        {/* {!currentAsset[name]?'on': 'off'} */}
     </span>
            }
        {(name!=='qr_id' && checkType() !== 'checkbox')
    
        && (edit
        ? <TextField className='asset-item-text' id='asset-item-text'
            style={{width:`${props?.width}%`, minWidth: '300px', maxWidth:'400px'}}
            type={checkType()}
            label={name}
            defaultValue={name!=='pic'?currentAsset[name]:''}
            name={name} 
            onChange={(e)=>{
                handleChange(e)
                // if(name === 'pic'){ postPicDetails(e.target.files[0])}
                // else{ 
                    // handleChange(e)
                //  }
            }}/>
        :currentAsset[name]
        ?(<p className='asset-item-text' 
            style={{ width:'200px',textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
        }}>{currentAsset[name]}</p>)
        :<p className='blink'>please provide</p>
        )}
        </div>
        
        <div style={{display:'flex', flexDirection:'column'}}>
            {edit && name ==='qr_id'&&<QROptions view={view}/>}

            {!edit && name ==='qr_id'&&(
                    <b>{object?.name ?object?.name:'please select a QR'}</b>)}
                {/* {(edit && view !== 'create') && <div style={{
                    cursor: 'pointer',
                    padding: '0 0.125rem',
                    borderRadius: '5px',
                    border: '2px solid #FFFFFF',
                    boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                    padding: !edit?'0 0.125rem':'0.125rem 0.125rem 0 0.125rem',
                    backgroundColor: !edit?'rgba(253, 156, 0, 0.628)':'rgba(61, 255, 158, 0.628)'}} 
                    onClick={()=>setEdit(!edit)}
                    >
                        <img width='55rem' src='/assets/LinkToPersona.svg' alt="link to persona"/>
                    </div>} */}
            <div style={{display:'flex',flexDirection: edit?'column':'row',}}>

        {!view.includes('create') && (
                    <div style={{ display:'flex',
                    alignItems:'center',justifyContent:'center', gap: '0.5rem'}}>
        
        <div className='button-accent-hover'
                style={{
                cursor: 'pointer',
                padding: '0 0.125rem',
                borderRadius: '5px',
                border: '2px solid #FFFFFF',
                boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                padding: !edit?'0 0.125rem':'0.125rem 0.125rem 0  0.125rem',

                backgroundColor: !edit?'rgba(253, 156, 0, 0.628)':'rgba(61, 255, 158, 0.628)'}} 
                onClick={()=>{
                    if(edit){
                        // if( name === 'pic'){
                        //     postPicDetails(pic)
                        // }
                        handleItemSave()
                    }
                    setEdit(!edit)}}
                >
                    {!edit && <img width='20rem' src='/assets/pencil.svg' alt="edit item"/>}
                    {edit && <img width='17rem' src='/assets/save.svg' alt="save item"/>}
                </div>
                {(edit && !view.includes('create')) && <div style={{cursor: 'pointer'}}
                        onClick={()=>{
                            setEdit(!edit)
                            setIsSaved(true)
                        }}>
                            cancel 
                        </div>}
            </div>
                            )}
            </div>
        </div>

    {/* <div style={{color: isSaved?'#000000':'red',fontSize:'0.75rem',padding:'0.125rem ',
    border:`2px solid ${isSaved?'#00FF00':'red'}`,borderRadius:'5px',
    backgroundColor:'rgba( 255,255,255,0.5)',
    // backgroundColor:'rgba( 0,0,0,0.5)',
    backdropFilter: 'blur(5px)'
    }}>{!isSaved?'unsaved':'saved'}</div> */}
    
    </div>
      {/* {(name !=='personaTitle' && name !=='QRname')&& !edit && <hr */}

         {(name !=='name' &&( view !=='home' && view !== 'edit-asset')) &&
         <hr
            style={{
                alignSelf: 'center',
                color: "#868686",
                backgroundColor: "#868686",
                height: '0.25px',
                width:"90%",
                marginTop:"0.5rem"
            }}
    />}
    {/* // :''}  */}
    {/* )} */}
    

        
    </div>
  )
}

export default AssetFieldComponent