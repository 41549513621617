import React,{useContext,useEffect,useState} from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Container, Divider, FormControl, Switch, Stack,
    InputLabel, MenuItem, Modal, Paper, Select, TextField, 
    InputAdornment, IconButton,Typography, OutlinedInput, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { AppContext } from "../context/AppProvider";
import * as API from '../utils/APICalls'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CloseButton from './CloseButton';
import InputTypeSelect from './InputTypeSelect';


const CreateGeoCondition = (props) => {
    const { appData,userData, setUserData, updateAppData,setSelectedQR, selectedQR, setActionError, actionError} = useContext(AppContext);

    const { qr } = props
    const [ showModal, setShowModal ] = useState(false)
    const [ gpOpen, setGpOpen] = useState(false)
    const [ regionTypeOpen, setRegionTypeOpen] = useState(false)
    const [ selectedPin, setSelectedPin ] = useState({})
    const [ name, setName ] = useState(null)
    const [ geoOn, setGeoOn ] = useState(false)
    const [ directScan, setDirectScan ] = useState(false)
    const [ radius, setRadius ] = useState(null)
    const [ payload, setPayload ] = useState(null)
    const [ payloadType, setPayloadType ] = useState('url')
    const [ region, setRegion ] = useState(null)
    const [ regionQuery, setRegionQuery ] = useState('')
    const [ regionOpen, setRegionOpen ] = useState(false)
    const [ regionList, setRegionList ] = useState([])
    const [ regionType, setRegionType ] = useState('')
    const regionTypes = [
        'location','zip','city','state','country',
        'exact ( lat, lon ) coords'
    ]

    const checkType = () =>{
     
        if( payloadType === 'image'){
            return 'file'
        }
        if(payloadType ==='file'  ||  payloadType ==='url' ||  payloadType ==='text'){
            return payloadType?.toString()
        }
 
    }
    const handleModalOpen = (e)=>{
        e.preventDefault()
        setShowModal(true)
    }

    const handleModalClose = (e)=>{
        e.preventDefault()
        setShowModal(false)
    }

    const handleGpClose = () => { setGpOpen(false);};
    const handleGpOpen = () => { setGpOpen(true);};
    const handleRegionTypeClose = () => { setRegionTypeOpen(false);};
    const handleRegionTypeOpen = () => { setRegionTypeOpen(true);};
    const handleRegionClose = () => { setRegionOpen(false);};
    const handleRegionOpen = () => { setRegionOpen(true);};
    const handleRegionSelect = (e) => { setRegion(e.target.value);};

    const handleGpChange = async(e) => {
   
        await setSelectedPin(e.target.value)
      
        console.log('[handleGpChange] selectedPin:',selectedPin)
 
    };
    const handleNameChange = async(e) => { 
        setActionError('')
         await setName(e.target.value)  
        };
    const handleRadiusChange = async(e) => {  
            setActionError('')
            await setRadius(e.target.value) 
         };
    const handlePayloadChange = async(e) => {  
        setActionError('')
        await setPayload(e.target.value)  
    };
    const handleRegionTypeChange = async(e) => {
        setActionError('')
        await setRegionType(e?.target?.value?.toString())  
     }
    const handleRegionChange = async(e) => {
        setActionError('')
        await setRegionQuery(e.target.value)  
     }

    const handleRegionSubmit = async(e) => { 
            
            var requestOptions = {
            method: 'GET',
            };

            fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${e}&format=json&apiKey=2489433b042f4e95acb6ee6f13bb447b`, requestOptions)
            .then(response => response.json())
            .then(result => {console.log(result)
                setRegionList(result.results)
            })
            .catch(error => console.log('error', error));

         
        
        };

    const handleSave = async() =>{
          try {

            if(!name){
                setActionError('please enter a name')
                return
            }

            if(selectedPin?.lat && ( radius < 0 || !radius )){
                setActionError('please enter radius for geo-pin')
                return
            }
            if((!selectedPin?.lat && radius<0 || !radius) && (!region)){
                setActionError('please select a geographical referrence')
                return
            }
            if(region && !regionType)
            if(!payload){
                setActionError('please select region type')
                return
            }
            const cond = {
                on:!geoOn,
                name: name,
                pin: {...selectedPin, range: radius },
                region: {...region, regionType:regionType?regionType:null},
                bypassPayload: payload,
                payload_type: payloadType,
                directScan: false,
                isPriority: false
            }
            let conditions =[]; 
        
            if( qr?.geoConditions?.length > 0 ){
                conditions = qr?.geoConditions
                conditions?.push(cond)
              
            }else{
                conditions = [cond]
            
            }
        console.log('[Save]:',cond)
    
        const data = await API.updateQRCode({
            ...qr,
            geoConditions: conditions})
            setSelectedQR(data)
            setSelectedPin({})
            setName(null)
            setGeoOn(false)
            setRadius(null)
            setPayload(null)
            setPayloadType(null)
            setRegion(null)
            setRegionQuery('')
            setRegionList([])
            setShowModal(false)
        }catch(err){
            setActionError('Error saving condition')
            console.log(err)}
            
    }
  return (
    <div style={{position:'relative'}}>
        <Button variant='outlined'
            onClick={handleModalOpen}
        >
            Create Geo-Condition
        </Button>
      
        <Modal open={showModal}>
            <Container sx={{display:'flex', flexDirection:'column',minHeight:'40vh',maxHeight:'80vh',width:'90%',
            position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%)',justifyContent:'center',alignItems:'center',overflow:'hidden', overflowY:'scroll',
        }} >
                <CloseButton func={setShowModal} val={!showModal}/>
                <Paper sx={{display:'flex', flexDirection:'column',height:'100%',boxShadow:'0px -3px 10px 5px rgba( 0, 0, 0, 0.6 )',
                    alignItems:'center', padding:'0.75rem',textAlign:'center',gap:'0.5rem', fontFamily:'Gilroy,sans-serif',
                    borderRadius:'5px', overflowY:'scroll'}} >
                   <Typography style={{fontWeight:'bold'}}>Create Geo-Condition</Typography>
                    <Box sx={{display:'flex', flexDirection:'column',gap:'0.5rem',
                        alignItems:'center',justifyContent:'center'}}>
                        
                        <TextField type='text' label='name' onChange={handleNameChange} required/>
                        <InputTypeSelect func={setPayloadType} val={payloadType}/>
                       {(payloadType === 'url') && (
                        <Box sx={{display: 'flex', flexDirection:'column'}}>
                            <b>Direct-Link</b>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                                <Typography>off</Typography>
                                <Switch  inputProps={{ 'aria-label': 'ant design' }} onChange={(e)=>{setDirectScan(!directScan); console.log('Direct link:',directScan)}} checked={!directScan} />
                                <Typography>on</Typography>
                            </Stack>
                        </Box>
                        
                        )}

                        {/* <TextField type='text' label='Alternate payload' onChange={handlePayloadChange} required/> */}

                        {( payloadType !== 'text' ) && <TextField className='qr-item-text' id='qr-item-text'
                            type={checkType()}
                            label={`Alternate payload ${checkType()}`}
                            name={name} onChange={handlePayloadChange}/>
                        }
                            
                        {(  checkType() === 'text') && <TextField className='qr-item-text' id='qr-item-text'
                            
                            label={`Alternate payload ${checkType()}`}
                            multiline
                            rows={4}
                            type={checkType()}
                           
                            name={name} onChange={handlePayloadChange}/>
                        }
                            
        
                           <Accordion  sx={{display:'flex',flexDirection:'column', width:'70%',minWidth:'300px',margin:'auto',textAlign:'center',alignItems:'center'}}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            // sx={{textAlign:'center'}}
                            >
                        via Geo-Pin
                            </AccordionSummary>
                            <AccordionDetails>
                            <Box sx={{position:'relative',display:'flex', flexDirection:'column',gap:'0.5rem',alignItems:'center',
                            justifyContent:'center',border:'1.5px solid rgba(24,118,210,1)', borderBottom:'none',
                            borderRadius:'5px', padding:'2rem 1rem',width:'70%', margin:'1rem auto'}}>
                           
                          <InputLabel sx={{position:'absolute', top:'-0.75rem', left:'50%',transform:'translate(-50%,0%)',backgroundColor:'#FFF',padding:'0 0.5rem'}}>
                            select Geo-Pin
                            </InputLabel>
                            <Select
                            // labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={gpOpen}
                            onClose={handleGpClose}
                            onOpen={handleGpOpen}
                            defaultValue={0}
                            min={0}
                            label="geo-pin"
                            name="geo pin"
                            sx={{width:'80%', minWidth:'100px'}}
                            onChange={(e)=>handleGpChange(e)}
                                
                            >
                               
                                    <MenuItem value={0}>
                                        {/* <em>0</em> */}
                                        geo-pin:
                                    </MenuItem> 
                                    {userData?.geoPins?.map((o,j)=>{
                                        return(
                                            <MenuItem value={o} key={j} sx={{flexDirection:'column'}}>
                                                <em>{o?.name}</em>
                                                <span style={{fontSize:'0.75rem'}}>
                                                    [ {o?.lat}/{o?.lon} ]
                                                </span> 
                                                <span style={{fontSize:'0.75rem'}}>
                                                    ({o?.city?.slice(0,3)}, {o?.state})
                                                </span> 
                                                <Divider />
                                            </MenuItem>
                                        )
                                    })

                                    }
                                
                                    </Select>
                                <TextField type='number' min={1} label='radius (in miles)' onChange={handleRadiusChange} required={selectedPin?true:false}/>
                                </Box>
                            </AccordionDetails>
                            </Accordion>
                          -or-
                          <Accordion  sx={{display:'flex',flexDirection:'column', width:'70%',minWidth:'300px',margin:'auto',textAlign:'center',alignItems:'center'}}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            // sx={{textAlign:'center'}}
                            >
                        via Region
                            </AccordionSummary>
                            <AccordionDetails>

                           <Box sx={{position:'relative',display:'flex', flexDirection:'column',gap:'0.5rem',alignItems:'center',
                            justifyContent:'center',border:'1.5px solid rgba(24,118,210,1)',  borderBottom:'none',boxShadow:'0px -2px 3px 1px rgba(24,118,210, 0.2 )',
                            borderRadius:'5px', padding:'2rem 1rem',width:'70%',maxWidth:'100%', margin:'1rem auto'}}>
                           
                          <InputLabel sx={{position:'absolute', top:'-0.75rem', left:'50%',transform:'translate(-50%,0%)',backgroundColor:'#FFF',padding:'0 0.5rem'}}>
                            select Region</InputLabel>

                             <Select
                            // labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={regionTypeOpen}
                            onClose={handleRegionTypeClose}
                            onOpen={handleRegionTypeOpen}
                            defaultValue={0}
                            min={0}
                            label="region type"
                            name="region type"
                            sx={{width:'80%', minWidth:'100px'}}
                            onChange={(e)=>handleRegionTypeChange(e)}
                                
                            >
                               
                                    <MenuItem value={0}>
                                        {/* <em>0</em> */}
                                       select type
                                    </MenuItem> 
                                    {regionTypes?.map((o,j)=>{
                                        return(
                                            <MenuItem value={o} key={j} >
                                                    {o}
                                                <Divider />
                                            </MenuItem>
                                        )
                                    })

                                    }
                                
                                    </Select>
                        <OutlinedInput type='text' label='Location' 
                            onChange={handleRegionChange} 
                            required
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e)=>handleRegionSubmit(regionQuery)}
                                    onMouseDown={(e)=>handleRegionSubmit(regionQuery)}
                                    edge="end"
                                    >
                                    <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                                }
                        />
                        {regionList && <Select
                            // labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={regionOpen}
                            onClose={handleRegionClose}
                            onOpen={handleRegionOpen}
                            defaultValue={0}
                            min={0}
                            label="region"
                            name="region"
                            sx={{width:'80%', minWidth:'100px'}}
                            onChange={(e)=>handleRegionSelect(e)}    
                            >
                               
                                    <MenuItem value={0}>
                                       {regionList?.length >0 ?`select 1 / ${regionList?.length}`: 'enter search above'}
                                    </MenuItem> 
                                    {regionList?.map((o,j)=>{
                                        return(
                                            <MenuItem value={o} key={j} sx={{flexDirection:'column'}}>
                                                <em>{o?.address_line1}</em>
                                                <em>{o?.address_line2}</em>
                                                <span style={{fontSize:'0.75rem'}}>
                                                ({o?.county})
                                                </span> 
                                                <span style={{fontSize:'0.75rem'}}>
                                                    [ {o?.lat}/{o?.lon} ]
                                                    {/* ({o?.city?.slice(0,3)}, {o?.state}) */}
                                                </span> 
                                                
                                                <Divider />
                                            </MenuItem>
                                        )
                                    })

                                    }
                                
                                    </Select>}
                          </Box>
                            </AccordionDetails>
                            </Accordion>
                          
                            {/* <p>(in miles)</p> */}

                            <Divider />
                       
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                            <Typography>off</Typography>
                            <Switch  inputProps={{ 'aria-label': 'ant design' }} onChange={(e)=>{setGeoOn(!geoOn); console.log(geoOn)}} checked={!geoOn} />
                            <Typography>on</Typography>
                        </Stack>
                      {actionError && <Typography>
                       {actionError}
                       </Typography>}
                        <Button variant='outlined' onClick={handleSave}>
                            Save
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </Modal>
        </div>
  )
}

export default CreateGeoCondition