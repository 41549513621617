import React, {useState, useContext, useEffect } from "react";

import { AppContext } from "../context/AppProvider";
import { Container } from "@mui/material";
import CloseButton from "./CloseButton";

const PicView = () => {
    const { updateAppData,assets, currentAsset,modalOpen ,personas, userData,
        setCurrentAsset,newAsset, setNewAsset} = useContext(AppContext);
    return (
        <Container style={{position:'relative',width:'100%', height:'100%',
                padding:'2rem', maxWidth:'100vw'}}>
                    <div style={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
               <CloseButton func={updateAppData} val={{
                    modalOpen:!modalOpen,
                    modalView:''
                }}/>     
                </div>
            <img width='100%'src={currentAsset?.LSAsset?.isLSA? currentAsset?.LSAsset?.url:currentAsset?.pic} alt='asset pic viewer'/>
        </Container>
    )
}

export default PicView