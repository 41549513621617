import React, { useEffect, useRef, useState, useContext } from "react";
import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";

import QRCodeStyling from "qr-code-styling";
import { borderExtension } from "../extensions/QRextensions";
import extensionOptions from '../defaults/ExtensionOptions';
import useWindowDimensions from "../hooks/windowDimensions";


const LSAQR = () => {
    // const cropperRef = createRef();
    const { height, width } = useWindowDimensions();
    const [ fileCopy , setFileCopy ] = useState(false)
    const [ QRImage, setQRImage ] = useState(false)

     const { userData, setAssets,appData, 
            updateAppData,setCurrentAsset, 
            currentAsset, newAsset, LSAsset, 
            setLSAsset, assetQR, setAssetQR, 
            setNewAsset, currentPersona,selectedQR, 
            rawQR, setRawQR, setQRFile } = useContext(AppContext);
    const ref3 = useRef(null);

    let qrOptions ={
            ...assetQR,
            // ...size
            // width:150, 
            // height:150,
            width: width * .4, 
            height: width * .4,
            type:'svg',
            margin: (width * .4)/30-2 
        }
    let qrCode = new QRCodeStyling(qrOptions);
    // useEffect(() => {
    //     // qrCode.applyExtension(extensionOptions)
    //     // qrCode.applyExtension(extensionOptions)
        
        
        if(document.getElementById('canvas3')){
            let canvas = document.getElementById('canvas3')
            canvas.innerHTML = ''
            // console.log("div.content: ",canvas)
        }
        
        // let updatedQR = qrCode?.applyExtension(borderExtension(extensionOptions));
        
        let raw_qr = qrCode?.getRawData('png')
        // let raw_qr = qrCode?.applyExtension(borderExtension(extensionOptions))?.getRawData('png')
        // let raw_qr = updatedQR?.getRawData('png')
        // let qr_file =  new File([raw_qr], "qr.png");
        
        qrCode?.append(ref3.current);
        // updatedQR?.append(ref3.current);
        // updatedQR?.append(ref3.current);
        // let raw_qr = updatedQR?.getRawData('png')
        // setRawQR(raw_qr)
        
        // console.log('rawQR', qr_file)
        
        // console.log("ref3.current: ", ref3.current)
        // setRawQR(raw_qr)
        
        //     }, [assetQR]);
        useEffect (()=>{
            
            // let raw_qr = qrCode?.getRawData('png')
           if(!rawQR){

               setRawQR(raw_qr)
           }
            // console.log('rawQR', rawQR)
       
        },[])//assetQR,rawQR])
        

  return (
      <>
     {/* <div style={{width:'100%'}}> */}
      <div ref={ref3} id='canvas3'/>
    {/* </div> */}
    </>
        
  )
}

export default LSAQR