import { Box, Container, Divider, Stack, Typography } from '@mui/material'
import React, { useState , useEffect} from 'react'
import CloseButton from '../../components/CloseButton'
import Copyright from '../../components/Copyright';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useWindowDimensions from "../../hooks/windowDimensions";

const CompleteOrder = () => {
  const { height, width } = useWindowDimensions();
   const [ hideCopy, setHideCopy ] = useState(false)
    
    const colors ={
        main:'#858585',
        accent: '#000',
     }
  return (
    <Container sx={{position:'relative',display:'flex', flexDirection:'column',textAlign:'center', 
        margin:'2rem auto', padding:'1rem',gap:'0.5rem', fontFamily:'Gilroy, sans-serif',
       width:'100%'
    }}>
      <Stack sx={{width:'80%',padding:'0.5rem', margin:'auto', 
        gap:'0.5rem',boxShadow:'0 0 5px rgba( 0, 0, 0,0.3)',
        border: '2px solid #757575', borderRadius:'10px', minHeight:'200px'}}>
          <img style={{borderRadius:'10px 10px 0 0'}}
            src='https://res.cloudinary.com/dzxzdsnha/image/upload/v1707683707/Rex_Entropy_Logo_Flat_LG_BLK_zuc1wp.png'/>
          {/* <u> */}
          <Typography variant='h3'sx={{fontFamily:'Protest Riot',fontWeight:'bold'}}>Order Complete!</Typography>
          {/* </u> */}
          <Typography variant='h4'sx={{fontFamily:'Protest Riot',fontWeight:'bold'}}>Thank you for your Support!</Typography>
          <Divider />
          <Typography variant='p'sx={{fontSize:'1.5rem'}}>Check your email for your confimation, and order processing details.</Typography>
          <Divider />
          <Box sx={{display:'flex', flexDirection: width<= 700?'column':'row',//justifyContent:'center', 
            padding:'0.5rem', margin:'auto',flexWrap:'no-wrap',
            alignContent:'center',alignItems:'center',width:'90%',gap:'1rem'}}>
                <span 
                  style={{
                    position:'relative',
                    cursor:'pointer',
                    // bottom:'-1rem',
                    // left:'25%',
                  //  transform:'translate(-10%,0)',
                    display:'flex',color:'#000' ,
                    backgroundColor:'#FFF',fontFamily:'Gilroy,sans-serif',
                    width:'fit-content',
                 
                    boxShadow:'0 0 8px rgba(0,0,0, 0.7), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
                    border:'3px solid #000', borderRadius:'30px',padding:'0.5rem' }}
                    onClick={()=>{
                      window.location.assign('/RexPlayer','_blank')
                    }}
                    className="blink-2"
                    >
                <b style={{ position:'absolute',left:'2rem', top:'0.25rem'}}>
                    The 
                </b>
                <h1>RexPlayer</h1>
                </span> 

                <Box className="blink-2"
                  onClick={()=>{window.location.assign('/partners/parallaxr','_blank')
                    }}
                  sx={{margin:'auto', alignItems:'center', display:'flex',width:'100%',cursor:'pointer',}}>
                      <img src={`https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg`}
                         width='100px'style={{opacity:'1', alignSelf:'center',margin:'auto'}} />
                  </Box>
        <h1  onClick={()=>{window.location.assign('/','_blank')}} style={{cursor:'pointer'}}className="text-shadow-02 blink-2">QauRa</h1>
          </Box>
      </Stack>
      <Box sx={{
                display:'flex',
                flexDirection:'column',
                position:'fixed',
                bottom:'0',
                left:'50%',
                transform:'translate(-50%,20%)',
                width:'50%',
                minWidth:'350px',
                maxWidth:'450px',
                height:hideCopy? '0':'8.5rem'
                }}>

                <Box sx={{position:'relative',alignSelf:'start',width:'100%', }} onClick={()=>{
                  setHideCopy(!hideCopy)
                  }}>

                {!hideCopy ? 
                <Box sx={{position:'absolute',left:'0.5rem',top:'0.5rem', zIndex:999}}>
                    <CloseButton /> 
                </Box>
                : <Box sx={{
                    display:'flex',
                    position:'absolute',
                    bottom:'-2px',
                    left:'50%',
                    transform:'translate(-50%,0)',
                    border:'2px solid #858585',
                    padding:'0.25rem 1.25rem',
                    backgroundColor:'#fff',cursor:'pointer',
                    alignItems:'center',
                    boxShadow: '0px -1px 7px 2px rgba(0,0,0,0.2)',
                    borderRadius:'5px 5px 0px 0px'}}>
                    <ArrowDropUpIcon />
                    about us
                    </Box>}
                </Box>
                
                {!hideCopy && <Copyright view='signupSheet' 
                hide={hideCopy}
                color={colors.main}
                shadow={true}
                /> }
                </Box>
    </Container>
  )
}

export default CompleteOrder