import React, {useState, useContext, useEffect } from "react";
import axios from 'axios'

import { AppContext } from "../context/AppProvider";
import AssetFieldComponent from "./AssetFieldComponent"
import CreateHMNIStickerAsset from "./CreateHMNIStickerAsset"
import CloseButton from './CloseButton'

import * as API from '../utils/APICalls'

import EnvRoute from '../middleware/envRoute';
import BuildLSAsset from "./BuildLSAsset";
import { Button, Container, Divider, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";

const ENDPOINT = EnvRoute('prod'); 

const defaultAsset = require('../defaults/AssetDefault.json')
const CreateAsset = () => {
    //   const ENDPOINT = 'http://localhost:5003/api'
    // const ENDPOINT = 'https://qaura-api.onrender.com/api'
    const { updateAppData,assets, setAssets, modalOpen ,userData,setCurrentAsset,
        setNewAsset,newAsset, setAssetQR,  assetQR,QRs, setQRs, appData} = useContext(AppContext);
    const [ isVcard, setIsVcard ] = useState(newAsset?.isVcard)
    const [ isCreated, setIsCreated ] = useState(false)
    const [ QRMessage, setQRMessage] = useState(false)
    const [ QROpen, setQROpen] = useState(false)
    const [ isQRCheck, setIsQRCheck] = useState(false)
    const [ assetTypeOpen, setAssetTypeOpen] = useState(false)
    const [ step, setStep ] = useState(0)

    const [isBuild, setIsBuild] = useState(false)
    const [actionError, setActionError] = useState('')

    const assetTypes = ['LSA','Hello Name Sticker','Physical']
    
     const untitledCount = () =>{
    let count = assets.filter(a=>a?.name.includes('untitled')).length
    return count
}
    const saveAsset = async (obj) =>{
         try{
            // const config = {
            //     headers: {
            //     "Content-type": "application/json",
            //     },
            // };
            // if(!obj?.owner){
            //     console.log('no persona owner?🤔 :', userData)
            // }
            // console.log('[saveAsset] obj?🤔 :', newAsset)

            // const { data } = await axios.post(
            //     `${ENDPOINT[0]}/asset/create`,
            //     {
            //         ...obj,
            //         owner: userData?._id,
            //         // personaTitle: obj?.personaTitle ? obj?.personaTitle:'untitled persona: #'+untitledCount() 
            //     },
            //     config
            //     );
            // if(newAsset?.asset_type ==='LSA'){

                const data = await API.createAsset({...newAsset,
                    isPhysical: newAsset?.asset_type === 'Physical'? true : false,
                },userData?._id,assets)
                await setAssets([...assets,data])
                await setCurrentAsset({...data})
                setIsCreated(true)
                 updateAppData({
                        modalOpen:false,
                        modalView:''
                    })
            // }
                // setNewAsset({...data})
        }catch(err){console.log(err)}   
    }
        const fetchQRs = async () =>{
        let temp
        try{
            const data = await API.fetchQRs(userData?._id)
            temp = data
            console.log('[CreateAssets] fetchQRs->',data)
            if (temp){
                setQRs(temp)
                updateAppData({...appData, QRs})
                setIsQRCheck(true)
            }else{
                setIsQRCheck(true)
                return null
            }
        }catch(err){console.log(err)}
    }
     const handleQRClose = () => { setQROpen(false);};
    const handleQROpen = () => { setQROpen(true);};

     const handleAssetTypeClose = () => { setAssetTypeOpen(false);};
    const handleAssetTypeOpen = () => { setAssetTypeOpen(true);};

     const handleAssetTypeChange = async(e) => {
   
        await setNewAsset({
            ...newAsset,
           asset_type: e.target.value
        })
      
        console.log('[handleGpChange] selectedPin:',newAsset)
 
    };
     const handleQRChange = async(e) => {
        let obj = e.target.value
        await setNewAsset({
            ...newAsset,
           qr_id: obj?._id
        })
        await setAssetQR({
            ...obj
        })
        updateAppData({
            ...appData,
            newAsset,
            assetQR,
        })
      
        console.log('[handleGpChange] selectedPin:',newAsset)
 
    };
      
    const completionCheck = (qr) =>{
        if( newAsset?.qr_id && newAsset?.asset_type && newAsset?.pic){
            return true
        }else return false
    }     

    useEffect(() => {
        window.scrollTo(0, 0)
        }, [])         

    useEffect(()=>{
    //    if(newAsset?.owner ==='')
            setNewAsset({
                // ...newAsset,
                owner: userData?._id
            })
        // setNewAsset ({...defaultAsset})
    },[])
    useEffect(()=>{
    
    },[step])

    useEffect(()=>{
        try{
            if(!QRs && !isQRCheck )
              {
                fetchQRs()
              }
        }catch(err){console.log(err)}
    },[QRs,appData])

    // useEffect(()=>{
    //    if(!newAsset?.qr_id){
    //     //    setNewAsset({
    //     //        ...newAsset,
    //     //        owner: userData?._id
    //     //    })
    //     // setActionError('no qr sselected')
    // }else{
    //        setActionError('')

    //    }
    //     // setNewAsset ({...defaultAsset})
    // },[newAsset?.qr_id])

    // useEffect(()=>{
    //     console.log('NewAsset :',newAsset)
    //     setNewAsset({
    //         ...newAsset,
    //         owner: userData?._id
    //     })
    //     // setNewAsset ({...defaultAsset})
    // },[newAsset])
    // },[NewAsset])
  return (
    //    <Container style={{flex:1,height:'100%',width:'100%',overflow: 'hidden',padding: '2rem 0rem',border:'3px solid #000',}}>
        <Container
    // className='left-content'
        style={{
            position:'relative',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width:'100%',
            minWidth:'300px',
            maxWidth:'96vw',
            height: '100%',
            minHeight:'13rem',
            // maxHeight:'40vh',
            borderRadius:'10px',
            backgroundColor:'rgba(256,256,256,1)',
            // backdropFilter: 'blur(5px)',
            // boxShadow: 'inset 0px 0px 10px 3px rgba(255, 255, 255, 0.5), 0px 0px 5px 3px rgba(0, 0, 0, 0.3)',
            border:'3px solid #000',
            padding:'0.25rem',
            overflow:'hidden',
            // overflowY:'scroll',
            justifyContent:'center',
            textAlign:'center',
            // margin:'1rem 1rem 1rem 1rem'
            // zIndex:'10'
            gap: '0.5rem',
            margin:'auto'
            
        }}  
        >
        <div style={{width:'100%', height:'3rem', backgroundColor:'#fff'}}>
            <h2>Create an asset</h2>
            <div style={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                <CloseButton func={updateAppData} val={{
                            modalOpen:!modalOpen,
                            modalView:''
                        }} />
            </div>
        </div>

    {newAsset && 
    <Container style={{
        position: 'relative',
        display: 'flex', 
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #000',
        borderRadius:'10px',
        borderBottom: '0',
        backgroundColor:'rgba(228,249,253, 1)',
        // backgroundImage: "url('/assets/bodybg.png')",
        backgroundAttachment:'fixed',
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        // boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.3)',
        boxShadow: 'inset -1px -3px 10px 5px rgba(0,0,0,0.8)',

        // backdropFilter:'blur(5px)',
        padding: '0.75rem',
        height: '100%',
        width:'100%',
        maxWidth: '100%',
        margin: 'auto',
        fontSize: '0.75rem',
        color: '#000000',
        overflow: 'hidden',
        // overflowX: 'scroll',
        overflowY: 'scroll' ,

    }}>
       
         {/* <div style={{width:'100%',display:'flex',flexDirection: 'column',
                justifyContent:'left'}}> */}
             {/* {newAsset && ,*/}
            {/* <span style={{display: 'flex', alignItems:'center', gap:'0.5rem'}}> <b><u>link persona to asset </u> </b>
               <div style={{
                   width:'fit-content',
                    cursor: 'pointer',
                    padding: '0 0.25rem',
                    borderRadius: '5px',
                    border: '2px solid #FFFFFF',
                    boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                    padding: '0.125rem 0.125rem 0  0.125rem',
                    backgroundColor: 'rgba(61, 255, 158, 0.628)'}} 
                // onClick={()=>setEdit(!edit)}
                >
                <img width='55rem' src='/assets/LinkToQR.svg'/>
            </div>
            </span> */}
           
             {/* } */}
         {/* </div> */}
        <div style={{display: 'flex', flexDirection:'column', justifyContent:'center',
            alignItems: 'left', width:'100%', height:'100%',
            overflow:'hidden', overflowY:'scroll', margin:'1rem 0.5rem',
            padding:'1rem', flex:1}}> 
           
            <div style={{ display:'flex', flexDirection:'column', gap:'0.5rem',margin: '0.75rem 0', alignSelf:'center', width:'100%'}}>
                {/* <u> */}
                 {step === 0 && <Select
                        // labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={QROpen}
                        onClose={handleQRClose}
                        onOpen={handleQROpen}
                        defaultValue={0}
                        min={0}
                        label="Select a QR"
                        name="qrs"
                        sx={{width:'80%', minWidth:'100px',margin:'auto'}}
                        onChange={(e)=>handleQRChange(e)}
                            
                        >
                            
                              <MenuItem value={0}>
                                    {/* <em>0</em> */}
                                    select a QR
                                </MenuItem> 
                            
                                {QRs ? QRs?.map((q,j)=>{
                                    // console.log('[qr]:', q)
                                    return(
                                        <MenuItem value={q} key={j} sx={{flexDirection:'column', maxWidth:'400px'}}>
                                            <b>{q?.name}</b>
                                            <span style={{fontSize:'0.75rem',textOverflow: 'ellipsis',maxWidth:'320px', 
                                                whiteSpace: 'nowrap', 
                                                overflow: 'hidden',}}>
                                                ({q?.payload})
                                            </span> 
                                        <span style={{fontSize:'0.75rem'}}>
                                             {q?.description} 
                                        </span> 
                                            <Divider />
                                        </MenuItem>
                                    )
                                })
                                :<Typography>No QRs available</Typography>
                                }
                            
                                </Select>}
               
                {step === 1 && <Select
                        // labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={assetTypeOpen}
                        onClose={handleAssetTypeClose}
                        onOpen={handleAssetTypeOpen}
                        defaultValue={0}
                        min={0}
                        label="qrs"
                        name="qrs"
                        sx={{width:'80%', minWidth:'100px',margin:'auto'}}
                        onChange={(e)=>handleAssetTypeChange(e)}
                            
                        >
                            
                              <MenuItem value={0}>
                                    {/* <em>0</em> */}
                                    select an asset type:
                                </MenuItem> 
                            
                                {assetTypes?.map((q,j)=>{
                            
                                    return(
                                        <MenuItem value={q} key={j} sx={{flexDirection:'column'}}>
                                            <em>{q !=='LSA'?q:'Lock Screen Asset (LSA)'}</em>
                                            {/* <span style={{fontSize:'0.75rem'}}>
                                                [ {q?.description} ]
                                            </span> 
                                            <span style={{fontSize:'0.75rem'}}>
                                                ({q?.payload})
                                            </span>  */}
                                            <Divider />
                                        </MenuItem>
                                    )
                                })

                                }
                            
                         </Select>}
               {step === 2 && <AssetFieldComponent
                    value={newAsset?.name}
                    object={newAsset}  name='name'
                    on={true}
                    edit_on={true}
                    viewTitle={true}
               
                    index={0} 
                     view='create-asset'
                     width={100}
                     />}
                {step === 2 && <AssetFieldComponent
                    value={newAsset?.description}
                    object={newAsset}  name='description'
                    on={true}
                    edit_on={true}
                    viewTitle={true}
               
                    index={0} 
                    width={100}
                     view='create-asset'
                     
                     />}
                

                      
              
                 {/* {newAsset?.personaTitle}  */}
                {/* </u> */}
                </div>  
            <div style={{
                    display:'flex', 
                    flexDirection:'column', 
                    gap:'0.5rem',
                    width:'100%', 
                    margin:'auto',
                    flex:1
                    }}>
                          {/* <AssetFieldComponent 
                                value={newAsset['qr_id']}
                                object={newAsset}  name={'qr_id'}
                                key={'qr_id'}
                                edit_on={true}
                                index={1}
                                 view='create-asset'
                                /> */}

                    {/* <InputLabel sx={{position:'absolute', top:'-0.75rem', left:'50%',transform:'translate(-50%,0%)',backgroundColor:'#FFF',padding:'0 0.5rem'}}>
                        qr-code
                        </InputLabel> */}
                       
           </div>
            {/* { Object.keys(newAsset).map((key,i)=>{ 
               
                if( key !== '_id' && key !== 'qr_id' && key !=='createdAt' && key !== 'updatedAt' 
                    && key !=='__v' && key !=='LSAsset' && key !=='asset_id'&& key !=='owner'&& key !=='name' ){

                        return(
                            <AssetFieldComponent 
                                value={newAsset[key]}
                                object={newAsset}  name={key}
                                key={i}
                                edit_on={true}
                                index={i}
                                 view='create-asset'
                                />
                        )
                    
                }
            })} */}

        </div>

    {/* {(isBuild && step === 1) && <BuildLSAsset />} */}
               {(newAsset?.asset_type === 'LSA' && step === 3)&& <BuildLSAsset view='create-asset'/>}
               {(newAsset?.asset_type === 'Hello Name Sticker' && step === 2 )&& <CreateHMNIStickerAsset view='create-asset'/>}
               {(newAsset?.asset_type === 'Physical' && step === 2 )&& 
                <AssetFieldComponent
                    value={newAsset?.pic}
                    object={newAsset}  name='pic'
                    on={true}
                    edit_on={true}
                    viewTitle={true}
                    key={1}
                    index={0} 
                    width={100}
                     view='create-asset'
                     
                     />}

        {actionError &&<h6>{actionError}</h6>}
        <Stack 
            sx={{display:'flex',flexDirection:'row',justifyContent:'space-evenly', width: '100%', margin:'2rem'}}>
           { step === 0 && <Button variant='outlined' onClick={()=>{

               setNewAsset()
               setAssetQR()
               updateAppData({
                ...appData,
                newAsset,
                assetQR,
                modalOpen:false,
                modalView:''
               })
            }}>
                Cancel
            </Button>}
           { step !== 0 && <Button variant='outlined' onClick={()=>{

                    setStep(step-1)
                
            }}>
                Back
            </Button>}
            <Button variant='outlined' onClick={()=>setStep(step+1)}>
                Next
            </Button>
        </Stack>
         {/* <button className="button save-button" style={{display:'flex', flexDirection:'column',alignItems:'center', color:'#000000', backgroundColor:'rgba(255,255,255,0.7)'}}
                onClick={(e)=>{
                    e.preventDefault()
                        if(newAsset?.qr_id){

                            setQRMessage('')
                            setIsBuild(!isBuild)
                        }else{
                            setQRMessage('please slelect a QR to build with')
                            console.log('NO QR!!!!!')
                        }
                    }}>
                    <img width='15px' src='/assets/hammer-solid.svg'
                    
                     />
                     build asset
                </button> */}
                {QRMessage?QRMessage:''}
           

               <hr/>

         {completionCheck() && <button className="button save-button " variant="outlined"
            id="save-button" 
            onClick={async () => {
               await  saveAsset(newAsset)
                console.log('[click] new assset:',newAsset)
            }}
                style={{display:'flex', flexDirection:'column',alignItems:'center',
                backgroundColor:'rgba(255,255,255,0.7)',
                color:'#000',margin:'1rem auto',padding:'0.25rem 0.25rem 0', 
                position:'relative', right:'0.5rem', top:'0.5rem'}}>
            <img className='blink' src="/assets/save.svg" style={{height:'35px'}} alt="save QR info"/>
           <b>
             save
            </b>
            </button>}
            
    </Container>
     } 
 </Container>
  )
}

export default CreateAsset