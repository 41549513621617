import React, {useState, useContext,useEffect } from "react";
import { AppContext } from "../context/AppProvider";
// var vCardJS = require('vcards-js');


const PersonaCardBack = (props) => {
    const persona = props.persona
    const view = props.view
    const VCData = props.VCData
    const name = (persona?.preferredName?.on && persona?.preferredName?.data) ? persona?.preferredName?.data : persona?.firstName?.data

    const { appData,userData, updateAppData,modalOpen,setModalOpen ,currentPersona, setCurrentPersona} = useContext(AppContext);
//   if (persona){setCurrentPersona({...persona})}
    const themeOptions={

    borderColor:'#000'
}

    const saveVCard = (data) =>{
        // vCard.saveToFile(`./${persona?.preferredName?.data?persona?.preferredName?.data:persona?.firstName?.data}.vcf`)
        console.log('[save vCard]: ',data)
        downloadToFile(data, `${name}vcard.vcf`, 'text/vcard')
    }

function downloadToFile(content, filename, contentType) {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
}

    const elemCheck = (elem) =>{
    // if( elem?.on && name === 'links' ){
    //     return
    // }else
     if( elem?.on ){
        return elem?.data
    }else{return null}
    }
     const linkCheck = (l) =>{
        if(l.indexOf('http://') === -1 || l.indexOf('https://') === -1){
            return 'https://'+ l
        }else{ return l}
    }
// setCurrentPersona({...persona})
  return (
    
     <div className={view !=='edit'?"flip-card-back":''}  style={{ borderRadius:'15px',
        border:`3px solid ${themeOptions.borderColor}`,
        // backgroundImage:"url(" +`${currentPersona?.cover_pic?.data}` + ")",
        // backgroundImage:"url(" +'/assets/Bodybg.png' + ")"
        // backgroundRepeat:'no-repeat',
        // backgroundSize:'cover',
        
        boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.5),0px 0px 5px 3px rgba(0,0,0,0.5)',
        overflow: 'hidden', overflowY:'scroll'
        ,minHeight: '300px', height: 'fit-content'}}>
            <section style={{minHeight:'300px',padding: '0.5rem',position:'relative'}}>
            <div className=' button-accent-hover' 
                style={{
                        display: 'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        position:'absolute',
                        right:'0.75rem',
                        top:'0.75rem',
            
                        alignItems:'center',
                        // alingSelf:'center',
                        width:'fit-content',
                        cursor: 'pointer',
                        padding: '0 0.125rem',
                        borderRadius: '5px',
                        fontSize:'1rem',
                        boxShadow: '0px 0px 5px 3px rgba( 255, 255 ,255 ,0.3)',
                        padding: '0.25rem',// 0.125rem 0  0.125rem',
                        border: '2px solid #FFFFFF',
                        backgroundColor: 'rgba(61, 255, 158, 0.628)'
                    }} 
                    onClick={()=>{
                        saveVCard(VCData)
                    }}
                    onTouchStart={()=>{
                        saveVCard(VCData)
                    }}
                    ><b>save</b>
                    <img className='blink button-accent-hover' width='30rem' src='/assets/address-card-solid.svg' alt='download Vcard'/>
                </div>
      {!persona?.firstName?.on ? <h1>{persona?.firstName?.data} {persona?.lastName?.data}</h1>:
      <h1>{persona?.preferredName?.data}</h1>
      }
     {!elemCheck(persona?.firstName) ? <p>( {persona?.preferredName?.data} )</p> :''}
      <p>{persona?.title?.data}</p>
      <h3> {persona?.organization?.data}</h3>
      <hr width='50%' style={{margin:'0.5rem auto'}}/>
    
      <p style={{width: '80%', margin:'0.75rem auto'}}>{persona?.about?.data}</p>
      {persona && Object.keys(persona).map((keyVal,i)=>{ 
                let links = persona?.links?.data
                // let name = item?.keyVals()[i] 
                // console.log("currentPersona[keyVal]: ",keyVal ,currentPersona[keyVal])
                // console.log("currentPersona?.links?.data.length ",currentPersona?.links?.data.length )
                if( keyVal === 'directLink'|| keyVal === 'email'|| keyVal === 'phone'|| keyVal === 'links'){
                    if(keyVal === 'links'&& persona?.links?.data?.length >=1 ){
                        // console.log("currentPersona['links']: ",key ,currentPersona['links'])
                        return(
                            <div key={'objElem'+i} style={{display:'flex',flexDirection:'column',listStyle:'none',
                                 justifyContent: 'left',textAlign: 'left', padding:'0.5rem'}}>
                            <ul style={{display:'flex',flexDirection:'column',listStyle:'none',
                                 justifyContent: 'left',textAlign: 'left'}}>
                                <b><u>links</u>:</b>
                                { persona?.links?.data?.map((l,j)=>{
                                // if (i !== 0){
                                //     console.log("currentPersona['links']---link---->: ",l)
                                //     console.log("currentPersona['links']--index---->: ",i)
                                    //    <li>{l}</li>
                                    if(elemCheck(l)){

                                        return (
                                             <div key = {'cardLink-'+j}>
                                              { elemCheck(l) && 
                                              <a style={{color:'#FFFFFF'}} //key={i}
                                              href={linkCheck(elemCheck(l))}
     
                                              >{elemCheck(l)}</a>
                                              }
                                              <br/>
                                                 {/* <PersonaFieldComponent 
                                                     value={l?.data}
                                                     object={currentPersona}  name={key}
                                                     on={currentPersona?.links?.data?.on}
                                                     key={i}
                                                     index={i}
                                                     /> */}
                                             </div>
                                        )
                                    }
                            //    }else{return}
                                })}
                            </ul>  
                            
                           
                        </div>
                        )
                    } 
                    if( keyVal === 'personaTitle' ){return}
                    //if(keyVal === 'alt_pic' || keyVal ===  'personaTitle' ){return}
                    else{
                        return(
                             <div key={i}>
                                {(keyVal !=='directLink' && elemCheck(persona?.[keyVal])) 
                                ? <span><b>{keyVal}: </b>{elemCheck(persona?.[keyVal])}</span>
                                :<a style={{color:'#FFFFFF'}} href={elemCheck(persona?.[keyVal])}>
                                    {elemCheck(persona?.[keyVal])}
                                    </a>}
                                <br />
                            </div>
                             
                        )
                    }
                }
                    
            
            })}
    </section>
    </div>
  )
}

export default PersonaCardBack