import React, {useEffect, useContext, useState} from 'react'
import { useParams } from "react-router-dom";
// import Radar from 'react-native-radar';
import * as API from '../utils/APICalls'

import RedirectHeader from './RedirectHeader'
import Modal from './Modal';
import PersonaCard from './PersonaCard';
import Persona from './Persona';
import { Box } from '@mui/material';



const FieldMode = () => {

    const [cards , setCards ] = useState()
    const id = useParams().id?.toString()

    // Radar.initialize(pubKey);

    const fetchPersonas = async () => {

        try{
            
            const personas = await API.fetchPersonas({_id:id})
            let tempCards = personas?.filter((p,i)=>{return p.isVcard === true})
            console.log('tempCards: ',tempCards)
            setCards(tempCards)
            console.log('tempCards: ',cards)
        }catch(err){
            console.log('[fetchPersonas] Error:', err) 
            return
        }
        // return(
        //     <>
           
        //     </>
        // )
    }
    useEffect(()=>{
       window.addEventListener("load", fetchPersonas)
        // if(!cards){
        //     fetchPersonas()
        // }
    },[])
    useEffect(()=>{
     
        if(!cards){
            fetchPersonas()
        }
    },[cards])

  return (
    <div className='fieldMode-container'
        style={{position:'absolute',
            top:'0', left:'0',
            width:'100%',maxWidth:'100%', 
            margin: 'auto',marginBottom:"10rem",
            // background:'url("assets/BodyBG2.png")'
            
            }}>
        <RedirectHeader />
    {/* <Modal className=''> */}
        <div style={{
            display:'flex', 
            flexDirection:'column', 
            color:"#FFFFFF",
            maxHeight:'100vh',
            height:'100vh',
            minHeight:'100%',
            overflow:'hidden',
            overflowY:'scroll', 
            gap:'1rem',
            // paddingBottom:'100px'
            }}>

        {/* FieldMode:  */}
        {/* {cards[0]?.preferredName?cards[0]?.firstName:''} */}
        {/* {fetchPersonas} */}
         {cards && cards?.map((c,i)=>{
                return(
                    <div>
                        <PersonaCard persona={c} view='view-persona' fieldMode={true} key={i}/>
                    </div>
                    // <p>{c.personaTitle}</p>
                )
            })}
            <Box style={{display:'flex',flex:1,position:'relative',height:'100px',marginTop:'200px'}}>&nbsp;</Box>
        </div>
    {/* </Modal> */}
        
        
        </div>
  )
}

export default FieldMode