import React, { useState, createContext } from "react";
import * as API from '../utils/APICalls'
import * as Order from '../utils/OrderLogic'
import * as helpers from '../utils/helpers'
// const Default_QR = require('../options_02.json')
const defaultQR = require('../defaults/BaseShopQR.json')
const orderDefault = require('../defaults/OrderDefault.json')
// Create a new context
export const StorefrontContext = createContext();



// Create a context provider component
export const StorefrontProvider = ({ children }) => {

    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ department, setDepartment ] = useState('')
    const [ notes, setNotes ] = useState('')
    const [ inquiryText, setInquiryText ] = useState('')
    const [ isFollower, setIsFollower ] = useState(true)
    const [ storefrontData, setStorefrontData] = useState({})
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ menuOpen, setMenuOpen ] = useState(false)
    const [ cartOpen, setCartOpen ] = useState(false)
    const [ framingOpen, setFramingOpen ] = useState(false)
    const [ productOptionModalOpen, setProductOptionModalOpen ] = useState(true)
    const [ view , setView ] = useState('home')
    const [ currentProduct , setCurrentProduct ] = useState(null)
    const [ currentOrder, setCurrentOrder] = useState(orderDefault)
    const [ selectedSize, setSelectedSize ] = useState('l')
    const [ buildStep, setBuildStep ] = useState(null)
    const [ subView , setSubView ] = useState('')
    const [ shop, setShop ] = useState(null)
    const [ owner, setOwner ] = useState(null)
    const [ items, setItems ] = useState(null)
    const [ customer, setCustomer ] = useState({})
    const [ billingInfo, setBillingInfo ] = useState({})
    const [ shippingInfo, setShippingInfo ] = useState({})

    const [ cart, setCart ] = useState({})
    const [ musicCart, setMusicCart ] = useState([])
  
    // const [ tempOrder, setTempOrder ] = useState([...t_ord])
    const [ tempOrder, setTempOrder ] = useState([])

    const createCart = async (item,type) =>{
      try{
        const init = ['init']
          const res = await API.createCart({
            shop: shop?._id,
            owner: shop?.owner?._id,
            type: type,
            status:'draft',
            isComplete: false,
            items:[item],
            // tracks: type === 'music'?[item]:''
          })

          if(res[0]){
            if(type === 'merch'){
              console.log('[createCart] res:',res[0])
              setCart({...res[0],items:[item]})
              setCurrentOrder({
                ...currentOrder,
                cart_id: res[0]?._id
              })
              updateStorefrontData({
                ...storefrontData,
                cart,
                currentOrder
              })
              // if(cart?._id){
  
                await generateOrder(res[0],currentProduct)
                return {...res[0],items:[item]}
            }else if(type === 'music'){
              console.log('[createMusicCart] res:',res[0])
              setMusicCart({...res[0],items:[item]})
              updateStorefrontData({
                ...storefrontData,
                musicCart,
              })
              return {...res[0],items:[item]}
            }

            // }
          }else{return}
      }catch(err){console.log('[createMusicCart] error:',err)}
      
  }

  const generateOrder = async (cart,item) => {
    // const item = currentProduct 
    console.log('[generateOrder] [cart]:',cart)
    // console.log('[AddToCart] pre-order click [qrCodeData]:', qrCodeData)
    // const cart_id = cart?.length > 0 ? cart[0]?.cart_id : await generatecart_id()
    var cart_id   
       
    try{
        const baseOrder = {
          shop: shop?._id,
          shopName: shop?.operating_name,
          cart_id: cart?._id,
          itemName: item?.name,
          item: item?._id,
          type: item?.tags?.includes('music') ? 'music':'shirt',
          size: item?.tags?.includes('music') ? "n/a" : selectedSize,
          price: item?.price,
          shirtType: item?.tags?.includes('music') ? "n/a" : "Bella + Canvas 3100" ,
          shirtColor: item?.tags?.includes('music') ? "n/a" : "white",
          printBase: item?.tags?.includes('music') ? "n/a": item?.printBase_url[0],
          printFile: helpers.itemNameFilter(item,"[ Base ]") ? item?.printBase_url[0] : item?.type === 'music'?'n/a':null,
          notes:"",
          qr_id: helpers.itemNameFilter(item,"[ Base ]") || item?.tags?.includes('music') ?"n/a":"",
          qr_pin: helpers.itemNameFilter(item,"[ Base ]") || item?.tags?.includes('music') ?"n/a":"",
          qr_pic: helpers.itemNameFilter(item,"[ Base ]") || item?.tags?.includes('music') ?"n/a":"",
          asset_url: helpers.itemFieldFilter(currentProduct,'tags','nameTag')?'':'n/a',
          asset_text: !helpers.itemFieldFilter(currentProduct,'tags','nameTag')?'n/a': currentOrder?.asset_text ? currentOrder?.asset_text : [],
          shippingAddress: {},
          status: "draft",
          // isComplete: true, 
          isFulfilled: false,
          isShipped: false,
          isActivated: false,
          owner: shop?.owner?._id,
          proof: item?.images[0],
          ip: ''//geoD?.IPv4
          // const getGeo = ()=>{

          //   return  geo?.IPv4
          // }

        }

        // await setCurrentOrder(baseOrder)
        // await updateStorefrontData({
        //     ...storefrontData,
        //       currentOrder
        //   })
          
          // console.log('[currentOrder]:',currentOrder)
        // await routeOrder(baseOrder)
          await createOrder({
              ...baseOrder,
              isComplete: item?.tags?.includes('music') ? true : false
            })
       
      }catch(err){console.log('[Order generation failed]: ',err)}
        
    }

const addToCart = async (cart,obj) =>{
  try{
    let tempCart = cart?.items
    tempCart?.push(obj)
    const {res} = await API.updateCart({...cart,items: tempCart})
    if (res){
        console.log('[addToCart]--res:',res )
        await setCart(res)
        await setCurrentProduct({})
        await updateStorefrontData({
            ...storefrontData,
            cart,
            currentProduct
        })
    }
  }catch(err){console.log('[addToCart] error:',err)}
  }

  const createOrder = async (ord) =>{
    try{

      const order = await API.createOrder(ord)
      if (order && cart?._id){
        await addToCart(cart,order)
         console.log('[ createOrder ] order:',order)
         await setCurrentOrder(order)
         await updateStorefrontData({
          ...storefrontData,
          currentOrder
         })
      }
    }catch(err){console.log('[createOrder] Error:',err)}
  }
   

    const  updateCart = (newData) => {
      setCart((prevState) => ([
        // ...prevState,
        ...newData
      ]));
  
    };
    const  updateTempOrder = (newData) => {
      setTempOrder((prevState) => ([
      //   ...prevState,
        ...newData
      ]));
  
    };

  const  updateStorefrontData = (newData) => {

    setStorefrontData((prevState) => ({
    //   ...prevState,
    //   ...newData,
        order: [...tempOrder],
        firstName,
        lastName,
        email,
        phone,
        department,
        notes,
        inquiryText,
        isFollower,
        cart,
        musicCart,
        modalOpen,
        menuOpen,
        view,
        shop,
        owner, 
        currentProduct,
        items,
        currentOrder,
        selectedSize,
        buildStep, 
        defaultQR,
        orderDefault,
        cartOpen,
        framingOpen, 
        customer,
        billingInfo, 
        shippingInfo, 
    }));
 
  };


  return (
    <StorefrontContext.Provider value={{ 
        storefrontData, 
        setStorefrontData,
        updateStorefrontData, 
        tempOrder,
        setTempOrder,
        updateTempOrder,
        createCart, 
        generateOrder,
        createOrder,
        firstName, 
        setFirstName,
        lastName, 
        setLastName,
        email, 
        setEmail,
        phone, 
        setPhone,
        department, 
        setDepartment,
        notes, 
        setNotes, 
        inquiryText, 
        setInquiryText,
        isFollower, 
        setIsFollower,
        cart,
        setCart,
        musicCart, 
        setMusicCart,
        updateCart,
        modalOpen, 
        setModalOpen,
         menuOpen,
        setMenuOpen,
        view, 
        setView,
        subView, 
        setSubView,
        cart, 
        setCart,
        shop, 
        setShop,
        currentProduct, 
        setCurrentProduct,
        items,
        setItems,
        currentOrder, 
        setCurrentOrder,
        selectedSize, 
        setSelectedSize,
        buildStep, 
        setBuildStep,
        owner, 
        setOwner,
        defaultQR,
        orderDefault,
        cartOpen, 
        setCartOpen,
        framingOpen, 
        setFramingOpen,
        customer,
        setCustomer,
        billingInfo,
        setBillingInfo,
        shippingInfo, 
        setShippingInfo 
        }}>
            
      {children}
    </StorefrontContext.Provider>
  );
};
