import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";
import * as API from '../utils/APICalls'

import Modal from './Modal'
import EnvRoute from '../middleware/envRoute';

const ENDPOINT = EnvRoute('prod'); 

const PersonaOptions = (props) => {
    const view = props.view
    const useCase = props.useCase
    const preOpen = props.preOpen
    const { appData, updateAppData,modalOpen, currentAsset,assetQR,setAssetQR,userData, setPersonas,personas,
            newAsset , setNewAsset,selectedQR , setSelectedQR} = useContext(AppContext);
    const [ showList, setShowList ] = useState(false)
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    // const [ selectedQR , setSelectedQR ] = useState(false)
    const fetchPersonas = async () =>{
       try{
           const data = await API.fetchPersonas(userData)
           if(data){
                console.log('[PersonaOptions] fetchPersonas->',data)
               await setPersonas(data)
            }else{console.log('[No Personas]')}
       }catch(err){console.log(err)}
    }


    useEffect (()=>{
      
    },[currentAsset])
    useEffect (()=>{
      
    },[personas])

    useEffect (()=>{
        if(!userData){
            console.log('[PersonaOptions] -> no user logged in')
        }
        fetchPersonas()
    },[userData])
  return (

    <div style={{
        alignItems:'center', justifyContent:'center',textAlign:'center', alignSelf:'center', minWidth: '150px',
        margin: '0.5rem auto',padding:'0.25rem', borderRadius:'5px',backgroundColor:'rgba(255,255,255,0.6)',backdropFilter: 'blur(3px)',
        outline:'2px solid #000'}}>

        <span style={{display: 'flex', flexDirection:'row', alignItems:'center', textAlign:'center',cursor: 'pointer',gap:'0.125rem',whiteSpace:'nowrap'}}
            onClick={()=>{setShowList(!showList)}}
            >
            <u style={{display:'flex', flexDirection:'row', width:'100%',textAlign:'center', 
                justifyContent:'center', gap:'0.25rem',
                alignItems:'center',marginBottom:'0.5rem',
                // borderTop:'0.75px solid #868686',
                zIndex:'9999', padding:'0.5rem', height:'1rem'
                    }}>
                {(view !== 'persona'  && useCase ==='persona')?<b>card </b>:<b>profile</b>}
                {/* {(view !== 'persona' && !showList && assetQR?.name && useCase ==='profile')?<b>profiles</b>:<b>profiles</b>} */}
            <img src={`/assets/arrow-${showList?'up':'down'}.svg`} style={{height:'0.45rem', alignSelf: 'center'}} alt="show persona list"/>
            </u>
        </span>
        {/* <Modal> */}
            {(showList && personas) && 
                <div style={{ display: 'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    margin:'auto',
                    alignItems:'center',listStyle:'none', gap:'0.25rem' }}>
                    {personas && personas?.map((q,i)=>{
                        return(
                        <div className="qr-option-li" value={q.name} key={i}
                        style={{display:'flex', marginLeft:'0', minWidth:'100%'}}
                        >
                        <div style={{border:'0.75px solid #868686',borderRadius:'4px',zIndex:'9999',fontSize:'0.75rem',
                        padding:'0.25rem', height:'1rem',width:'100%',overflow:'hidden',textOverflow: 'ellipsis', }}
                        onClick={()=>{
                            try{
                                
                                console.log(q)
                                if(view === 'edit-asset'){
                                    setAssetQR({
                                        ...assetQR,
                                        payload_type:'url',
                                        payload:`https://qaura.pro/persona/${q?._id}`})
                                    updateAppData({...appData,assetQR })
                                }
                               else if(view === 'edit-QR'){
    
                                   setSelectedQR({
                                       ...selectedQR,
                                       payload_type:'url',
                                           payload:`https://qaura.pro/profile/${userData?._id}`
                                       })
                                   updateAppData({...appData,selectedQR })
                           
                                }
                            }catch(err){console.log(err)}
                            
                            setShowList(false)
                            // updateQRCodeData({...q})
                        }}
                        >
                            {q.personaTitle}</div> 
                        </div>
                        )
                    })}
            </div>}
        {/* </Modal> */}
    </div>
  )
}

export default PersonaOptions