import React, { useEffect, useRef, useState, useContext } from "react";
import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";

import QRCodeStyling from "qr-code-styling";
import { borderExtension } from "../extensions/QRextensions";
// const TH_LOGO = require('../public/assets/TownHall_Icon_v1_2')
// import TH_LOGO from '../../public/assets/TownHall_Icon_v1_2.png'

export const ManagerQR = (props) => {
    // const [ url, setUrl ] = useState("https://RexEntropy.com");
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);

    const { appData,previewQR, setPreviewQR, selectedQR, defaultQR } = useContext(AppContext);
    const view = props.view
    const [ qr, setQR ] = useState(false)
    const [ id, setId ] = useState("0000001");
    const [ fixedUrl, setFixedUrl ] = useState(`http://localhost:3000/${id}`);
    const [ dynamicUrl, setDynamicUrl ] = useState(`https://RexEntropy.com`);
    const [ fileExt, setFileExt] = useState("png");
    const [ size, setSize ] = useState(false)
    // const [ dotType, setDotType] = useState('classy-rounded')
    const [ dotType, setDotType ] = useState('dots')
    const [ bgColor, setBgColor ] = useState('#fff')
    const [ dotColor, setDotColor ] = useState('#000')
    const [ borderColor, setBorderColor ] = useState('#4267b2')

    
    const sizes = {
        sm : {width:'150', height:'150'},
        md : {width:'250', height:'250'},
        lg : {width:'350', height:'350'}
    }
    const viewSizing = (s) => {
        const sizes = {
            sm : {width:'150', height:'150'},
            md : {width:'250', height:'250'},
            lg : {width:'350', height:'350'}
        }
        console.log('[viewSizing]:',sizes[s])
        setSize(sizes[s])
        // return sizes[s]
    }

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const extensionOptions = {
            round: qr?.shape ==='circle'?1 :0, // 0 - 1
            thickness: 60,
            // color: "#1513AB",
                // dasharray: '4 1',
            decorations: {
            // top: {
            //     type: "text",
            //     value: "Powered by QauRa",
            //     style: `font: .75em gilroy; fill: ${qr?.dotOptions?.color};`
                
            //     // style: "font: 30px sans-serif; fill: #1513AB;"
            // },
            // bottom: {
            //     type: "text",
            //     value: "©2023 RexEntropy LLC",
            //     style: `font: .75em gilroy; fill: ${qr?.dotOptions?.color};`
                
            // },
            // left: {
            //     type: "image",
            //     value: "./assets/Rex_Entropy_Icon_Glow.png",
            //     style: "width: 30px;  transform: translate(-15px, -15px);"
            //     // style: "font: 30px sans-serif; fill: #1513AB; letter-spacing: 10px;"
            // },
            // right: {
            //     type: "image",
            //     value:
            //     "./assets/Rex_Entropy_Icon_Glow.png",
            //     style: "width: 30px;  transform: translate(-15px, -15px);"
            // }
            },
            // borderInner: {
            // color: "#000",
            // thickness: 5,
            // // dasharray: '4 1'
            // },
            // borderOuter: {
            // color: "#000",
            // thickness: 7
            // // dasharray: '4 1'
            // }
        };

        // viewSizing('sm')
        
            // setSize({...sizes})
             useEffect (()=>{
            // if(!qr){
                // if(view ==='edit-QR'){

                //     updateQRCodeData({...selectedQR})
                //     setQR({...selectedQR})
                // }else{
                    
                //     updateQRCodeData({...defaultQR})
                //     setQR({...defaultQR})
                // }
            // }
            // if(view ==='home'){
            
                setPreviewQR({ 
                    ...qr,
                        width: 200,
                        height: 200
                    })
            // }
        },[])
        useEffect (()=>{
           if(view === 'dashboard-edit'){
                setQR({...selectedQR})
           }
        //    if(view === 'create-QR'){
        //     setQR(defaultQR)   
        //    }
        },[appData,selectedQR])
        
        useEffect (()=>{
           if(view === 'create-QR'){
                setQR({...defaultQR})   
           }
           else if(view === 'edit-QR'){
                setQR({...selectedQR})   
           }
        },[appData,qrCodeData])
        
        useEffect (()=>{
            if(!size){
                 setSize(viewSizing('sm'))
            }
            // if(view ==='home'){
            
                setPreviewQR({ 
                    ...qr,
                        width: 200,
                        height: 200})
            // }
        },[qr,size,selectedQR])
        
       
        // console.log('[sizes]: ', size)
        // setPreviewQR({...qr, width: "200", height: "200"})
//https://res.cloudinary.com/dzxzdsnha/image/upload/v1690071590/Origins_Expanded_Resize_Lo_mpoxfh.jpg?timestamp=1695172723572
        let qrOptions ={
        ...qr,
        // ...size
        width:view==='create-QR'? qr?.width:view.includes('dashboard')?'175':'250', 
        height:view==='create-QR'? qr?.height:view.includes('dashboard')?'175':'250'
        }

            // let qrCode = new QRCodeStyling({...qrOptions})
            let qrCode = new QRCodeStyling({...qrOptions})
        // let qrCode = new QRCodeStyling({...qr,size})
        
        // let qrCode = new QRCodeStyling({...previewQR})
        // setPreviewQR({...qr,size})
        // let prevQrCode = new QRCodeStyling({...previewQR})
        // console.log('[qrCode]: ', qrCode)
        // let qrCode = new QRCodeStyling(qr)
        // qrCode.update(document.getElementById('canvas'));
        
        useEffect(() => {
            // qrCode.applyExtension(extensionOptions)
          // qrCode.applyExtension(extensionOptions)

         
        if(document.getElementById('canvas2')){
            let canvas = document.getElementById('canvas2')
            canvas.innerHTML = ''
            // console.log("div.content: ",canvas)
        }
        // if(document.getElementById('canvas2')){
        //     let canvas = document.getElementById('canvas2')
        //     canvas.innerHTML = ''
        //     // console.log("div.content: ",canvas)
        // }
            
        //   qrCode.append(ref.current);
            // console.log("ref.current.id",ref.current.innerHtml)
            
            let updatedQR = qrCode?.applyExtension(borderExtension(extensionOptions));
            
            // if(view === 'home' || view === 'dashboard-single' ){

                qrCode?.append(ref2.current);
                // prevQrCode?.append(ref.current);
            //   qrCode?.update(document.getElementById("canvas"));
                console.log("ref2.current: ", ref2.current)
            // }
            // if(view === 'dashboard-asset'){

            //     qrCode?.append(ref2.current);
            //     // prevQrCode?.append(ref.current);
            // //   qrCode?.update(document.getElementById("canvas"));
            //     console.log("ref2.current: ", ref2.current)
            // }
          
        }, [qr]);
      
        // useEffect(() => {
        //   qrCode.update({
        //     data: qr?.fixedUrl
        //   });
        //   console.log('qr: ',qr)
        // }, [qr.fixedURL]);
// qrCode.applyExtension(extensionOptions)
        //   borderExtension({
        //     ...extensionOptions,
        //   })
        // );


  return (
      <>
    
    <div className="color-grad-blue-border blink"
        style={{ width:'fit-content', 
            margin:'auto',padding:'.5rem', boxShadow:"0px 0px 10px 3px rgba(0,0,0,0.6)",borderRadius:"15px", 
            border: "2px outset", backgroundColor: "#ffff"}} >
      <div ref={ref2} id='canvas2'/>
    </div>
      
      </>
  )
}
export default ManagerQR
