import React from 'react'

const LoadingSpinner = (props) => {
    const { statusText } = props
  return (
    <div>
        <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
            <div className='scaler'>

            <img className={'spinner'} src={'../assets/spinner-solid.svg'} 
            style={{height:'50px', alignSelf: 'center', 
            marginRight:'0.5rem'}} alt="loading"/>
            </div>
            {statusText}
        </div>
    </div>
  )
}

export default LoadingSpinner