import { Box, Button, Container, Link, Modal, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState, useContext } from "react";
import * as API from '../utils/APICalls'
import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";

import Signup from './auth/Signup';
import CloseButton from './CloseButton';



const ActivateQR = (props) => {
    const { appData,updateAppData,setUserData, userData,setActionError,actionError } = useContext(AppContext);
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const [ email, setEmail ] = useState()
    const [ pin, setPin ] = useState()
    const [ showHelp, setShowHelp ] = useState(false)
    const [ showModal, setShowModal ] = useState(false)
    const [ isProcessing, setIsProcessing ] = useState()

    const handleEmailChange = (e) =>{
            setActionError('')
            setShowHelp(false)
            setEmail(e.target.value) 
        }

    const handlePinChange = (e) =>{
            setActionError('')
            setShowHelp(false)
            setPin(e.target.value) 
        }

    const checkEmail = async () =>{
        try{
            const data = await API.userByEmail(email)
            if (data?.success) {
                setActionError('')
                console.log('[User By Email]:', data)
                setUserData(data?.user)
                updateAppData({...appData,userData})
                return true
            }else{
                setActionError('Email not in our system. Please signup first')
                setShowModal(true)
                return false
            }
        }catch(err){
            console.log(err)
            setActionError('Error checking email')
        }
    }

    const checkPin = () =>{
        if ( pin === qrCodeData?.pin ){
            return true
        }else{
            setActionError('Invalid pin')
            return false
        }
    }

  const submitActivation = async () =>{
        // console.log('[Activation]:',email , pin )
        setIsProcessing(true)
        if( email ){
            checkEmail()
        }else{ 
            setActionError('Please enter email')
            setIsProcessing(false)
            return
        }
        if( email && pin && checkPin() ){
            setActionError('')
            if( email === userData?.email){
                console.log('!!!!PIN CHECKS!!!!')
                //TODO Set QR to active
                try{
                    
                const data = await API.updateQRCode({...qrCodeData,
                    owner: userData?._id,
                    // payload:'https://qaura.pro',
                    isActivated:true
                })
                if (data){
                    console.log('updated QR data:',data)
                    setActionError('Successfully activated QR')
                    window.location.assign(`/assets/${data?._id}`)
                }else{
                    setActionError('Error activating QR')
                    setShowHelp(true)
                    setIsProcessing(false)
                }
                }catch(err){console.log(err)}

                if(userData?.email &&  email !== userData?.email){
                    setActionError('Email not in our system. Please signup first')
                    setShowModal(true)
                    setIsProcessing(false)
                return
            }
        }else{ 
            setActionError('Please enter pin')
            setIsProcessing(false)
            return
        }
    }
  }
  useEffect(()=>{

  },[qrCodeData,appData, userData])

  return (
    <Container sx={{ display:'flex',flexDirection:'column',
        gap: '0.75rem',justifyContent:'center', alignItems:'center',
        width:"100%", height:'100%',padding:'1rem',marginBottom:'3rem'}}>
        {/* <Box sx={{ dsiplay:'flex',flexDirection:'column'}}> */}

            <Typography>New Asset?</Typography>
            <Typography sx={{fontSize:'0.75rem'}}>( activate below )</Typography>
            <TextField type='email' label='email' onChange={handleEmailChange} required/>
            <TextField type='pin' label='pin' onChange={handlePinChange} required/>
           
            <Typography sx={{color:'red'}}>{actionError} </Typography>
            
            <Button variant='contained' onClick={async(e)=>{await submitActivation()}}>
                {isProcessing ?'Activating':'Activate'}
            </Button>
           {showHelp && <Typography sx={{color:'red',fontSize:'0.75rem'}}>
            Please contact <Link href='mailto:help@rexentropy.com'target="_blank">help@rexentropy.com</Link> for assistance</Typography>}
            
            <Modal open={showModal}>
                <Container sx={{position:'absolute',top:'50%',left:'50%',fontFamily:'Gilroy,sans-serif',
                transform:'translate(-50%,-50%)',display:'flex',
                    // flexDirection:'column',justifyContent:'center',margin:'auto',
                    // borderRadius:'10px'
                }}>
                        <CloseButton func={setShowModal} value={showModal} />
                    <Paper  className= "color-grad-blue-border "
                     sx={{ border: '3px solid',width:'70%', height:'70vh', margin:'auto',overflow:'hidden',textAlign:'center', overflowY:'scroll'
                    ,borderRadius:'10px',padding:'1rem',
                    }}>

                    <Signup view='activate qr' qr_id={qrCodeData?._id}/> 
                    </Paper>
                </Container>
            </Modal>
        {/* </Box> */}
    </Container>
  )
}

export default ActivateQR