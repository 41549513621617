import React, {useEffect,useContext} from 'react'
import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";
import useWindowDimensions from "../hooks/windowDimensions";


const ModalComponent = ({children},props) => {
  const { confirm }   = props
    const { appData, updateAppData,
           setModalOpen, modalOpen, selectedQR, setSelectedQR} = useContext(AppContext);
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
  const { height, width } = useWindowDimensions();

    useEffect(() => {
        window.scrollTo(0, 0)
        }, [])  

    useEffect (()=>{
        
      },[appData, modalOpen])

  // if (confirm){
    return(
 <>
 {!confirm ? <div className={!confirm?'home-modal':'confirm-modal' }
    style={{
      // padding:'1rem',
      // position:'absolute', left:'25%', top:'10%',
      width: width<=600 ? '90%':'70%',
      // maxWidth:'50vw', 
      maxHeight:'90vh',
      height: '100%',
      minHeight:'70vh',

      minWidth:'375px',
      overflow:'hidden',
      overflowY:'scroll',
  // overflowY: (appData?.modalView === 'create-persona'||  appData?.modalView === 'create-asset'|| appData?.modalView ==='info'|| appData?.modalView === 'create-QR' || appData?.modalView === 'pic-view')? 'scroll':'hidden',
  // overflowX: appData?.modalView ==='create-persona'? 'scroll':'hidden',
    // padding:'0.75rem',
    paddingBottom:'2rem',
    margin:'5rem auto',
    zIndex:99999}}>
      {/* style={{padding:'0.5rem'}}> */}
          <button className="modal-close-button button-accent-hover" 
          type="submit"
              onClick={()=>{
                  if( appData.modalView === 'create-QR'){setSelectedQR()}
                  updateAppData({
                      modalOpen:false,
                      modalView:''
                  })
                  
                  console.log('modal close1?',appData.modalOpen)
              }}
              style={{color:"#FFFFFF",top:'0.5rem'}}>
           <img src={`/assets/close-white.svg`} style={{height:'1.5rem', alignSelf: 'center'}} alt="Clear Current Asset"/>

             {/* x close */}
          </button>

          {children}
      </div>
    // )
  // }else{

    // return (
    :  <div className='confirm-modal' 
          style={{
            position:'absolute',
            left:0, 
            top:0,
            width:'100%',
            maxWidth:'100vw', 
            overflow:'hidden', 
            
            padding:'0.5rem',
            paddingBottom:'2rem'
            }} >
      {/* style={{padding:'0.5rem'}}> */}
          <button className="modal-close-button" 
          type="submit"
              onClick={()=>{
                  updateAppData({
                      modalOpen:false,
                      modalView:''
                  })
                  
                  console.log('modal close2?',appData.modalOpen)
              }}
              style={{color:"#FFFFFF"}}>
             x close
          </button>
          <div className='center'>

          {children}
          </div>
      </div>}
 </>
    )
  // }
}

export default ModalComponent