import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import * as mediasoupClient from 'mediasoup-client';
import EnvRoute from '../../../middleware/envRoute';
import { Box, Button, Stack, Typography } from '@mui/material';

const ENDPOINT = EnvRoute('prod');

const WebRTCComponent = (props) => {
  const { listener } = props;
  const [currentCameraIndex, setCurrentCameraIndex] = useState(0);
  const [iceConfig, setIceConfig] = useState(null);
  const [routerRTPCaps, setRouterRTPCaps] = useState(null);
  const [videoDevices, setVideoDevices] = useState([]);
  const socketRef = useRef();
  const deviceRef = useRef();
  const producerTransportRef = useRef();
  const consumerTransportRef = useRef();
  const producerRef = useRef();
  const consumersRef = useRef({});
  const [isConnected, setIsConnected] = useState(false);
  const localVideoRef = useRef();
  const [remoteStreams, setRemoteStreams] = useState({});

  const [retryCount, setRetryCount] = useState(0);
const [isRetrying, setIsRetrying] = useState(false);
const maxRetries = 5; // Maximum number of retry attempts
const retryInterval = 3000; // 3 seconds between retries

const connectWithRetry = async () => {
  setIsRetrying(true);
  if( !routerRTPCaps || !iceConfig ){
      const { routerRtpCapabilities, iceConfiguration } = await fetch(`${ENDPOINT[0]}/rtc-config`).then(res => res.json());
        setIceConfig(iceConfiguration);
        setRouterRTPCaps(routerRtpCapabilities);
        console.log('[iceConfig]:',iceConfig)
        console.log('[routerRTPCaps]:',routerRTPCaps?.codecs)
  }
  socketRef.current = io(`${ENDPOINT[0]}` //)
  , {
    transports: ['websocket'],
    reconnectionAttempts: maxRetries,
    reconnectionDelay: retryInterval,
  },(res)=>console.log('websocket',res));

  socketRef.current.on('connect', (socket) => {
    console.log('Connected to server');
    console.log('Socket ID:', socket.id);
    console.log('Namespace:', socket.nsp);
    setIsConnected(true);
    setIsRetrying(false);
    setRetryCount(0);
  });

  socketRef.current.on('connect_error', (error) => {
    console.error('Connection error:', error);
    setRetryCount((prevCount) => prevCount + 1);
    if (retryCount >= maxRetries) {
      console.error('Max retries reached. Unable to connect.');
      setIsRetrying(false);
    }
  });

  socketRef.current.on('disconnect', () => {
    console.log('Disconnected from server');
    setIsConnected(false);
  });

  // Other socket event listeners...
};

useEffect(() => {
  connectWithRetry();
  getVideoDevices();

  return () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }
  };
}, []);

useEffect(() => {
  if (isRetrying && retryCount < maxRetries) {
    const timer = setTimeout(() => {
      console.log(`Retrying connection (${retryCount + 1}/${maxRetries})...`);
      connectWithRetry();
    }, retryInterval);

    return () => clearTimeout(timer);
  }
}, [isRetrying, retryCount]);


//   useEffect(() => {
    
//     socketRef.current = io(ENDPOINT[0]);

//     socketRef.current.on('connect', () => {
//       console.log('Connected to server');
//       setIsConnected(true);
//     });

//     socketRef.current.on('disconnect', () => {
//       console.log('Disconnected from server');
//       setIsConnected(false);
//     });

//     getVideoDevices();

//     return () => {
//       socketRef.current.disconnect();
//     };
//   }, []);

  const getVideoDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    setVideoDevices(videoDevices);
  };

  const joinRoom = async (roomId, userId) => {
    try {
      
      deviceRef.current = new mediasoupClient.Device();
      await deviceRef.current.load({ routerRTPCaps });

      socketRef.current.emit('join-room', roomId, userId);

      socketRef.current.on('transport-created', async (params) => {
        producerTransportRef.current = deviceRef.current.createSendTransport(params);
        
        producerTransportRef.current.on('connect', ({ dtlsParameters }, callback, errback) => {
          socketRef.current.emit('connect-transport', { dtlsParameters, role: 'producer' }, callback);
        });

        producerTransportRef.current.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
          try {
            const { producerId } = await new Promise((resolve) => {
              socketRef.current.emit('produce', { roomId, userId, kind, rtpParameters }, resolve);
            });
            callback({ id: producerId });
          } catch (err) {
            errback(err);
          }
        });

        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: videoDevices[currentCameraIndex].deviceId },
          audio: true
        });
        localVideoRef.current.srcObject = stream;

        producerRef.current = await producerTransportRef.current.produce({ track: stream.getVideoTracks()[0] });
      });

      socketRef.current.on('new-consumer', async ({ producerId, id, kind, rtpParameters, producerUserId }) => {
        const consumer = await consumerTransportRef.current.consume({
          id,
          producerId,
          kind,
          rtpParameters,
        });

        consumersRef.current[producerId] = consumer;

        const stream = new MediaStream([consumer.track]);
        setRemoteStreams(prev => ({...prev, [producerUserId]: stream}));
      });

    } catch (error) {
      console.error('Error joining room:', error);
    }
  };

  const leaveRoom = (roomId, userId) => {
    socketRef.current.emit('leave-room', { roomId, userId });
    if (producerRef.current) {
      producerRef.current.close();
      producerRef.current = null;
    }
    Object.values(consumersRef.current).forEach(consumer => consumer.close());
    consumersRef.current = {};
    if (producerTransportRef.current) {
      producerTransportRef.current.close();
      producerTransportRef.current = null;
    }
    if (consumerTransportRef.current) {
      consumerTransportRef.current.close();
      consumerTransportRef.current = null;
    }
    setRemoteStreams({});
    if (localVideoRef.current && localVideoRef.current.srcObject) {
      localVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
      localVideoRef.current.srcObject = null;
    }
  };

  const toggleCamera = async () => {
    const newIndex = (currentCameraIndex + 1) % videoDevices.length;
    setCurrentCameraIndex(newIndex);

    if (producerRef.current) {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoDevices[newIndex].deviceId },
        audio: true
      });
      const track = stream.getVideoTracks()[0];
      await producerRef.current.replaceTrack(track);
      localVideoRef.current.srcObject = stream;
    }
  };

  const renderRemoteVideos = () => {
    return Object.entries(remoteStreams).map(([userId, stream]) => (
      <Box key={userId} sx={{ width: '200px', margin: '10px' }}>
        <video
          ref={el => { if (el) el.srcObject = stream; }}
          autoPlay
          playsInline
          style={{ width: '100%', height: 'auto' }}
        />
        <Typography variant="caption">{userId}</Typography>
      </Box>
    ));
  };

  return (
    <div>
      <Stack sx={{gap:'0.5rem', padding:'0.5rem'}}>
        <Box sx={{alignText:'center', justifyContent:'center'}}>
          <video ref={localVideoRef} autoPlay muted playsInline style={{width: '100%', maxWidth: '600px'}} />
        </Box>
       
        <Typography>Welcome {listener?.preferredName || 'listener' + listener?._id}</Typography>
       <Typography>
            Connection Status: {isConnected ? 'Connected' : isRetrying ? 'Retrying...' : 'Disconnected'}
            </Typography>
        <Box sx={{display:"flex", margin:'auto', gap:'0.5rem'}}>
          <Button variant='outlined' onClick={() => joinRoom('room1', listener?.preferredName || listener?._id)}>Join Room</Button>
          <Button variant='outlined' onClick={() => leaveRoom('room1', listener?.preferredName || listener?._id)}>Leave Room</Button>
          {videoDevices.length > 1 && (
            <Button variant='outlined' onClick={toggleCamera}>Switch Camera</Button>
          )}
        </Box>
        <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
          {renderRemoteVideos()}
        </Box>
      </Stack>
    </div>
  );
};

export default WebRTCComponent;