import React, {useState, useContext, useEffect } from "react";

import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";
import * as API from "../utils/APICalls"
import axios from 'axios'


import EnvRoute from '../middleware/envRoute';

const ENDPOINT = EnvRoute('prod'); 

const AssetListCard = (props) => {
    const asset = props?.asset
    const id = props?.id
    
    const { appData, updateAppData,modalOpen,currentAsset, setCurrentAsset, assetQR, setAssetQR } = useContext(AppContext);
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const [active, setActive] = useState(false)

    //  const ENDPOINT = 'http://localhost:5003/api'
    // const ENDPOINT = 'https://qaura-api.onrender.com/api'
    const user ={
        _id: '64a57f30cb8857d1c2c67620',
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0YTU5NjA4YWJlMWRkYzkwZGZiMWUxMyIsImlhdCI6MTY4ODU3MzQ0OCwiZXhwIjoxNjkxMTY1NDQ4fQ.aMCUSj2k4wczP3jjFqLa87qJRNA4djf1yRbRzcPQ0xA'
    }

    const activeElemCheck = (i) => {
        //!Change to ID
    
    return ( active === i ) ?'#00ff00':'#000'
    }

    const fetchQR  = async() =>{
        console.log("[fetching Assets]")
        const config = {
            headers: {
            Authorization: `Bearer ${user?.token}`,
            },
        };
        try{
            // const data  = await axios.post(`${ENDPOINT[0]}/qr/`,{_id: currentAsset?.qr_id}, config)
            const data  = await API.fetchQRByID( currentAsset?.qr_id)
            // console.log("fetchQR: ", data?.data)
            // updateQRCodeData(data?.data)
            setAssetQR(data?.data)
        }catch(err){console.log(err)}
        }
    const handleSelection = () =>{
        try{
             setCurrentAsset()
             setCurrentAsset(asset)
             setActive(id)
            // updateAppData({...appData,currentAsset})
            
                try{
                fetchQR()
                // console.log('[AssetListCard] qrCodeData:',qrCodeData)
                }catch(err){console.log(err)}
                
                console.log('click', asset)
            }catch(err){console.log(err)}
    }
    useEffect(()=>{
        if(currentAsset && !assetQR){
                fetchQR()
        }

    },[currentAsset,assetQR])

    useEffect(()=>{
      
    },[active,currentAsset])

  return (
    <div className="asset-list-card"
        style={{
            position:'relative',
            minWidth: '130px',
            width:'95%',
            maxWidth: '125px',
            height: '3rem',
            // minHeight:'fit-content',
            borderRadius:'5px 5px',
            // border:`1px solid #000`,
            border:'2px solid #000000',
            // borderTop:'none',
            // borderBottom:'none',
            alignItems: 'center',
            textAlign: 'left',
            padding: '0.125rem  0.25rem',
            margin: '0.25rem ',
            boxShadow: '0px 0px 3px 2px rgba(0,0,0,0.5)',
            cursor:"pointer",
            backgroundColor: currentAsset?._id === asset?._id ?"rgba(61, 255, 158, 0.628)":'#fff',
            backdropFilter: 'blur(5px)',
            overflow: 'hidden',
        }}
        onClick={async ()=>{
            // handleSelection()
            //  try{
            setCurrentAsset()
             await setCurrentAsset(asset)
            //  await setActive(id)
            // updateAppData({...appData,currentAsset})
            
                try{
                fetchQR()
                // console.log('[AssetListCard] qrCodeData:',qrCodeData)
                }catch(err){console.log(err)}
                
                console.log('click', asset)
            // }catch(err){console.log(err)}
        }}
        >
        <h5 style={{borderBottom:'1px solid #000',whiteSpace:'nowrap'}}>
            {asset?.name?asset?.name:'untitled'}</h5>
            <div id="hover-scroll-container" >  
                    <div id="hover-scroll-text">
                        <h6>{asset?.description?asset?.description:'no description'}</h6>
                       
                    </div>   
                 </div>
                 {/* <div style={{height:'1.25rem'}}> */}

                        <div style={{
                        position:'relative',
                        bottom:'0.5rem',
                        left:'0.25rem',
                        fontSize:'0.75rem', 
                        color: asset?.isActivated?'#00FF00':'red',padding:'0 0.25rem',
                        // marginBottom:'0.125rem', 
                        backgroundColor:"#000",
                        borderRadius:'0 3px',
                        
                        width: 'fit-content'}}>
                        <b >
                        
                            {asset?.isActivated?'active':'inactive'}
                        </b>
                        </div>
                        {asset?.isPhysical &&<img style={{position:'absolute', bottom:'0.125rem', right:'0.25rem'}}width='10rem' src='/assets/lock.svg' alt='locked asset'/>}
                {/* </div> */}
    </div>
  )
}

export default AssetListCard