export const menu = [
    {
        name:'THE WOODY BURGER',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Double Bacon Cheeseburger with Hot Link on top. Served with French Fries)',
        prices: [14],
        tags:['burger'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    }, 
    {
        name:'THE BOWNATOR (UPON AVAILABILITY)',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Double Bacon Cheeseburger, Ham, Onion, and Bell Pepper. Served with French Fries)',
        prices: [14],
        tags:['burger'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
    {
        name:'PHILLY CHEESESTEAK (UPON AVAILABILITY)',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Beefsteak, Onion, and Bell Pepper. Served with French Fries)',
        prices: [14],
        tags:['sandwich'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
    
    {
        name:'HAMBURGER',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Served with French Fries)',
        prices: [8],
        tags:['burger'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
    {
        name:'CHEESEBURGER',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Served with French Fries)',
        prices: [9],
        tags:['burger'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
    {
        name:'BACON CHEESEBURGER',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Served with French Fries)',
        prices: [10],
        tags:['burger'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
    {
        name:'DOUBLE BACON CHEESEBURGER',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Served with French Fries)',
        prices: [12],
        tags:['burger'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
    {
        name:'GRILLED BBQ CHICKEN SANDWICH',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Served with French Fries)',
        prices: [9],
        tags:['sandwich'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
    {
        name:'HOT LINK SANDWICH',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Served with French Fries)',
        prices: [9],
        tags:['sandwich'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
     {
        name:'CHOPPED CHEESE',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Served with French Fries)',
        prices: [12],
        tags:['sandwich'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
    {
        name:'GRILLED CHEESE',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Served with French Fries)',
        prices: [5],
        tags:['sandwich'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
    {
        name:'PEANUT BUTTER & JELLY',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'(Served with French Fries)',
        prices: [5],
        tags:['sandwich'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
   
    {
        name:'SWEET POTATO STRIPS',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'',
        prices: [6],
        tags:['side'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
    {
        name:'FRENCH FRIES',
        pic:'https://res.cloudinary.com/dzxzdsnha/image/upload/v1708377855/RoastedGarlicHummus_oksytr.jpg',
        descr:'',
        prices: [3],
        tags:['side'],
        rating:{
            isOn: true,
            ratings:[5,5,5,5,5]
        },
        isActive: true
    },
]