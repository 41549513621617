import React , { useState, useContext, useRef ,useEffect} from 'react'
import { Box, Button, Container, Divider, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import {  Modal, Tooltip } from '@mui/material';
import useWindowDimensions from "../../hooks/windowDimensions";

import { StorefrontContext, }  from "../../context/StorefrontProvider";

const PicFullView = (props) => {
    const { modalOpen, setModalOpen, cart, setCurrentProduct,currentProduct,
        shop, setCart,order, view, setView,subView , setSubView} = useContext(StorefrontContext);

    const pic = props.pic
    const { height, width } = useWindowDimensions();
    const [ showPic, setShowPic] = useState()
    // const [ showPic, setShowPic] = useState()

    const handlePicOpen = () => {
         props.func(true);
        // setModalView('')
    };

    const handlePicClose = () => {
         props.func(false);
        // setModalView('')
    };

  return (
     <Modal
        open={props?.value}
        onClose={handlePicClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
                <Container sx={{ position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height: '100%',
                    width: '95vw',
                    maxHeight:'95vh',
                    // maxWidth: width >= 750 ? '600px':'350px',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    display:'flex',
                    overflow:'hidden',
                    borderRadius:'8px',
                    p: 1,}}>
                        <CloseIcon onClick={handlePicClose}  color='#000'sx={{position:'absolute',right:'0.5rem',top:'0.5rem'}}/>
             
                <Box sx={{position:'relative',margin:'auto',width:'100%', height:'100%',justifyContent:'center',
                    overflow:'scroll', display:'flex', alignItems:'center',
                    maxWidth: width >= 750 ? '600px':'350px',
                    }}>
                    <img src={pic} style={{margin:'auto',width:'100%', }}/>
                </Box>
                </Container>
            </Modal>
  )
}

export default PicFullView