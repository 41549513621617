import * as React from 'react';
import { useEffect, useRef, useState, useContext } from "react";
import { SignupContext, SignupProvider }  from "../context/SignupProvider";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Rating from '@mui/material/Rating';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from './Copyright';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SignupOrderOption from './SignupOrderOption';
import ConfirmSignupModal from './ConfirmSignupModal';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ShareIcon from '@mui/icons-material/Share';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import * as API from '../utils/APICalls'
import InquirySendModal from './InquirySendModal';
import { AppBar, CardMedia, Tooltip } from '@mui/material';

import * as vars from '../utils/vars'


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function AlixInWonderland() {
    

 const { signupData, 
        setSignupData,
        updateSignupData, 
        tempOrder,
        setTempOrder,
        updateTempOrder,
        firstName, 
        setFirstName,
        lastName, 
        setLastName,
        email, 
        setEmail,
        phone, 
        setPhone,
        department, 
        setDepartment,
        notes, setNotes, 
        inquiryText, 
        setInquiryText,
        isFollower, 
        setIsFollower} = useContext(SignupContext);
 const [ orderObj, setOrderObj ] = useState({})
//  const [ firstName, setFirstName ] = useState('')
//  const [ lastName, setLastName ] = useState('')
//  const [ email, setEmail ] = useState('')
//  const [ phone, setPhone ] = useState('')
//  const [ department, setDepartment ] = useState('')
//  const [ inquiryText, setInquiryText ] = useState('')
//  const [ isFollower, setIsFollower ] = useState(true)
    const [ inquiry, setInquiry ] = useState({})
    const [  orderView,setOrderView ] = useState(true)
    const [ showAbout, setShowAbout ] = useState(true)
    const [ open, setOpen ] = useState(false);
    const [ isSubmitting , setIsSubmitting ] = useState(false)
    const [ vCardData, setVCardData ] = useState()
    const [ actionError, setActionError ] = useState('')


  const [snackOpen, setSnackOpen] = React.useState(false);
  /*/----------------------------//
    Campaign Details
//----------------------------*/

const owner = '65cebbf3cc3c6b916336ee63'
const owner_email ='alixtalley@gmail.com'
const owner_firstName ='Alix'
const owner_lastName ='Talley'
const owner_phone = '(919)710-9792'
// const owner_pic = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1706284713/Alix-Headshot_hdwrga.jpg'
const owner_pic = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1707066484/Alix-Headshot-02_pw7go7.jpg'

const operating_name  = 'Alix-In-Wonderland!'

const ownerObj ={

    firstName : {on:true, data:owner_firstName},
    lastName : {on:true, data:owner_lastName},
    organization: {on:true, data:operating_name},
    email: {on:true, data:owner_email},
    phone: {on:true, data:owner_phone},
    alt_pic: {on:true, data:owner_pic},
    directLink:{on:true, data:['https:/qaura.pro/partners/alix-in-wonderland']}
}
const campaingName  = 'Signup Sheet'
const type= 'bake sale'

const infoHTML = " <h2 style='font-family:Glass Antiqua;'>Hi Everyone!🖐️ </h2><br/> <b>&nbsp; &nbsp;My name is Alix</b>, and I love to make simple food <b>absolutely delicious!</b> I would love to bring my obsession for all things tasty to you. There's <b>much more to come</b>, but have a look and see what I have to offer.</b> <br/><br/>&nbsp; &nbsp;Take a trip with me down the rabbit hole of scrumptious flavor and <b>let me feed you!</b> </b><br/> <br/> <b>Thanks for stopping by!</b>"

const items = vars.items


   const fetchVCard  = async(ownerInfo) =>{
        // console.log('fetchvCard persona: ', persona)
        if(ownerInfo){
            const data = await API.fetchVCard(ownerInfo)
            console.log('fetchvCard data: ', data)
            setVCardData(data)
            // setVCardData(data?{...data}:fetchVCard())
            console.log('fetchvCard VCardData: ', vCardData)
        }
    }

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };



const orderTemplate = () =>{
    return items?.map((e,i)=>{
        return ({
        item_type: e?.type,
        size: e?.sizes[0],
        quantity:0

    }) })
}

const getAvg = (values) => {
    return values?.reduce((m, x, i) => m + (x - m) / (i + 1), 0)
}
const saveVCard = (data) =>{
    // vCard.saveToFile(`./${persona?.preferredName?.data?persona?.preferredName?.data:persona?.firstName?.data}.vcf`)
    console.log('[save vCard]: ',data)
    downloadToFile(data, `${ownerObj?.firstName?.data}-${ownerObj?.lastName?.data}-vCard.vcf`, 'text/vcard')
}

const downloadToFile = (content, filename, contentType) => {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
}
//TODO MAKE FUNCTION TO GET CAMPAIGN DETAILS FROM DB TO BUILD ITEM LIST

const handleAboutHide =(e)=>{
    setShowAbout(!showAbout)
}
    const handleInquirySubmit =  (e) =>{
        e.preventDefault(e);
        setIsSubmitting(true)

        // console.log('inquiry:', inquiryText)
        // const data = new FormData(e.currentTarget);
            setInquiry({...inquiry,
                owner_email,
                owner,
                operating_name,
                campaingName,
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone:  phone,
                department: department,
                // firstName: data.get('firstName'),
                // lastName: data.get('lastName'),
                // email: data.get('email'),
                // phone: data.get('phone'),
                // department: data.get('department'),
                isFollower: isFollower,
                inquiry: inquiryText,
                isInquiry: true,
                // tempOrder: tempOrder
            })
        try{
            
            if(inquiry.firstName && inquiry.lastName && inquiry?.email && inquiry?.inquiry){
                const inq_res = API.saveSignup(inquiry)
                if( inq_res ){
                    window.location.assign(`/thanks/${owner_email}`)
                 //    console.log("inq_res", inq_res )
                }
            }
            setIsSubmitting(false)

        }catch(err){
            setIsSubmitting(false)

            console.log(err)
        } 
   
    }
    const handleInquiryChange = (e) =>{
       setInquiryText(e.target.value)
        // console.log('inquiry:', inquiry)

    }
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true)
    // const data = new FormData(event.currentTarget);
    // setOrderObj({
    //     ...orderObj,
    //     owner_email,
    //     owner,
    //     operating_name,
    //     campaingName,
    //     firstName: firstName,
    //     lastName: lastName,
    //     email: email,
    //     phone:  phone,
    //     department: department,
    //     // firstName: data.get('firstName'),
    //     // lastName: data.get('lastName'),
    //     // email: data.get('email'),
    //     // phone: data.get('phone'),
    //     // department: data.get('department'),
    //     isFollower: isFollower,
    //     inquiry: inquiryText
    //     // tempOrder: tempOrder
       
    // })
    if( firstName === ''|| lastName === '' ||
        email  === '' || phone  === ''){
            alert('Please complete or re-enter your contact information')
        setIsSubmitting(false)
        }
    else{

        try{
    
            updateSignupData({ ...signupData,
                owner_email,
                owner,
                operating_name,
                campaingName,
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone:  phone,
                department: department,
                isFollower: isFollower,
                inquiry: inquiryText,
                order:[...tempOrder],
                tempOrder
            })
            console.log('Submit tempOrder',  tempOrder);
            console.log('signupData', signupData);
            setOpen(!open)
            setIsSubmitting(false)
        }catch(err){
            setIsSubmitting(false)
            setActionError('Order failed to submit')
            console.log(err)  
        }
    }
  };
  
    useEffect(() => {
        window.scrollTo(0, 0)
        if(!vCardData){
            fetchVCard(ownerObj)
        }
    }, [vCardData])

    useEffect(() => {
        
    }, [signupData,orderObj,tempOrder])

  return (
    <SignupProvider>
        <Container  sx={{position: 'relative', 
                 width:'100%',height:'fit-content',p:0}}>

        <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs" className='signup-container' >
              <AppBar position="sticky"
                sx={{background:"rgba(255,255,255,1)",boxShadow:'0px 1px 5px 3px  rgba(0,0,0,0.5)', 
                top: '0px', left:'0px', maxWidth:'100vw', width:'100%',
                backdropFilter:'blur(6px)', display:'flex',position:"sticky",
                flexDirection:' row', justifyContent:'center'
                // background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 15%, rgba(255,255,255,1) 100%)',
                }}>

                <Tooltip title="Follow on Instagram">
                    <Link href="#" target='_blank'>
                        <InstagramIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}}  />     
                    </Link>
                </Tooltip>

                    <Tooltip title="Follow on Facebook">
                        <Link href="#" target='_blank'>
                            <FacebookIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Copy to clipboard">

                        <Link href="#"  onClick={(e)=>{
                            // let val=ownerObj?.directLink?.data.toString()
                            let val='https:/qaura.pro/partners/alix-in-wonderland'
                            console.log('url:',val)
                            
                            // navigator.clipboard.writeText(val);
                            // alert(val,'\nurl copied to clipboard')
                            navigator.clipboard
                                .writeText(val)
                                .then(() => {
                                    alert(`${val} successfully copied`);
                                })
                                .catch(() => {
                                    alert("something went wrong");
                                });
                            handleSnackClick()
                            }} >

                        <ShareIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                        {/* <Snackbar
                            anchorOrigin={'bottom'} 
                            open={snackOpen}
                            onClose={handleSnackClose}
                            message="url copied to clipboard!"
                        
                        /> */}
                        </Link>
                    </Tooltip>
                    <Tooltip title="Download contact vCard">

                <Link href="#" label='save contact to phone' onClick={()=>{
                    console.log('click',vCardData)
                    // if(vCardData){
                        saveVCard(vCardData)
                        // }
                    }}>
                     <ContactPhoneIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                </Link>
                    </Tooltip>
            </AppBar>
            <CssBaseline />
            <Box 
            // component='img'
                sx={{position:'absolute', top:'0px', left:'0px', 
                // backgroundColor:'rgba(203, 244, 245, 0.8)', 
                background: 'rgb(255,255,255)',
                background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 15%, rgba(0,0,0,1) 100%)',
                width:'100%',height:'225px',zIndex:0, overflow:'hidden',
                boxShadow:'inset 0px -3px 10px 5px rgba(255,255,255,0.8),0px -2px 5px 3px rgba(0,0,0,0.5)', 
                opacity:1,borderBottom:'3px solid #000',
                marginBottom:'1.5rem'
            }}
            // src ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1705938116/Orzo_fkhl2x.jpg'
                >
          

            <Box 
            component='img'
                sx={{position: 'absolute', top:'0px', left:'0px', 
                backgroundColor:'rgba(203, 244, 245, 0.8)',
                 width:'100%',height:'auto',minHeight:'240px',zIndex:-1, opacity:'0.8',
                boxShadow:'inset 0px -3px 10px 5px rgba(255,255,255,0.8),',//,0px -2px 5px 3px rgba(0,0,0,0.5)'
                
                }}
                src ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1705938116/Orzo_fkhl2x.jpg'
                >
            </Box>
            </Box>

            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent:'center',
                gap: '0.5rem'
            }}
            >
            <Avatar sx={{ bgcolor: 'secondary.main', width:'150px', height:'150px',
            border:'3px solid #000',marginBottom:'-1rem', boxShadow:'0px 0px 15px 5px rgba(0,0,0,0.8)'}} 
            src={owner_pic}>
            </Avatar>
            <Typography component="h1" variant="h5" 
            sx={{zIndex:1 , color:"#000", backgroundColor:'#FFF', fontFamily:'Gilroy',
                    padding:'0.25rem 0.75rem', borderRadius:'10px 10px 0 0', 
                    boxShadow:'0px -4px 3px 1px rgba(0,0,0,0.3)',marginTop:'0rem'}}>
               <b> Greetings from </b>
            </Typography>

            <Box sx={{ display:'flex', flexDirection:'column', gap:'0.5rem', 
            borderRadius: '10px ',
            border:'2px dotted #000',
            // borderBottom:'none',
            
            backgroundColor:'#fff',
            justifyContent:'center',
            p:2, marginTop:'-1rem'
            }}>

            <Box component='img'
            src='/assets/BodyBG_alpha.png'
            sx={{ position:'absolute', top:0, left:0, zIndex:-1, height:'100%',maxWidth:'100vw', width:'100%',
            backgroundAttachment:'fixed'
        }}

            >
            </Box >

            <Typography component="h1" variant="h4" sx={{fontFamily:'Glass Antiqua'}}>

            <b>{operating_name}</b>
            </Typography>
            {/* <Typography component="h2" variant="h5">
            Feel free to place an order
            </Typography> */}
            
             <Box sx={{position:'relative', padding: showAbout ? '0.75rem':'0rem 1rem', border: '1px dashed grey', 
             justifyContent:'center', borderRadius: '10px', width: !showAbout?'fit-content':'100%' ,margin: 'auto'}}>
                    <IconButton
                        aria-label="close"
                        onClick={handleAboutHide}
                         sx={{
                            position: showAbout?'absolute':'relative',
                            right: showAbout? 8:0,
                            top: showAbout? 8:0,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        >
                            {showAbout?<CloseIcon />:<div style={{display:'flex',flexDirection:'column'}}>
                                🖐️
                                <MoreHorizIcon />
                            </div>
                            }
                        </IconButton>
                {showAbout &&  <Typography component="p" sx={{fontSize:'0.85rem', textAlign:'left',textIndent: '1rem'}} dangerouslySetInnerHTML={{ __html:infoHTML}}>
                     
                    
                {/* {infoHTML} */}
                {/* &nbsp; &nbsp;<b>Hi All! 🖐️ My name is Alix</b>, and I’m a scrub tech here at Duke Raleigh. <b>Please take a sample</b> of my <b>homemade Roasted Garlic Hummus </b>and<b> Apple Chicken Salad</b>!
                <br/>
                <br/>
                 &nbsp; &nbsp;I make these and many more delicious dishes in my home and sell them to close friends and family. I would like to extend my clientele and bring my love for food to all I can. 
                <br/>
                <br/>
                &nbsp; &nbsp;I'm currently working on a website that will showcase all I have to offer.
                <br/>
                <br/>
                If you are interested in these two items for purchase, <b>please fill out an order !</b>
                <br/> 
                <br/> 
                <b>Thanks so much!</b> */}
              
                </Typography>}
             </Box>

             <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                <Typography>Deli</Typography>
                <Switch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={(e)=>{setOrderView(!orderView)}} checked={!orderView} />
                <Typography>Catering/Inquiry</Typography>
            </Stack>
            {!orderView && <TextField
                    placeholder="What do you have in mind?"
                    name='inquiry'
                    value={inquiryText}
                    onChange={handleInquiryChange}
                    multiline
                    rows={6}
                    // maxRows={10}
                    sx={{width:'100%'}}
                    />}
            {orderView && <Box sx={{display: 'flex', flexDirection:'column', 
                    margin:'0.5rem auto', alignItems:'center',justifyContent:'center'}}>

                { items && items.map((o,i)=>{

                    return ( 
                        <Accordion  key={i} sx={{ width:'300px',margin:'auto'}}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >

                            {/* {o?.type} |  ( 16oz. )  X  1 */}
                            {o?.type} |  
                            <Rating name="half-rating" defaultValue={getAvg(o?.rating?.ratings) } precision={0.5} />
                            {/* {tempOrder[i]?.size ? tempOrder[i]?.size:'___'}  X  { tempOrder[i]?.quantity? tempOrder[i]?.quantity:'___'} */}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box
                                    component="img"
                                    sx={{
                                        height: 'auto',
                                        width: 270,
                                        // maxHeight: { xs: 233, md: 167 },
                                        maxWidth: { xs: 300, md: 250 },
                                        borderRadius: '15px 15px 0px 0px',
                                        margin:'auto',
                                        padding: '0.5rem'
                                    }}
                                    alt= {o.type}
                                    src= {o.pic}
                                    />

                                <Box sx={{ p: 2, border: '1px dashed grey', justifyContent:'center' }}>

                                {o?.descr}
                                <SignupOrderOption option={o} index={i} userObj={orderObj}/>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )})
                }
                </Box>}
            <Box component="form" //noValidate 
                // onSubmit={orderView? handleSubmit : handleInquirySubmit } 
                sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    onChange={(e)=>setFirstName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    onChange={(e)=>setLastName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                     onChange={(e)=>setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    name="phone"
                    label="Phone"
                    type="phone"
                    id="phone"
                    autoComplete="phone"
                     onChange={(e)=>setPhone(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    name="department"
                    label="Department"
                    type="text"
                    id="department"
                    autoComplete="department"
                     onChange={(e)=>setDepartment(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    name="notes"
                    label="Notes"
                    type="text"
                    id="notes"
                    autoComplete="notes"
                     onChange={(e)=>setNotes(e.target.value)}
                    />
                </Grid>
                
                
                <Grid item xs={12} >
                    <FormControlLabel sx={{margin:'auto'}}
                    control={<Checkbox value={isFollower} onChange={(e)=>{setIsFollower(!isFollower)}} checked={isFollower} color="primary"/>}
                    label="I want to receive information, marketing promotions and updates via email."
                    />
                </Grid>
                </Grid>
                <React.Fragment>
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={orderView? handleSubmit : handleInquirySubmit } 
                    disabled={isSubmitting?true:false}
                    >
                    Submit{isSubmitting?'ting':''}
    
                    </Button>
                    {actionError}
                    {(open && !orderView && signupData?.inquiry  && signupData?.email)  && <InquirySendModal open={open} order={signupData} 
                    owner_email={owner_email}
                    owner={owner}
                    />}
                    {(open && orderView && signupData?.email ) && <ConfirmSignupModal open={open}  order={{...signupData,order:[...tempOrder]}} email={email}
                    owner_email={owner_email}
                    operating_name={operating_name}
                    userObj={{firstName, lastName, email, phone, department, inquiryText, isFollower, }}
                    owner={owner}
                    />}
                </React.Fragment>

                <Grid container justifyContent="flex-end">
                <Grid item>
                    {/* <Link href="#" variant="body2">
                    Already have an account? Sign in
                    </Link> */}
                </Grid>
                </Grid>
            </Box>
            </Box>
            </Box>
            <Box sx={{margin:'1rem auto', marginBottom: '0'}}>

            <Copyright view='signupSheet'/>
            </Box>
        </Container>
        </ThemeProvider>
        </Container>
    </SignupProvider>
  );
}