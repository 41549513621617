import React,{useContext} from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip } from '@mui/material';

import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";

const PreviousViewButton = (props) => {
   const { menuOpen,setMenuOpen,
            cart,setCart,currentOrder, orderDefault, shop,storefrontData, 
            buildStep, setBuildStep,currentProduct,
            updateStorefrontData} = useContext(StorefrontContext);
    const config = props.config
    const handleBack = () =>{

    }

  return (
    <Tooltip title='Back to menu'>
        <ArrowBackIcon style={{fontSize:'2rem',position:'absolute',
            left:'2rem', cursor: 'pointer', opacity: '0.5',
            top: config?.headerHeight, marginTop:'3.25rem'}} 
            onClick={()=>{setMenuOpen(!menuOpen)}}
            />
    </Tooltip>
  )
}

export default PreviousViewButton