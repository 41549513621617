import React from 'react'

const BecomeAPartner = () => {
  return (
    <di style={{color:"#000",margin:'1rem auto', fontFamily:'Gilroy'}}>
     <h2 >Become A Partner!</h2>
      <p>( contact: 
       <b>
         <a href='mailto:services@rexentropy.com?subject = QauRa Partner Inquiry'

        style={{textDecoration:'none'}}
      >
        services@rexentropy.com </a>
        </b>
        for more details )</p>
    </di>
    
    )
}

export default BecomeAPartner