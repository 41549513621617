import React, {useState, useContext, useEffect } from "react";
import axios from 'axios'

import GeneratedQR from './GeneratedQR'
import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";
import useWindowDimensions from "../hooks/windowDimensions";
import AssetFieldComponent from './AssetFieldComponent'
import QRFieldComponent from './QRFieldComponent'
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Container, Divider, Stack, Typography } from "@mui/material";

import QROptions from './QROptions'

import * as API from '../utils/APICalls'
import EnvRoute from '../middleware/envRoute';


const ENDPOINT = EnvRoute('prod'); 


const SingleAsset = (props) => {
  // const qr = props.qr
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const { appData, updateAppData,setPreviewQR, previewQR,userData,
      modalOpen ,currentAsset, setCurrentAsset,assetQR,setAssetQR, QRs,
      setActionError,
    } = useContext(AppContext);
    const { height, width } = useWindowDimensions();
    const [ settings, setSettings ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ update, setUpdate ] = useState(false)
  
    const [ FMValue, setFMValue ] = useState(currentAsset?.useFieldMode)
      const pairAssetToQR = () =>{
        let qr = QRs.find((q)=>{return q?._id === currentAsset?.qr_id})
        setAssetQR({...qr})
        updateAppData({...appData,assetQR})
        console.log('[pairAssetToQR]: ', assetQR)
      }
    const updateQRPayload = async () =>{
    
      try{
          console.log('[updateFieldMode][currentAsset]--->Pre: ', currentAsset)
          // if(){
          API.updateQRCode({
            ...assetQR,
            payload: currentAsset?.useFieldMode?`https://qaura.pro/fm/${userData._id}`:assetQR.payload})
    //  }
          //  console.log('updateFieldMode][currentAsset]--->Post: ', data)
          //!  updateQRPayload()
           setUpdate(false)
           setFMValue(currentAsset?.useFieldMode)
        }catch(err){console.log(err)}
    }
    const updateFieldMode = async () =>{
      // !-------Find the assetQR for  
      updateQRPayload()
      try{
          console.log('[updateFieldMode][currentAsset]--->Pre: ', currentAsset)
          const data = await API.updateAsset({...currentAsset})
           console.log('updateFieldMode][currentAsset]--->Post: ', data)
           setUpdate(false)
           setFMValue(currentAsset.useFieldMode)
        }catch(err){console.log(err)}
        
        }
//      useEffect(()=>{
  
// },[assetQR])

   useEffect (()=>{
    if(currentAsset?.qr_id && !assetQR){
  
      pairAssetToQR()

    }
        },[currentAsset,update,appData, assetQR])
   useEffect (()=>{
    // if(currentAsset.qr_id && !assetQR){
  
    //   pairAssetToQR()

    // }
        },[assetQR,FMValue])
    return (
  //  <div>
      <div style={{display: 'flex', 
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        // width: '100%',
        border: '2px solid #000000',
        borderRadius:'10px',
        backgroundColor:'rgba(256,256,256, 0.2)',
        boxShadow: 'inset -2px 0px 5px 3px rgba(0,0,0,0.3)',
        backdropFilter:'blur(3px)',
        padding: '0.5rem',
        height: '95%',
        width: '95%',
        margin: 'auto',
        fontSize: '0.75rem',
        gap: '0.5rem'
    }}>

       <div className='user-info-bottom-right'
            style={{width:'100%'}}
            >
             {currentAsset ? <span className='blink'
                style={{cursor:'pointer',display:'flex', flexDirection:'column',
                maxWidth:'100px', padding:'0.25rem 0.5rem',
                backgroundColor:'rgba( 255,255,255, 0.3)',backdropFilter: 'blur(4px)',
                margin:'0.5rem auto',
                boxShadow:`0px 0px 3px 1px rgba( 0, 0 , 0, 0.4),${FMValue?'inset -2px -2px 15px 5px rgba( 255, 255, 255, 0.3)':''}`,
                border:`2px solid ${FMValue?'#00ff00':'#ff0000ff'}`,borderRadius:'5px', gap:'0.125rem',                
                }}
                onClick={(e)=>{
                    e.preventDefault()
                    setUpdate(true)
                    setFMValue(!currentAsset?.useFieldMode)
                    setCurrentAsset({...currentAsset,useFieldMode:!currentAsset?.useFieldMode})
                    // setCurrentAsset({...currentAsset,useFieldMode:!currentAsset?.useFieldMode})
                    updateAppData({...appData,currentAsset})
                    console.log('Updating fieldMode--appData : ', appData)
                    if (update){
                      console.log('Updating fieldMode: ', currentAsset)
                      updateFieldMode()

                    }
                   
                }}
                ><div style={{fontSize:'0.5rem',color:"#000000",margin:'0 auto'}}>
                  {update?'click again to confirm':''}
                </div>
                <div style={{display:'flex', flexDirection:'row',alignItems:'center', gap:'0.5rem'}}>

                <img width='15rem' src={`/assets/${FMValue?'FM_on':'FM_off'}.svg`} alt='field mode toggle'/>
                <p style={{color: FMValue?'#000':'#ff0000ff',fontSize:'0.7rem'}}><u>field mode</u>: {FMValue?'on':'off'} </p>
                </div>
            </span>:'no asset'}

{/* -------Frame--------- */}
              <div style={{display:'flex', 
                flexDirection:'column',
                padding:'0.5rem', 
                position:'relative',
                alignItems:'center', justifyContent:'center', backgroundColor:'rgba(255,255,255,0.5)',
                backdropFilter: 'blur(3px)', border:'3px solid rgba(0,0,0,1)',boxShadow:"0px 0px 10px 3px rgba(0,0,0,0.6)",
                borderRadius:'10px', flex:1}}>

  {/* -------responsive container--------- */}

              <div style={{display:'flex', width:'100%',flexDirection: width<=785?'column':'row',
              alignItems:'center', flex:1}}>
                  

                  {/* <div style={{display:'flex', flexDirection:'column',width:'100%',border:'1px solid red'}}> */}
  {/* -------card 1--------- */}
                <div 
                style={{display:'flex', flexDirection:'column',alignItems:'center', flex: 1}}>
                  {/* <p style={{margin:'0.5rem 0'}}>asset name: {currentAsset?.name}</p>  */}
                  <AssetFieldComponent 
                        value={currentAsset?.name}
                        object={currentAsset}  name='name'
                        view='edit-asset'
                        key='name'
                        />  
                  <div className="button-accent-hover"
                   style={{display:'flex',width: '200px',height:'200px', overflow: 'hidden',
                        boxShadow:"0px 0px 10px 3px rgba(0,0,0,0.6)",cursor:'pointer',
                        borderRadius:'15px', border:'3px solid rgba(0,0,0, 1)', 
                        alignItems: 'center',alignContent:'center' }} 
                      onClick={ ()=>{
                        updateAppData({
                              modalOpen:true,
                              modalView:'pic-view'
                          })}

                      } >
                        

                    <img style={{width: '100%', borderRadius:'15px', alignSelf: 'center'}} src={currentAsset?.LSAsset?.isLSA? currentAsset?.LSAsset?.url:currentAsset?.pic} />
                  </div>
                  <AssetFieldComponent 
                        value={currentAsset?.asset_type}
                        object={currentAsset}  name={'asset_type'}
                        key={'asset_type'}
                        view='edit-asset'
                        /> 
                </div>
                <Stack sx={{gap:'0.5rem', justifyContent:'center'}}>
                    <Box className='button-accent-hover'
                          sx={{margin:'1rem auto',cursor:'pointer',border:'2px solid #000', backgroundColor:'rgba( 255,255,255,1 )',
                                  borderRadius:'10px',padding:'0.5rem', boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.3)',
                                  justifyContent:'center'}}  onClick={()=>{
                          setSettings(!settings)
                        }}>

                        {currentAsset?.isLocked ? <LockIcon sx={{fontSize:'3rem',margin:'auto',color: currentAsset?.isLocked ? 'limegreen':'red'}} />
                                :<LockOpenIcon sx={{fontSize:'3rem',margin:'auto',color: currentAsset?.isLocked ? 'limegreen':'red'}}/> }
                        </Box>
                      {currentAsset.isPhysical && <LinkIcon sx={{fontSize:'3rem',margin:'auto'}}/>}
                      {/* {currentAsset.isPhysical && <img style={{width:'2rem', borderRadius:'0px', alignSelf: 'center'}} src={'/assets/lock.svg'} />} */}
                </Stack>

  {/* -------card 2--------- */}

                <div style={{flex:1}}>
                {/* <p style={{margin:'0.5rem 0'}}>QR name: {qrCodeData?.name}</p>  */}
                  <AssetFieldComponent 
                        value={assetQR?.qr_id}
                        object={assetQR}  name={'qr_id'}
                        view='edit-asset'
                        />  
                
                {!assetQR ? (
                        <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                            <div className='scaler'>

                            <img className={'spinner'} src={'../assets/spinner-solid.svg'} 
                            style={{height:'50px', alignSelf: 'center', 
                            marginRight:'0.5rem'}} alt="loading"/>
                            </div>
                            {/* {statusText} */}
                            loading... 
                        </div>):

                    <GeneratedQR view='dashboard-asset' qr={assetQR}/>
                        }
                  
                    <QRFieldComponent 
                      value={assetQR?.payload}
                      object={assetQR}  name='payload'
                      view='edit-asset'
                       payload_type={assetQR?.payload_type}
                    />

                  </div>


              
                {/* </div> */}

            </div>
            <hr
                style={{
                  position:'relative',
                    alignSelf: 'center',
                    color: "#868686",
                    backgroundColor: "#868686",
                    height: '0.25px',
                    width:"90%",
                    marginTop:"0.5rem"
                }}
            />
            <div style={{marginBottom:'1.25rem'}}>
                <AssetFieldComponent 
                  value={currentAsset?.description}
                  object={currentAsset}  name='description'
                  view='edit-asset'
                  />
            </div>
                    <img className="settings-gear" width='35rem' src='/assets/SettingsGear.svg' alt="additional asset settings"
                      style={{position:'absolute',
                      right:'0.5rem',
                      bottom:'0.5rem',
                      cursor:'pointer'}}
                      onClick={()=>{
                        setSettings(!settings)
                      }}
                      />
                   
        </div>

             
        {(settings && currentAsset) &&( 
            <Container sx={{display:'flex', flexDirection:'column',margin:'1rem auto',
                      width:'100%', justifyContent:'center',gap:'0.5rem'}}>
                      <h2>settings</h2>
                       <Stack className='button-accent-hover'
                              sx={{margin:'1rem auto', gap:'0.5rem',border:'2px solid #000',
                                borderRadius:'10px',padding:'0.5rem', boxShadow:'0px 0px 5px 3px rgba(0,0,0,0.3)',
                                justifyContent:'center', 
                                cursor:'pointer', 
                                textAlign:'center',
                                width:'150px',height:'110px'
                                }}
                            onClick={async()=>{
                              let val = currentAsset?.isLocked
                              try{
                                const res = await API.updateAsset({
                                  ...currentAsset,
                                    isLocked: !val
                                })
                                if(res){
                                  setCurrentAsset(res)
                                  updateAppData({
                                      ...appData,
                                      currentAsset
                                  })
                                }else{
                                  setActionError('unable to toggle Asset lock')
                                }
                              }catch(err){console.log('Asset lock toggle error')}
                            }}
                            >
                            {currentAsset?.isLocked ?<LockIcon sx={{margin:'auto',color: currentAsset?.isLocked ? 'limegreen':'red'}} />
                            :<LockOpenIcon sx={{margin:'auto',color: currentAsset?.isLocked ? 'limegreen':'red'}}/> }
                            <Divider />

                            <Stack sx={{width:'100%',gap:'0'}}>
                              <Typography>
                                Asset is
                                </Typography>
                              <Typography sx={{fontWeight:'bold',color: currentAsset?.isLocked ? 'limegreen':'red'}}>
                                {!currentAsset?.isLocked ?'un':'' }locked
                                </Typography>
                              <Typography sx={{fontSize:'0.8rem'}}>
                                ( click to {currentAsset?.isLocked ?'un':'' }lock )
                                </Typography>
                            </Stack>
                          
                          </Stack> 
                      <Box sx={{ margin:'auto', width: '100%',
                          display:'flex',
                          flexDirection:'column',justifyContent:'center'}}>
                      { Object.keys(currentAsset)?.map((key,i)=>{ 
                      // { Object.keys(currentAsset)?.toReversed().map((key,i)=>{ 
                          // if( key !== '_id' && key !=='createdAt' && key !== 'updatedAt' && key !=='__v' && key !=='owner'&& key  !== 'qr_id' && key  !== 'name' && key  !== 'clicks'&& key  !=='asset_type' && key  !== 'description' && key  !== 'useFieldMode'){
                          if( key === 'pic' || key === 'isPhysical' || key === 'isActivated'){
                        
                            return(
                              <span style={{width: '80%', margin: 'auto'}}>
                              <b>{key}:</b>
                              <AssetFieldComponent 
                                  value={currentAsset[key]}
                                  object={currentAsset}  name={key}
                                  view='edit-asset-list'
                                  key={i}
                                  />
                                </span>
                            )
                          }
                          // if(key === 'qr_id'){
                          //     return(
                          //     <div style={{width: '100%', alignItems: 'center', justifyContent:'center', margin:'auto'}}>
                          //       <QROptions />
                          //     </div>
                          //   )
                          //   }
                      })}
                    

                          </Box>
                  <div style={{ display:'flex', flexDirection:'column',
                  // border:'1px solid red',width:'100%',
                  //     justifyContent:'right'
                      }}>
                          <div className="manager-delete-button"
                          style={{
                            display: 'flex',
                            justsifyContent:'center',
                            width:'fit-content',
                            cursor: 'pointer',
                            margin: '2rem auto  1rem auto',
                            padding: '0.125rem',
                            borderRadius: '5px',
                            boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
                            // padding: '0.25rem',// 0.125rem 0  0.125rem',
                            border: '2px solid #000000',
                            backgroundColor: 'rgba(255, 255, 255, 0.327)'
                            // backgroundColor: 'rgba(253, 0, 0, 0.627)'
                          }} 
                            onClick={()=>{
                              updateAppData({
                                modalOpen: true,
                                modalView:'confirm-asset-delete'
                            })
                                // deletePersona()
                            }}
                            >
                              <img width='35rem' src='/assets/DeleteAsset.svg' alt='Delete Asset'/>
                          </div>
              </div>
            </Container>
                )}
               
        </div>
      
   </div>
  //  </div>
  )
}

export default SingleAsset