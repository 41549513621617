import ContactPhone from '@mui/icons-material/ContactPhone'
import { Container, Modal,Box, Button, Input, Typography, TextField } from '@mui/material'
import React, { useState } from 'react'
import CloseButton from '../../../components/CloseButton'
import Storefront from '../../Storefront'
const ShopModal = (props) => {
     const {  shopModalOpen, setShopModalOpen, src } = props
    const [ pin, setPin ] = useState()
    const [ actionError, setActionError ] = useState('')
  return (
     <>
        <Modal 
            open={shopModalOpen}
            onClose={()=> setShopModalOpen(!shopModalOpen)}
            >
            <Container sx={{zIndex:999999}}>
                <Box sx={{ 
                
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    maxHeight:'100vh',
                    maxWidth:'100vw',
                    backgroundColor:'#000',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem',
                    backgroundColor:'#FFF',
                    overflow:'hidden',
                    overflowY:'scorll'
                    }}>
                        <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setShopModalOpen} val={shopModalOpen}
                        
                            />
                        </Box>
                        <Container sx={{position:'absolute',top:'0px', left:'0px',zIndex:999999, width:'100%',height:'100%'}}>
                            <Storefront id={'6634372a7d97baf1e077b325'}  />
                        </Container>

                      
                    </Box>
                    
            </Container>
        </Modal>
        
    </>
  )
}

export default ShopModal