import React, {useState, useContext, useEffect } from "react";
import { AppContext } from "../context/AppProvider";
import PersonaFieldComponent from "./PersonaFieldComponent"
import useWindowDimensions from "../hooks/windowDimensions";
import PersonaCardBack from "./PersonaCardBack";
import Follow from './Follow'
import * as API from '../utils/APICalls'

const PersonaCard = (props) => {
//    const view = props.view
//    const persona = props.persona
   const {view, persona,fieldMode} = props
    const { appData,userData, updateAppData,modalOpen,setModalOpen ,currentPersona, setCurrentPersona} = useContext(AppContext);
    const { height, width } = useWindowDimensions();
    const [ isFront, setIsFront ] = useState(true)
    const [ isFollow, setIsFollow ] = useState(false)
    const [ vCardData, setVCardData ] = useState(null)


    const themeOptions = {
        borderColor:'#000'
    }
    const fetchVCard  = async() =>{
        console.log('fetchvCard persona: ', persona)
        if(persona){
            const data = await API.fetchVCard(persona)
            console.log('fetchvCard data: ', data)
            setVCardData(data)
            // setVCardData(data?{...data}:fetchVCard())
            console.log('fetchvCard VCardData: ', vCardData)
        }
    }
    useEffect(()=>{
        fetchVCard()
        // document.addEventListener("load",)
    },[persona])
    
  return (
    <div 
         className={"card-wrap"}
        style={{width:'100%',maxWidth:'600px',height:'fit-content', minHeight:view==='view-persona'?'400px':'',
        display:'flex',flexDirection:'column',margin: '2rem auto',
        justifyContent: 'center',position: 'relative'
        
        }}>
    <div className={view==='view-persona'?"flip-card":''} style={{width:'100%'}}>
     {view ==='view-persona'   && <div style={{color: !props.fieldMode?'#000':'#000', margin:'0.5rem'}}> 
        tap on & off or hover to flip
        <br/>
            <button  
            className="button-accent-hover"
            style={{curso: 'pointer', height: '1.5rem', color:'#00FF00',backgroundColor:'#000000', alignItems:'center',
            boxShadow:'0px 0px 5px 5px rgba(0,250,0,0.5)', marginTop:'0.5rem'
        }}
        onClick={()=>{setIsFollow(!isFollow)}}
        >follow</button> 
       <h2 className='text-shadow-02 blink-2' style={{marginTop: '0.5rem'}}>{persona?.personaTitle}</h2> 
        </div>  }
  <div className={(view === 'view-persona')?"flip-card-inner":'' } >
 <div className={view === 'view-persona'?"flip-card-front":''}>
        
   {/* Flip Card Front---------- */}
   {isFront? <section style={{display:'flex',flexDirection:'column',
    width:'100%',minHeight:view==='view-persona'?'300px':'300px',
    alignItems:'center',
    borderRadius:'15px',
        border:`3px solid ${themeOptions.borderColor}`,
        backgroundImage:"url(" +`${persona? persona.cover_pic?.data : currentPersona?.cover_pic?.data}` + ")",
        // backgroundImage:"url(" +'/assets/Bodybg.png' + ")"
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.5),0px 0px 10px 5px rgba(0,0,0,0.5)',
        overflow: 'hidden'
        }}>
         <div  className='persona-card-pic' style={{ width:'200px',height:'200px',borderRadius:'100px',
          overflow:'hidden',
          
         margin: 'auto', backgroundColor:'rgba(255,255,255,0.1)',backdropFilter:'blur(2px)',
        
        border: '6px solid #000',boxShadow:'0px -4px 10px 8px rgba(255,255,255,0.5)'}}>
         <div style={{ flex:1,width:'200px',height:'200px',borderRadius:'100%',
          overflow:'hidden'
             ,clipPath:'circle(40%)'
         ,}}>
            <img style={{height:'200px',
            borderRadius:'100%',overflow: 'hidden',
            }} src={persona? persona?.alt_pic?.data: currentPersona?.alt_pic?.data?currentPersona?.alt_pic?.data:userData?.pic} alt="persona alt pic" />
         </div>
         </div>
        
 {(view === 'edit') && <div className="persona-title-bar"
        style={{
            minWidth: '200px',
            width:'100%',
            // flex:1,
            // maxWidth: '125px',
            height: '3rem',
            borderRadius:'0px 0px 15px 15px',
            // border:`1px solid #000`,
            border:'2px solid #000000',
            backgroundImage:"url(" +'/assets/Bodybg.png' + ")",
            // backgroundColor: "#fff",
           
            // backdropFilter: 'blur(5px)',
            backgroundRepeat:"no-repeat",backgroundSize:"cover",
            // filter:'opacity(20%)',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent:'center',
            padding: '0.125rem  0.25rem',
            margin: '0',
            boxShadow: 'inset 0px -4px 10px 8px rgba(255,255,255,0.3), 0px 0px 3px 2px rgba(0,0,0,0.5)',
            cursor:"pointer",
            // backdropFilter: 'blur(5px)',
            overflow: 'hidden',
        }}
        // onClick={()=>{
        //     handleSelection()
        // }}
        >
            <h2 >
                {/* style={{ margin: '0.75rem 0'}}> */}
                <u>
                <PersonaFieldComponent
                    value={persona? persona?.personaTitle : currentPersona?.personaTitle}
                    object={persona? persona : currentPersona}  name={'personaTitle'}
                    on={true}
                    key={'personaTitle'}
                    index={0} />
                {/* {currentPersona?.personaTitle} */}
                </u>
            </h2>  
        </div>}
</section>:<div style={{backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '15px'}}>
    <PersonaCardBack persona={persona? persona : currentPersona} view='edit'/>
 
    </div>}
    </div>
     {view === 'style' && 
     <span>
        Front 
        <label className="switch" style={{marginTop:'0.5rem'}}>
        
        <input type="checkbox" value={isFront} 
            onChange={()=>{
                setIsFront(!isFront)
             console.log('isFront',isFront)  
            }}/>
            <span className="slider round">

            </span>
        </label>
        Back
     </span>
}

    <PersonaCardBack persona={persona? persona : currentPersona} VCData={vCardData}/>
    </div>
          </div>
    {/* // {!isFront &&  */}
         
       
    {/* //       } */}
    {/* </div> */}
    {/* :<PersonaCardBack /> */}
{(view ==='view-persona' && isFollow) && <Follow persona={persona} isOpen={isFollow} />}
</div>

  )
}

export default PersonaCard