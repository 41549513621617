import { Box, Typography } from '@mui/material';
import React, {useEffect,useState,useRef} from 'react';
import AddComment from './AddComment';
import * as helpers from '../../../utils/helpers'


const CustomProgressBar = ({ className,currentTime,setTimestamp, 
  setCommentModalOpen,markers,onSeek, duration, ...props }) => {
//   console.log('CustomProgressBar rendered', { className, markers, duration });
  const [ currentComment, setCurrentComment ] = useState('')
     const progressBarRef = useRef(null);
      const handleProgressBarClick = (event) => {
    if (progressBarRef.current && duration) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const clickX = event.clientX - rect.left;
      const progressBarWidth = rect.width;
      const clickPercentage = clickX / progressBarWidth;
      const newTime = clickPercentage * duration;

      // Call the onSeek function with the new time
      onSeek(newTime);
    }
  };

  useEffect(()=>{

    },[currentTime])
    return (
      <>
     
        <div className={`${className} custom-progress-wrapper`} 
        style={{ cursor:'pointer',position: 'relative',borderRadius:'5px 5px 0 0' }} 
        onClick={handleProgressBarClick}
        ref={progressBarRef}
        >
          <div {...props} /> {/* This is the original progress bar */}
          <Typography sx={{textAlign:'center',alignSelf:'center',color:'#656565'}} >comments</Typography>
          {markers?.map((marker, index) => {
            // console.log(`Position:" ${(marker?.time / duration) * 100}% time`, duration)
            return(
            <div
              key={index}
              className="marker"
              style={{
                position: 'absolute',
                left: `${(marker?.time / duration) * 100}%`,
                top: 0,
                bottom: 0,
                width: '2px',
                backgroundColor: 'red',
                zIndex: 99999,
                cursor:'pointer',

              }}
              onClick={(e)=>{
                 e.stopPropagation(); // Prevent triggering the progress bar click
                console.log('Marker click',marker)
              }}
            />
          )})}
        <Box sx={{position:'absolute', left: `${( currentTime / duration) *100 }%` , top:'-0.5rem', alignContent:'center',
        justifyContent:'center',alignItems:'center'}}
            onClick={(e)=>{
                try{
                   e.stopPropagation(); // Prevent triggering the progress bar click
                    // console.log('[current Time]:', currentTime)
                    setTimestamp(currentTime)
                    setCommentModalOpen(true)
                }catch(err){console.log(err)}
                }}>
                    <AddComment  sx={{margin:'auto'}} />
                </Box>
        </div>
      </>
  );
};

export default CustomProgressBar;