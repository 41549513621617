import React, { useState, useEffect } from 'react'
import {QrScanner} from '@yudiel/react-qr-scanner';
import { QrReader } from 'react-qr-reader';
import jsQR from 'jsqr';

import { Box, Button, Container, Input, Stack, Tooltip, Typography } from '@mui/material';

const Scan = () => {
    const [ QRResult, setQRResult ] = useState('')
    const [data, setData] = useState('No result');
    const [ result, setResult ] = useState('')
    const [ isUserView, setIsUserView ] = useState(true)
    const [ isUpload, setIsUpload ] = useState(true)
    const [ showPopup, setShowPopup ] = useState(false)
    const [ popupText, setPopupText ] = useState('')
    const URL_RegEx = 'https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'

     const sleep = (time) => {
      return new Promise(resolve => setTimeout(resolve, time))
    }
    
    const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

        // Use a QR code decoding library like jsQR
        const code = jsQR(imageData.data, imageData.width, imageData.height);
        if (code) {
          console.log('[Decoded Code]',code?.data)
          setData(code?.data);
          setQRResult(code?.data);
        } else {
          setData('QR code not found in the image.');
          setQRResult('QR code not found in the image.');
        }
      }
      img.src = e.target.result;
    }
    reader.readAsDataURL(file);
  }
    const showCopyPopup = async() =>{
        setPopupText('data copied to clipboard')
        setShowPopup(true)
        await sleep(800)
        setShowPopup(false)
    }
    const handleData = (data)  =>{
        // if(data.match(URL_RegEx)){
            window.open(data, '_blank')
            // }
            return
        }
        const bookmarkData = async (data)  =>{
            setPopupText('Bookmarks coming soon!🤞🏽')
            setShowPopup(true)
            await sleep(800)
            setShowPopup(false)

        console.log('Bookmarks coming soon!🤞🏽')
        return
    }
    useEffect (()=>{
       
    },[QRResult])
  return (
    <Container sx={{padding:'2rem', display:'flex', flexDirection:'column', gap:'0.5rem',
      justifyContent:'center'
    }}>
      <Stack sx={{flexDirection:'row', margin:'auto'}}>
        <Button onClick={(e)=>setIsUpload(true)}>Upload</Button>
        <Button onClick={(e)=>setIsUpload(false)}>Scan</Button>

      </Stack>
      
      
        {showPopup && <div style={{backgroundColor:'rgba(0, 0, 0, 0.5)', 
          color:"#00FF00",maxWidth:'600px',alignItems:'center',
          height:'2.5rem', textAlign:'center', alignContent:'center',
          justifyContent:'center',
           borderRadius: '5px ',
            padding:'0.5rem',
            border:'2px solid rgba(0, 0, 0, 1)',
           boxShadow: '0px 0px 5px 3px rgba(0, 0, 0,0.5)',
          margin: 'auto'}}>
            
            {popupText}
        </div>}
       {QRResult && (
      <div style={{color:"#ffffff", padding:'0.25rem 0',
         backgroundColor:'rgba(0, 0, 0, 0.5)', 
         maxWidth:'600px', margin:'auto',
         padding:'0.5rem',
        //  borderRadius: '0 0 10px 10px',
         border:'2px solid rgba(0, 0, 0, 1)',
        //  boxShadow: '0px 0px 10px 5px rgba(0, 0, 0,0.5)',
        backdropFilter: 'blur(3px)'

         }}>
        <div style={{display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            margin: 'auto',
            justifyContent: 'center',
            alignSelf: 'center'
            }}>

            <div className="qr-data-button blink" 
                onClick={()=>{
                    navigator.clipboard.writeText(QRResult)
                    showCopyPopup()
                    console.log('Copy  data: ', QRResult)
                }}
                type="submit" style={{display: 'flex', maxWidth:'325px',wordBreak:'break-word'}}>
                <img src={'/assets/copy.svg'} 
                style={{height:'25px', alignSelf: 'center', 
                marginRight:'0.5rem'}} alt="copy data"/>

                {QRResult}

            </div>
            <div className="qr-data-button blink" 
                // onClick={()=>{setModalOpen(!modalOpen)}}
                onClick={()=>{
                   handleData(QRResult)
                }}
                type="submit" style={{display: 'flex'}}>
                <img src={'/assets/external-link.svg'} style={{height:'25px', alignSelf: 'center'}} alt="follow link"/>
            </div>
            <div className="qr-data-button blink" 
                // onClick={()=>{setModalOpen(!modalOpen)}}
                onClick={()=>{
                   bookmarkData(QRResult)
                }}
                type="submit" style={{display: 'flex'}}>
                <img src={'/assets/bookmark.svg'} style={{height:'25px', alignSelf: 'center'}} alt="bookmark link"/>
            </div>
        </div>
          </div>)}

        <Box style={{ 
          display:'flex',
            justifyContent:'center',
            position: 'relative',
            maxHeight:'85vh', 
            // maxHeight:'600px', 
            maxWidth:'600px', 
            margin: 'auto', 
            borderRadius:'5px', 
            overflow: 'hidden',
            padding: "1rem",
            border: '2px solid rgba( 0, 0, 0, 1)',
            // boxShadow: '0px 0px 10px 5px rgba(0, 0, 0,0.5)',
            // backgroundColor:'rgba(0, 0, 0, 0.6)',
            backdropFilter: 'blur(3px)',
            color:'#000',
            flex:1

            }}>


    {/* <form onSubmit={}> */}

      {/* <input type="file" onChange={(e) => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.videoContainerStyle= {width:'150px', height:'150px'}
            reader.onload = () => {
                const imageData = reader?.result;
                const qrReader = new QrReader();
                qrReader?.decode(imageData)?.then((result) => {
                setResult(result);
                console.log('result',result?.text)
                });
            };
            reader.readAsDataURL(file);
            }} /> */}
    {/* </form> */}
       {isUpload && <Input type="file" onChange={handleUpload} />}
       {/* {!isUpload && <QrReader
              //   videoContainerStyle={{width:'100%',height:'100%'}}
          onResult={(result) => {
              if (!!result) {
              setResult(result);
  
              console.log('result',result)
              }
          }}
          />} */}
       {!isUpload && 
       <Stack>
       
          <QrScanner
            onDecode={(result) => 
            setQRResult(result)
            }
            containerStyle={{
              display:'flex',//flex:'1', 
              margin: 'auto',
            // border:'2px solid #00FF00',
            // borderRadius: '20px',
            //  maxHeight:'70vh', maxWidth:'80vw', 
             height: '250px', width:'250px', 

            //  overflow: 'hidden', 
            backgroundColor:'rgba(0, 0, 0, 1)'
          }}
            videoStyle={{borderRadius:'20px'}}
            hideCount={true}
            scanDelay={500}
            // facingMode= {isUserView ?'user' :'environment'}
            // constraints={{ facingMode: isUserView ?'user' :'environment'}}
            onError={(error) => console.log(error?.message)}
        /> 
        <Tooltip title='coming soon'>

          <Button 
            disabled={true}
            variant='outlined'
            sx={{margin:'0.75rem auto 0 auto'}} 
            onClick={()=>setIsUserView(!isUserView)}>
            Switch camera view
            {/* Switch {!isUserView ?'to':'from'} user view */}
        </Button>
        </Tooltip>
       </Stack>
      }
        </Box>
        
      

     
    </Container>
  )
}

export default Scan