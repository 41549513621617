import React,{ useEffect, useRef, useState, useContext } from  'react'
import { AppBar, Button, CardMedia, Container, Divider, List, ListItem, ListItemIcon, ListItemText, Modal, Stack, Tooltip } from '@mui/material';

import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
// import CustomerInfoForm from '../../components/CustomerInfoForm';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import * as API from '../../../utils/APICalls'
import { StorefrontContext, StorefrontProvider }  from "../../../context/StorefrontProvider";
// import CustomerBilling from './CustomerBilling';
// import CustomerShipping from './CustomerShipping';
import CartReview from '../../storefrontComponents/CartReview';
import Payment from '../../../components/Payment';



const CheckoutModal = (props) => {
       const { modalOpen, setModalOpen,musicCart,
        setMusicCart,
        order, view, setView, shop, setShop,
        storefrontData, setStorefrontData, currentProduct,
        items, setItems,owner, setOwner,musicCartOpen, setMusicCartOpen,
        subView, setSubView ,isFollower
    } = useContext(StorefrontContext);

    // const musicCartOpen = props.musicCartOpen
    // const setMusicCartOpen = props.setMusicCartOpen
    // var musicCart = props.musicCart
    const {checkoutModalOpen, setCheckoutModalOpen ,listener } = props
    // var setMusicCart = props.setMusicCart
    // const shop = props.shop
    const [ modalView, setModalView ] = useState(props.modalView)

    const [ musicCartItems, setMusicCartItems ] = useState(musicCart?.items?.length>0 ? musicCart?.items :[])
    // const [ subView, setSubView ] = useState(props.subView)


    const handleCheckout = async  () =>{
        console.log('[Checkout Click] subview:', subView)
        console.log('[Checkout Click] musicCart:', musicCart)
        console.log('[Checkout Click] shop:', storefrontData)
        await API.updateCart(musicCart)
        // if(isFollower){
        //     let temp = shop?.subscribers
        //     temp.push(musicCart?.billing_info?.email)
        //     const res = await API.updateShop({
        //         ...shop,
        //         subscribers: temp
        //     })
        // }
        // if(subView ==='musicCart'){
             setSubView('billing')
        // }
    //     else if(subView ==='shipping'){
    //         setSubView('billing')
    //     }
    //    else  if(subView ==='billing'){
    //         setSubView('review')
    //     }
        // else if(subView ==='review' && musicCart?.billing_info ){

            try{
                console.log('[CheckoutModal] pre-send Cart:',musicCart)

                const res = await API.sendCart({
                    cart:{
                        ...musicCart,
                        isComplete:true,
                        status:'checkout'
                    },
                     shop: shop
                    })
                if(res){
                    console.log('[send musicCart]:',res)
                    setMusicCart({})
                    handleCartClose()
                    setView('thank you')
                    setStorefrontData({
                        ...storefrontData,
                        musicCart
                    })
                }
            }catch(err){console.log(err)}
        // }
    }
    // const locCart = localStorage.getItem('musicCart')
    // let musicCart =[]
    // if (locCart) musicCart = JSON.parse(locCart)
    
    const handleRemoveClick = async (arr,obj,i) => {
        const index = arr.indexOf(obj)
        await API.deleteOrder(obj)
        try{
            if(index > -1){
                    arr.splice(index,1)
                    // setSubView('musicCart')
                    console.log('[Remove Click]:',arr)
                    // musicCart = arr
                    await setMusicCartItems(arr)
                    const {res} = await API.updateCart({...musicCart,items:arr})
                    if (res){
                        await setMusicCart({
                            ...musicCart,
                                items:arr,
                                tracks:arr
                            })
                        // localStorage.setItem('musicCart',JSON.stringify(res))
                        await setStorefrontData({
                            ...storefrontData,
                            musicCart:musicCart
                        })
                    }
                    await setMusicCartItems(musicCart?.items)
                    console.log('[Remove Click] musicCart(prop):',musicCartItems)
                
                }
        //    if(resp){
        //     console.log('[Remove Click] deleteResponse:',resp)
        //    }
        }catch(err){console.log(err)}
    };
    
    const handleCartClose = () => {
        setCheckoutModalOpen(false);
        setModalView('')
        // setSubView('musicCart')
    };

    const musicCartTotal = () =>{
        let total = 0
        // if (musicCart){
            musicCart?.items?.forEach((e,i)=>{
                total = ( 1* e?.price ) + total
            })
            // console.log('[Cart Total]:', total)
         return total
        // } else {return}

    }

    useEffect(()=>{
        //  if (locCart) musicCart = JSON.parse(locCart)
        // console.log('[Cart Modal] musicCart :', musicCart)
      setMusicCartItems(musicCart?.items)
    },[musicCart?.items ,storefrontData,musicCartItems ])
    useEffect(()=>{
        //  if (locCart) musicCart = JSON.parse(locCart)
        // console.log('[Cart Modal] musicCart :', musicCart)
    //   setMusicCartItems(musicCart?.items)
    },[musicCartItems ])
    useEffect(()=>{
        setSubView('cart')
      
    },[])
    useEffect(()=>{
   
      
    },[subView])

    // if(init_musicCart !== musicCart){
            // setSubView('checkout')
            // .then()
            // setSubView('musicCart')
        // }
        // console.log('[musicCart]:', musicCart)
  return (
      <Modal
        open={checkoutModalOpen}
        onClose={handleCartClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
                <Container sx={{
                    p:1, 
                    }}>

                <Box sx={{ position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    // height:'90%',
                    minHeight:'50vh',
                    overflow: 'hidden',
                    overflowY:'scroll',
                    maxHeight:'90vh',
                    // maxHeight:'90vh',
                    maxWidth:'98vw',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    borderRadius:'10px',
                    boxShadow: 24,
                    p: 1,}}>
                        <CloseIcon 
                        className='button-woddle'
                        onClick={handleCartClose}  color='#000'sx={{position:'absolute',right:'0.5rem',top:'0.5rem',cursor:'pointer'}}/>
                   {/* <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                    <Typography>Map</Typography>
                    <Switch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={(e)=>{
                        if(modalView ==='map'){
                            setModalView('menu')
                        }
                        if(modalView ==='menu'){
                            setModalView('map')
                        }
                    }} checked={modalView ==='menu'} />
                    <Typography>Menu</Typography>
                </Stack> */}
                {/* <Container >

                </Container> */}
              {/* {(props.modalView ==='musicCart' ) && (   */}
                    <Box sx={{p:1,display:'flex',
                    flexDirection:'column',
                    gap:'0.5rem',
                    fontFamily:'Gilroy,sans-serif', justifyContent:'center'}}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily:'Sofia',fontWeight:'bold'}}>
                           Cart
                        </Typography>
                        {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          
                        </Typography> */}
                        {/* <Box sx={{border:'1px solid #000'}}> */}
                        
                        <Box sx={{display:'flex',flexDirection:'column',border:'1px solid #000',borderRadius:'10px',
                            overflow:'hidden',overflowY:'scroll',minHeight:'100%', height:'100%',p:1}}>
                            { subView === 'cart' && musicCartItems?.length >0 && musicCartItems?.map((p,i)=>{
                                return( 
                                    <Box sx={{position:'relative',display:'flex', flexDirection:'column',p:1, gap:'0.5rem'}} key={i}>
                                        <RemoveCircleIcon className='button-woddle'
                                            sx={{cursor:'pointer'}}
                                            onClick={(e)=>{handleRemoveClick(musicCartItems,p,i)}}/>
                                        <Stack sx={{display:'flex', flexDirection:'row',fontSize:'1rem', justifyContent:'baseline', gap: '0.5rem'}}>
                                            <img src={p?.images ? p?.images: p?.album_pic? p?.album_pic[0]:''}  width='50px'height='auto' alt={`${ p?.name ? p?.name: p?.title? p?.title:''} pic`}/>
                                            <b style={{width:'30%',maxWidth:'150px'}}>
                                                { p?.name ? p?.name: p?.title?p?.title:''}
                                                </b>
                                            <Stack sx={{display:'flex',flexDirection:'row', flex:1,justifyContent:'space-between'}}>
                                             
                                              <span style={{width:'30%', maxWidth:'100px'}}>
                                                {/* &nbsp;( {p?.size?.toUpperCase()} )  */}
                                                </span>  
                                                {/* <b style={{width:'30%',maxWidth:'50px',textAlign:'center'}}>  */}

                                                {/* {p.quantity} */}
                                                {/* </b>
                                                <span style={{width:'30%',maxWidth:'50px',textAlign:'center'}} >
                                                ${p?.price}
                                                </span>
                                                &nbsp;=&nbsp;
                                                 */}
                                                <span style={{width:'30%',maxWidth:'50px',textAlign:'center'}}>
                                                 ${p?.price }
                                                </span>
                                            </Stack>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                    )
                                })
                            }
                       {musicCartTotal(musicCart?.items)>0 ?
                        <Typography sx={{margin:'auto'}}><b>Total:</b>&nbsp;${musicCartTotal(musicCart)}</Typography>
                      :<Typography sx={{margin:'auto'}}>
                            Cart currently empty
                            </Typography> } 
                  
                        {/* {

                        subView === 'shipping' &&
                        <Container sx={{display:'flex', flexDirection:'column',
                            justifyContent:'center'
                        }}>
                             <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily:'Sofia',fontWeight:'bold'}}>
                          Shipping Information
                        </Typography>
                     
                            <CustomerShipping shop={shop} 
                                musicCart={musicCart}
                                setSubView={setSubView} 
                                subView={subView}/>

                        </Container>
                     } */}

                     {
                        subView === 'billing' &&
                        <Container sx={{display:'flex', flexDirection:'column',
                            justifyContent:'center'
                        }}>
                             <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily:'Sofia',fontWeight:'bold'}}>
                                Billing Information
                                </Typography>
                     
                            {/* <CustomerBilling shop={shop} 
                                musicCart={musicCart}
                                setSubView={setSubView} 
                                subView={subView}/> */}

                            <Payment shop={shop} 
                                cart={musicCart}
                                setSubView={setSubView} 
                                subView={subView}
                                 type='music'
                                />
                  
                        </Container>
                     }
                     {
                        subView === 'review' &&
                        <Container sx={{display:'flex', flexDirection:'column',
                            justifyContent:'center'
                        }}>
                             <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily:'Sofia',fontWeight:'bold'}}>
                          Review
                        </Typography>
                     
                            <CartReview 
                                shop={shop} 
                                musicCart={musicCart}
                                setSubView={setSubView} 
                                subView={subView}/>
                  
                        </Container>
                     }
                        </Box> 
                        {/* </Box> */}


                         <b style={{margin:'auto', textAlign:'center'}}>
                            ( Checkout Currently Unavailable )
                        </b>
                          {subView !== 'cart' && <Tooltip title='review cart'>
                                <Button variant='contained' 
                                // disabled={true}
                                //!! disabled={musicCartTotal(musicCart)<=0}
                                sx={{display:'flex',position:'relative',margin:'1rem auto'}}
                                onClick={()=>{
                                       setSubView('cart')
                                 
                                }}
                                >
                             
                                    Review Cart
                              
                            </Button>
                           </Tooltip>}

                          {subView !== 'billing' && <Tooltip title='Checkout currently unavailable'>
                                <Button variant='contained' 
                                // disabled={true}
                                //!! disabled={musicCartTotal(musicCart)<=0}
                                sx={{display:'flex',position:'relative',margin:'1rem auto'}}
                                onClick={()=>{
                                //     if (!musicCart?.shipping_addresses[0] ){
                                       
                                    
                                //     }
                                //    else if(musicCart?.shipping_addresses[0] && musicCart?.billing_info){
                                        handleCheckout()
                                    // }
                                }}
                                >
                                {/* {subView !=='shipping' && subView !=='billing' &&
                                subView !=='review'
                                ?  'Checkout': ''} */}
                                    checkout
                                {/* {subView ==='musicCart' ? 'Checkout' :''} */}
                                {/* {subView ==='shipping' ?  'Billing': ''}
                                {subView ==='billing' ?  'Review' :' '}
                                {subView ==='review' ?  'Submit Order' :''} */}
                            </Button>
                           </Tooltip>}
                         
                       
                        </Box>
                     {/* )} */}
                     
                     
                </Box>
                </Container>
            </Modal>
  )
}

export default CheckoutModal