const createRect = () => {
  return document.createElementNS("http://www.w3.org/2000/svg", "rect");
};

export const setAttributes = (element, attributes) => {
  Object.keys(attributes).forEach((attribute) => {
    element.setAttribute(attribute, attributes[attribute]);
  });
  return element;
};

export const generateDefaultAttributes = ({ width, height, options, round }) => {
  const size = Math.min(width, height);
  return {
    fill: "none",
    x: (width - size + options.thickness) / 2,
    y: (height - size + options.thickness) / 2,
    width: size - options.thickness,
    height: size - options.thickness,
    stroke: options.color,
    "stroke-width": options.thickness,
    "stroke-dasharray": options.dasharray,
    rx: round? 
    Math.max(0, (size / 2) * round - options.thickness / 2):20,
    // rx: 20,
    // ry:  round? 30:0,
    "stroke-linejoin":"round"
  };
};

const createTextPath = ({
  position,
  rotate,
  flip,
  thickness,
  height,
  width,
  round
}) => {
  const size = Math.min(width, height);
  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  const r = ((size - thickness) / 2) * round;
  let transform = "";

  if (rotate) {
    transform += `rotate(${rotate},${width / 2},${height / 2}) `;
  }

  if (flip) {
    transform += `scale(1 -1) translate(0 ${-height}) `;
  }

  path.setAttribute("id", `${position}-text-path`);
  path.setAttribute("transform", transform);
  path.setAttribute(
    "d",
    `
              M${(width - size + thickness) / 2},${
      (height - size + thickness) / 2 + r
    }
              a${r},${r} 0 0 1 ${r},${-r}
              h${size - thickness - 2 * r}
              a${r},${r} 0 0 1 ${r},${r}
            `
  );
  return path;
};
const createText = ({ value, rotate, position, style }) => {
  const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
  const textPath = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "textPath"
  );
  textPath.setAttribute("href", `#${position}-text-path`);
  textPath.setAttribute("text-anchor", "middle");
  textPath.setAttribute("startOffset", "50%");
  textPath.textContent = value;
  textPath.setAttribute("alignment-baseline", "central");
  text.setAttribute("rotate", `-${rotate}`);
  text.setAttribute("style", style || "");
  text.appendChild(textPath);

  return text;
};

const addText = ({
  thickness,
  value,
  svg,
  position,
  height,
  width,
  round,
  style
}) => {
  let rotate = 0,
    flip = false;

  if (position === "right") {
    rotate = 90;
  } else if (position === "bottom") {
    flip = true;
  } else if (position === "left") {
    rotate = 90;
    flip = true;
  }
  svg.getElementsByTagName("defs")[0].appendChild(
    createTextPath({
      position,
      rotate,
      flip,
      height,
      width,
      round,
      thickness
    })
  );
  svg.appendChild(
    createText({
      position,
      rotate,
      value,
      style
    })
  );
};

const addImage = ({
  thickness,
  value,
  svg,
  position,
  height,
  width,
  style
}) => {
  const image = document.createElementNS("http://www.w3.org/2000/svg", "image");
  const size = Math.min(width, height);
  let x = (width - size + thickness) / 2;
  let y = (height - size + thickness) / 2;

  if (position === "top") {
    x += (size - thickness) / 2;
  } else if (position === "right") {
    x += size - thickness;
    y += (size - thickness) / 2;
  } else if (position === "bottom") {
    x += (size - thickness) / 2;
    y += size - thickness;
  } else if (position === "left") {
    y += (size - thickness) / 2;
  }

  image.setAttribute("href", value || "");
  image.setAttribute("x", x);
  image.setAttribute("y", y);
  image.setAttribute("style", style);

  svg.appendChild(image);
};

export const borderExtension = (extensionOptions) => (svg, options) => {
  const { width, height } = options;
  const border = createRect();
  const borderAttributes = generateDefaultAttributes({
    width,
    height,
    options: extensionOptions,
    round: extensionOptions.round
  });

  setAttributes(border, borderAttributes);
  svg.appendChild(border);

  if (extensionOptions.borderInner) {
    const borderInner = createRect();
    const borderInnerAttributes = generateDefaultAttributes({
      width,
      height,
      options: extensionOptions.borderInner,
      round: extensionOptions.round
      
    });

    borderInnerAttributes.x =
      borderInnerAttributes.x -
      extensionOptions.borderInner.thickness +
      extensionOptions.thickness;
    borderInnerAttributes.y =
      borderInnerAttributes.y -
      extensionOptions.borderInner.thickness +
      extensionOptions.thickness;
    borderInnerAttributes.width =
      borderInnerAttributes.width +
      2 * (extensionOptions.borderInner.thickness - extensionOptions.thickness);
    borderInnerAttributes.height =
      borderInnerAttributes.height +
      2 * (extensionOptions.borderInner.thickness - extensionOptions.thickness);
    borderInnerAttributes.rx = Math.max(
      0,
      borderInnerAttributes.rx +
        extensionOptions.borderInner.thickness -
        extensionOptions.thickness
    );

    setAttributes(borderInner, borderInnerAttributes);
    svg.appendChild(borderInner);
  }

  if (extensionOptions.borderOuter) {
    const borderOuter = createRect();
    const borderOuterAttributes = generateDefaultAttributes({
      width,
      height,
      options: extensionOptions.borderOuter,
      round: extensionOptions.round
    });

    setAttributes(borderOuter, borderOuterAttributes);
    svg.appendChild(borderOuter);
  }

  Object.keys(extensionOptions?.decorations).forEach((position) => {
    const decoration = extensionOptions.decorations[position];
    let addDecorationFunction;

    if (decoration.type === "image") {
      addDecorationFunction = addImage;
    } else if (decoration.type === "text") {
      addDecorationFunction = addText;
    }

    addDecorationFunction?.({
      svg,
      position,
      thickness: extensionOptions.thickness,
      value: decoration.value,
      style: decoration.style,
      height,
      width,
      round: extensionOptions.round
    });
  });
};
