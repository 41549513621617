import { Box, Button, Container, Input, Modal, 
    Stack, Typography,InputLabel,TextField  } from '@mui/material'
import React, { useState, createContext ,useContext, useEffect} from "react";
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { QRCodeContext } from "../../context/QRCodeProvider";

import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import * as API from '../../utils/APICalls'
import * as Order from '../../utils/OrderLogic'
import * as helpers from '../../utils/helpers'

import InterestWidget from './InterestWidget';
import QrCode from '@mui/icons-material/QrCode';
import QRFraming from '../../components/QRFraming';
import CreateHMNIStickerAsset from '../../components/CreateHMNIStickerAsset';
import CompileComboPrintfile from './CompileComboPrintfile';
import CompileNametagPrintfile from './CompileComboPrintfile';
import CreatePortholeAsset from './CreatePortholeAsset';
import CompletePrintfile from './CompletePrintfile';



const BuildShirt = (props) => {
    
        const { modalOpen, setModalOpen, defaultQR, setCurrentOrder,
            cart,setCart,currentOrder, orderDefault, shop,storefrontData, 
            buildStep, setBuildStep,currentProduct,
            updateStorefrontData} = useContext(StorefrontContext);
        const item = props.item
        const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
        const [ email, setEmail ] = useState(null)
        const [ loading, setLoading ] = useState(false)
        const [ isBuilding, setIsBuilding ] = useState(false)
        const [ subView, setSubView ] = useState('qr')

        const subViews = ['qr','asset','art','review']
        const stickerList = ["The Gridwalker","Sage","Hello My Name is","GloAun"]
        
        const assetTypes ={
            nameTag:{
                qr_x: '',
                qr_y: '',
                qr_id: '',
                qr_size: '',
                asset_x: '',
                asset_y: '',
                baseArt_url: '',
            },
            Porthole:{
                qr_x: '',
                qr_y: '',
                qr_id: '',
                qr_size: '',
                asset_x: '',
                asset_y: '',
                baseArt_url: '',
            },
        }
        const processes = {
            'the basics':[,],
            'unique QR':[],
            'personalized QR':[],
            'purpose driven':[]
        }
   
    const handleModalClose = () =>{
        if( qrCodeData?._id ){
            cancelBuild()
        }
        // else{
        props.setModalOpen(false) 
    }

    const handleFieldChange = (e) =>{
        setEmail(e.target.value)
    }
   
    const removeItemFromCart = (item) =>{
        let tempCart = cart
        const index = tempCart.indexOf(item);

        // Remove the object from the array at the specified index
        tempCart.splice(index, 1);
        console.log('New Cart:',cart)
              
    }

    const cancelBuild = async (e) =>{
      try{ // e.preventDefault()
        // if(!currentOrder?.isComplete){
        //     removeItemFromCart(currentOrder)
        // }
        if(qrCodeData?._id){
            // if( qrCodeData?.pic_url){
            //     await API.deletePic(qrCodeData?.pic_url)
            // }
            await API.deleteOrder(currentOrder)
            await API.deleteQR(qrCodeData)
        }
        setCurrentOrder()
        setBuildStep(null)
        setCurrentOrder(orderDefault)
        updateStorefrontData({
            ...storefrontData,
            currentOrder,
            buildStep
        })
        // updateQRCodeData()
        await updateQRCodeData(defaultQR)
        setModalOpen(false)
        }catch(err){console.log(err)}
    }

    const generateQRImage = async (e) =>{
        if(qrCodeData?.pin && !qrCodeData?.pic_url){
        //    await API.(qrCodeData)
        }
        
    }
   

     const buildQR = async () =>{
     
         if(qrCodeData?.pin){
           const data = await API.deleteQR(qrCodeData)
         }
        try {
          setLoading(true);
          const hexString = await helpers.generateRandomHex(6);
          // const pic_url =  await saveGeneratedPic()
          console.log('[BuildShirt] shop:', shop)
          if(currentOrder){
              const data = await API.createQR({
                ...qrCodeData,
                  owner: shop?.owner?._id, 
                  name: currentOrder?.itemName +' Asset - ', 
                //   data: `https://qaura.pro/assets/${qrCodeData?._id}`,
                  pin: hexString,
                })
                if(data){
                    console.log('[BuildShirt] BuildQR:', data)
                    await updateQRCodeData(data)
                    await setCurrentOrder({
                        ...currentOrder,
                        qr_id: data?._id,
                        qr_pin: data?.pin,
                        // qr_pic: data?.pic_url,
                    })
                    
                    setBuildStep(2)
                    await updateStorefrontData({
                        ...storefrontData,
                            currentOrder,
                            buildStep
                        })
                    // updateStorefrontData({
                    // ...storefrontData,
                    // buildStep
                    // })
                      
                    }
                    console.log('[buildShirt] [currentOrder]:',currentOrder)
                }
                setLoading(false);

        
        } catch (error) {
        //   setActionError("QR save error occured") 
          setLoading(false);
        }  
  } 
  const showStickerBuilder = () =>{
   if( qrCodeData?.pic_public_id && currentOrder?.qr_id && stickerList.includes(currentOrder?.itemName)){
       if (!currentOrder?.pic_public_id){
           setIsBuilding(true)
       }
       return true
    }
    if( qrCodeData?.pic_public_id && currentOrder?.qr_id && stickerList.includes(currentOrder?.itemName)){
       if (currentOrder?.pic_public_id){
           setIsBuilding(false)
       }
        return false
   }
                   
  }

  const addToCart = async (cart,obj) =>{
    let tempCart = cart?.items
    tempCart?.push(obj)
    const {res} = await API.updateCart({...cart,items: tempCart})
    if (res){
        console.log('[addToCart]--res:',res )
        setCart(res)
        updateStorefrontData({
            ...storefrontData,
            cart
        })

    }
  }

  const createOrder = async () =>{
     const order = await API.createOrder(currentOrder)
     if (order){
        console.log('[ BuildShirt] (Create Order) response:',order)
        // setCurrentOrder(order)
     }
  }
  window.addEventListener('beforeunload', function(event) {
    if(!currentOrder?.isComplete){
        cancelBuild()
    }
});

    useEffect(()=>{
        if(!shop){console.log('****No Shop****')
            return}
        else{
            // if(helpers.itemNameFilter(item,"[ Base ]")){
            if(helpers.itemNameFilter(item,"[ Base ]")){
                console.log('********[Base Shirt Order]********:', currentOrder)
                console.log('********[Base Shirt Order buildStep]********:', buildStep)
                
                // let tempCart = cart
                // tempCart.push(currentOrder)
                // setCart(tempCart)
                // updateStorefrontData({
                //     ...storefrontData,
                //     cart
                // })
                // return
            }
            if(item?.tags.includes('music')){
            //    createOrder()
            return
            }
            else if(!qrCodeData && !helpers.itemNameFilter(item,"[ Base ]") && !item?.tags.includes('music')){
              updateQRCodeData(defaultQR)
              return
            }
            else if(qrCodeData && !qrCodeData?.pin && !helpers.itemNameFilter(item,"[ Base ]") && !item?.tags.includes('music')){
                // if(currentOrder?.cart_id){
                    buildQR()
                    setBuildStep(1);
                    updateStorefrontData({
                        ...storefrontData,
                        buildStep
                    })
                // }
                return
            }
            // if(qrCodeData && qrCodeData?.pin && !qrCodeData?.pic_public_id){
            //   generateQRImage()
            // }
        }
    },[qrCodeData,shop,currentOrder,buildStep])
  return (
      <>
      {!shop?.isActive && <InterestWidget/>}
      {shop?.isActive && <Modal
         open={modalOpen}
         onClose={(e)=>{
            cancelBuild(e)
            handleModalClose()
        }}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
         
     >
             <Container sx={{p:1}}>
                 <Box sx={{ position: 'absolute',
                     display:'flex',
                     flexDirection:'column',
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-50%, -50%)',
                     width: '90%',
                     height:'fit-content',
                     minHeight:'60vh',
                     overflow: 'hidden',
                     overflowY:'scroll',
                     maxHeight:'90vh',
                     // maxHeight:'90vh',
                     maxWidth:'98vw',
                     bgcolor: 'background.paper',
                     border: '2px solid #000',
                     borderRadius:'10px',
                     boxShadow: 24,
                     justifyContent:'center',
                     
                     padding: '1rem',}}>
 
                     <CloseIcon onClick={handleModalClose}  color='#000'sx={{position:'absolute',right:'0.5rem',top:'0.5rem'}}/>
                     
                     <Container sx={{display:'flex',flexDirection:'column',textAlign:'center', 
                            justifyContent:'center', height:'100%',
                            margin:'1rem auto',
                          

                            }}>
                 
                         <Box sx={{position:'relative',display:'flex',flexDirection:'column',
                                textAlign:'center', alignItems:'center', minHeight:'100%',
                                justifyContent:'center', borderRadius: '10px',minHeight:'60vh',
                                border:'1px solid #868686'}}>
                             {!qrCodeData?.pic_public_id && !helpers.itemNameFilter(item,"[ Base ]") && <Typography variant='h4'>
                                 Generating Your {(!helpers.itemNameFilter(item,"[ Base ]") && !item?.tags.includes('music'))?'Unique QR':'Order'}
                             </Typography>}
                           {/* {qrCodeData && <canvas id='canvas3' />} */}
                           {/* {( qrCodeData?._id && !qrCodeData?.pic_public_id ) && <QRFraming  view='build-shirt'/>} */}
                           {  buildStep === 1 && !qrCodeData?.pic_public_id && (!helpers.itemNameFilter(item,"[ Base ]" && !item?.tags.includes('music')) && (!currentOrder?.printFile || currentOrder?.printFile !== null)) && <Container sx={{position:'absolute',display:'flex', flexDirection:'column',
                                maxWidth:'400px', height:'400px',
                                gap:'0.75rem',justifyContent:'center',alignItems:'center',padding:'1rem'}}>

                               <QrCode className='blink' sx={{fontSize:'10rem', position:'absolute'}}/>
                           </Container>
                           }
                           
                           {/* {( qrCodeData?._id && (!currentOrder?.qr_pic || currentOrder?.qr_pic === undefined) && buildStep === 1 && !helpers.itemNameFilter(item,"[ Base ]") ) && <QRFraming  view='build-shirt'/>} */}
                           
                            {( buildStep === 2   && (qrCodeData?._id && !qrCodeData?.pic_public_id && !helpers.itemNameFilter(item,"[ BASE ]"))) && 
                                <QRFraming  view='build-shirt' buildQR={buildQR}/>}
                            
                            {(  buildStep === 2 && qrCodeData?._id && qrCodeData?.pic_public_id && currentOrder?.qr_pic &&
                                stickerList?.includes(currentOrder?.itemName)
                            )&& <CreateHMNIStickerAsset view='build-shirt' qr_pic={currentOrder?.qr_pic }/>}

                            {(  buildStep === 2 && qrCodeData?.pic_public_id && currentOrder?.qr_pic &&
                                helpers.itemNameFilter(item,"Porthole")
                            )&& <CompileComboPrintfile  view='build-shirt' type='porthole'/>}

                            {(  buildStep === 3 &&
                                // helpers.itemNameFilter(item,"[ BASE ]")
                                helpers.itemFieldFilter(item,'tags',"basic")
                            // )&& <CompileNametagPrintfile view='build-shirt'  type='nametag'/>}
                            )&& <CompileComboPrintfile view='build-shirt'  type='base'/>}
                            
                            {(  buildStep === 3 && currentOrder?.qr_pic &&
                                stickerList?.includes(currentOrder?.itemName) &&
                                helpers.itemNameFilter(item,"Hello My Name is")
                            // )&& <CompileNametagPrintfile view='build-shirt'  type='nametag'/>}
                            )&& <CompileComboPrintfile view='build-shirt'  type='nametag plain'/>}

                            {(  buildStep === 3 && currentOrder?.qr_pic &&
                                stickerList?.includes(currentOrder?.itemName) &&
                                 !helpers.itemNameFilter(item,"Hello My Name is")
                            )&& <CompileComboPrintfile view='build-shirt'  type='nametag mod'/>}


                            {( buildStep === 4 && currentOrder?.printFile && currentOrder?._id &&
                              ( stickerList?.includes(currentOrder?.itemName) ||  helpers.itemNameFilter(item,"Porthole") ||  
                               helpers.itemFieldFilter(item,'tags',"basic")) &&  !helpers.itemNameFilter(item,"Hello My Name is")
                            )&& <CompletePrintfile view='build-shirt'  type='standard'/>}
{/*                             
                            {( currentOrder?.printFile && currentOrder?._id &&
                                !helpers.itemNameFilter(item,"Hello My Name is") && helpers.itemNameFilter(item,"Porthole")
                            )&& <CompletePrintfile view='build-shirt'  type='nametag mod'/>} */}

                            {(  buildStep === 4 && currentOrder?.printFile && currentOrder?._id &&
                                helpers.itemNameFilter(item,"Hello My Name is")
                            )&& <CompletePrintfile view='build-shirt'  type='nametag plain'/>}
                            {/* )&& <CreatePortholeAsset view='build-shirt'/>} */}
                            

                           {/* {( qrCodeData?._id && (currentOrder?.qr_pic && currentOrder?.qr_pic !== undefined)) && buildStep === 3   && ! <CompileComboPrintfile  view='build-shirt'/>: <CreatePortholeAsset view='build-shirt'/>} */}

                           
{/*                            
                            {(qrCodeData?.pic_public_id && 
                            currentOrder?.qr_id &&
                            stickerList?.includes(currentOrder?.itemName)
                            )&& <CreateHMNIStickerAsset view='build-shirt'/>} */}

                             <Stack sx={{width:'80%',
                                 margin:'1.5rem auto',
                                 display:'flex', 
                                 flexDirection:'row',
                                 gap:'0.75rem',
                                 justifyContent:'space-evenly'
                                 //  border:'1px solid red'
                                 
                                 }}>
                                 {/* <InputLabel >
                                 </InputLabel> */}
                               
                                  <Button 
                                     sx={{alignSelf:'end'}} 
                                     variant='outlined'
                                     onClick={(e)=>{
                                        cancelBuild(e)
                                    }}
                                    startIcon={<DeleteForeverIcon />}
                                     >cancel</Button>

                                 { ((currentOrder?.printFile && currentOrder?._id && currentOrder?.isComplete &&  currentOrder?.status === 'cart') || item?.tags.includes('music') )&& <Button 
                                     
                                     sx={{alignSelf:'end'}} 
                                        variant='outlined'
                                        // disabled={loading || ( buildStep === 2  && !qrCodeData?.pic_public_id)}
                                        disabled={currentOrder?.type === 'shirt' && currentOrder?.printFile === null}
                                        onClick={async (e)=>{
                                            try{

                                                // if() //!for whne ready to add to cart
                                                console.log('[Next Click] (currentOrder):',currentOrder)
                                                console.log('[Next Click] (qrCodeData):',qrCodeData)
                                                setCurrentOrder({
                                                    ...currentOrder,
                                                    isComplete: true,
                                                    status: 'cart'
                                                })
                                                updateStorefrontData({
                                                    ...storefrontData,
                                                    currentOrder
                                                })
                                                //    if(buildStep === 3){
                                                    //        addToCart(cart)
                                                    //    }
                                                }catch(err){console.log(err)}
                                                if((currentOrder?.isComplete) || item?.tags.includes('music')){
                                                    try{
                                                        const ord = await API.updateOrder(currentOrder)
                                                        if( ord ){
                                                            addToCart(cart,ord)
                                                        }
                                                        setCurrentOrder({})
                                                        setBuildStep(0)
                                                        updateStorefrontData({
                                                            ...storefrontData,
                                                            currentOrder,
                                                            buildStep,
                                                        })
                                                        setModalOpen(false)
                                                    }catch(err){
                                                    console.log(err)
                                                }
                                            }
                                        }}
                                        endIcon={<NavigateNextIcon />}
                                     >next</Button>}
                                     
                              
                             </Stack>
                         </Box>
                     </Container>
                 </Box>
             </Container>
     </Modal>}
    </>
  )
}

export default BuildShirt