import React , { useState, useEffect, useContext } from 'react';
 
let gradVar1 = `rgba(253, 192, 247, 0.3)`
    let gradVar2 = `rgba(255, 234, 0, 0.3)`
    let gradVar3 = `rgba(16, 174, 216,0.3)`
    // let gradVar3 = `hsl(${hue}deg 16.5% 50.49%)`
    let hue = 0
    let gradVar4 = `hsl(${221-hue/5}deg, 50%, 70%)`
  let rotDegree = 45

const colors = {
    
     
      aura:{
        bg:`linear-gradient( ${rotDegree}deg,${gradVar1} 10%, ${gradVar2} 34%,${gradVar3} 50%, ${gradVar4} 90%),linear-gradient(50deg, #ba4aff, rgba(	186, 74, 255,0 ) 70%),linear-gradient(178deg,#008aff, rgba( 0, 138, 255, 0 ) 50%),linear-gradient(24deg,#00ffc6, rgba(	0, 255, 198, 1) 35%) no-repeat`,
        tx:'rgba(0, 0, 0,1)',
        txAccent:'rgba(0, 0, 0,1)',
        },
      dark:{
        bg:'rgba(0, 0, 0,0.9)',
        tx:'rgba(0, 0, 0,1)',
        txAccent:'rgba(255, 255, 255,1)',
    },
      light:{
        bg:'rgba(250, 241, 227,0.9)',
        tx:'rgba(0, 0, 0,1)',
        txAccent:'rgba(0, 0, 0,1)',
    },
     custom:{
        bg:'',
        tx:'rgba(0, 0, 0,1)',
        txAccent:'rgba(0, 0, 0,1)',
        },
      none:{
        bg:'',
        tx:'rgba(0, 0, 0,1)',
        txAccent:'rgba(0, 0, 0,1)',
        },
     
    }

  export default colors