import { Container, Modal,Box, Button, Input, Typography, TextField, Stack } from '@mui/material'
import React, { useState } from 'react'
import CloseButton from '../../../components/CloseButton'

const ChatModal = (props) => {
     const { chatModalOpen, setChatModalOpen  } = props
  return (
     <>
        <Modal 
            open={chatModalOpen}
            onClose={()=>setChatModalOpen(!chatModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    backgroundColor:'rgba( 0, 0, 0, 0.8)',
                    backdropFilter:'blur(5px)',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem',
                    color:'#FFF'
                    }}>
                        <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setChatModalOpen} val={chatModalOpen}
                            />
                        </Box>
                         
                        {/* <Typography variant='h2'>
                            Chat currently unavailable.
                        </Typography> 
                        
                        <Typography  variant='caption'>
                            
                            ( Check back soon! )
                        </Typography>
                        */}
                        <Container>
                            <iFrame src="https://tin-can-app.netlify.app/chats" 
                            style={{minWidth:'100%',height:'fit-content',minHeight:'600px',borderRadius:'10px'}}/>
                        </Container>

                        {/* <ChatComponent isStreaming={isStreaming} setIsStreaming={setIsStreaming}/> */}
                    </Box>                   
            </Container>
        </Modal>
        
    </>
  )
}

export default ChatModal