import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { byPrefixAndName } from '@awesome.me/kit-KIT_CODE/icons'

export const socials = 
    {
        'facebook': <FacebookIcon />,
        'instagram':<InstagramIcon />,
        'linkedIn':<FontAwesomeIcon icon="fa-brands fa-linkedin" />,
        'github':<FontAwesomeIcon icon="fa-brands fa-github" />,
        'twitter':<FontAwesomeIcon icon="fa-brands fa-twitter" />,
        'bandCamp':<FontAwesomeIcon icon="fa-brands fa-bandcamp" />,
        'soundcloud':<FontAwesomeIcon icon="fa-brands fa-soundcloud" />,
        'spotify':<FontAwesomeIcon icon="fa-brands fa-spotify" />,
        'apple music':<FontAwesomeIcon icon="fa-brands fa-itunes" />,
        'cashApp':<FontAwesomeIcon icon="fa-brands fa-cashapp" />,
        'venmo':<FontAwesomeIcon icon="fa-brands fa-venmon" />,
        'paypal':<FontAwesomeIcon icon="fa-brands fa-paypal" />,
        'playstation':<FontAwesomeIcon icon="fa-brands fa-playstation" />,
        'xbox':<FontAwesomeIcon icon="fa-brands fa-xbox" />,
    }