import { Box, Button, Stack } from '@mui/material'
import React from 'react'

const TrackDownload = (props) => {
    const {album, track } = props
    const fileName = `${album?.artist}_-_${track?.subtitle? track.name + '' + track?.subtitle : track?.name}.mp3`
    const downloadAudio = () => {
    const link = document.createElement('a');
    link.href = track?.track_url;
            // link.download = `Rex_Entropy_-_${track?.subtitletrack.name}.mp3`;
            link.download = fileName;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
  return (
   <Stack sx={{gap:'0.5rem', justifyContent:'center', alignItems:'center'}}>
    {album?.artist} - {track?.subtitle? track.name + '' + track?.subtitle : track?.name}.mp3
     <Button variant='outlined'
     onClick={downloadAudio}>Download Audio</Button>
   </Stack>
  )
}

export default TrackDownload