import React , { useState, useEffect, useContext, useRef } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, 
  Button, Container, Drawer, Icon, Paper, SwipeableDrawer, Tooltip,Modal, Typography, Stack } from '@mui/material';
import { AppContext } from "../context/AppProvider";

const CurrentLocation = (props) => {
    // const {loc} = props
    const { appData,userData, setUserData, updateAppData} = useContext(AppContext);

    const [  loc, setLoc  ] = useState(userData?.currentLocation)
useEffect(()=>{

},[userData])
  return (
    <Box sx={{cursor:'pointer'}} onClick={props.func} className="button-accent-hover  ">
        <Typography sx={{fontSize:'0.75rem'}}>(click to set)</Typography>
        <Box className='color-grad-green-border blink'
         sx={{display:'flex', flexDirection:'column',justifyContent:'center',margin:'auto',minHeight:'2.5rem',width:'fit-content', padding:'0.25rem 0.5rem',
                border: '3px solid #000', borderRadius:'8px',background:'rgba(0,0,0,0.8)', boxShadow:' 0px 0px 3px 1px rgba(212, 255, 252,0.4), inset 0px 0px 3px 1px rgba(212, 255, 252,0.4)', backdropFilter:'blur(5px)',
                color:'#FFF'}}>
        <Typography style={{color:'rgb(254, 255, 212)'}}><b style={{color:'rgb(212, 255, 213)'}}>Current Location:</b> {loc?.name ? loc?.name:''}</Typography> 
       { loc ?(<Stack >
            <Typography sx={{fontSize:'0.75rem',color:'rgb(212, 255, 252)'}}> {loc?.lat} / {loc?.lon}  </Typography> 
            <Typography sx={{fontSize:'0.75rem',color:'rgb(212, 255, 213)'}}> ( {loc?.city} , {loc?.state} ) </Typography> 
        </Stack>)
        :<Typography  sx={{color:'orange'}}>No location selected</Typography>
        }
    </Box>
    </Box>
)

}

export default CurrentLocation