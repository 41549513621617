import { Box, Divider, Drawer, ListSubheader, Typography } from '@mui/material'
import { AppBar,Link, CardMedia, List, Stack,
    ListItem, ListItemIcon, ListItemText, Modal, Tooltip } from '@mui/material';
import React, {useState, useContext, useEffect} from 'react'
import ExploreIcon from '@mui/icons-material/Explore';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ShareIcon from '@mui/icons-material/Share';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import StarIcon from '@mui/icons-material/Star';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import MenuIcon from '@mui/icons-material/Menu';
import AlbumIcon from '@mui/icons-material/Album';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import MenuContent from './MenuContent';

import * as API from '../../utils/APICalls'

const NavBar = (props) => {
    const { cart, setCart, order, view, setView,
        cartOpen, setCartOpen,menuOpen, setMenuOpen,setSubView,
        modalOpen,setModalOpen,owner ,shop} = useContext(StorefrontContext);
    const [ vCardData, setVCardData ] = useState()
    // const [ cartOpen, setCartOpen ] = useState(false)
    // const [menuOpen, setMenuOpen] = useState(false);

    // const collections = ['basic','unique','personal']
    const collections = props?.shop?.collections
    const {email} =props
    
    const ownerObj ={

    firstName : {on:true, data: shop?.owner?.firstName},
    lastName : {on:true, data: shop?.owner?.lastName},
    organization: {on:true, data: shop?.operating_name},
    email: {on:true, data: shop?.owner?.email},
    phone: {on:true, data: shop?.owner?.phone},
    alt_pic: {on:true, data: shop?.config?.avatar_pic},
    directLink:{on:true, data:['https://parallaxr.shop']},
    musicLink:{on:true, data:['https://music.rexentropy.com']}
    // directLink:{on:true, data:['https://qaura.pro/partners/alix-in-wonderland']}
}
     const fetchVCard  = async(ownerInfo) =>{
        // console.log('fetchvCard persona: ', persona)

        if(owner){
            try{

                const data = await API.fetchVCard(ownerInfo)
               if (data){
                   console.log('fetchvCard data: ', data)
                   setVCardData(data)
                   // setVCardData(data?{...data}:fetchVCard())
                   console.log('fetchvCard VCardData: ', vCardData)
               }else{console.log('unable to fecth vCard')}
            }catch(err){console.log(err)}
        }
    }

    const saveVCard = (data) =>{
    // vCard.saveToFile(`./${persona?.preferredName?.data?persona?.preferredName?.data:persona?.firstName?.data}.vcf`)
        console.log('[save vCard]: ',data)
        downloadToFile(data, `${shop?.campaign_name}-vCard.vcf`, 'text/vcard')
    }

    const downloadToFile = (content, filename, contentType) => {
    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });

    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();

    URL.revokeObjectURL(a.href);
    }

  const toggleDrawer = () => {
      console.log('click')
      setMenuOpen(!menuOpen);
    // setMenuOpen(true);
  };
     let localCart = []

    const handleCartClick = (e) => {
        //TODOShow cart
        // setCartOpen(true)
        console.log("*****[Cart]******:",cart)
        setCartOpen(true)
        setSubView('cart')
    }
    const returnCartAmount =(a)=>{
        let count = 0
        a.forEach((e,i)=>{
            count = count + e?.quantity
        })
        console.log('[Cart Count]:',count)
        return count
    }
    useEffect(() => {
            
    }, [cart])

     useEffect(() => {
        window.scrollTo(0, 0)
    
        if(!vCardData){
            fetchVCard(ownerObj)
        }
    }, [vCardData,owner,shop])

    useEffect(() => {
        // setCartAmount(cart?.length)
        // if(!cart){
            // let loc = localStorage.getItem('cart')
            // if (loc){ 
            //     localCart = JSON.parse(loc)
            //     setCart(localCart)
            // }
            // else{localCart = null
            //     return
            // }
            // console.log('[NavBar] collections:',collections)
        // }
    }, [cart, localCart])
  return (
     <AppBar position="sticky"
        sx={{background:"rgba(255,255,255,1)",//boxShadow:'0px 1px 5px 3px  rgba(0,0,0,0.5)', 
            top: '0px', left:'0px', maxWidth:'100vw', width:'100%',
            minWidth: '300px', height:'2.5rem',alignItems:'center',
            backdropFilter:'blur(6px)', display:'flex',
            borderBottom:'3px solid #000', borderTop:'none',
            flexDirection:' row', justifyContent:'center',borderRadius:'0px 0px 5px 5px',
            // background: 'linear-gradient(0deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.8) 15%, rgba(255,255,255,1) 100%)',
            backgroundColor: '#FFF',
            }}>

            <Tooltip title="Menu" className="button-woddle">  
                <div style={{cursor:'pointer', position: 'absolute', left:'0.5rem',zIndex:9999}} //href="#" 
                    onClick={()=>toggleDrawer(true)}
                    >
                    <MenuIcon sx={{zIndex:9999, color:'#000', width:'3.5rem', cursor: 'pointer'}}/>     
                 
                </div>
            </Tooltip>

            {/* <Tooltip title="See where I'm at!">
                <Box sx={{cursor:'pointer'}} href="#" target='_blank'
                //  onClick={handleMapOpen}
                    >
                    <ExploreIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}}  />     
                </Box>
            </Tooltip> */}

            <Tooltip title="Follow on Instagram" className="button-woddle">
                <Link href="https://www.instagram.com/parallaxrbrand" target='_blank'>
                    <InstagramIcon sx={{zIndex:2, color:'#000', width:'3.5rem', cursor:'pointer'}}  />     
                </Link>
            </Tooltip>

                <Tooltip title="Follow on Facebook" className="button-woddle">
                    <Link href="https://www.facebook.com/profile.php?id=61559339336553" target='_blank'>
                        <FacebookIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                    </Link>
                </Tooltip>

                {/* {view !='home' &&  */}
                <Tooltip title="music" className="button-woddle">

                    <Link href={`https://music.rexentropy.com${email?'/'+email:''}`} target='_blank'>
                        <AlbumIcon className='blink'
                            sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                    </Link>
             
                    </Tooltip>
                    {/* } */}
                    
                {/* {view !='home' && 
                <Tooltip title="Home">

                <Box onClick={()=>setView('home')} sx={{cursor:'pointer'}} >
                    <img src='https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg'
                    height="30rem" 
                    />
                    </Box>
                    </Tooltip>
                    } */}

                <Tooltip title="Copy to clipboard" className="button-woddle">

                    <Link href=""  onClick={(e)=>{
                        e.preventDefault()
                        // let val=ownerObj?.directLink?.data.toString()
                        // let val='https://qaura.pro/storefront/6634372a7d97baf1e077b325'
                        let val= shop?.url
                        console.log('url:',val)
                        
                        // navigator.clipboard.writeText(val);
                        // alert(val,'\nurl copied to clipboard')
                        navigator.clipboard
                            .writeText(val)
                            .then(() => {
                                alert(`${val} successfully copied`);
                            })
                            .catch(() => {
                                alert("something went wrong");
                            });
                        // handleSnackClick()
                        }} >

                    <ShareIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                    {/* <Snackbar
                        anchorOrigin={'bottom'} 
                        open={snackOpen}
                        onClose={handleSnackClose}
                        message="url copied to clipboard!"
                    
                    /> */}
                    </Link>
                </Tooltip>
                <Tooltip title="Download contact vCard" className="button-woddle">

                    <Link href="" label='save contact to phone' onClick={(e)=>{
                        e.preventDefault()
                        console.log('click',vCardData)
                        
                        if(vCardData){
                            saveVCard(vCardData)
                            }
                        }}>
                        <ContactPhoneIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                    </Link>
                </Tooltip>

                 <Tooltip title="Cart" className="button-woddle">
                        <Link sx={{position:'absolute',right:'0.5rem',}}
                        href="#" label='cart' onClick={(e)=>{handleCartClick(e)}}>
                                <Stack sx={{display:'flex', flexDirection:'row'}}>
                                    <b style={{position:'absolute', right:'0.5rem'}}>{cart?.items?.length
                                    // returnCartAmount([...cart])
                                    }</b>
                                    <ShoppingCartIcon sx={{zIndex:2, color:'#000', width:'3.5rem'}} />
                                </Stack>
                        </Link>
                    </Tooltip>
                    <Drawer open={menuOpen} onClose={()=>toggleDrawer(false)}>
                       <MenuContent collections={collections} func={toggleDrawer} menuOpen={menuOpen} />
                    </Drawer>
            </AppBar>
  )
}

export default NavBar