import { Stack,Button,Container } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import EnvRoute from '../../../middleware/envRoute';
const ENDPOINT = EnvRoute('prod'); 

// const socket = io('https://your-render-app-name.onrender.com');


const StreamComponent = (props) => {
  const { isStreaming, setIsStreaming } = props
  // const [ isStreaming, setIsStreaming ] = useState()
  const [ currentStreamId, setCurrentStreamId ] = useState()
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  useEffect(() => {
    const startStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        videoRef.current.srcObject = stream;
        streamRef.current = stream;
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    };

    startStream();

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const startBroadcast = async () => {
    if (!streamRef.current) return;

    const mediaRecorder = new MediaRecorder(streamRef.current, { mimeType: 'video/webm' });
    mediaRecorderRef.current = mediaRecorder
    mediaRecorder.ondataavailable = async (event) => {
      if (event.data.size > 0) {
        const formData = new FormData();
        formData.append('stream', event.data, 'stream.webm');
        
        try {
          await fetch(`${ENDPOINT[0]}/stream`, {
            method: 'POST',
            body: formData
          });
        } catch (error) {
          console.error('Error sending stream data:', error);
        }
      }
    };
    setIsStreaming(true)
    mediaRecorder.start(1000); // Send data every second
  };

  const endBroadcast = async () => {
  if (!mediaRecorderRef.current) return;

  try {
    // Stop the MediaRecorder
    mediaRecorderRef.current.stop();

    // Stop all tracks in the stream
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }

    // Notify the server that the stream has ended
    await fetch(`${ENDPOINT[0]}/end-stream`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ streamId: currentStreamId }), // Assuming you have a streamId
    });

    // Reset state
    setIsStreaming(false);
    streamRef.current = null;
    mediaRecorderRef.current = null;

    console.log('Broadcast ended successfully');
  } catch (error) {
    console.error('Error ending broadcast:', error);
  }
};

  return (
   
      <Stack sx={{justifyContent:'center',gap:'0.5rem',margin:'auto'}}>
          <video ref={videoRef} autoPlay muted />
        {!isStreaming && <Button variant='outlined' onClick={startBroadcast}>Start Broadcasting </Button>}
          {isStreaming && <Button variant='outlined' onClick={endBroadcast}>End Broadcasting </Button>}
        </Stack>
 ); 
};

export default StreamComponent;