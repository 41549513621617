import React , { useState, useContext, useEffect } from 'react'
import { Box, Divider, Paper, Typography } from '@mui/material'
import {  List, Stack,ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { StorefrontContext, }  from "../context/StorefrontProvider";
import ProductCard from './storefrontComponents/ProductCard';
// import * as helpers from '../../utils/helpers'
import {QauRaTagFilter} from '../utils/helpers'

const CollectionView = (props) => {
    const { modalOpen, setModalOpen,cart,
        setCart,order, view, setView, subView , setSubView} = useContext(StorefrontContext);
    const items = props.items
    const collection = props?.collection//shop?.collections?.filter((collection)=> collection?.name?.toString() === subView)[0]
    const header_pic = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg'
    const [newMap, setNewMap] = useState([])
   
    const catMap = async () =>{
      try{
        console.log('[catMap] [Collection]:', collection)
        console.log('[catMap] [items]:', items)
        
        const arr = items?.map((item,i)=>{
          
          console.log('[catMap] [item]listing-------:', item?._id)
          if(collection?.items?.includes(`${item?._id}`) === true){
            console.log('[catMap] [item] IN LIST?-------:', item?._id)
           
            return item
        }
      
      })
        if(arr?.length > 0){
            await setNewMap(arr)
            console.log('[catMap] Category:',arr)
          return arr
        }else{ 
          console.log('[catMap]----No items in collection-----')
          return
        }
      }catch(err){console.log(err)}
    }
   
    useEffect(()=>{
    //    if(collection?.name !== subView){
    //   catMap()
    // }
    },[subView])
    // const qauRaTagFilter = (text,string='QauRa')=>{

    //   // let newText = text
    //         if(text.includes(string)){  
    //            const regex = new RegExp(`\\b${string}\\b`, 'gi');
    //          return text.replace(regex, <b> <a  href="https://qaura.pro"  target="_blank" style={{textDecoration:'none'}}>QauRa</a></b>)
    //         }
    //  else{ return text }

    // }
    
    
  return (
     <Box  sx={{minWidth:'250px',width:'100%',fontFamily:'Gilroy, sans-serif', padding:'0.5rem'}}>
        {/* <Typography  variant='h3' sx={{textAlign:'left'}}>Menu</Typography>   */}
        <Box sx={{margin:'auto', alignItems:'center', textAlign:'center',
        display:'flex',width:'100%'}}>
          <Stack sx={{textAlign:'center',margin:'auto',gap:'0.5rem',padding:'0.5rem'}}>

            <h1 variant='h2' style={{margin:'auto',textTransform:'capitalize'}}>
              {/* {subView} */}
              {collection?.name}
            </h1> 
            {collection?.tagline && 
              <Typography variant='p'
                // ref={collectionTag}
                sx={{width:'70%', minWidth:'300px',margin:'auto'}}>
                {/* {QauRaTagFilter(collection?.tagline)} */}
                 <QauRaTagFilter text={collection?.tagline} />

              </Typography> 
            
              }
          </Stack>
        </Box>
        <Divider />
        <Box sx={{display:'flex',flexDirection:'row',width:'100%',flexWrap:'wrap',justifyContent:'space-evenly', gap:'0.5rem'}}>

        {items && items?.map((ele,i)=>{

          //  console.log('collection test',collection)
          
           if(collection?.items?.includes(`${ele?._id.toString()}`) === true){
            // console.log('[collection items]:',ele?.name)
            return <ProductCard key={i} item={ele} size={'md'}/>
           }else{return}
        })}
        </Box>
    </Box>
  )
}

export default CollectionView