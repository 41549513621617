import React, {useState, useContext, useEffect } from "react";
import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";
import { borderExtension } from "../extensions/QRextensions";


import extensionOptions from "./GeneratedQR"

import axios from "axios";
import QRCodeStyling from "qr-code-styling";
import LogoCopy from "./LogoCopy";

const RedirectHeader = (props) => {
   
    const { userData, appData, updateAppData,modalOpen } = useContext(AppContext);
    useEffect(()=>{

    },[userData,appData])
  return (
    <div 
    className= "color-grad-blue-border "
    style={{
        position:'sticky',
        top:0,
        left:0,
        display: 'flex',
        flexDirection: 'row',
        // display: 'grid',
        // gridTemplateRows: '6dvh',
        // gridTemplateColumns:'15% 15% 50% 20%', 
        padding:'.5rem',
        backgroundColor:"rgba(0,0,0,.4)",
        // background: `linear-gradient(0deg, rgba(0, 0, 0, 0) 0% ,rgba(0, 0, 0,0.7) 60%, rgba(0,0,0,0.8) 100%)`,
         background: 'linear-gradient(45deg,rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.3)10%, rgba(255,255,255,0.4)15%, rgba(255,255,255,0.2)23%, rgba(255,255,255,0.2)45%, rgba(255,255,255,0.3)50% ,rgba(255,255,255,0.4)53%, rgba(255,255,255,0.1)83%, rgba(255,255,255,0.3)90%, rgba(255,255,255,0.2)93%, rgba(255,255,255,0.7)94%, rgba(255,255,255,0.3)96%, rgba(255,255,255,0.1)100%)',
        //  backdropFilter: 'blur(15px)',
        // width: '100%',
        maxWidth: '100%',
        height:"7dvh",
        maxHeight:"7dvh",
        minHeight: "72px",
        color:"#fff", alignItems:'center', justifyItems:"end", 
        boxShadow:'0px 0px 10px 5px rgba(0,0,0,0.7)',
        backdropFilter: 'blur(3px)',
        WebkitBackdropFilter: 'blur(3px)',
        // , inset -5px -10px 10px 10px rgba(221, 253, 251, 1)',
        border: "none",
        borderBottom: '3px ridge #868686',
        alignItems:'center',
        justifyContent: 'space-evenly',
        flex: 1,
        zIndex:99999,
        }}
        >
       <button className="button save-button" 
            style={{fontSize:'0.75rem',display:'flex', flexDirection:'column',color:"#000000",height: 'auto', padding:'0.25rem',borderRadius:'0 5px',position: 'absolute',top:'10px',left: '10px', alignItems:'center'}}
                // onClick={()=>{setModalOpen(!modalOpen)}}
                onClick={()=>{
                  if(userData?._id){
                    window.location.assign(`/dashboard/${userData?._id}`)
                  }else{
                    window.location.assign("/")
                  }
                }}
                type="submit" >
                <img src={'/assets/home.svg'} style={{height:'40px', alignSelf: 'center'}} alt="New QR"/>
            home
            </button>

        {/* <div style={{ display: 'flex',flexDirection:'column', flex:3, alignSelf:'center'}}>
            <h1 className="text-shadow-02 blink-2">QauRa</h1>
            <h6>( QR based marketing tool )</h6>
            <span>
            <h5 className="text-shadow-01"style={{color:'rgba(35,40,53,1)'}}>
                  
                  Expand Your Brand, 
                </h5>
                  </span>  
            <h5 className="text-shadow-01"style={{color:'rgba(35,40,53,1)'}}>
              <span>
                Grow Your Glow
                  </span>  
                </h5>
        </div> */}

        <LogoCopy />
 {/* <div > */}
        {/* style={{flex:'1', maxWidth:'200px',alignSelf: 'right'}}> */}
           <button 
                className="button save-button"
                type="submit" 
                onClick={()=>{
                    updateAppData({
                        modalOpen:!modalOpen,
                        modalView:'info'
                    })
                }}
                style={{position: 'absolute',top: '10px', right:'25px',fontSize:'0.75rem',display:'flex', flexDirection:'column',color:"#000000"}}>
                <img src={'/assets/InfoPage.svg'} style={{height:'35px', alignSelf: 'center'}} alt="about QauRa"/>
                about
            </button>
        {/* </div> */}
        
    </div>
  )
}

export default RedirectHeader