import { Container, Modal,Box, Button, Input, Typography, TextField, Stack } from '@mui/material'
import React, { useState, useContext,useEffect,useRef } from 'react'
import CloseButton from '../../../components/CloseButton'
import StreamComponent from './StreamComponent'
import { AppContext } from "../../../context/AppProvider";
import Login from '../../../components/auth/Login';
import io from 'socket.io-client';
import WebRTCComponent from './WebRTCComponent';

const StreamModal = (props) => {
    const { artist, streamModalOpen, setStreamModalOpen, 
        listener,
        isStreaming, setIsStreaming  } = props
     const { appData,userData, setUserData,actionText, setActionText} = useContext(AppContext);
const [ showLogin, setShowLogin ] = useState(false)
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const peerConnectionRef = useRef();
  const socketRef = useRef();

    useEffect(()=>{

    },[userData])
  return (
     <>
        <Modal 
            open={streamModalOpen}
            onClose={()=>setStreamModalOpen(!streamModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100vh',
                    overflow: 'hidden',
                    overFlowY:'scroll',
                    backgroundColor:'rgba( 0, 0, 0, 0.8)',
                    backdropFilter:'blur(5px)',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem',
                    color:'#FFF',
                    }}>
                        <Box sx={{position:'absolute', top:'1.5rem', right:'1rem'}}>
                            <CloseButton func={setStreamModalOpen} val={streamModalOpen}
                            />
                        </Box>
                      <Container sx={{display:'flex',
                         flexDirection:'column', justifyContent:'center', gap:'0.5rem'}}>
                        { !isStreaming && ( 
                            <Stack sx={{position:'sticky', top:'0', }}>
                                <Typography variant='h3'>
                                    Stream currently unavailable.
                                </Typography>
                                <Typography  variant='caption'>
                                    
                                    ( Check back soon! )
                                </Typography>
                            </Stack>
                        )}
                        {!userData?._id && <Box
                        className='color-grad-blue-border'
                            sx={{
                                margin:'auto',
                                // position:'absolute',
                                // top:'50%',
                                // left:'50%',
                                // transform: 'translate(-50%,-50%)',
                                zIndex:999999,
                                width:'60%',
                                maxWidth:'400px',
                                backgroundColor:'rgba(255,255,255,0.7)',
                                border:'2px solid #000',
                                borderRadius:'10px',padding:'0.5rem'
                                }}>
                                
                                    {showLogin?<Login view='stream'/>:
                                    <Box sx={{cursor:'pointer'}}
                                    onClick={()=>setShowLogin(!showLogin)}
                                    >
                                     <Typography>
                                        Host?
                                        </Typography>   
                                   (click to login)
                                    </Box>}
                                </Box> 
                            }
                        {/* {artist?.owner === userData?._id &&  */}
                        <Container sx={{position:'relative',padding:'0.5rem'}}>
                            {/* <StreamComponent isStreaming={isStreaming} setIsStreaming={setIsStreaming}/> */}
                            <WebRTCComponent 
                                listener={listener}
                                isStreaming={isStreaming} 
                                setIsStreaming={setIsStreaming}/>
                        </Container>
                        {/* } */}
                        </Container>   

                    </Box>                   
            </Container>
        </Modal>
        
    </>
  )
}

export default StreamModal