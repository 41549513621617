import { Container, Modal,Box, Button,
     Input, Typography, TextField, Stack } from '@mui/material'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';

import CloseButton from '../../../components/CloseButton'

const GameModal = (props) => {
    const { gameModalOpen,setGameModalOpen } = props
  return (
     <>
        <Modal 
            // open={true}
            open={gameModalOpen}
            onClose={()=>setGameModalOpen(!gameModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    backgroundColor:'rgba( 0, 0, 0, 0.8)',
                    backdropFilter:'blur(5px)',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem',
                    color:'#FFF'
                    }}>
                        <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setGameModalOpen} val={gameModalOpen}
                            />
                        </Box>
                         
                         
                       {/* <iframe src="https://subearth.rexentropy.com" title="Embedded React App" /> */}
                       <iframe src="http://subearth.rexentropy.com" title="Embedded React App" />
                  
                    </Box>                   
            </Container>
        </Modal>
        
    </>
  )
}

export default GameModal