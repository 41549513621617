import React, { useEffect, useRef, useState, useContext } from "react";
import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";
import axios from 'axios'
import * as API from '../utils/APICalls'

import EnvRoute from '../middleware/envRoute';

import QRListCard from './QRListCard'
const ENDPOINT = EnvRoute('prod'); 


const QRList = () => {
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    // const [ assets, setAssets ] = useState(false)
    const { QRs,setQRs ,userData,appData, modalOpen, currentAsset, selectedQR} = useContext(AppContext);

   
    const user ={...userData}
    const fetchQRs  = async() =>{
        let temp 
        try{
            const data  = await API.fetchQRs(userData?._id)
            temp = data
            // console.log('QRs: ',QRs)
        }catch(err){console.log('[FetchQRs Error]: ',err)}
        if(temp){
            setQRs(temp)
        }
    }
    
    useEffect(()=>{
       fetchQRs()
    },[selectedQR,appData])


    useEffect(()=>{
        if(!QRs){
           try{
               console.log('[FetchQRs]: QRs',QRs)
               fetchQRs()
               console.log('[QRList] QRs: ',QRs)
           }catch(err){console.log('[FetchQRs Error2]: ',err)}
        }
    },[QRs,qrCodeData])
    
  return (
  
    //  <div style={{height: '100%', borderRadius:'20px',
    //     // padding:'0.25rem',
    //     width: '100%'
    //     }}>
         <div data-slick='{"slidesToShow": 4, "slidesToScroll": 4}'
          style={{display: 'flex',
            flexDirection:'row', width:'100%', padding: '0.15rem', borderRadius:'5px',
            alignItems:'center', overflow:'hidden', overflowX:'scroll'}}>
        {QRs  ? QRs?.map((q,i)=>{
            return( 
            <QRListCard  
            // onClick={()=>{
            //     updateQRCodeData(a)
            // }} 
            qr={q} id={i} key={i} /> )
        }):
             <div id="scroll-container" >  
                    <div id="scroll-text">
                        <h4>fetching QRs...</h4>
                    </div>              
                </div>
            }
    {/* </div> */}
    </div>
  )
}

export default QRList