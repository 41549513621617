import { Container } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const CampaignManager = () => {
  return (
    <Container sx={{display:'flex', flexDirection:'column',textAlign:'center', backgroundColor:'rgba( 256,256,256, 0.1)',
        backdropFilter: 'blur(5px)',gap:'0.5rem',padding:'0.5rem'}}>
      <h2>
        Optimize your brand materials to work <h1>for you</h1> and <h1>with you!</h1>
        </h2>
      <h3>
        Campaigns are only available through paid plans. 
      </h3>
      <h4>
         Contact <Link to='services@rexentropy.com'>services@rexentropy.com</Link> to schedule a consult!
      </h4>
    </Container>
  )
}

export default CampaignManager