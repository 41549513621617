import React, {useState, useContext, useEffect } from "react";
import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";
import { borderExtension } from "../extensions/QRextensions";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import extensionOptions from "./GeneratedQR"

import axios from "axios";
import QRCodeStyling from "qr-code-styling";
import LogoCopy from "./LogoCopy";

const NavBar = () => {
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const { appData, updateAppData,modalOpen, userData } = useContext(AppContext);
  const [cookies, removeCookie] = useCookies([]);
    
    const handleSave = async (e) => {
        e.preventDefault();

        const qrCode = new QRCodeStyling(qrCodeData);
        // qrCode?.applyExtension(borderExtension(extensionOptions))
        await qrCode?.download({ name: "QauraQR", extension: "png" });
        // try {
        //     const response = await axios.post("API_endpoint", qrCodeData);
        //     console.log(response.data);
        // } catch (error) {
        //     console.error(error);
        // }
        };

        useEffect(()=>{
            
        },[userData])
  return (
    <div style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'sticky',
        top: '0',
        left: '0',
        right: '0',
        flex:1,
        // display: 'grid',
        // gridTemplateRows: '6dvh',
        // gridTemplateColumns:'15% 15% 50% 20%', 
        padding:'.5rem',
        backgroundColor:"rgba(0,0,0,.4)",
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0) 0% ,rgba(255,255,255,0.7) 60%, rgba(255,255,255,0.8) 100%)`,
        // background: 'linear-gradient(45deg,rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.3)10%, rgba(255,255,255,0.4)15%, rgba(255,255,255,0.2)23%, rgba(255,255,255,0.2)45%, rgba(255,255,255,0.3)50% ,rgba(255,255,255,0.4)53%, rgba(255,255,255,0.1)83%, rgba(255,255,255,0.3)90%, rgba(255,255,255,0.2)93%, rgba(255,255,255,0.7)94%, rgba(255,255,255,0.3)96%, rgba(255,255,255,0.1)100%)',backdropFilter: 'blur(15px)',
        // width: '100%',
        maxWidth: '100vw',
        height:"8vh",
        minHeight: "72px",
        color:"#fff", alignItems:'center', justifyItems:"end", 
        boxShadow:'0px 0px 10px 5px rgba(0,0,0,0.7)',
        backdropFilter: 'blur(3px)',
        WebkitBackdropFilter: 'blur(3px)',
        // , inset -5px -10px 10px 10px rgba(221, 253, 251, 1)',
        border: "none",
        borderBottom: '3px ridge #868686',
        justifyContent: 'space-evenly'
        }}
        className= "color-grad-blue-border"
        id="navbar"
        >
        <div style={{display:'flex', flexDirection:'row', flex:'1', maxWidth:'200px'}}> 
            <button className="button save-button " 
                // onClick={()=>{setModalOpen(!modalOpen)}}
                onClick={()=>{
                    updateAppData({
                        modalOpen:!modalOpen,
                        modalView:'scan'
                    })
                }}
                type="submit" 
                style={{
                    fontSize:'0.75rem',
                    display:'flex', 
                    flexDirection:'column',
                    alignItems:'center',
                    color:"#000000",
                    width: '50px'}}>
                <img src={'/assets/ScanQR(plain).svg'} style={{height:'40px', alignSelf: 'center'}} alt="menu"/>
           reader
            </button>
            {/* <button className="button save-button  blink" type="submit"  
                style={{display: 'flex', flexDirection: 'column', 
                alignItems:'center', width: '50px'}}
                onClick={handleSave}>
                <img src={'/assets/download.svg'} style={{height:'19px'}} alt="download"/>
                <img src={'/assets/qrcode.svg'} style={{height:'19px'}} alt="QR code"/>
            </button> */}
                {/* QR Code */}
        </div> 
            <LogoCopy />
        {/* <div style={{ display: 'flex',flexDirection:'column', flex:'1',alignSelf:'center'}}>
            <h1 className="text-shadow-02 blink-2">QauRa</h1>
            <h6>(dynamic QR manager)</h6>
              <span>
            <h5 className="text-shadow-01"style={{color:'rgba(35,40,53,1)'}}>
                  
                  Expand Your Brand, 
                </h5>
                  </span>  
            <h5 className="text-shadow-01"style={{color:'rgba(35,40,53,1)'}}>
              <span>
                Grow Your Glow
                  </span>  
                </h5>
        </div> */}

        <div style={{display: 'flex',flex:'1', maxWidth:'200px', flexDirection: 'row', gap: 5,
                alignSelf: 'right', margin:'0',right: '25px',justifyContent:'space-between'}}>
                    <button 
                        className="button save-button "
                        type="submit" 
                        onClick={()=>{
                            updateAppData({
                                modalOpen:!modalOpen,
                                modalView:'info'
                            })
                        }}
                        style={{
                            fontSize:'0.75rem',
                            display:'flex', 
                            flexDirection:'column',
                            color:"#000000"}}>
                        <img src={'/assets/InfoPage.svg'} style={{height:'35px', alignSelf: 'center'}} alt="about QauRa"/>
                        about
                    </button>
            <button 
                className="button save-button "
                type="submit" 
                onClick={()=>{
                    // window.location.assign('/profile')
                    if(!userData || userData?._id === undefined){
                        updateAppData({
                            modalOpen:!modalOpen,
                            modalView:'auth'
                        })
                    }else{
            window.location.assign(`/dashboard/${userData?._id}`)
                    }
                }}
                style={{
                    fontSize:'0.75rem',
                    display:'flex', 
                    flexDirection:'column',
                    color:"#000000"}}>
                    {/* <div style={{fontSize:'0.75rem',display:'flex', flexDirection:'column',color:"#00FF00"}}> */}

                <img src={'/assets/dashboard.svg'} style={{height:'35px', alignSelf: 'center'}} alt="dashboard"/>
               {!cookies?.token? 'login/signup':'dashboard'}
                    {/* </div> */}
               {/* {userData?._id ? <img src={'/assets/dashboard.svg'} style={{height:'25px', alignSelf: 'center'}} alt="dashboard"/>
               :<img src={'/assets/user-solid.svg'} style={{height:'25px', alignSelf: 'center'}} alt="login"/>
               } */}
            </button>
        </div>
    </div>
  )
}

export default NavBar