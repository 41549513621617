import { Box } from '@mui/material'
import React , { useState, useContext, useRef ,useEffect} from 'react'
import { StorefrontContext, }  from "../../context/StorefrontProvider";


const SizeSelector = (props) => {
    const sizes = props?.sizes
    const {currentProduct, currentOrder,selectedSize, setSelectedSize} = useContext(StorefrontContext);
    // const [ selectedSize, setSelectedSize ] = useState('l')
 
    useEffect(()=>{

    },[selectedSize])
    return (
    <Box sx={{display:'flex',flexWrap:'wrap',gap:'0.5rem'}}>
        { sizes?.map((s,i)=>{
            return <Box key={i} sx={{
                    cursor:'pointer', alignItems:'center',
                    display:'flex',margin:'auto',
                    textAlign:'center', fontSize:'0.75rem',
                    width: '1.25rem', height:'1.25rem', justifyContent:'center',
                    backgroundColor: selectedSize === s ? `rgba(0,0,0,0.2)`:'#FFF',
                    color: selectedSize === s ? `#000`:'#000',backdropFilter:'blur(5px)',
                    border: `1px solid ${selectedSize === s? 'blue':'#656565'}`,borderRadius:'3px', 
                    padding:'0.125rem',"&:hover":{border: '1px solid blue'}}}
                    onClick={async (e)=>{
                        setSelectedSize()
                        try{
                            await setSelectedSize(s)
                            console.log('selectedSize: ',await selectedSize)
                        }catch(err){console.log(err)}
                        
                    }}
                    >
                        {s}
                    </Box>
        })}
    </Box>
  )
}

export default SizeSelector