

import { AppProvider, AppContext, AppState } from "../context/AppProvider";
import React, {useEffect, useContext, useState} from 'react'
import SingleAsset from './SingleAsset'
import AssetList from './AssetList'
import { QRCodeContext } from "../context/QRCodeProvider";
import SearchIcon from '@mui/icons-material/Search';
import { Input, InputAdornment, IconButton, Stack } from "@mui/material";

import axios from 'axios'
import EnvRoute from '../middleware/envRoute';

const ENDPOINT = EnvRoute('prod'); 


const Default_QR = require('../defaults/QRRough.json')

const AssetManager = () => {
    // const ENDPOINT = 'http://localhost:5003/api'
    // const ENDPOINT = 'https://qaura-api.onrender.com/api'


    const { appData, updateAppData,modalOpen,setCurrentAsset, currentAsset,assets } = useContext(AppContext);
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    // console.log("(currentAsset?.useFieldMode): ",currentAsset?.useFieldMode)
    const [ useFieldMode, setUseFieldMode ] = useState(currentAsset?.useFieldMode)
    const [ loading, setLoading ] = useState(true)


    // const updateFieldMode = async () =>{
    //     setCurrentAsset({...currentAsset,useFieldMode:!currentAsset?.useFieldMode})
    //     try{
    //         updateAssetDb({...currentAsset,useFieldMode:!currentAsset?.useFieldMode})
    //         console.log('[currentAsset]: ', currentAsset)
    //     }catch(err){console.log(err)}
        
    //     }

    const updateAssetDb = async (obj) =>{
         try{
            const config = {
                headers: {
                "Content-type": "application/json",
                },
            };
            const { data } = await axios.put(
                `${ENDPOINT[0]}/asset/update`,
                {
                    _id: currentAsset?._id,
                    ...obj,
                },
                config
                );
                console.log('[updateAssets]: data', data)
                // setCurrentAsset({...data})
        }catch(err){console.log(err)}   
    }

     
    useEffect(() =>{
        updateQRCodeData({...Default_QR})
        },[])
    useEffect(() =>{

        },[currentAsset,qrCodeData])
  return (
     <div className='asset-manager-container'
        style={{
            display:'flex', 
            flexDirection: 'column',
            // width:'100%', 
            // height:'95%',
            borderRadius:'10px',
            margin: 'auto', 
            padding: '0.5rem',
            alignItems:'center',
            justifyContent: 'center',
            gap:'0.5rem'
            }}>
        {/* UserInfoCard */}
    {/* <section className='user-info-bottom'
        style={{display:'flex', flexDirection: 'row',borderTop:'1px solid #868686'}}
    > */}

    <div style={{display: 'flex', flexDirection:'row',width: '100%',flex:1, alignItems:'center',
        justifyContent:'space-between'}}>

        <span style={{display: 'flex', flexDirection:'row',
        // position:'absolute',left:'1rem',top:'0.75rem', 
        textAlign:'center',cursor: 'pointer',gap:'0.125rem',whiteSpace:'nowrap'}}
            onClick={()=>{setCurrentAsset(false)}}
            >
           {/* {currentAsset &&  */}
           <img className={`${currentAsset?'rot180':'rot-180'}` }
           src={`/assets/arrow-${currentAsset?'up':'down'}.svg`} style={{height:'0.75rem', alignSelf: 'center',opacity:currentAsset?'1':'0.3'}} alt="Clear Current Asset"/>
           {/* } */}
        </span>

        
         <Stack sx={{}}>

         <h4 
        //  className='text-shadow-01 ' 
         style={{color:'#000',zIndex:0, marginBottom:'1rem'}}> 
            {assets?.length}&nbsp;<u>Assets</u>
         </h4>
          <Input
                    // onChange={handleSearchChange}
                      endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          // onClick={()=>toggleEdit(!edit)}
                          // onMouseDown={()=>toggleEdit(!edit)}
                          edge="end"
                        >
                        {/* <BackspaceIcon />  */}
                        <SearchIcon /> 
                        </IconButton>
                        
                      </InputAdornment>
                    }
                    />
        </Stack>

    <div className="manager-add-button"
        style={{
            display: 'flex',
            position: 'relative',
            right: '0.5rem',
            // top: '0.25rem',
            alingSelf:'right',
            width:'fit-content',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '0 0.125rem',
            borderRadius: '5px 10px',
            border: '2px solid #FFFFFF',
            boxShadow: '0px 0px 6px 1px rgba( 0, 0 ,0 ,0.2)',
            padding: '0.25rem',// 0.125rem 0  0.125rem',
            
            backgroundColor: 'rgba(61, 255, 158, 0.628)'}} 
            onClick={()=>{
                updateAppData({
                    ...appData,
                    modalOpen: true,
                    modalView:'create-asset'
                })
            }}
            >
                <img height='25rem' src='/assets/AddAsset.svg' alt='create asset'/>
            </div>
        </div>
        <div className='asset-manager-left'
         style={{
             overflow:'hidden',
            overflowX: 'scroll',
            width: '90%',
            // minWidth:'28px',
            margin:'auto',
            // flex: 1,
            // height:'100%',
            // minHeight:'20rem',
            border:'2px solid #000000', 
            borderRadius: '10px',
            padding:'.25rem',
           backgroundImage:"url(" +'/assets/Bodybg.png' + ")",
            backgroundRepeat:"no-repeat",backgroundSize:"cover",
            boxShadow:'inset 0px 0px 5px 2px rgba(0,0,0,0.6)'}}>
            <AssetList />
        </div>
    {/* </div> */}
    <div style={{margin:'0.5rem auto',width:'100%'}}>

            {currentAsset ? <SingleAsset />:<p className="">no asset selected</p>}
    </div>
            {/* <label forHtml='payload'> link: </label><input type='text' name='payload' value={currentAsset?.payload}/>
            <label forHtml='name'> name: </label><input type='text' name='name' value={currentAsset?.name}/> */}
      
      
    {/* </section> */}
      </div>

    
  )
}


export default AssetManager