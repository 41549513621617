import React, { useState, useContext, useEffect } from "react";
import { QRCodeContext } from "../context/QRCodeProvider";
import axios from "axios";
import QRCodeStyling from "qr-code-styling";
import { AppContext } from "../context/AppProvider";
import * as API from '../utils/APICalls'
import QRFieldComponent from "./QRFieldComponent";

import EnvRoute from '../middleware/envRoute';

const ENDPOINT = EnvRoute('prod'); 
const QRCodeForm = (props) => {
  const view = props.view

  const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
  const { appData, updateAppData,modalOpen, userData, selectedQR, setSelectedQR } = useContext(AppContext);
  
  const [ activeTab, setActiveTab ] = useState("general");
  const [ bgColorType, setBgColorType ] = useState({...qrCodeData?.backgroundOptionsHelper?.colorType});
  const [ dotColorType, setDotColorType ] = useState({single: true, gradient: false});
  const [ cornerColorType, setCornerColorType ] = useState({single: true, gradient: false});
  const [ corneDotColorType, setCornerDotColorType ] = useState("single");
  const [ actionError, setActionError ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ pic_url, setPic_url ] = useState("");




  // const QR_API = `https://qaura-api.onrrender.com/api`
  // const QR_API_LOC = `http://localhost:5003/api`


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const saveGeneratedPic = async () =>{
    try{
      const qrCode = new QRCodeStyling(qrCodeData).getRawData('png');


             const data = new FormData();
               data.append("file", qrCode);
               data.append("upload_preset", "TinCan");
               data.append("cloud_name", "dzxzdsnha");
               fetch("https://api.cloudinary.com/v1_1/dzxzdsnha/upload", {
                 method: "post",
                 body: data,
               })
                 .then((res) => res.json())
                 .then((data) => {
                   setPic_url(data?.url?.toString());
           
                    setSelectedQR({
                        ...selectedQR,
                       pic_url: data?.url?.toString()
                    })
                   updateAppData({
                             ...appData,
                             selectedQR,
                         })
                
                   console.log('[BuildLSAsset]:',data.url);
                  //  setPicLoading(false);
                  return data?.url?.toString()
                 })
                 .catch((err) => {
                   console.log(err);
                  //  setPicLoading(false);
                 });
    }catch(err){console.log(err)}
  }

  const updateQR = async() =>{
      try {
          console.log('----updating from click----', qrCodeData)
          let qrObj ={
            ...qrCodeData,
              data: `https://qaura.rexentropy.com/assets/${qrCodeData?._id}`,
              pic_url: await saveGeneratedPic()
            }
       setLoading(true);

      //  const config = {
      //      headers: {
      //      "Content-type" : "application/json",
      //      //   Authorization: `Bearer ${user.token}`,
      //      },
      //  };
      
       const { data } = await API.updateQRCode({...qrObj})
        
        console.log('update response: ',data)
        // updateQRCodeData(data)
        setLoading(false);
    } catch (error) {
       setActionError("error occured") 
       setLoading(false);
    }
  }
  const handleSave = async() =>{
       try {
         console.log('Save Click', qrCodeData)
        setLoading(true);
        const config = {
            headers: {
            "Content-type": "application/json",
            //   Authorization: `Bearer ${user.token}`,
            },
        };
        const { data } = await axios.post(
            `${ENDPOINT[0]}/qr/create`,
           ...qrCodeData,
            config
        );
        // updateQR()
        updateQRCodeData({...data})
        console.log('Pre-update: ', qrCodeData)
         if ( data?._id &&  data?.data === data_default){
            updateQR()
        }
        console.log('save response: ',data)
      setLoading(false);
    } catch (error) {
        setActionError("error occured") 
        setLoading(false);
    }

    
  }  

  const handleColorTypeChange = (e) => {
      const { name, value } = e.target;
    //   console.log("qrCodeData.backgroundOptionsHelper?.single: ",qrCodeData.backgroundOptionsHelper.colorType.single)
    if (name === 'backgroundOptionsHelper.colorType'){
        if (value === 'single'){
            setBgColorType({single: true, gradient: false});
       
            updateQRCodeData({
                backgroundOptionsHelper:{
                    colorType: bgColorType
                }
            })
        }
        if (value === 'gradient'){
            setBgColorType({single: false, gradient: true});
            updateQRCodeData({
                backgroundOptionsHelper:{
                    colorType: bgColorType
                }
            })
        }
    }
    if (name === 'dotColorType'){
        setDotColorType(e.target.value);
    }
    if (name === 'cornerColorType'){
        setCornerColorType(e.target.value);
    }
    if (name === 'cornerDotColorType'){
        setCornerDotColorType(e.target.value);
    }
      
  };
  const handleGradientTypeChange = (e) => {
      const { name, value } = e.target;
      console.log("qrCodeData.backgroundOptionsHelper?.single: ",qrCodeData.backgroundOptionsHelper.colorType)
    if (name === 'bgColorType'){
        if (value === 'single'){
            setBgColorType({single: true, gradient: false});
            updateQRCodeData({
                backgroundOptionsHelper:{
                    colorType: bgColorType
                }
            })
        }
        if (value === 'gradient'){
            setBgColorType({single: false, gradient: true});
            updateQRCodeData({
                backgroundOptionsHelper:{
                    colorType: bgColorType
                }
            })
        }
        updateQRCodeData({
            backgroundOptionsHelper:{
                colorType: bgColorType
            }
        })
    }
    if (name === 'dotColorType'){
        setDotColorType(e.target.value);
    }
    if (name === 'cornerColorType'){
        setCornerColorType(e.target.value);
    }
    if (name === 'cornerDotColorType'){
        setCornerDotColorType(e.target.value);
    }
      
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if ( name === "backgroundOptions.gradient.rotation" ){
        updateQRCodeData({ 
            backgroundOptions:{
             gradient:{
                rotation: value,
                colorStops: qrCodeData?.backgroundOptions?.gradient?.colorStops
                }
        }}); 
    }
    if ( name === "shape" ){
        updateQRCodeData({ 
            [name]: value,
            backgroundOptions:{
             round: value === "circle" ? 1 : 0,
        }}); 
    if ( name === "dotOptions.type" ){
        updateQRCodeData({ 
            // [name]: value,
            dotOptions:{
             type: value,
        }}); 
    }
    }else{updateQRCodeData({ [name]: value }); }

    if( view==='edit' ){ setSelectedQR({...qrCodeData})}
    if( view==='create' ){ }
}

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    updateQRCodeData({ image: file });
    if( view==='edit' ){ setSelectedQR({...qrCodeData})}

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const qrCode = new QRCodeStyling(qrCodeData);
    await qrCode?.download();

    try {
      const response = await axios.post("API_endpoint", qrCodeData);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const data_default = 'https://qaura.netlify.com/assets/00000001'
//   const data_default = 'https://qaura.rexentropy.com/assets/00000001'

  useEffect(() => {
   console.log("qrCodeData: ",qrCodeData)
   if ( qrCodeData?._id && qrCodeData?.data.toString() === data_default.toString()){
       updateQR()
   }
  }, [qrCodeData,selectedQR])
  

  return (
    <div className='qr-form-wrap' style={{maxHeight:'100%', overflow:'hidden'}}>
     <section className="color-grad-green-border blink"
        style={{display:'flex', flexDirection:'column',width:'89%', minWidth: '250px',
          height: '35dvh',
          minHeight: '300px',
          margin:'1rem auto',padding:'1rem', boxShadow:"0px 0px 10px 5px rgba(223 255 103 / 75%), inset 0px 0px 10px 5px rgba(223 255 103 / 75%)", 
          borderRadius:"10px", gap:'0.5rem',backgroundColor:'rgba(255,255,255,0.7)',
          background: 'linear-gradient(45deg,rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.3)10%, rgba(255,255,255,0.4)15%, rgba(255,255,255,0.2)23%, rgba(255,255,255,0.2)45%, rgba(255,255,255,0.3)50% ,rgba(255,255,255,0.4)53%, rgba(255,255,255,0.1)83%, rgba(255,255,255,0.3)90%, rgba(255,255,255,0.2)93%, rgba(255,255,255,0.7)94%, rgba(255,255,255,0.3)96%, rgba(255,255,255,0.1)100%)',backdropFilter: 'blur(15px)',
          backgroundAttachment: 'fixed',
          backgroundPosition: '0px 0px',
        //   backgroundSize:'cover',
          border:'2px outset rgba(255,255,255,0.5)',
          overflow:'hidden',
          overflowY: 'scroll'}}
        >
           <QRFieldComponent 
                  value={qrCodeData?.name}
                  object={qrCodeData}  name='name'
                  view='create'
                  edit_on= {true}
                  />
    <span //className="button-row"
        style={{display: 'flex', flexDirection:'column',
        alignItems:'center',
        display:"space-between",
        backgroundColor:"rgba(0,0,0,0.3)", 
        borderRadius:'5px', padding:'0.25rem 0.5rem',
        boxShadow: '0px -1px 5px 3px rgba(0, 0, 0, 0.5)',marginBottom: '0.5rem'}}>
        {/* <section className="options-header"> */}
            <div className="settings-nav">
              <div style={{display:'flex', flexDirection:'row',alignItems: 'center'}}>
                <button
                className={activeTab === "general" ? "active" : "blink"}
                onClick={() => handleTabClick("general")}
                >
                General 
                </button>
                <button
                className={activeTab === "dot" ? "active" : "blink"}
                onClick={() => handleTabClick("dot")}
                >
                Dots
                </button>
                <button
                className={activeTab === "corner" ? "active" : "blink"}
                onClick={() => handleTabClick("corner")}
                >
                Corners
                </button>
              </div>
              <div style={{display:'flex', flexDirection:'row',alignItems: 'center'}}>

                <button
                className={activeTab === "border" ? "active" : "blink"}
                onClick={() => handleTabClick("border")}
                >
                Borders
                </button>
                <button
                className={activeTab === "misc" ? "active" : "blink"}
                onClick={() => handleTabClick("misc")}
                >
                Misc
                </button>
            <button className="button save-button blink" 
                    id="save-button" 
                    onClick={() => { 
                      if(!userData?._token){
                     
                           updateAppData({
                            modalOpen:modalOpen,
                            modalView:'login-signup'
                        })
                      }
                        else {
                          handleSave()
                        }
                        }
                    }
                    style={{padding:'0.25rem 0.25rem 0'}}>
                <img src="/assets/save.svg" style={{height:'20px'}} alt="save QR info"/>
            </button>
              </div>
            </div>
        {/* </section>  */}
      </span>
    {/* <section className="options-content">
        
    </section> */}
      {activeTab === "general" && (
        <div style={{overflow:'hidden', overflowY:'scroll'}}>
            <h2>General Options</h2>
            <form  style={{display: 'flex', flexDirection:'row', justifyContent: 'space-evenly'}}>
                <div id='general-form-content' >
                    <div id="general-left">
                        <label>
                            Data:
                            <h6>(url,promo code, email address, etc...)</h6>
                            <input
                            type="text"
                            name="payload"
                            value={qrCodeData?.payload}
                            onChange={handleChange}
                            />
                        </label>
                        <div style={{display: 'flex', flexDirection:'row',justifyContent:'center', gap: 5 }}>

                        <label>
                        Width:
                        <input
                            type="number"
                            name="width"
                            step="5"
                            min="100"
                            max="500"
                            value={qrCodeData.width}
                            onChange={handleChange}
                        />
                        </label>
                        <label>
                        Height:
                        <input
                            type="number"
                            name="height"
                            min="100"
                            max="500"
                            step="5"
                            value={qrCodeData.height}
                            onChange={handleChange}
                        />
                        </label>
                        <label>
                            Margin:
                            <input
                            type="number"
                            name="margin"
                            min="0"
                            max="50"
                            step="1"
                            value={qrCodeData.margin}
                            onChange={handleChange}
                        />
                        </label>  
                        </div>
                        <label>
                            Shape:
                            <select type="select" value={qrCodeData.shape}  defaultValue={"square"} name="shape" onChange={handleChange} >
                                <option  value="square">square</option>
                                <option value="circle">circle</option>
                            </select>
                        </label>  
                    </div> 

                    {/* Other general options form fields */}

                    <div id="general-right" >
                        <label>Background Color Type
                            <select type="select" 
                                defaultValue={ qrCodeData?.backgroundOptions?.colorType?.gradient === true ? "gradient" : "single"} 
                                // value={ qrCodeData?.backgroundOptions?.colorType?.gradient === true ? "gradient" : "single"} 
                                name="backgroundOptionsHelper.colorType" onChange={handleColorTypeChange} >
                                <option  
                                    // default = {!qrCodeData?.backgroundOptions?.colorType?.gradient } 
                                    value="single">single color</option>
                                <option 
                                    // default = {qrCodeData?.backgroundOptions?.colorType?.gradient } 
                                    value="gradient">gradient</option>
                            </select>
                        </label>
                    
                    
                        {(qrCodeData?.backgroundOptionsHelper?.colorType?.single === true && qrCodeData?.backgroundOptionsHelper?.colorType?.gradient === false)&&( 
                            <label>
                                Color:
                                <input
                                    type="color"
                                    name="backgroundOptions.color"
                                    // name="bgColorType"
                                    value={qrCodeData?.backgroundOptions?.color}
                                    onChange={handleChange}
                                    />
                            </label>)}
                        {(qrCodeData?.backgroundOptionsHelper?.colorType?.gradient === true && qrCodeData?.backgroundOptionsHelper?.colorType?.single === false ) && (
                            <div>
                                <div style={{display: 'flex',flexDirection: 'row', width: '100%', justifyContent: 'center', gap:5}}>
                                    <label>   
                                            Color 1:
                                        <input
                                            type="color"
                                            name="backgroundOptionsHelper.gradient.colorStops[0].color"
                                            value={qrCodeData?.backgroundOptions?.gradient?.colorStops[0]?.color}
                                            // value={qrCodeData?.backgroundOptions?.gradient?.colorStops[0]?.color}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label>
                                        Color 2:
                                        <input
                                            type="color"
                                            name="backgroundOptionsHelper.gradient.colorStops[0].color"
                                            value={qrCodeData?.backgroundOptions?.gradient?.colorStops[1]?.color}
                                            onChange={handleChange}
                                            />
                                    </label>
                                </div>
                            <label>Gradient Type
                                <select type="select" defaultValue={'single'} value={qrCodeData?.backgroundOptions?.gradient?.type === 'linear' ?"linear":"radial"} name="bgGradientType" onChange={handleGradientTypeChange} >
                                    <option  value="linear">linear</option>
                                    <option value="radial">radial</option>
                                </select>
                            </label>
                            <label>
                                Rotation:
                                <input
                                type="number"
                                name="backgroundOptions.gradient.rotation"
                                step="1"
                                min="0"
                                max="360"
                                value={qrCodeData?.backgroundOptions?.gradient?.rotation}
                                onChange={handleChange}
                                />
                            </label>
                        </div>
                    )}
                    </div> 
                    </div>  
            </form>
        </div>
      )}

      {activeTab === "dot" && (
        <div>
          <h2>Dot Options</h2>
          <form>
                <label>
                    Shape:
                    <select type="select" value={qrCodeData?.dotOptions?.type}  defaultValue={qrCodeData?.dotOptions?.type} name="dotOptions.type" onChange={handleChange} >
                        <option  value="rounded">rounded</option>
                        <option value="square">square</option>
                        <option value="dots">dots</option>
                        <option value="classy">classy</option>
                        <option value="classy-rounded">classy-rounded</option>
                        <option value="extra-rounded">extra-rounded</option>
                    </select>
                </label>  
            {/* <h4>single color | gradient</h4> */}
           {dotColorType !== "gradient" && ( 
           <label>
              Dot Color:
              <input
                type="color"
                name="dotColorType"
                value={qrCodeData.dotsOptions.color}
                onChange={handleChange}
              />
            </label>)}
            {dotColorType === "gradient" && (
              <div>
                {/* Dot gradient color options */}
              </div>
            )}
            {/* Other dot options form fields */}
          </form>
        </div>
      )}

      {activeTab === "corner" && (
        <div>
          <h2>Corner Options</h2>
          <form>
            <label>
              Corner Color:
              <input
                type="color"
                name="cornerColorType"
                value={qrCodeData.cornersSquareOptions.color}
                onChange={handleChange}
              />
            </label>
            {cornerColorType === "gradient" && (
              <div>
                {/* Corner gradient color options */}
              </div>
            )}
            {/* Other corner options form fields */}
          </form>
        </div>
      )}

      {activeTab === "misc" && (
        <div>
          <h2>Misc Options</h2>
          <form>
              <label>
                Logo:
                <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                />
            </label>
            <label>
                Data Dot Radius:
                <input
                type="number"
                name="dataDotRadius"
                step="0.1"
                min="0"
                max="1"
                value={qrCodeData.dataDotRadius}
                onChange={handleChange}
                />
            </label>
          </form>
        </div>
      )}
      </section>
    </div>
  );
};

export default QRCodeForm;
