import { Box, Divider, Link, Paper, Typography } from '@mui/material'
import React from 'react'

export const HowItWorks = () => {
  return (
    <Box sx={{height:'100%',padding:'0rem', gap:'0.5rem'}}>
        <h2>
            How It Works
        </h2>
      
        <Paper  sx={{height:'100%',padding:'1rem',textAlign:'left',
        maxWidth:'600px',
        width:'80%',margin:'auto'}}>
           <u> <h3 style={{fontFamily:'Gilroy,sans-serif'}}>Unique QR & Personalized QR Assets</h3></u>
        
            <br/>
           <Typography>
                Upon placing your order, we generate the unique *QR-code and associated personalizations for your artwork, that are then combined with the print files for your order, which is then sent off for fullfilment.
            </Typography> 
            <br/>
           <Typography>
                Once your order is received, you will find an insert with your *QR-code printed on it, accompanied by an activation pin for the QR.
            </Typography> 
            <br/>
           <Typography>
                Upon scanning the QR you will be redirected to the <b><Link  href="https://qaura.pro"  target="_blank" style={{textDecoration:'none'}}>QauRa</Link></b> web application that powers the dynamic QR experince.
            </Typography> 
            <br/>
           <Typography>
                If you do not already have an account, you will be prompted to create one for transfering your dynamic QR asset to, and then managing it through.
            </Typography> 
            <br/>
           <Typography>
                If for any reasons you are having issues along the way, please contact us at <Link  href="mailto:support@parallaxr.shop"  style={{textDecoration:'none'}}><b>support@parallaxr.shop</b></Link>
            </Typography> 
           <Typography sx={{fontSize:'0.75rem',marginTop:'0.75rem',textAlign:'end'}}>
              * - Registered trademark of <Link href='https://www.denso-wave.com/en/' target='_blank'>DENSO WAVE INCORPORATED</Link>
            </Typography> 


        </Paper>
    </Box>
  )
}
