import React, {useState, useContext, useEffect } from "react";
import { AppContext } from "../context/AppProvider";
import useWindowDimensions from "../hooks/windowDimensions";

import EditUserInfo from './EditUserInfo'

import * as API from '../utils/APICalls'
import { Container, Divider, List, ListItem } from "@mui/material";
import CloseButton from "./CloseButton";
import { Spinner } from "@chakra-ui/react";

const MetricCard = () => {
    const { appData,updateAppData,userData,currentScanSet,  setCurrentScanSet,
      scans, setScans,modalOpen, follows, setFollows, QRs,setModalOpen ,scanHeaders, setScanHeaders } = useContext(AppContext);
    const [ followHeaders, setFollowHeaders ] = useState()
    const [ cities, setCities ] = useState({}) 
    const [ showCities, setShowCities ] = useState(false) 
    const [ refresh, setRefresh] = useState(false)
    const [ view , setView ] = useState('')
    const [ followsTabs , setFollowsTabs ] = useState()
    const { height, width } = useWindowDimensions();


const fetchScans = async ()=>{
  const data = await API.fetchOwnerScans(userData?._id)
  // console.log('fetchScans data:', data)
  setScans(data)
  updateAppData({...appData,scans})
  setRefresh(false)

  return data
}

const fetchFollows = async ()=>{
  const data = await API.fetchOwnerFollows(userData?._id)
  // console.log('fetchFollows data:', data)
  setFollows(data)
  updateAppData({...appData,follows})

  setRefresh(false)

  return data
}

/*******************
//!---Scan helpers
******************/
const headersFilter = () =>{  
  // let s= [] = scans
  // console.log('headers (scans): ', scans)
  const hdrs = [...new Set(scans?.map(s => s?.qr_id))]
  // console.log('headers: ', hdrs)
  setScanHeaders([...hdrs])
  return hdrs
}
const followsFilter = (arr,elem,func) =>{  
  // let s= [] = scans
  // console.log('headers (arr): ', arr)
  const hdrs = [...new Set(arr?.map(s => s[elem]))]
  // console.log('headers: ', hdrs)
  func([...hdrs])
  if (arr === follows){
    for(let i=0; i < follows?.length; i++){
      setFollowsTabs({...followsTabs,
        [i]:false
      })
    }
  }
  return hdrs
}
const createSets = (arr,field,val)=>{
  const arrSet = arr?.filter(s=> {return s[field] === val})
  console.log('createArrSet: ',arrSet)
  return arrSet
}
const cityListFilter = async (arr,id) =>{  
  // console.log('city (scans): ', scans)
  //  const unq = [...new Set(scans?.map(s=>{
    
    //   })]
  const set = await createSets(arr,'qr_id',id)
  // console.log('city (set): ', set)
  const cts = [...new Set(set?.map(s => s?.geolocation?.city +', '+s?.geolocation?.state))]
  console.log('cities: ', cts)
  if (cities){
    setCities({
      ...cities,
      [id]: [...cts]
    })

  }else(
    setCities({[id]: [...cts]})
  )

  // if(cities){
    // setCities(...cities,cts)
  // }else{setCities(cts)}
  return  cts
  // (
  //   <>
  //     {cities?.map(c=>
  //       <>{c}:</>
  //     )}
  //   </>
  //   )
}
const mostFreq = (arr) =>{
  var mf = 1;
  var m = 0;
  var item;

  for (var i=0; i<arr?.length; i++)
  {      
          for (var j=i; j<arr.length; j++)
          {
                  if (arr[i] == arr[j])
                  m++;
                  if (mf<m)
                  {
                    mf=m; 
                    item = arr[i];
                  }
          }
          m=0;
  }
  return item
}
const scanCount = (arr,key,val) =>{
const count =  arr?.filter(e=>{return e[key] === val})
 return count?.length
}

const qrName = (id)=>{
  let qr = QRs?.find(q=>{
    return q?._id === id 
  })
  return qr?.name
}

useEffect(()=>{

    fetchScans()
    fetchFollows()
    try{
    
        headersFilter()
  
      let arr = followsFilter(follows,'POC_email',setFollowHeaders)
      console.log('follow arr,', arr)
      setFollowHeaders(arr)
      //  setFollowHeaders(arr)
      console.log('follow headers', followHeaders)
   
    }catch(err){console.log(err)}
  
  },[])

useEffect(()=>{
  if(!scans){
    fetchScans()
    // headersFilter()
  }
  if(scans && !scanHeaders){
    headersFilter()
    // setRefresh(!refresh)
  }
},[scans,scanHeaders])

// useEffect(()=>{
  
// },[])

useEffect(()=>{
  // setRefresh(true)
  // setRefresh(false)
},[scans,follows,followHeaders,followsTabs,scanHeaders,cities])

useEffect(()=>{
  let l = Object.keys(cities).length
 if(scans && scanHeaders && !Object.keys(cities).length){
    for( let i = 0 ; i<scanHeaders?.length ; i++)
        { cityListFilter(scans,scanHeaders[i]) }
      }
      console.log('[cityfilterlist]:',cities,'length',l,':',scanHeaders?.length)
    },[])

  return (
    <>
    
    {!refresh && (<Container
    // className='left-content'
        style={{
            position:'relative',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width:'100%',
            // maxWidth:'80vw',
            height: '100%',
            // minHeight:'13rem',
            minHeight:'40vh',
            borderRadius:'10px',
            backgroundColor:'rgba(256,256,256,0.6)',
            backdropFilter: 'blur(5px)',
            boxShadow: 'inset 0px 0px 10px 3px rgba(255, 255, 255, 0.5), 0px 0px 5px 3px rgba(0, 0, 0, 0.3)',
            border:'3px solid #000',
            padding:'0.25rem',
            overflow:'hidden',
            // overflowY:'scroll',
            justifyContent:'center',
            textAlign:'center',
            // margin:'1rem 1rem 1rem 1rem'
            // zIndex:'10'
            gap: '0.5rem',
            margin:'auto'
            
        }}  
        >
          <h2>Insights</h2>
        <div style={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
            <CloseButton func={updateAppData} val={{
                        modalOpen:!modalOpen,
                        modalView:''
                    }} />
        </div>
          {/* <span 
              style={{display: 'flex', flexDirection:'row',
              position:'absolute',left:'0.75rem',top:'1.25rem', 
              textAlign:'center',cursor: 'pointer',gap:'0.125rem',whiteSpace:'nowrap'}}
                  onClick={()=>{setView('')}}
                  >
                <img className={`${view?'rot180':'rot-180'}` }
                src={`/assets/arrow-${view?'up':'down'}.svg`} style={{height:'0.75rem', alignSelf: 'center',opacity:view?'1':'0.3'}} alt="Clear Current QR"/>
              
          </span> */}
<div style={{display:'flex', flexDirection:'row', margin:'auto',alignItems:'center',justifyContent:'center', 
    gap:'0.75rem'}}>
    <span
      className='metric-card-btn button-accent-hover'
      style={{display:'flex', cursor:'pointer', boxShadow:'0px 0px 5px 1px rgba(0,0,0,0.2)',
      border:'2px solid #868686', borderRadius:' 5px', textAlign:'center',backgroundColor: view ==='scans'? 'rgba(146, 251, 181, 0.2)':'rgba(255,255,255,1)',
      padding:'0.25rem'}}
      onClick={(e)=>{
        setView('scans')
        headersFilter()
      }}
      > 
        scans: &nbsp; <b>{scans? scans?.length:<Spinner/>}</b>
      </span>
       

    <span
        className='metric-card-btn button-accent-hover'
        style={{display:'flex',  cursor:'pointer', boxShadow:'0px 0px 5px 1px rgba(0,0,0,0.2)',
        border:'2px solid #868686', borderRadius:' 5px', textAlign:'center',backgroundColor: view ==='follows'? 'rgba(146, 251, 181, 0.2)':'rgba(255,255,255,1)',
        padding:'0.25rem'}}
        onClick={()=> setView('follows') }
        >
        follows: &nbsp;
        <b>{follows? follows?.length:<Spinner/>}</b>
      </span>

      </div>

                
            <div style={{
                possition: 'relative',
                flexDirection:'row',
                display: 'flex',
                // border:'2px solid #fff',
                borderRadius: '0px 0px 20px 20px',
                // borderLeft:'none',
                width: '100%',
                height: '100%',
                // transform:'translate(25%,-180%)',
                // zIndex:'1',
                //  backgroundColor:'rgba(256,256,256,0.3)'
                }}>
                {/* <div style={{flexDirection:'column',
                    display: 'flex',width:'50%',
                   backgroundColor:'none',
                   border: 'none'
                    }}>
                    
                  
                </div> */}
                <div >
                  <img src="/assets/refresh.svg" 
                  className='refresh-button'
                    style={{ cursor:'pointer', position:'absolute',left:'0.75rem',top:'1rem',width:' 2rem'}}
                    onClick={()=>{
                      fetchScans()
                      fetchFollows()
                      setRefresh(true)
                    }}
                    /> 
                </div>
          
{/* <span style={{display:'flex', flexDirection:'row', gap: '0.5rem', fontSize:'0.75rem'}}>
  
  
    </span> */}
    {view && <Container style={{position:'relative',
        display:'flex',flexDirection:'column',flex:1, 
        alignItems:'center',justifyContent:'center',
        height:'80vh',overflow:'hidden',
        borderRadius:'10px',
        //  padding:'0.5rem',
        // paddingTop: scanHeaders?.length >= 3 ?'': view === 'follows'?'1rem':'1rem', 
        border:'2px solid #000', 
        boxShadow: 'inset 0px 0px 10px 3px rgba(0, 0, 0, 0.5)',
        overflowY:'scroll'}}>

       {view === 'scans' && <List style={{ display:'flex', flexDirection:'column',
        listStyle:'none', justifyContent: 'left',textAlign: 'left',margin:'0',
        marginTop:'350vh',width:'80vw', padding:'0.5rem'}} >
      {/* <ul style={{ display:'flex', flexDirection:'column',
        listStyle:'none', justifyContent: 'left',textAlign: 'left',margin:'0',marginTop:'10%',width:'fit-content', padding:'0'}}> */}
       

       
       {scanHeaders?.map((s,i)=>{
        // if( k !== '_id' && k !=='createdAt' && k !== 'updatedAt' && k !=='__v' && k !=='token'&&  k !=='isAdmin'&&  k !=='isOnline'){
          // let cits = cityListFilter(s)
    //  console.log('mostFreq()',cities)
    let thisSet = [...createSets(scans,'qr_id',s)]
    console.log('thisScanSet', thisSet)
          return(
            <ListItem sx={{minWidth:'200px',maxWidth:'400px'}}>
              {i+1}/{scanHeaders?.length}
              <div
            style={{display:'flex',flexDirection:'column',border:'2px solid #000000',
                minHeight:'90px',
              padding: '0.5rem',cursor:'pointer',
            fontSize:'0.75rem',borderRadius: '5px',
            // margin:'0.25rem auto',marginLeft:0,marginRight:0,
            width:'100%',
            alignSelf:'center',
            //boxShadow:'0px 0px 3px 2px rgba(0,0,0,0.3)',
          }} 
          onClick={()=>{
            setCurrentScanSet(thisSet)
            try{
              updateAppData({
              ...appData,
              currentScanSet,
              modalOpen: true,
              modalView:'single-metric'
            }) 
            }catch(err){console.log(err)}
        }}
          className='metric-list-card'// button-accent-hover' 
              key={i}>
             <b style={{width:'100%',margin:'auto',justifyContent:'center'}}> {qrName(scanHeaders[i])}</b> 
             {/* <br/> */}
             <span style={{marginBottom:'0.25rem',fontSize:'0.75rem'}}>
                <b>id:&nbsp;</b>{s} 
              </span> 
             {/* <br/> */}
             <hr/>
             {/* <Divider /> */}
             <div style={{display:'flex', flexDirection:'row', gap:'0.5rem',marginTop: '0.25rem'}}>

             <div style={{display:'flex', flexDirection:'column' }}>
              <b>scans</b>
              {scanCount(scans,'qr_id',s)} 
              </div>

              <div style={{display:'flex', flexDirection:'column' }}>
                <b>top locations</b>
                <div style={{display:'flex', flexDirection:'row', gap: '0.25rem' }}>
                  <span style={{display:'flex', gap:'0.25rem', alignItems:'center'}}>
                      <b>
                      {cities[s]?.length}  
                      </b>
                      {/* {mostFreq(cities)} */}
                      {/* {mostFreq(cityListFilter(scanHeaders[i]))} */}
                      <div style={{cursor:'pointer',display: 'flex', flexDirection:'column',}}>

                      { cities[s]?.map((c,i) =>{
                          return( 
                          <span key={i}>{c}</span>
                          )
                        })}
                        {/* {showCities && cities[s]?.map((c,i) =>{
                          return( 
                          <span key={i}>{c}</span>
                          )
                        })} */}
                        {/* {!showCities && <span>{cities[s][0]? cities[s][0]:''}</span>} */}
                      </div>
                      {/* {cities[s].filter(x => x === c).length} */}
                      <img height='10rem' src='/assets/arrow-trend-up-solid.svg' />
                  </span>
                </div>
              </div>
                  <hr />

             </div>
             <hr/> 
              <span className='metric-list-item' style={{maxWidth: width <= 600? '325px':'100%', display:'flex'}}>
                <b>last scan: &nbsp;</b>
                <div id="hover-scroll-container" >  
                    <div id="hover-scroll-text">
                        {/* <h6>{qr?.payload?qr?.payload:'*no payload set*'}</h6> */}
                       {/* {thisSet ? Date(thisSet[thisSet?.length-1]?.createdAt).toLocaleString():''} */}
                       {thisSet ? thisSet[thisSet?.length-1]?.createdAt?.toLocaleString():''}
                       {/* {Date(scans[scans.length-1].createdAt).toLocaleString()} */}
                    </div>   
            
                </div>
                
              </span> 
              </div>
            </ListItem>
         
          )
        })}
         </List> }

        {view === 'follows' && (<ul 
        // className='center'
         style={{ display:'flex', flexDirection:'column',maxHeight:'100%',height:'100%',
        listStyle:'none', justifyContent: 'center',textAlign: 'left', 
        // marginTop: width <= 600 ? '30rem':'30rem',
        width:'90%', padding:'1rem 0',margin:0,marginTop:'10rem'
        
        }}> 
        { followHeaders?.map((fh,x)=>{

           return (
              <div  key={x}>  
                <span style={{display: 'flex', flexDirection:'row',
                // position:'absolute',left:'1rem',top:'0.75rem', 
                textAlign:'center',cursor: 'pointer',gap:'0.25rem',whiteSpace:'nowrap'}}
                onClick={()=>{
                  // followTabs[fh]= !followTabs[fh]
                setFollowsTabs({...followsTabs,
                  [fh]:!followsTabs[fh]
                })
                }}
                >
                  {/* <br/> */}
                  <div style={{margin:'0.5rem auto'}}>
                    <b>{fh}</b>
                  </div>
              <img className={`${followsTabs[fh]?'rot180':'rot-180'}` }
              src={`/assets/arrow-${followsTabs[fh]?'up':'down'}.svg`} style={{height:'0.75rem', alignSelf: 'center',opacity:followsTabs[fh]?'1':'0.3'}} alt="show hearder content"/>
              {/* } */}
            </span>
        <br/>
        {/* <hr/> */}
        {/* <div style={{border:'1px solid #868686'}}> */}

              {follows?.map((f,j)=>{
            //  let date = 
              return (
                <>
                  { (followsTabs[fh] && fh === f.POC_email )&& <li className='follow-list-card button-accent-hover' 
                          style={{display:'flex',flexDirection:'column',
                          alignSelf:'center',
                          border:'1px solid #000000',padding: '0.5rem',cursor:'pointer',fontSize:'0.75rem',borderRadius: '5px',margin:'0.25rem auto',
                          // marginRight:0,
                          width:'100%',minWidth:'200px',
                          height:'fit-content',minHeigh:'3rem'
                          }} key={j}>
                          
                            <b>{f?.follower_email}:</b>
                            <b> {f?.geolocation? f?.geolocation?.city+', ' +f?.geolocation?.state:''} </b>
                            <p> {Date(f?.createdAt).toLocaleString()} </p>
                          </li>  }
                  </>
                        ) 
                        })}
        </div>
              //  </div>
        )
        })}
                 
                 </ul>)}
            </Container>  }    
                {/* <div style={{textAlign:'left', margin: 'auto', width:'100%', padding:'0.25rem'}}> */}
                    {/* <h3> {user?.preferredName}</h3>
                    <p>{user?.email}</p>
                    <p>{user?.phone}</p> */}
                {/* </div> */}
                
                
            </div>
          



</Container>)}
    </>
  )
}

export default MetricCard