import RedirectHeader from './RedirectHeader';
import { AppProvider, AppContext, AppState } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";

import Modal from './Modal'

import { useParams } from "react-router-dom";
import React, {useEffect, useContext, useState} from 'react'
import * as API from '../utils/APICalls'

import axios from 'axios'

import EnvRoute from '../middleware/envRoute';
import ResetPasswordComponent from './ResetPasswordComponent';


const ResetPassword = () => {
  let { token } = useParams()

  return (
    <div style={{ height:'100%',width:'100%'}}>
        <RedirectHeader /> 
        <div style={{ display: 'flex', flexDirection:'column', height: '100%',minHeight:'60vh', justifyContent: 'center', alignItems: 'center'}}>
            {/* { appData.modalOpen &&
              <Modal confirm={false}>
                <h1 style={{color:"#FFFFFF"}}>{appData?.modalView}</h1>
              
                    { appData?.modalView==='info' && (<LandingPage view ='modal'/> )}
                
              </Modal>} */}
            <div className= "color-grad-blue-border "
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '80%', height: '70%', 
                    border: '3px solid',
                    borderRadius: '20px', 
                    backgroundColor: 'rgba(255,255,255, 0.1)', 
                    backdropFilter: 'blur(3px)',
                    boxShadow: 'inset 0px 0px 10px 5px rgba( 255, 255, 255, 0.4), 0px 0px 20px 15px rgba( 0, 0, 0, 0.5)',
                    padding: '1rem',
                    marginTop: '2rem',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <h1 className='text-shadow-02 blink' 
                    style={{marginBottom: '1.5rem', display: 'flex', flexDirection:'row'}}>
                Password Reset <div style={{content:'',width:'1rem',height:'1rem', borderRadius:'50%',backgroundColor: '#00FF00', boxShadow:'inset 0px 0px 5px 3px rgba(0,0,0,0.5)'}}></div>
                </h1> 
                <div // className='blink'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '20px',
                        width: '95%',height:'50%',
                        minHeight:'300px',
                        backgroundColor: 'rgba( 255,255,255, 0.2)',
                        backdropFilter: 'blur(3px)',
                        border: '3px solid rgba( 0, 0, 0, 1)', 
                        boxShadow: 'inset  0px 0px 15px 10px rgba( 0, 0, 0, 0.4),0px 0px 10px 5px rgba( 255, 255, 255, 0.7)',
                        justifyContent: 'center',
                        gap:'1rem',
                        overflow:'hidden'
                        }}>
  
                     <ResetPasswordComponent view='email link' token={token}/>
      
                </div>
                {/* <h5 style={{margin:'.5rem auto'}}>QR id:</h5>
                <h4 style={{margin:'.25rem auto'}}>{qr_id}</h4> */}
             <div style={{display:'flex', width:'100%', justifyContent: 'center', alignItems: 'center', color:'#000000', margin: '0.5rem auto'}}>
                © 2023 RexEntropyLLC
            </div> 
            </div>
        </div>
    </div>
  )
}

export default ResetPassword