import React, { useEffect, useRef, useState, useContext } from "react";
import { QRCodeContext } from "../context/QRCodeProvider";
import { AppContext } from "../context/AppProvider";

// import QRCodeStyling from "qr-code-styling-expansion";
import QRCodeStyling from "qr-code-styling";

import { borderExtension } from "../extensions/QRextensions";
// const TH_LOGO = require('../public/assets/TownHall_Icon_v1_2')
// import TH_LOGO from '../../public/assets/TownHall_Icon_v1_2.png'

export const GeneratedQR = (props) => {
    // const [ url, setUrl ] = useState("https://RexEntropy.com");
    const view = props.view
    const qr = props.qr
    const [ id, setId ] = useState("0000001");
    const [ fixedUrl, setFixedUrl ] = useState(`http://localhost:3000/${id}`);
    const [ dynamicUrl, setDynamicUrl ] = useState(`https://RexEntropy.com`);
    const [ fileExt, setFileExt] = useState("png");
    const [ size, setSize ] = useState(false)
    // const [ dotType, setDotType] = useState('classy-rounded')
    const [ dotType, setDotType ] = useState('dots')
    const [ bgColor, setBgColor ] = useState('#fff')
    const [ dotColor, setDotColor ] = useState('#000')
    const [ borderColor, setBorderColor ] = useState('#4267b2')

    // const { qrCodeData, updateqr } = useContext(QRCodeContext);

    const { previewQR, setPreviewQR,assetQR } = useContext(AppContext);
    
    const sizes = {
        sm : {width:'150', height:'150'},
        md : {width:'250', height:'250'},
        lg : {width:'350', height:'350'}
    }
    const viewSizing = (s) => {
        const sizes = {
            sm : {width:'150', height:'150'},
            md : {width:'250', height:'250'},
            lg : {width:'350', height:'350'}
        }
        console.log('[viewSizing]:',sizes[s])
        setSize(sizes[s])
        // return sizes[s]
    }

    const ref = useRef(null);
    const extensionOptions = {
            round: qr?.shape ==='circle'?1 :0, // 0 - 1
            thickness: 60,
            // color: "#1513AB",
            //     dasharray: '4 1',
            decorations: {
            // top: {
            //     type: "text",
            //     value: "Powered by QauRa",
            //     style: `font: .75em gilroy; fill: ${qr?.dotOptions?.color};`
                
            //     // style: "font: 30px sans-serif; fill: #1513AB;"
            // },
            // bottom: {
            //     type: "text",
            //     value: "©2023 RexEntropy LLC",
            //     style: `font: .75em gilroy; fill: ${qr?.dotOptions?.color};`
                
            // },
            // left: {
            //     type: "image",
            //     value: "./assets/Rex_Entropy_Icon_Glow.png",
            //     style: "width: 30px;  transform: translate(-15px, -15px);"
            //     // style: "font: 30px sans-serif; fill: #1513AB; letter-spacing: 10px;"
            // },
            // right: {
            //     type: "image",
            //     value:
            //     "./assets/Rex_Entropy_Icon_Glow.png",
            //     style: "width: 30px;  transform: translate(-15px, -15px);"
            // }
            },
            // borderInner: {
            // color: "#000",
            // thickness: 5,
            // // dasharray: '4 1'
            // },
            // borderOuter: {
            // color: "#000",
            // thickness: 7
            // // dasharray: '4 1'
            // }
        };

        // viewSizing('sm')
        
            // setSize({...sizes})
        useEffect (()=>{
            if(!size){
                    setSize(viewSizing('sm'))
            
            }
            // if(view ==='home'){
                
                setPreviewQR({ 
                    ...assetQR,
                        width: "200",
                        height: "200"})
            // }
        },[qr,size])
        // console.log('[sizes]: ', size)
        // setPreviewQR({...qr, width: "200", height: "200"})

        let qrOptions ={
        ...qr,
        // ...size
        // margin:view==='home'?qr.width:view.includes('dashboard')?'10 ':'20', 
        width:view==='home'?qr.width:view.includes('dashboard')?'175':'250', 
        height:view==='home'?qr.height:view.includes('dashboard')?'175':'250'
        }

            let qrCode = new QRCodeStyling({...qrOptions})
        // let qrCode = new QRCodeStyling({...qr,size})
        
        // let qrCode = new QRCodeStyling({...previewQR})
        // setPreviewQR({...qr,size})
        // let prevQrCode = new QRCodeStyling({...previewQR})
        // console.log('[qrCode]: ', qrCode)
        // let qrCode = new QRCodeStyling(qr)
        // qrCode.update(document.getElementById('canvas'));
        
        useEffect(() => {
            // qrCode.applyExtension(extensionOptions)
          // qrCode.applyExtension(extensionOptions)

         
        if(document.getElementById('canvas')){
            let canvas = document.getElementById('canvas')
            // canvas.removeChild(canvas)
            canvas.innerHTML = ''
            // console.log("div.content: ",canvas)
        }
            
        //   qrCode.append(ref.current);
            // console.log("ref.current.id",ref.current.innerHtml)
            
            let updatedQR = qrCode?.applyExtension(borderExtension(extensionOptions));
            
            qrCode?.append(ref.current);
            // prevQrCode?.append(ref.current);
        //   qrCode?.update(document.getElementById("canvas"));
            console.log("ref.current: ", ref.current)
          
        }, [assetQR]);
      
        // useEffect(() => {
        //   qrCode.update({
        //     data: qr?.fixedUrl
        //   });
        //   console.log('qr: ',qr)
        // }, [qr.fixedURL]);
// qrCode.applyExtension(extensionOptions)
        //   borderExtension({
        //     ...extensionOptions,
        //   })
        // );


  return (
      <>
    {/* <div style={{border:`1rem solid ${borderColor}`,borderRadius: 10, width:'fit-content', margin:'auto',padding:'.5rem', boxShadow:"0px 0px 10px 3px rgba(0,0,0,0.6)"}}> */}
    <div style={{ width:'fit-content', margin:'auto',padding:'.5rem', boxShadow:"0px 0px 10px 3px rgba(0,0,0,0.6)",borderRadius:"15px", border: "2px outset", backgroundColor: "#ffff"}} className="color-grad-blue-border blink">
        { !qrCode ?<div style={{display: 'flex', flexDirection: 'column',alignContent:'center', gap: '0.5rem', minWidth:'150px', minHeight:'150px'}}>
                            <div className='scaler'>

                            <img className={'spinner'} src={'../assets/spinner-solid.svg'} 
                            style={{height:'50px', alignSelf: 'center', 
                            marginRight:'0.5rem'}} alt="loading"/>
                            </div>
                            {/* {statusText} */}
                            loading...
                        </div>
                        :<div ref={ref} id='canvas'/>
        
        
        }
    </div>
        {/* <h6>{qr?.payload}</h6> */}
        {/* <p>{qr}</p> */}
      </>
  )
}
export default GeneratedQR
