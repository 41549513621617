import * as React from 'react';
import { useEffect, useRef, useState, useContext } from "react";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import * as vars from '../utils/vars'

import { SignupContext, SignupProvider}  from "../context/SignupProvider";
import AddToCartButton from './AddToCartButton';
import { Snackbar } from '@mui/material';

const SignupOrderOption = (props) => {

    const option = props?.option
    const sizes = option?.sizes
    const prices = option?.prices
    const index = props?.index 
    const userObj = props.userObj

    const { signupData, updateSignupData,
            tempOrder,updateTempOrder, cart,setCart, updateCart } = useContext(SignupContext);
    const [snackOpen, setSnackOpen ] = useState(false)

    const [ size, setSize ] = useState('');
    const [ price, setPrice ] = useState(0);
    const [ quantity, setQuantity ] = useState(null);
    // var size = sizes[0]
    // var quantity 
    const [ sizeOpen, setSizeOpen ] = useState(false);
    const [ quantOpen, setQuantOpen ] = useState(false);
    const [ order, setOrder ] = useState({ })
    const  horizontal = 'center'
    const  vertical = 'top'
    // const [ order, setOrder ] = useState({ item_type: option?.type,size,price,quantity})
     const orderObj = {}
    

    const findObjectByType = (array, type) => {
        return array?.find((obj) => obj?.item_type === type);
    };

    const assessOrder = () => {
        let tempOrders  =  tempOrder
        let sz = size
        // if( (sz === '8oz.' || sz === '16oz.') && Number(quantity) > 0 ){  
        // if( (sz === '8oz.' || sz === '16oz.') && Number(quantity) > 0 ){  
            // setOrder({
            //     item_type: option?.type,
            //     size: size,
            //     quantity: Number(quantity)
            // })
            
        console.log('[assessOrder] Pre - order: ', order)

        tempOrders[index] ={
            item_type: option?.type,
            size: size,
            price: price,
            quantity: Number(quantity)
            } 
            //order

            console.log('[assessOrder] Post - tempOrders: ', tempOrders)
            
            updateTempOrder(tempOrders)
            // updateCart(tempOrders)
            updateSignupData({
                ...signupData,
                order: tempOrder,
               
            })

        console.log('[assessOrder] Post - SignupData: ', signupData)
        
        // }
        // else { console.log('item not complete.')}    

    }

    const compileOrder = () =>{
        let tempArr = []
        if( tempOrder?.length > 0 ){
            tempArr = tempOrder
            console.log('[Old Array]', tempArr)
            tempArr[index] = order
            console.log('[New Array]', tempArr)
            updateTempOrder([...tempArr])
            console.log(' tempOrder',  tempOrder)
            updateSignupData({...signupData,...userObj,
                order: tempOrder
            
            })
        }else{
            updateTempOrder([tempOrder])
            console.log(' tempOrder',  tempOrder)
            updateSignupData({...signupData,...userObj,
                order: tempOrder
            
            })
            
        }
        tempArr = tempOrder
        console.log('[compileOrder] tempArr:', tempArr)
    }
    
    // const handleChange = (event) => {
        
    //     assessOrder()
    //     // assessOrder()
    //     if(orderObj?.size && orderObj?.quantity){
    //         setOrder ({
    //             item_type: option?.type,
    //             size,
    //             quantity: Number(quantity)     
    //         })
            
    //         compileOrder()
    //     }
    // };
    
    const handleSizeChange = (e) => {
       let sz = e.target.value.toString()
       
       let pr = prices[sizes.indexOf(sz)]
        setSize(sz)
        setPrice(pr)
        // setOrder ({...order,
        //     size:e.target.value,
        // })
        console.log('[handleSizeChange] size | price :', size,'| ',price)
        assessOrder()
        // compileOrder()
    }
    
    
    const handleQuantChange = (e) => {
        // const quant = Number(e.target.value)
    //    quantity = e.target.value
        // setQuantity(quant)

        setQuantity(e.target.value)
        // setOrder ({
        //     ...order,
        //     quantity: Number(e.target.value)     
        // })
        console.log('[handleQuantChange] quantity:',quantity)
        assessOrder()
        // compileOrder()
        
    };

    const handleSizeClose = () => { setSizeOpen(false);};

    const handleSizeOpen = () => { setSizeOpen(true);};

    const handleQuantClose = () => { setQuantOpen(false);};

    const handleQuantOpen = () => { setQuantOpen(true);};

     const handleSnackClose  = () =>{ setSnackOpen(false)}
   
    useEffect(() => {
   
        // console.log('orderObj: ',orderObj)

        // if(orderObj?.size && orderObj?.quantity){
        //      assessOrder()
        //     return
        // }

    }, [order,size,quantity,tempOrder,signupData,userObj,cart,snackOpen])
    
    useEffect(() => {
   

    }, [size,quantity])

    useEffect(() => {
   

    }, [snackOpen])
    useEffect(() => {
   

    }, [])
    

  return (
    <Box  spacing={2} sx={{display:'flex', flexDirection:'column', gap:'0.5rem'}} key={index}>
    <Box  spacing={2} sx={{display:'flex', flexdirection:'row',alignItems:'flex-end'}}>
        <Grid item xs={12} sm={6}>

            <InputLabel required>Size</InputLabel>
                <Select
                // labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={sizeOpen}
                onClose={handleSizeClose}
                onOpen={handleSizeOpen}
                defaultValue={''}
                // value={size}
                label="size"
                name="size"
                sx={{width:'80%', minWidth:'100px'}}
                onChange={handleSizeChange}
                    
                >
                <MenuItem value="">
                     <em>None</em>
                </MenuItem> 
                {sizes && sizes?.map((o,j)=>{
                    return(
                        <MenuItem value={o} key={j}><em>{o}</em> &nbsp;| ${option?.prices[j]}</MenuItem>
                    )
                })

                }
            
                </Select>
                </Grid>
                <Grid item xs={12} sm={6} sx={{flex:1}}>
                  <InputLabel required>Quantity</InputLabel>
                <Select
                // labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={quantOpen}
                onClose={handleQuantClose}
                onOpen={handleQuantOpen}
                defaultValue={0}
                // value=eeQuant}
                label="quantity"
                name="quantity"
                sx={{width:'80%', minWidth:'100px'}}
                onChange={handleQuantChange}
                    
                >
                <MenuItem value={0}>
                     {/* <em>0</em> */}
                </MenuItem> 
                {[...Array(11).keys()]?.map((o,j)=>{
                    return(
                        <MenuItem value={j} key={j}><em>{j}</em></MenuItem>
                    )
                })

                }
            
                </Select>
                
                    {/* <TextField
                        required
                        id="outlined-required"
                        type ='number'
                        label="Quantity"
                        name= "quantity"
                        // defaultValue={0}
                        value={quantity}
                        onChange={handleQuantChange}
                        // onChange={e=> setQuantity(e.target.value)}
                        sx={{width:'80%', minWidth:'100px'}}

                        // sx={{flex:1}}
                        InputProps={{ inputProps: { min: 0 , max: 40} }}
                        /> */}

                </Grid>
        </Box>
          <Snackbar
            anchorOrigin={{vertical,horizontal}}
            open={snackOpen}
            autoHideDuration={6000}
            onClose={handleSnackClose}
            message="Added to Cart"
            // action={action}
        >

        </Snackbar>
            <AddToCartButton 
                item={option?.type} 
                size={size} 
                quantity={quantity} 
                price={price}
                snackOpen={snackOpen}
                setSnackOpen={setSnackOpen}
            />
        </Box>
  )
}

export default SignupOrderOption