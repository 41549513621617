import React ,{ useEffect, useRef, useState, useContext } from "react";
import { SignupContext, SignupProvider }  from "../context/SignupProvider";
import { AppBar, Button,Grid, TextField, Box, Container, Stack, } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InquirySendModal from './InquirySendModal';
import ConfirmSignupModal from "./ConfirmSignupModal";

import * as API from '../utils/APICalls'


const CustomerInfoForm = (props) => {
    const [value, setValue] = useState(0);
    const { owner, operating_name, owner_email, campaingName } = props.shop
    const subView = props.subView
    const [ inquiry, setInquiry ] = useState({})
   
    const { signupData, 
        setSignupData,
        updateSignupData, 
        tempOrder,
        setTempOrder,
        updateTempOrder,
        firstName, 
        setFirstName,
        lastName, 
        setLastName,
        email, 
        setEmail,
        phone, 
        setPhone,
        department, 
        setDepartment,
        notes, setNotes, 
        inquiryText, 
        setInquiryText,
        isFollower, tempOrders,
        setIsFollower, cart,setCart,order} = useContext(SignupContext);
    const [ actionError, setActionError ] = useState('')
    const [ isSubmitting , setIsSubmitting ] = useState(false)
    const [ open, setOpen ] = useState(false);


    const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true)

    if( firstName === ''|| lastName === '' ||
        email  === '' || phone  === ''){
            alert('Please complete or re-enter your contact information')
        setIsSubmitting(false)
        }
    else{

        try{
    
            updateSignupData({ ...signupData,
                owner_email,
                owner,
                operating_name,
                campaingName,
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone:  phone,
                department: department,
                isFollower: isFollower,
                inquiry: inquiryText,
                order:[...tempOrder],
                tempOrder,
                cart
            })
            console.log('Submit tempOrder',  tempOrder);
            console.log('signupData', signupData);
            setOpen(!open)
            setIsSubmitting(false)
        }catch(err){
            setIsSubmitting(false)
            setActionError('Order failed to submit')
            console.log(err)  
        }
    }
  };

  const handleBack = () =>{
    props.setSubView('cart')
  }
      const handleInquiryChange = (e) =>{
        setActionError('')
       setInquiryText(e.target.value)
        // console.log('inquiry:', inquiry)

    }
    const handleInquirySubmit =  async(e) =>{
    e.preventDefault(e);
    setIsSubmitting(true)

    // console.log('inquiry:', inquiryText)
    // const data = new FormData(e.currentTarget);
        setInquiry({...inquiry,
            owner_email,
            owner,
            operating_name,
            campaingName,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone:  phone,
            // department: department,
            // firstName: data.get('firstName'),
            // lastName: data.get('lastName'),
            // email: data.get('email'),
            // phone: data.get('phone'),
            // department: data.get('department'),
            isFollower: isFollower,
            inquiry: inquiryText,
            isInquiry: true,
            // tempOrder: tempOrder
        })
    try{
        
        if(inquiry.firstName && inquiry.lastName && inquiry?.email && inquiry?.inquiry){
            
            const inq_res = await API.saveSignup(inquiry)
            // console.log('inq_res pre:',inq_res)
            if( inq_res ){
                // console.log('inq_res true:',inq_res)
                window.location.assign(`/thanks/${owner_email}`)
                //    console.log("inq_res", inq_res )
            }

        }else{setActionError('Please Complete Form')}
        setIsSubmitting(false)

    }catch(err){
        setIsSubmitting(false)

        console.log(err)
    } 

}

    useEffect(()=>{
        setCart(props.cart)
    },[])

  return (
    <Container  sx={{p:1}}>

   <Box component="form" //noValidate 
                // onSubmit={orderView? handleSubmit : handleInquirySubmit } 
                sx={{ mt: 3,p:3,border:'1px solid #000',borderRadius:'10px' }}>
                    <Grid container spacing={2}>
               {subView === 'inquiry' && 
                     <Grid item xs={24} sm={12}>
                <TextField
                        placeholder="What do you have in mind?"
                        name='inquiry'
                        // value={inquiryText}
                        onChange={(e)=>handleInquiryChange(e)}
                        multiline
                        rows={6}
                        // maxRows={10}
                        sx={{width:'100%'}}
                        />
                    </Grid>}
                <Grid item xs={12} sm={6}>
                    <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    onChange={(e)=>setFirstName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    onChange={(e)=>setLastName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                     onChange={(e)=>setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    required
                    fullWidth
                    name="phone"
                    label="Phone"
                    type="phone"
                    id="phone"
                    autoComplete="phone"
                     onChange={(e)=>setPhone(e.target.value)}
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <TextField
                    // required
                    fullWidth
                    name="department"
                    label="Department"
                    type="text"
                    id="department"
                    autoComplete="department"
                     onChange={(e)=>setDepartment(e.target.value)}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <TextField
                    // required
                    fullWidth
                    name="notes"
                    label="Notes"
                    type="text"
                    id="notes"
                    autoComplete="notes"
                     onChange={(e)=>setNotes(e.target.value)}
                    />
                </Grid>
                
                
                <Grid item xs={12} sx={{textAlign:'center'}} >
                    <FormControlLabel sx={{margin:'auto'}}
                    control={<Checkbox value={isFollower} onChange={(e)=>{setIsFollower(!isFollower)}} checked={isFollower} color="primary"/>}
                    label="I want to receive information, marketing promotions and updates via email."
                    />
                </Grid>
                </Grid>

                {subView === 'checkout' &&<Stack sx={{display:'flex', flexDirection:'row', gap:'0.5rem'}}>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{display:'flex',margin:'auto', mt: 3, mb: 2,  }}
                        onClick={handleBack}
                         >
                        Back To Cart
                    </Button>
                    <React.Fragment>
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{display:'flex',margin:'auto', mt: 3, mb: 2,  }}
                        onClick={ handleSubmit } 
                        disabled={isSubmitting?true:false}
                        >
                        {/* Check{isSubmitting?'ing ':''}out */}
                        Review Order
                        </Button>
                        {actionError}
                    
                        {(open && signupData?.email ) && <ConfirmSignupModal open={open}  order={{...signupData,order:[...tempOrder]}} cart={cart} email={email}
                        owner_email={owner_email}
                        operating_name={operating_name}
                        userObj={{firstName, lastName, email, phone, department, inquiryText, isFollower, }}
                        owner={owner}
                        />}
                    </React.Fragment>
                    
                </Stack>}
                {subView === 'inquiry' &&     <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={ handleInquirySubmit } 
                    disabled={isSubmitting?true:false}
                    >
                    Submit{isSubmitting?'ing ':''}
    
                    </Button>}
                <Grid container justifyContent="flex-end">
                <Grid item>
                    {/* <Link href="#" variant="body2">
                    Already have an account? Sign in
                    </Link> */}
                </Grid>
                </Grid>
            </Box>
    </Container>
  )
}

export default CustomerInfoForm