import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { useEffect, useRef, useState, useContext } from "react";
import { SignupContext, SignupProvider }  from "../context/SignupProvider";
import { AppContext } from "../context/AppProvider";

import * as API from '../utils/APICalls'
import axios from 'axios'

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));


export default function InquirySendModal(props) {
    const order = props.order
    const owner= props.owner
    const owner_email = props.owner_email

    const { signupData, updateSignupData, tempOrder, updateTempOrder } = useContext(SignupContext);
   
    const [open, setOpen] = React.useState(props?.open);
    const { appData,userData, updateAppData,modalOpen,setModalOpen ,currentPersona, setCurrentPersona} = useContext(AppContext);
    
    const [ geoData, setGeoData ]  = useState()
    const headerList =['firstName','lastName','email','phone','department']
 
    const getGeoData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log('user geo res.data: ', res?.data)
        setGeoData({...res?.data})
        updateAppData({...appData,geoData})
        console.log('user geodata: ', geoData)
        // if(res?.data){
        //     collectScanData(res?.data)

        // }
        return res.data
    }
    const handleConfirm = () => {
        try{
            let data = API.saveSignup(order)
            if(signupData?.isFollower){

                API.saveFollow({
                    owner: owner,
                    POC_email: owner_email,
                    geolocation:geoData
                })
            }
            setOpen(false);
            if(data){

                window.location.assign(`/thanks/${owner_email}`)
            }else{console.log(data)}
        }catch(err){console.log(err)}
    };
    const handleClose = () => {
        setOpen(false);
    };

       useEffect(()=>{
        if(!geoData){
             getGeoData()
        }
    },[geoData])

     useEffect(() => {
   
        // console.log('orderObj: ',orderObj)
    }, [signupData,tempOrder])
  return (
   
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
       sx={{m:0}}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
         Sending Inquiry!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
           
          </Typography>
          <Typography gutterBottom sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
          
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Update
          </Button>
          <Button autoFocus onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
   
  );
}