import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as helpers from '../../../utils/helpers'
import * as Brands from '@fortawesome/free-brands-svg-icons'
import { Box, Container, Stack, Tooltip, Typography,
  List, ListItem, ListItemText,
  IconButton,
  Button,
 } from '@mui/material'

const SocialLinks = (props) => {
    const { albums, albumNav } = props
       const linkIconCheck = (l) =>{
        // console.log('[linkIconCheck] brands?.some((k)=>l?.includes(k.toString))',brands?.find((k)=>l?.includes(k?.toString())))
        let result = helpers.brands?.find((k)=>l?.includes(k?.toString()))
        if(result){
            return result?.charAt(0).toUpperCase() + result?.slice(1);
        }else if(l?.includes('youtu.be')){
          return 'Youtube'
        }else{ return false}
    }
  return (
    <>
         {helpers.hasValue(albums[albumNav]?.streaming) && (<Box sx={{position:'relative',//bottom:'0px',
                    display:'flex',
                    //border:'1px solid red', 
                        minHeight:'1.5rem',
                        alignContent:'center',
                        gap:'0.5rem',
                        padding:'0.125rem',
                        // justifyContent:'center',
                        width:'100%', margin:'auto'}}>

                    {Object.keys(albums[albumNav]?.streaming).map((l,j)=>{
                     let album_title = albums[albumNav]?.title
                        let link = albums[albumNav]?.streaming[`${l}`]
                        // console.log('album_name:', album_title)
                       if(linkIconCheck(link)){
                            let iconName = linkIconCheck(link)
                            // console.log('iconName:', iconName)
                            return (
                                <Tooltip title={`${iconName} ${album_title =='Baseline'?'( "key of Me" )':''}`}>

                                    <div
                                    style={{
                                        // display:'flex',
                                        color:'#000',  
                                        width:'1.5rem',height:'1.5rem',
                                        '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                        // border:'1px solid blue', 
                                        borderRadius:'3px',
                                        alignContent:'center',
                                        minWidth:'1rem',minHeight:'1rem'}} //key={i}
                                        
                                        key={j}
                                        > 
                                    <a 
                                    style={{
                                        // display:'flex',
                                        color:'#000',  
                                    
                                        // border:'1px solid blue', 
                                        borderRadius:'3px',
                                        alignContent:'center',
                                        minWidth:'1rem',minHeight:'1rem'}} //key={i}
                                        href={link}
                                        target='_blank'
                                        key={j}
                                        > 
                                        {iconName !== 'Pandora' && <FontAwesomeIcon icon={Brands['fa'+ iconName]} key={j} />}
                                        {iconName === 'Pandora' && <img style={{height:'1.2rem'}}
                                        src={'/assets/pandora-icon.svg'} key={j} alt='pandora' />}
                            
                                    </a>
                                    </div>
                                </Tooltip>
                           )
                        }
        

                })}
            </Box>)
                }
    </>
  )
}

export default SocialLinks